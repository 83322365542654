import { observable, computed, action, autorun, makeObservable } from "mobx";
import { SingleDevice } from "../SingleDevice/SingleDevice";

type ConnectingStep = {type: 'connecting', attempt: number} | {type: 'failed', attempt: number} | {type: 'connected'};

export class SingleDeviceConnection {
    connectingStep: ConnectingStep = {type: 'connected'};

    get exists(): boolean {
        return this.connectingStep.type === 'connected';
    }

    get failed(): boolean {
        return this.connectingStep.type === 'failed';
    }

    get attempting(): boolean {
        return this.connectingStep.type === 'connecting';
    }

    get attempts(): number | null {
        if (this.connectingStep.type === 'failed' || this.connectingStep.type === 'connecting') {
            return this.connectingStep.attempt;
        }

        return null;
    }

    static readonly storageKey: string = 'device-connection-attempts';

    constructor(private device: SingleDevice, private storage: Storage) {
        makeObservable(this, {
            connectingStep: observable,
            exists: computed,
            failed: computed,
            attempting: computed,
            attempts: computed,
            initiateAsConnecting: action,
            startConnecting: action,
            failConnecting: action,
            succeedConnecting: action
        });

        autorun(() => {
            if (this.connectingStep.type === 'failed' || this.connectingStep.type === 'connecting') {
                const deviceConnectionAttemptsString = this.storage.getItem(SingleDeviceConnection.storageKey) || "{}";
                const deviceConnectionAttempts = JSON.parse(deviceConnectionAttemptsString);

                deviceConnectionAttempts[device.id] = this.connectingStep.attempt;

                this.storage.setItem(SingleDeviceConnection.storageKey, JSON.stringify(deviceConnectionAttempts));
            }
        });
    }

    initiateAsConnecting() {
        const deviceConnectionAttemptsString = this.storage.getItem(SingleDeviceConnection.storageKey) || "{}";
        const deviceConnectionAttempts = JSON.parse(deviceConnectionAttemptsString);

        this.connectingStep = {type: 'connecting', attempt: deviceConnectionAttempts[this.device.id] || 1};
    }

    startConnecting(): void {
        if (this.connectingStep.type === 'failed' || this.connectingStep.type === 'connecting') {
            this.connectingStep = {type: 'connecting', attempt: this.connectingStep.attempt + 1}
        } else {
            this.connectingStep = {type: 'connecting', attempt: 1};
        }
    }

    failConnecting(): void {
        if (this.connectingStep.type === 'failed' || this.connectingStep.type === 'connecting') {
            this.connectingStep = {type: 'failed', attempt: this.connectingStep.attempt};
        } else {
            this.connectingStep = {type: 'failed', attempt: 1};
        }
    }

    succeedConnecting(): void {
        this.connectingStep = {type: 'connected'};
    }
}
