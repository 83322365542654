import * as React from 'react';
import { BrowserQRCodeReader } from "@zxing/library";
import { CodeScannerProps, CodeScanner, drawImageOnCanvas } from "./CodeScanner";

export class CroppingBrowserQRCodeReader extends BrowserQRCodeReader {
    drawImageOnCanvas(canvasElementContext: CanvasRenderingContext2D, videoElement: HTMLVideoElement) {
        drawImageOnCanvas(canvasElementContext, videoElement);
    }
}

export class QRScanner extends React.Component<CodeScannerProps> {
	render() {
		return <CodeScanner {...this.props} CodeReader={CroppingBrowserQRCodeReader} />;
	}
}
