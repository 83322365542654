import { TimeDomainConfig } from './index';
import { format, setHours, startOfDay, addDays, startOfMonth, endOfMonth, setDate, subYears } from 'date-fns';

export const daily: TimeDomainConfig = {
    getTicks(data) {
        return [3, 9, 15, 21].map(hour => setHours(startOfDay(data[0].time), hour).getTime());
    },
    tickFormatter(tick) {
        return format(new Date(tick), 'HH:mm');
    },
    getDomain(data) {
        return [startOfDay(data[0].time).getTime(), addDays(startOfDay(data[0].time), 1).getTime()];
    },
    tooltipFormatter(tick) {
        return `godz: ${format(new Date(tick as string), 'HH:mm')}`;
    }
}

export const monthly: TimeDomainConfig = {
    getTicks(data) {
        const [start, end] = this.getDomain(data);
        return [+start, ...([10, 20].map(day => setDate(startOfMonth(data[0].time), day).getTime())), +startOfDay(end)];
    },
    tickFormatter(tick) {
        return format(new Date(tick), 'dd');
    },
    getDomain(data) {
        return [startOfMonth(data[0].time).getTime(), endOfMonth(data[0].time).getTime()];
    },
    tooltipFormatter(tick) {
        return `dzień: ${format(new Date(tick as string), 'dd')}`;
    }
}


export const yearly: TimeDomainConfig = {
    getTicks(data) {
        return data.map(d => +d.time)
    },
    tickFormatter(tick) {
        return format(new Date(tick), 'MM');
    },
    getDomain(data) {
        const endDate = new Date(Math.max(...data.map(d => +d.time)))

        return [subYears(startOfMonth(endDate), 1).getTime(), startOfMonth(endDate).getTime()];
    },
    tooltipFormatter(tick) {
        return `miesiąc: ${format(new Date(tick as string), 'MM.yyyy')}`;
    }
}
