import * as React from 'react';
import { Button } from '../../Button';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Paragraph } from '../../Text/Paragraph/Paragraph';
import { Header } from '../../Text/Header/Header';

const Instruction = require('../../../static/icons/connect_your_phone_icon.svg');

export interface StartingInstructionProps {
	onConfirmClick(): void;
	wifiCode: string;
}

export class StartingInstruction extends React.Component<StartingInstructionProps, any> {
	render() {
		return (
			<FullScreenContainer gradient>
				<div>
					<Paragraph>Podłącz swój telefon do sieci WiFi centralki.</Paragraph>
					<Header>Lerta-{this.props.wifiCode}</Header>
					<Instruction className="GatewayWizard-infographic" />
				</div>
				<Paragraph>
					Jest to <strong>tymczasowa sieć centralki nie posiadająca dostępu do internetu</strong>.
					<br />Nie przejmuj się tym jednak i pozostaw swój smartfon połączony z tą siecią mimo to.
				</Paragraph>
				<Button onClick={this.props.onConfirmClick}>Gotowe</Button>
			</FullScreenContainer>
		);
	}
}
