import * as React from 'react';
import { Balloon } from './Balloon';
import './styles.scss';
import { BalloonConfigFactory } from '../../stores/BalloonConfigFactory'

export interface Props {
    applianceName: string;
    appliancePercentage: number;
    maxPercentage: number;
    maxBalloonSize: number;
    minBalloonSize: number;
}

export const ApplianceBalloon = ({ applianceName, maxBalloonSize, appliancePercentage, maxPercentage, minBalloonSize }: Props) => {
    const percentage = appliancePercentage * 100 / maxPercentage;
    const calculatedSize = (percentage * (maxBalloonSize - minBalloonSize) / 100) + minBalloonSize;
    const size = Math.max(calculatedSize, minBalloonSize);
    const config = BalloonConfigFactory.getConfigByName(applianceName)
    const Icon = config.icon

    return (
        <div style={{ minWidth: `15vh` }} className="ApplianceBalloon-container">
            <div className="ApplianceBalloon-content">
                {config.name}
                <div className="ApplianceBalloon-value">
                    {appliancePercentage.toFixed(0)}
                    <span className="ApplianceBalloon-unit">%</span>
                </div>
            </div>
            <Balloon balloonSize={`${size}vh`} stringHeight={`${size}vh`} color={config.color}>
                {Icon && <div className={"ApplianceBalloon-icon " + config.className}><Icon /></div>}
            </Balloon>
        </div>
    );
};
