import * as React from 'react';
import './style.scss';
import {CircularProgress} from "@material-ui/core";
import { Chart } from '../Chart';
import { DeviceData as DeviceDataStore } from '../../stores/DeviceData/DeviceData';
import { daily, monthly } from '../Chart/configs';
import { DateMode } from '../../domain/DateMode';
import { Button } from '../Button';

export interface DeviceDataChartProps {
    deviceData: DeviceDataStore;
}

export class DeviceDataChart extends React.Component<DeviceDataChartProps, any> {
    render() {
        const { deviceData } = this.props;

        return (
            <div className="DeviceData-chart">
                {deviceData.fetching &&
                    <CircularProgress className="DeviceData-loader" size={50} />
                }
                {deviceData.fetchingFailed &&
                    <div className="DeviceData-fetchingFailed">
                        <div>Pobieranie nie powiodło się.</div>
                        <Button inline small onClick={() => this.props.deviceData.fetch()}>Spróbuj ponownie</Button>
                    </div>}
                {deviceData.data.length === 0 && !deviceData.fetching && !deviceData.fetchingFailed &&
                    <div className="DeviceData-noData">Brak danych</div>
                }
                {deviceData.data.length > 0 && !deviceData.fetching &&
                    <Chart
                        unit={deviceData.display.chartUnit!}
                        timeDomain={deviceData.dateMode === DateMode.Daily ? daily : monthly}
                        data={deviceData.data}
                        targetData={deviceData.targetData}
                        name={deviceData.display.displayName}
                        valueFormatter={deviceData.display.valueFormatter}
                        range={deviceData.display.chartRange!}
                        tooltipLabel={deviceData.display.tooltipName!}
                        targetTooltipLabel={deviceData.display.targetTooltipName}
                        type={deviceData.display.chartType!}
                        referenceLines={deviceData.display.chartReferenceLines}
                    />
                }
            </div>
        );
    }
}
