import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { getYear, getMonth } from 'date-fns';
import './style.scss';
import { numberToMonthMap } from '../../utils/numberToMonthMap';

export interface CarouselProps {
    startingDate?: Date;
    onDateChange: (date: Date) => void;
}

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const styles = {
    slide: {
        padding: 15,
        height: 60,
    },
};

export class Carousel extends React.Component<CarouselProps, {index: number}> {

    private today = new Date();

    constructor(props) {
        super(props);

        this.state = { index: this.getIndexForDate(this.props.startingDate || this.today) };

        this.slideRenderer = this.slideRenderer.bind(this);
    }

    handleChangeIndex = index => {
        this.props.onDateChange(new Date(this.getYearFromIndex(index), this.getMonthFromIndex(index)));

        this.setState({
            index,
        });
    };

    getIndexForDate(date: Date) {
        let index = 0;
        const todayYear = getYear(this.today);

        const initialMonth = getMonth(date);
        const initialYear = getYear(date);

        index = index + initialMonth;
        index = index - (todayYear - initialYear) * 12;

        return index;
    }

    getMonthFromIndex(index: number) {
        return index < 0 ? ((12 + index % 12) % 12) : (index % 12);
    }

    getYearFromIndex(index: number) {
        return Math.floor(index / 12) + getYear(this.today);
    }

    // getRange() {
    //     return [Number.NEGATIVE_INFINITY, this.getIndexForDate(this.today)];
    // }

    slideRenderer(params) {
        const { index, key } = params;

        const month = this.getMonthFromIndex(index);
        const year = this.getYearFromIndex(index);


        return (
            <div 
                className={`Carousel-card ${this.state.index === index ? "Carousel-activeCard" : ""}`}
                style={Object.assign({}, styles.slide)} 
                key={key}
            >
                {numberToMonthMap[month]} {year}
            </div>
        );
    }

    render() {
        return (
            <div className="Carousel-container">
                <VirtualizeSwipeableViews
                    style={{overflowY: 'visible'}}
                    containerStyle={{height: styles.slide.height, width: '100%'}}
                    index={this.state.index}
                    onChangeIndex={this.handleChangeIndex}
                    slideRenderer={this.slideRenderer}
                    overscanSlideBefore={10}
                    overscanSlideAfter={7}
                    resistance
                    axis="y"
                    springConfig={{
                        duration: '0.1s',
                        easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
                        delay: '0s',
                    }}
                />
            </div>
        );
    }
}
