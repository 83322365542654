import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { Input } from '../Input';
import { WithLabel } from '../WithLabel';
import { Header } from '../Text/Header/Header';
import { MagneticMeterInitialValuesForm } from '../../stores/MagneticMeterInitalValuesForm/MagneticMeterInitialValuesForm';
import { observer, inject } from 'mobx-react';
import { CalibrationParameters } from '../../domain/CalibrationParameters';
import { MeterType } from '../../domain/MeterType';

const Instruction = require('../../static/enegry_counter_plus_LM.svg')

export interface Props {
    meterType: MeterType;
    magneticMeterInitialValueForm?: MagneticMeterInitialValuesForm;
    onSubmit(result: CalibrationParameters): void;
}

export const meterTypeToUnit = (meterType: MeterType): string => {
    switch (meterType) {
        case MeterType.Electricity:
            return 'kWh';
        case MeterType.Gas:
            return 'm³';
        case MeterType.Heat:
            return 'GJ';
        case MeterType.WaterCold:
            return 'm³';
        case MeterType.WaterWarm:
            return 'm³';
        case MeterType.Inverter:
            return 'kWh';
        case MeterType.Water:
            return 'm³';
    }
}

export const MagneticMeterInitialValue = inject('magneticMeterInitialValueForm')(observer(
    ({ onSubmit, magneticMeterInitialValueForm, meterType }: Props) => (
        <FullScreenContainer>
            <Header>Podaj początkową wartość licznika</Header>
            <Instruction className="AddMeter-magneticInstruction" />
            <div className="AddMeter-initialValuesForm">
                <WithLabel labelAfter={meterTypeToUnit(meterType)}>
                    <Input
                        autoFocus
                        value={magneticMeterInitialValueForm!.calibrationOffsetInput.value}
                        onChange={magneticMeterInitialValueForm!.calibrationOffsetInput.onChange}
                        error={magneticMeterInitialValueForm!.calibrationOffsetInput.error}
                        label="Bieżące wskazanie licznika"
                    />
                </WithLabel>
                <WithLabel label={`1 ${meterTypeToUnit(meterType)} =`} labelAfter={"imp"}>
                    <Input
                        value={magneticMeterInitialValueForm!.impulsationInput.value}
                        onChange={magneticMeterInitialValueForm!.impulsationInput.onChange}
                        error={magneticMeterInitialValueForm!.impulsationInput.error}
                        label="Impulsacja"
                    />
                </WithLabel>
            </div>
            <Button onClick={() => magneticMeterInitialValueForm!.submit(onSubmit)}>Potwierdź</Button>
        </FullScreenContainer>
    )
));
