import * as React from 'react';
import { inject, observer } from "mobx-react";
import { match, Redirect } from 'react-router';
import { DeviceDetails } from './index';
import { DateMode } from '../../domain/DateMode';
import { DevicesStore } from '../../stores/DevicesStore/DevicesStore';
import { addMinutes, subMinutes } from 'date-fns';

interface DeviceDetailsContainerProps {
    devicesStore: DevicesStore;
    match: match<{ deviceId: string, dateMode?: DateMode, currentDate?: string }>
}

export const DeviceDetailsContainer = inject('devicesStore')(observer(
    class DeviceDetailsContainer extends React.Component<DeviceDetailsContainerProps, any> {

        componentDidUpdate() {
            const { dateMode, currentDate, deviceId } = this.props.match.params;

            const device = this.props.devicesStore.getDeviceById(deviceId);
            let date = currentDate ? new Date(currentDate) : undefined
            if (date) {
                const offset = date.getTimezoneOffset();
                date = Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset))
            }
            if (device) {
                device.setParameters({ dateMode, date });
            }
        }

        render() {
            const device = this.props.devicesStore.getDeviceById(this.props.match.params.deviceId);
            if (device) {
                if (!device.paired && device.connection.failed) {
                    if (device.class === 'meter') {
                        return <Redirect to={`/reconnect_meter/${device.id}`} />;
                    }
                    return <Redirect to={`/reconnect_device/${device.id}`} />;
                }
                if (device.class === 'meter' || device.class === 'inverter') {
                    return <DeviceDetails
                        device={device}
                        urlBack="/devices/meters"
                        route={`/devices/${device.id}`}
                        isEditable
                    />;
                }
                return <DeviceDetails
                    device={device}
                    urlBack="/devices/smart_home"
                    route={`/devices/${device.id}`}
                    isEditable
                />;
            }

            return null;
        }
    }
));
