import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { SmartDevicesApiResponse } from "../../infrastructure/SmartDevicesApiResponse";
import { observable, onBecomeObserved, computed, action, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { SingleDevice } from "../SingleDevice/SingleDevice";
import { SingleDeviceFactory } from "../SingleDevice/SingleDeviceFactory";
import { Subject, Observable } from "rxjs"
import { LocationsStore } from "../LocationsStore/LocationsStore";
import { AnalysisData } from "../DeviceData/AnalysisData";

export class MetersStore {
    private fetchedDevicesSubject: Subject<SingleDevice> = new Subject();

    meterDevicesPromise: IPromiseBasedObservable<SingleDevice[]> | null = null;

    get meterDevices(): SingleDevice[] | null {
        if (this.meterDevicesPromise === null || this.meterDevicesPromise.state !== 'fulfilled') {
            return null;
        }

        const devices = this.meterDevicesPromise.value;

        return devices;
    }

    get fetchingMeterDevices(): boolean {
        return this.meterDevicesPromise !== null && this.meterDevicesPromise.state === 'pending';
    }

    get fetchingMeterDevicesFailed(): boolean {
        return this.meterDevicesPromise !== null && this.meterDevicesPromise.state === 'rejected';
    }

    constructor(
        private httpService: HttpService, 
        private urlProvider: UrlProvider,
        private locationsStore: LocationsStore,
        private singleDeviceFactory: SingleDeviceFactory,
    ) {
        makeObservable(this, {
            meterDevicesPromise: observable,
            meterDevices: computed,
            fetchingMeterDevices: computed,
            fetchingMeterDevicesFailed: computed,
            retryFetchingMeterDevices: action.bound
        });

        onBecomeObserved(this, 'meterDevicesPromise', () => {
            if (this.meterDevicesPromise === null) {
                this.meterDevicesPromise = fromPromise(
                    this.fetchMeters()
                );
            }
        });
    }

    getMeterById(id: string): SingleDevice | null {
        if (this.meterDevices === null) {
            return null;
        }

        const meter = this.meterDevices.filter(device => device.id === id)[0];

        return meter || null;
    }

    getMeterConsumptionData(): AnalysisData | null {
        for (let i of this.meterDevices!) {
            if(i.analysisData !== null) {
                return i.analysisData as AnalysisData;
            }
        }
        return null;
    }

    async retryFetchingMeterDevices(): Promise<void> {
        this.meterDevicesPromise = fromPromise(
            this.fetchMeters()
        );

        await this.meterDevicesPromise;
    }

    fetchedDevices(): Observable<SingleDevice> {
        return this.fetchedDevicesSubject;
    }

    private async fetchMeters(): Promise<SingleDevice[]> {
        const currentLocationId = await this.locationsStore.getCurrentLocationId();
        if (currentLocationId === null) {
            return [];
        }

        const response = await this.httpService.get<SmartDevicesApiResponse>(
            this.urlProvider.getMetersUrl(currentLocationId)
        )
        const devices = await Promise.all((response.items || [])
            .map(item => this.singleDeviceFactory.fromDeviceApiResponse(item)));
        
        devices.forEach(device => this.fetchedDevicesSubject.next(device));

        return devices;
    }
}