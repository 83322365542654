import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Header } from '../../Text/Header/Header';
import { InstructionsList } from '../../Instructions/InstructionsList';

export interface Props {
    onNext(): void;
}

export const Instruction1 = require('../../../static/instructions/parowanie_wloz_i_wyjmij_baterie_emi_ryc1.svg');
export const Instruction2 = require('../../../static/instructions/parowanie_wloz_i_wyjmij_baterie_emi_ryc2.svg');
export const Instruction3 = require('../../../static/instructions/parowanie_umiejscowienie_urzadzenia_emi_ryc1.svg');
export const Instruction4 = require('../../../static/instructions/parowanie_umiejscowienie_urzadzenia_emi_ryc2.svg');
export const Instruction5 = require('../../../static/instructions/parowanie_umiejscowienie_urzadzenia_emi_ryc3b.svg');
export const Instruction6 = require('../../../static/instructions/parowanie_umiejscowienie_urzadzenia_emi_ryc4.svg');


export const Instruction = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <Header bold>Miernik EMI - montaż urządzenia</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę urządzenia, naciskając przycisk z tyłu obudowy i pociągnij obudowę do góry.</p>
                    <Instruction1 />
                </>,
                <>
                    <p>Włóż baterie do urządzenia pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>
                    <Instruction2 />
                </>,
                <p>Zamknij obudowę</p>,
                <>
                    <p>Podłacz próbnik LED do miernika EMI. Złącze znajduje się w dolnej części urządzenia.</p>
                    <Instruction3 />
                </>,
                <>
                    <p>Odszukaj na liczniku diodę, przy której znajduje się opis impulsacji (np. 1000 imp/kWh)</p>
                    <Instruction4 />
                </>,
                <p>Odłącz od próbnika LED miękką część rzepa.</p>,
                <>
                    <p>Przyklej ją stroną samoprzylepną dookoła diody tak, by dioda znalazła się dokładnie na środku otworu w rzepie.</p>
                    <Instruction5 />
                </>,
                <>
                    <p>Przymocuj próbnik LED za pomocą rzepa do licznika.</p>
                    <Instruction6 />
                </>,
                <p>Miernik EMI możesz przymocować do ściany szafki licznikowej za pomocą załączonej taśmy dwustronnej lub kołków i wkrętów.</p>
            ]}
        />
    </FullScreenContainer>
);
