import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import './style.scss';
import { InstructionsList } from './InstructionsList';

export interface Props {
    onNext(): void;
}

const SmokeSensorInstruction1 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_dymu_ryc2.svg');
const SmokeSensorInstruction2 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_dymu_ryc4.svg');
const SmokeSensorInstruction3 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_dymu_ryc3.svg');
const SmokeSensorInstruction4 = require('../../static/instructions/parowanie_pierwsze_kroki_czujnik_dymu_ryc1.svg');
const SmokeSensorInstruction5 = require('../../static/instructions/parowanie_montaz_czujnik_dymu_ryc1.svg');
const SmokeSensorInstruction6 = require('../../static/instructions/parowanie_montaz_czujnik_dymu_ryc2.svg');
const SmokeSensorInstruction7 = require('../../static/instructions/parowanie_montaz_czujnik_dymu_ryc3.svg');

export const SmokeSensorInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Czujnik dymu</Header>
            <Header bold>Zalecane rozmieszczenie</Header>
            <InstructionsList
                instructions={[
                    <p>Czujnik dymu należy umieścić w pomieszczeniach o zakresie temperatur 0-50°C.</p>,
                    <p>Zalecamy umiejscowić czujniki dymu w sypialniach i na drogach ewakuacyjnych. Na jednej kondygnacji należy umieścić co najmniej jeden czujnik dymu, maksymalna odległość pomiędzy czujnikami powinna wynosić 10 metrów.</p>,
                    <>
                        <p>Odległość między czujnikami dymu i ścianami, wentylacją lub innymi przeszkodami powinna wynosić przynajmniej 50 cm.</p>
                        <SmokeSensorInstruction2 />
                    </>,
                    <>
                        <p>Jeżeli czujnik montowany jest na ścianie, należy umieścić go wysoko - w odległości 30 a 50 cm od sufitu.</p>
                        <SmokeSensorInstruction3 />
                    </>,
                    <p>Odległość między czujnikiem i piecem bądź kominkiem powinna wynosić przynajmniej 4 m.</p>,
                    <p>Pomieszczenia o powierzchni większej niż 80 m kwadratowych wymagają zastosowania więcej niż jednego czujnika dymu, celem zapewnienia pełnego bezpieczeństwa pożarowego.</p>,
                    <p>Czujnik należy umieścić w dostępnym miejscu, celem umożliwienia ewentualnej wymiany baterii i konserwacji.</p>,
                ]}
            />
            <Header bold>Przykłady rozmieszczenia</Header>
            <SmokeSensorInstruction1 />
            <Header bold>Montaż</Header>
            <InstructionsList
                instructions={[
                    <p>Odłącz czujnik od podstawy montażowej przekręcając go w kierunku przeciwnym do ruchu wskazówek zegara.</p>,
                    <>
                        <p>Wyciągnij pasek izolujący baterię jak to pokazuje ilustracja poniżej.</p>
                        <SmokeSensorInstruction4 />
                    </>,
                    <>
                        <p>Użyj podstawy montażowej, aby oznaczyć punkty do nawiercenia otworów na kołki montażowe na suficie lub na ścianie.</p>
                        <SmokeSensorInstruction5 />
                    </>,
                    <>
                        <p>Użyj załączonych śrub i kołków, aby zamontować podstawę montażową w zaznaczonej pozycji.</p>
                        <SmokeSensorInstruction6 />
                    </>,
                    <>
                        <p>Zamontuj czujnik, obracając go zgodnie z ruchem wskazówek zegara, aż do usłyszenia charakterystycznego kliknięcia.</p>
                        <SmokeSensorInstruction7 />
                    </>,
                ]}
            />
        </div>
    </FullScreenContainer>
)

const WindowSensorInstruction1 = require('../../static/instructions/parowanie_montaz_czujnik_drzwi_i_okien_ryc1.svg');
const WindowSensorInstruction2 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_drzwi_i_okien_ryc1.svg');
const WindowSensorInstruction3 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_drzwi_i_okien_ryc4.svg');
const WindowSensorInstruction4 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_drzwi_i_okien_ryc8.svg');
const WindowSensorInstruction5 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_drzwi_i_okien_ryc3.svg');
const WindowSensorInstruction6 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_drzwi_i_okien_ryc6.svg');
const WindowSensorInstruction7 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_drzwi_i_okien_ryc2.svg');

export const WindowSensorInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Czujnik drzwi i okien</Header>
            <Header bold>Montaż baterii</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Otwórz obudowę urządzenia przyciskiem znajdującym się w jej górnej części. Zdejmij przedni panel.</p>
                        <WindowSensorInstruction2 />
                    </>,
                    <p>Włóż baterie do urządzenia pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>,
                    <p>Zamknij obudowę.</p>,
                ]}
            />
            <Header bold>Montaż urządzenia</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Czujnik przeznaczony jest do montażu na oknie lub drzwiach od strony pomieszczenia. Dopuszczalna temperatura użytkowania to 0-50°C.</p>
                        <WindowSensorInstruction3 />
                        <WindowSensorInstruction4 />
                    </>,
                    <p>Oczyść powierzchnię przed montażem.</p>,
                    <>
                        <p className="Instructions-paragraph">Czujnik należy przykleić na <strong>ramie</strong>, a magnes na <strong>skrzydle drzwi/okna</strong>.</p>
                        <p className="Instructions-paragraph">Zwróć uwagę, aby <strong>trójkąt</strong> narysowany na czujniku był zwrócony w kierunku magnesu i znajdował się na podobnym poziomie.</p>
                        <p className="Instructions-paragraph">Minimalna odległość między czujnikiem a magnesem nie może przekraczać 5 mm.</p>
                    </>,
                ]}
            />
            <WindowSensorInstruction1 />
            <Header bold>Przykładowe warianty montażu</Header>
            <WindowSensorInstruction5 />
            <WindowSensorInstruction6 />
            <p>Widok z góry różnych wariantów montażu w zależności od konstrukcji okna/drzwi:</p>
            <WindowSensorInstruction7 />
        </div>
    </FullScreenContainer>
);

export const HumidityAndVocSensorInstruction1 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_czujnik_wilgotnosci_ryc1.svg');
export const HumidityAndVocSensorInstruction2 = require('../../static/instructions/parowanie_montaz_czujnik_wilgotnosci_ryc1.svg');
export const HumidityAndVocSensorInstruction3 = require('../../static/instructions/parowanie_montaz_czujnik_wilgotnosci_ryc2.svg');
export const HumidityAndVocSensorInstruction4 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_wilgotnosci_ryc1.svg');

export const HumiditySensorInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Czujnik wilgotności i temperatury</Header>
            <Header bold>Zalecane rozmieszczenie</Header>
            <InstructionsList
                instructions={[
                    <p>Zamontuj czujnik w pomieszczeniu. Temperatura wewnątrz powinna zawierać się w przedziale 0-50°C.</p>,
                    <>
                        <p>Czujnik należy umieścić na ścianie w dostępnym miejscu, celem umożliwienia ewentualnej wymiany baterii i konserwacji.</p>
                        <HumidityAndVocSensorInstruction1 />
                    </>,
                ]}
            />
            <Header bold>Montaż</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Otwórz obudowę czujnika.</p>
                        <HumidityAndVocSensorInstruction2 />
                    </>,
                    <>
                        <p>Skorzystaj z taśmy dwustronnej lub śrub, w celu przymocowania czujnika na ścianie.</p>
                        <HumidityAndVocSensorInstruction3 />
                    </>,
                    <>
                        <p>Włóż baterie do urządzenia pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>
                        <HumidityAndVocSensorInstruction4 />
                    </>,
                    <p>Zamknij obudowę czujnika.</p>,
                ]}
            />
        </div>
    </FullScreenContainer>
)

export const VocSensorInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Czujnik jakości powietrza</Header>
            <Header bold>Zalecane rozmieszczenie</Header>
            <InstructionsList
                instructions={[
                    <p>Zamontuj czujnik w pomieszczeniu. Temperatura wewnątrz powinna zawierać się w przedziale 0-50°C.</p>,
                    <>
                        <p>Czujnik należy umieścić na ścianie w dostępnym miejscu, celem umożliwienia ewentualnej wymiany baterii i konserwacji.</p>
                        <HumidityAndVocSensorInstruction1 />
                    </>,
                ]}
            />
            <Header bold>Montaż</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Otwórz obudowę czujnika.</p>
                        <HumidityAndVocSensorInstruction2 />
                    </>,
                    <>
                        <p>Skorzystaj z taśmy dwustronnej lub śrub, w celu przymocowania czujnika na ścianie.</p>
                        <HumidityAndVocSensorInstruction3 />
                    </>,
                    <>
                        <p>Włóż baterie do urządzenia pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>
                        <HumidityAndVocSensorInstruction4 />
                    </>,
                    <p>Zamknij obudowę czujnika.</p>,
                ]}
            />
        </div>
    </FullScreenContainer>
)

const SmartPlugInstruction1 = require('../../static/instructions/parowanie_montaz_wtyczka_ryc1.svg');
const SmartPlugInstruction2 = require('../../static/instructions/parowanie_tryby_urzadzenia_wtyczka_ryc3.svg');
const SmartPlugInstruction3 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_wtyczka_ryc2_2.svg');
const SmartPlugInstruction4 = require('../../static/instructions/parowanie_tryby_urzadzenia_wtyczka_ryc2.svg');
const SmartPlugInstruction5 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_wtyczka_ryc1.svg');
const SmartPlugInstruction6 = require('../../static/instructions/parowanie_tryby_urzadzenia_wtyczka_ryc1.svg');

export const SmartPlugInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Wtyczka</Header>
            <Header bold>Montaż</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Umieść wtyczkę w gnieździe zasilającym.
                            Wtyczka przeznaczona jest do pracy we wnętrzu pomieszczeń.
                        </p>
                        <SmartPlugInstruction1 />
                    </>,
                    <>
                        <p>Upewnij się, że wtyczka jest poprawnie zamontowana w gniazdku.</p>
                        <SmartPlugInstruction2 />
                    </>,
                    <>
                        <p>Włącz wtyczkę naciskając przycisk umieszczony na wtyczce.</p>
                        <SmartPlugInstruction3 />
                    </>,
                    <>
                        <p>Dioda LED zaświeci się na zielono</p>
                        <SmartPlugInstruction4 />
                    </>,
                    <>
                        <p>
                            Podłącz urządzenie gospodarstwa domowego do wtyczki i sprawdź czy podłączone urządzenie działa.
                            Wtyczka jest zaprojektowana do ciągłego obciążenia prądem o natężeniu <strong>maksymalnie 10 A</strong>.
                        </p>
                        <SmartPlugInstruction5 />
                    </>,
                    <>
                        <p>Przy pierwszym uruchomieniu dioda LED miga światłem czerwonym, do momentu połączenia z centralką. To jest prawidłowe zachowanie.</p>
                        <SmartPlugInstruction6 />
                        <p>Dzięki połączeniu z centralką włączanie i wyłączanie wtyczki może się odbywać zdalnie poprzez aplikację.</p>
                    </>
                ]}
            />
        </div>
    </FullScreenContainer>
);

const FloodSensorInstruction1 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc1.svg');
const FloodSensorInstruction2 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc2.svg');
const FloodSensorInstruction3 = require('../../static/instructions/parowanie_montaz_umiejscowienie_urzadzenia_czujnik_zalania_ryc3.svg');
const FloodSensorInstruction4 = require('../../static/instructions/parowanie_montaz_umiejscowienie_urzadzenia_czujnik_zalania_ryc4.svg');

export const FloodSensorInstructions = ({onNext}: Props) => (
    <FullScreenContainer onNextButton={onNext}>
        <div>
            <Header bold>Czujnik zalania</Header>
            <Header bold>Zalecane rozmieszczenie</Header>
            <InstructionsList
                instructions={[
                    <p>Czujnik zalania należy umieścić w pomieszczeniu, o zakresie temperatur 0-50°C.</p>,
                    <p>Czujnik zalania należy umieścić na podłodze, w miejscu, w którym potencjalnie może wystąpić zalanie.</p>,
                    <p>Czujnik należy umieścić w dostępnym miejscu, celem umożliwienia ewentualnej wymiany baterii i konserwacji.</p>
                ]}
            />
            <Header bold>Montaż</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Odłącz od czujnika pięcioramienną nakrętkę przekręcając ją w kierunku przeciwnym do ruchu wskazówek zegara.</p>
                        <FloodSensorInstruction1 />
                    </>,
                    <>
                        <p>Zdejmij plastikowy dysk z czujnika tak, by mieć dostęp do miejsca na baterię.</p>
                        <FloodSensorInstruction2 />
                    </>,
                    <p>Włóż załączoną baterię CR123A pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>,
                    <p>Połóż z powrotem plastikowy dysk na czujnik.</p>,
                    <p>Przymocuj do czujnika pięcioramienną nakrętkę przekręcając ją zgodnie z ruchem wskazówek zegara.</p>,
                    <p>Czujnik połóż bezpośrednio na podłodze w miejscu, w którym potencjalnie może wystąpić zalanie.</p>
                ]}
            />
            <Header bold>Montaż z wykorzystaniem sondy detekcji wycieków</Header>
            <InstructionsList
                instructions={[
                    <>
                        <p>Jeżeli próbujesz zainstalować czujnik zalania w celu detekcji wycieków pod urządzeniem AGD (np. zmywarką lub pralką), i między tym urządzeniem a podłogą jest zbyt mało miejsca by czujnik zalania się tam zmieścił, wykorzystaj zewnętrzną sondę detekcji wycieków (Water Leak Detector Probe).</p>
                        <p>Sonda detekcji wycieków składa się z dwóch elementów połączonych przewodem:</p>
                        <ul>
                            <li>płaskiej sondy</li>
                            <li>podstawy czujnika zalania</li>
                        </ul>
                        <p>Płaską sondę należy wsunąć pod urządzenie AGD (np. pralkę lub zmywarkę), a podstawę czujnika zalania montuje się na ścianie w pobliżu.</p>
                        <FloodSensorInstruction3 />,
                    </>,
                    <p>Połóż płaską sondę na podłodze w miejscu, w którym potencjalnie może gromadzić się woda z wycieku.</p>,
                    <p>Zamontuj podstawę czujnika zalania na ścianie lub w innym dogodnym miejscu za pomocą załączonej taśmy dwustronnej lub śrub tak, aby płaska sonda pozostała na swoim miejscu, leżąc poziomo na podłodze.</p>,
                    <>
                        <p>Zamontuj czujnik zalania w podstawie czujnika. Zwróć uwagę na dopasowanie styków czujnika i podstawy.</p>
                        <FloodSensorInstruction4 />
                    </>
                ]}
            />
        </div>
    </FullScreenContainer>
)
