import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { Input } from '../Input';
import { Button } from '../Button';
import { LabeledCheckbox } from '../LabeledCheckbox/LabeledCheckbox';
import { UserRegistrationStore } from '../../stores/UserStore/Registration';
import { RouteComponentProps } from 'react-router';
import { observable, makeObservable } from 'mobx';
import { Link } from 'react-router-dom';
import { config } from '../../utils/config';

const Logo = require('mainLogo.svg');

interface Props extends RouteComponentProps<{}> {
    userRegistrationStore: UserRegistrationStore
}

export const Registration = inject("userRegistrationStore")(observer(class Registration extends React.Component<Props> {
    successStep: boolean = false;

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            successStep: observable
        });
    }

    componentDidMount() {
        this.successStep = false
    }

    render() {
        const { userRegistrationStore } = this.props
        const { termsOfUse } = config
        return (
            <React.Fragment>
                {!this.successStep &&
                    <div className="Registration-container">
                        <Logo className="Registration-logo" />

                        <p className="Register-header">Rejestracja - Twoje Dane</p>
                        <div className="Register-form">
                            <Input
                                label="E-mail"
                                type="email"
                                autoFocus
                                required
                                onChange={userRegistrationStore.form.$.email.onChange}
                                value={userRegistrationStore.form.$.email.value}
                                error={userRegistrationStore.form.$.email.error}
                            />
                            <Input
                                label="Hasło"
                                type="password"
                                required
                                onChange={userRegistrationStore.form.$.password.onChange}
                                value={userRegistrationStore.form.$.password.value}
                                error={userRegistrationStore.form.$.password.error}
                            />
                            <Input
                                label="Powtórz hasło"
                                type="password"
                                required
                                onChange={userRegistrationStore.form.$.repeatPassword.onChange}
                                value={userRegistrationStore.form.$.repeatPassword.value}
                                error={userRegistrationStore.form.$.repeatPassword.error}
                            />
                            <Input
                                label="Imię"
                                onChange={userRegistrationStore.form.$.firstName.onChange}
                                value={userRegistrationStore.form.$.firstName.value}
                                error={userRegistrationStore.form.$.firstName.error}
                            />
                            <Input
                                label="Nazwisko"
                                onChange={userRegistrationStore.form.$.surname.onChange}
                                value={userRegistrationStore.form.$.surname.value}
                                error={userRegistrationStore.form.$.surname.error}
                            />
                            <Input
                                label="Adres"
                                onChange={userRegistrationStore.form.$.address.onChange}
                                value={userRegistrationStore.form.$.address.value}
                                error={userRegistrationStore.form.$.address.error}
                                rows={2}
                            />
                            <LabeledCheckbox
                                onChange={userRegistrationStore.form.$.acceptTerms.onChange}
                                error={userRegistrationStore.form.$.acceptTerms.error}
                                required
                            >Akceptuję {termsOfUse ?
                                <Link to="/termsOfUse"><a href="/">warunki użytkowania</a></Link>
                                : <a href="http://lerta.energy">warunki użytkowania</a>}
                            </LabeledCheckbox>
                            {userRegistrationStore.error && <div className="Register-error">Wystąpił błąd podczas rejestracji nowego konta.</div>}
                        </div>
                        <Button loading={userRegistrationStore.registering} onClick={() => userRegistrationStore.submit(() => this.successStep = true)}>Zarejestruj</Button>
                    </div>}

                {this.successStep &&
                    <div className="RegisterSuccess-container">
                        <Logo className="Registration-logo" />
                        <p className="RegisterSuccess-header">Wysłano wiadomość e-mail z linkiem aktywacyjnym</p>
                        <Link to="/login" className="RegisterSuccess-paragraph">
                            <div className="RegisterSuccess-redirect">Przejdź do ekranu logowania</div>
                        </Link>
                    </div>}
            </React.Fragment>
        );
    }
}));
