import { observable, computed, makeObservable } from "mobx";

export class Input {
    validated: boolean = false;
    value: string = '';
    get error(): string | null {
        if (!this.validated) {
            return null;
        }
        return this.validate(this.value);
    }

    constructor(private validate: (value: string) => string | null) {
        makeObservable(this, {
            validated: observable,
            value: observable,
            error: computed
        });
    }
}