import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { Paragraph } from '../Text/Paragraph/Paragraph';

const Icon = require('../../static/icons/central_device_into_plug_connection.svg');

export interface Props {
    onClick(): void;
}

export const PlugGateway = ({onClick}: Props) => (
    <FullScreenContainer gradient>
        <div>
            <Icon className="AddGateway-plugIcon" />
            <Paragraph>Podłącz centralkę do prądu.</Paragraph>
        </div>
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
);
