import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { startOfMonth, subMonths, startOfDay, endOfDay, setHours, getHours, isBefore, isSameMonth, setMinutes, getMinutes, differenceInCalendarMonths, addMonths } from 'date-fns';
import { Calendar } from './Calendar';
import { TimePicker } from "./TimePicker"
import { FormattedMessage, useIntl } from 'react-intl';

export interface CalendarInterval {
    start: Date | null,
    end: Date | null
}

interface Props {
    interval: CalendarInterval;
    onStartDateChange: (date: Date) => void;
    onEndDateChange?: (date: Date, secondStep?: boolean) => void;
    isWeekendsDisabled?: boolean;
    oneDayView?: boolean;
    minDate?: Date;
    maxDate?: Date;
    minTime?: string;
    maxTime?: string;
    leftIsMain?: boolean;
    timePickers?: boolean;
    fullHours?: boolean;
}


export const Calendars = ({ interval, onStartDateChange, onEndDateChange, isWeekendsDisabled, oneDayView, minDate, maxDate, minTime, maxTime, leftIsMain, timePickers, fullHours }: Props) => {
    const [rangeState, setRangeState] = useState<'start' | 'end'>('start')
    const [isInvalidTime, setIsInvalidTime] = useState(false)
    const intl = useIntl();

    const dateForFirstCalendar = (interval.start && interval.end) && new Date(interval.start) ? isSameMonth(interval.start, interval.end) && !leftIsMain ? subMonths(startOfMonth(new Date(interval.start)), 1) : new Date(interval.start) : subMonths(startOfMonth(new Date()), leftIsMain ? 0 : 1)
    const [firstCalendarDate, setFirstCalendarDate] = useState<Date>(dateForFirstCalendar)
    const [secondCalendarDate, setSecondCalendarDate] = useState<Date>(interval.start && interval.end ? isSameMonth(interval.start, interval.end) && leftIsMain ? addMonths(interval.end, 1) : new Date(interval.end) : addMonths(new Date(), leftIsMain ? 1 : 0))
    const [hoverInterval, setHoverInterval] = useState<Interval>();


    const setEndDate = (date: Date, secondStep?: boolean) => {
        let dateWithTime = endOfDay(date)
        let maxHours = 23
        let maxMinutes = 59
        let hours = 23
        let minutes = 59
        if (maxTime) {
            maxHours = +maxTime.split(':')[0]
            maxMinutes = +maxTime.split(':')[1]
        }
        if (interval.end) {
            hours = getHours(interval.end)
            minutes = getMinutes(interval.end)
        }
        if (hours > maxHours || minutes > maxMinutes) {
            hours = maxHours
            minutes = maxMinutes
        }
        dateWithTime = setHours(dateWithTime, hours)
        dateWithTime = setMinutes(dateWithTime, minutes)
        onEndDateChange?.(dateWithTime, secondStep)
    }
    const setStartDate = (date: Date) => {
        let dateWithTime = startOfDay(date)
        let minHours = 0
        let minMinutes = 0
        let hours = 0
        let minutes = 0
        if (minTime) {
            minHours = +minTime.split(':')[0]
            minMinutes = +minTime.split(':')[1]
        }
        if (interval.start) {
            hours = getHours(interval.start)
            minutes = getMinutes(interval.start)
        }
        if (hours < minHours || minutes < minMinutes) {
            hours = minHours
            minutes = minMinutes
        }
        dateWithTime = setHours(dateWithTime, hours)
        dateWithTime = setMinutes(dateWithTime, minutes)
        onStartDateChange(dateWithTime)
    }
    const onClick = (date: Date) => {
        if (rangeState === 'start') {
            setStartDate(date)
            setEndDate(date)
            !oneDayView && setRangeState('end')

        } else if (rangeState === 'end') {
            if (interval.start) {
                if (isBefore(date, interval.start)) {
                    setStartDate(date)
                    setEndDate(date, true)
                } else {
                    setEndDate(date, true)
                    setRangeState('start')
                }
            }
        }
        setHoverInterval(undefined);
    }

    const onHover = (date: Date) => {
        if (rangeState === 'end') {
            let hoverInterval
            if (interval.start && isBefore(interval.start, date)) {
                hoverInterval = {
                    start: interval.start,
                    end: date
                }
            }
            setHoverInterval(hoverInterval);
        }
    }

    return <Box display="flex" justifyContent="center" flexDirection="column">
        {!oneDayView && <>
            <Calendar
                currentDate={firstCalendarDate}
                onClick={onClick}
                onHover={onHover}
                interval={interval}
                hoverInterval={hoverInterval}
                isWeekendsDisabled={isWeekendsDisabled}
                setDisplayedDate={setFirstCalendarDate}
                disabledNextMonth={differenceInCalendarMonths(secondCalendarDate, firstCalendarDate) === 1}
                minDate={minDate}
                maxDate={maxDate}
            />
            {timePickers && <TimePicker
                labelId="superDateRangePicker.hour.from.label"
                date={interval.start}
                onTimeChange={setStartDate}
                defaultTime={minTime || "00:00"}
                oneDayView={oneDayView}
                maxTime={maxTime}
                minTime={minTime}
                fullHours={fullHours}
                setIsInvalidTime={setIsInvalidTime}
            />}
        </>}
        <Box paddingTop={oneDayView ? 0 : 2} marginBottom={1} >
            <Calendar
                currentDate={secondCalendarDate}
                onClick={onClick}
                onHover={onHover}
                interval={interval}
                hoverInterval={hoverInterval}
                isWeekendsDisabled={isWeekendsDisabled}
                setDisplayedDate={setSecondCalendarDate}
                disabledPrevMonth={oneDayView ? false : differenceInCalendarMonths(secondCalendarDate, firstCalendarDate) === 1}
                minDate={minDate}
                maxDate={maxDate}
            />
            {timePickers && <TimePicker
                labelId="superDateRangePicker.hour.to.label"
                date={interval.end}
                onTimeChange={setEndDate}
                defaultTime={maxTime || "23:59"}
                oneDayView={oneDayView}
                maxTime={maxTime}
                minTime={minTime}
                fullHours={fullHours}
                setIsInvalidTime={setIsInvalidTime}
            />}
        </Box>
        {
            timePickers && <>
                <Box maxWidth={230} paddingBottom={2} paddingTop={((interval.end && interval.start && isBefore(interval.end, interval.start)) || isInvalidTime) ? 3 : 0} display="flex" alignItems="center" flexDirection="column">
                    {interval.end && interval.start && isBefore(interval.end, interval.start) && <Typography variant="caption" color="error" ><FormattedMessage id="superDateRangePicker.time.error" /></Typography>}
                    {isInvalidTime && <Typography align="center" variant="caption" color="error" ><FormattedMessage id="superDateRangePicker.time.invalid" values={{ from: minTime || '00:00', to: maxTime || '23:59' }} />{fullHours && ` ${intl.formatMessage({ id: "superDateRangePicker.time.invalid.fullHours" })}`}.</Typography>}
                </Box>
            </>
        }
    </Box >
}
