import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const Plus = require('../../static/icons/add.svg');

export interface Props {
    url: string;
}

export const AddDevicePanel = ({ url }: Props) => (
    <Link to={url}>
        <div className="AddDevicePanel-container">
            <Plus className="AddDevicePanel-plus" />
        </div>
    </Link>
);
