import React from 'react';
import { Typography } from '@material-ui/core';
import './styles.scss'
import { AnalysisData, AnalysisStore, AnalysisViewType } from '../../../stores/Statistics/AnalysisStore';
import { SuperDateRangePicker } from '../../SuperDateRangePicker/SuperDateRangePicker';
import { inject, observer } from 'mobx-react';
import { AnalysisDailyChart } from './AnalysisDailyChart';
import { Loader } from '../../Loader/Loader';
import { AnalysisPeriodChart } from './AnalysisPeriodChart';
import { FormattedMessage } from 'react-intl';
import { AnalysisChartLegend } from './AnalysisChartLegend';

interface Props {
    analysisStore?: AnalysisStore;
}

const getDateFormat = (accuracy: string) => {
    switch (accuracy) {
        case 'PT15M':
            return 'HH:mm';
        case 'P1D':
            return 'dd.MM';
        case 'P1W':
            return 'ww.yyyy';
        case 'P1M':
            return 'MM.yyyy';
        default:
            return "";
    }
}

export const UndecoratedAnalysis = ({ analysisStore: store }: Props) => {

    const getChart = (data: AnalysisData[]) => {
        switch (store!.viewType) {
            case AnalysisViewType.daily:
                return <AnalysisDailyChart data={data} />
            case AnalysisViewType.period:
                return <AnalysisPeriodChart data={data} dateFormat={getDateFormat(store!.accuracy)} />
            default:
                return null
        }
    }

    return (
        <div className="Analysis">
            <SuperDateRangePicker
                inputLabelId="statistics.datesRange.header"
                startDate={store!.from}
                setStartDate={(date: Date) => store!.from = date}
                endDate={store!.to}
                setEndDate={(date: Date) => store!.to = date}
            />
            <div className="Analysis-content">
                {store!.fetching && <Loader secondary size={50} />}
                {store!.failed && <Typography color="error"><FormattedMessage id="fetchError" /></Typography>}
                {store!.fetched && !store!.data?.length && <Typography><FormattedMessage id="noData" /></Typography>}
                {store!.fetched && !!store!.data?.length && <>
                    {getChart(store!.data)}
                    <AnalysisChartLegend variant={store!.viewType} />
                </>}
            </div>
        </div>
    )
}

export const Analysis = inject('analysisStore')(observer(UndecoratedAnalysis))
