import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './styles/index.scss';
import registerServiceWorker from './registerServiceWorker';
import { observer, Provider } from 'mobx-react';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { createStores } from './stores/index';
import blue from '@material-ui/core/colors/blue';
import { LoginOrApp } from './components/LoginOrApp';
import { Helmet } from 'react-helmet';
import { config } from './utils/config';
import { IntlProvider } from 'react-intl'
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LocaleType } from './stores/LocaleStore';

import localePl from "date-fns/locale/pl";

const theme = config.theme || createTheme({
	palette: {
		primary: blue,
		secondary: blue,
	},
});

const stores = createStores();

const setLocale = (currentLang?: LocaleType) => {
	return localePl;
}


const App = observer(() => {
	return <Provider {...stores} >
		{stores.localeStore.isReady && <IntlProvider messages={stores.localeStore.messages} locale={stores.localeStore.locale}>
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={setLocale(stores.localeStore.locale)}>
					<LoginOrApp userStore={stores.userStore} history={stores.history} />
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</IntlProvider>}
	</Provider>
})

const applicationWithStore = (
	<>
		<Helmet>
			<title>{config.htmlHeader.title}</title>
			<link rel="manifest" href={config.htmlHeader.manifestPath}></link>
		</Helmet>
		<App />
	</>
);

ReactDOM.render(applicationWithStore, document.getElementById('root'));
registerServiceWorker();
