import React from 'react';
import './styles.scss'
import { inject, observer } from 'mobx-react';
import { SuperDateRangePicker } from '../../SuperDateRangePicker/SuperDateRangePicker';
import { BalanceStore } from 'src/stores/Statistics/BalanceStore';
import { BalanceChart } from './BalanceChart';
import { BalanceDetails } from './BalanceDetails';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Loader } from '../../Loader/Loader';

interface Props {
    balanceStore?: BalanceStore;
}

export const UndecoratedBalance = ({ balanceStore }: Props) => {

    return <div className="Balance">
        <SuperDateRangePicker
            inputLabelId="statistics.datesRange.header"
            startDate={balanceStore!.from}
            setStartDate={(date: Date) => balanceStore!.from = date}
            endDate={balanceStore!.to}
            setEndDate={(date: Date) => balanceStore!.to = date}
        />
        <div className="Balance-content">
            {balanceStore!.fetching && <Loader secondary size={50} />}
            {balanceStore!.failed && <Typography color="error"><FormattedMessage id="fetchError" /></Typography>}
            {balanceStore!.fetched && !balanceStore!.data && <Typography><FormattedMessage id="noData" /></Typography>}
            {balanceStore!.fetched && !!balanceStore!.data && <div className="Balance-wrapper">
                <BalanceChart balanceData={balanceStore!.data} />
                <div className="divider"></div>
                <BalanceDetails balanceData={balanceStore!.data} />
            </div>
            }
        </div>
    </div>
}

export const Balance = inject('balanceStore')(observer(UndecoratedBalance))