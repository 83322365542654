import * as React from 'react';
import './style.scss';
import { RouteComponentProps } from 'react-router-dom';
import { CircleChart } from './CircleChart/CircleChart';
import { DashboardChart } from './DashboardChart/DashboardChart';
import { observer, inject } from 'mobx-react';
import { DashboardStore, DashboardDataFetcher } from '../../stores/DashboardStore/DashboardStore';
import { Loader } from '../Loader/Loader';
import { config } from '../../utils/config';
import { format, getMonth } from 'date-fns';
import { numberToMonthMap } from '../../utils/numberToMonthMap';
import { Typography } from '@material-ui/core';
import { DashboardViewSelector } from './DashboardViewSelector/DashboardViewSelector'
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { AvailableFeaturesStore } from '../../stores/AvailableFeatures/AvailableFeaturesStore';

const MeterIcon = require('../../static/icons/electricity_meter_icon.svg')
const MeterlessIcon = require('../../static/icons/wtyczka.svg')


interface Props extends RouteComponentProps<{}> {
    dashboardStore?: DashboardStore;
    availableFeaturesStore?: AvailableFeaturesStore;
}

export const Dashboard = inject('dashboardStore', 'availableFeaturesStore')(observer(class Dashboard extends React.Component<Props> {
    settings: Settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "50px",
        afterChange: this.afterChange.bind(this)
    };

    private isDashboard: boolean = false;

    constructor(props) {
        super(props);
        this.isDashboard = this.props.availableFeaturesStore!.hasAvailableFeature('dashboard');
    }

    componentDidMount() {
        const { resetData } = this.props.dashboardStore!;
        resetData()
        this.props.dashboardStore!.setDefaultView()
    }

    afterChange(index) {
        const { dashboardStore: store } = this.props;
        store!.activeGauge = index
    }

    render() {
        const { dashboardStore: store } = this.props;
        return <div className="Dashboard">
            {this.header}
            <div className="Dashboard--wrapper">
                <div>
                    <Slider {...this.settings}>
                        {this.isDashboard && this.gauge(store!.consumption, 'consumption')}
                        {this.isDashboard && store!.isInverter &&
                            this.gauge(store!.production, 'production')}
                    </Slider>
                </div>
            </div>
            {this.chart}
        </div>
    }

    private get header() {
        const { changeView, view, isSupplyPoint, activeGauge, } = this.props.dashboardStore!
        const isSmartHome = this.props.availableFeaturesStore!.hasAvailableFeature('smartHome');
        const today = new Date()
        return <div className="header">
            <div>
                <div className="title">
                    {this.isDashboard && activeGauge === 0 && "Zużycie energii elektrycznej"}
                    {this.isDashboard && activeGauge === 1 && "Produkcja energii elektrycznej"}
                </div>

                {activeGauge === 0 && <div className="subTitle">{view === 'meter' ? "według licznika" : "według inteligentnych wtyczek"}</div>}
            </div>

            {activeGauge !== 2 && <div className="date">{numberToMonthMap[getMonth(today)]} {format(today, 'yyyy')}</div>}

            {activeGauge === 0 && <div className="viewSelector">
                {isSupplyPoint && isSmartHome && <DashboardViewSelector
                    left={{
                        value: 'meter',
                        icon: <MeterIcon className="meterIcon" />,
                    }}
                    right={{
                        value: 'meterless',
                        icon: <MeterlessIcon className="meterlessIcon" />,
                    }}
                    default={view}
                    onChange={changeView}
                />}
            </div>}

        </div>
    }

    private gauge(fetcher: DashboardDataFetcher | null, key: "consumption" | "production" | "mixed") {
        if (!fetcher) {
            return null
        }
        let currentMonth = 0
        let maxValue = 0
        let unit = ''
        const { data, isFetching, didFailed, didFetch } = fetcher
        const chartData = data && data.data;
        if (chartData) {
            currentMonth = chartData[chartData.length - 1].value;
            maxValue = Math.max(...chartData.slice(0, 11).map(month => month.value))
            unit = chartData[0]!.unit;
        }
        return <div key={key}>
            <div className="gauge" >
                {isFetching && <Loader />}
                {didFailed && <div className="no-data"> <Typography>Brak danych do wyświetlenia</Typography></div>}
                {didFetch && data && <CircleChart
                    max={Math.max(currentMonth, maxValue, data.prediction)}
                    color={this.getColor(key)}
                    solid={{
                        title: "Bieżące",
                        unit: unit || '',
                        value: currentMonth
                    }}
                    dashed={{
                        title: "Prognozowane",
                        unit: unit,
                        value: data.prediction
                    }}
                />}
            </div>
        </div>
    }


    private get chart() {
        const { activeGauge, chartData } = this.props.dashboardStore!
        if (!this.props.dashboardStore!.chartData) {
            return <div className="chart"></div>
        }
        const { data } = chartData!;
        const color = this.getColor(activeGauge === 0 ? "consumption" : "production")
        if (data && data.data && data.data.length) {
            return <div className="chart">
                <DashboardChart dashboardData={data} color={color} />
            </div>
        }
        return <div className="chart"></div>
    }

    private getColor(direction: string) {
        return config.colorPalette && (direction === "production" ? config.colorPalette.energyProduction : config.colorPalette.energyConsumption)

    }
}));


