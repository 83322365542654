import * as React from 'react';
import './styles.scss';
import { Paragraph } from '../../../Text/Paragraph/Paragraph';
import { Header } from '../../../Text/Header/Header';
import { ReconnectDevicePromptProps } from '../ReconnectDevicePrompt';

export const Smartplug = require('../../../../static/wtyczka_pozioma.svg');

export const ReconnectSmartplugPrompt = ({ deviceName }: ReconnectDevicePromptProps) => (
    <>
        <Smartplug className="ReconnectSmartplugPrompt-logo" />
        <div>
            <Paragraph className="ReconnectSmartplugPrompt-firstParagraph">Nie udało się połączyć z wtyczką{deviceName ? '' : '.'}</Paragraph>
            <Paragraph className="ReconnectSmartplugPrompt-deviceName">{deviceName ? ` ${deviceName}` : ''}.</Paragraph>
            <Header className="ReconnectSmartplugPrompt-header" bold>Wyjmij wtyczkę z gniazdka i włóż ją tam ponownie!</Header>
            <Paragraph className="ReconnectSmartplugPrompt-secondParagraph">Upewnij się, że jest poprawnie podłączona.</Paragraph>
            <Paragraph className="ReconnectSmartplugPrompt-thirdParagraph">Jeśli wtyczka jest włożona do listwy zasilającej, sprawdź czy listwa jest włączona.</Paragraph>
        </div>
    </>
);
