import * as React from 'react';
import { Button } from '../Button';
import './styles.scss';
import { Paragraph } from '../Text/Paragraph/Paragraph';

export interface ScanErrorContentProps {
    onRetry(): void;
    onManualEnter?(): void;
}

export const ScanErrorContent = ({ onRetry, onManualEnter }: ScanErrorContentProps) => {
    return (
        <>
            <div>
                <Paragraph>Nie udało się włączyć kamery potrzebnej do zeskanowania kodu QR.</Paragraph>
                <Paragraph>Sprawdź w ustawieniach aplikacji, czy ma ona <strong>pozwolenie na używanie kamery</strong> w twoim telefonie.</Paragraph>
                <Paragraph>Jeśli dodałeś tą aplikację do ekranu głównego, na systemie Android wejdź w <strong>Ustawienia &gt; Aplikacje &gt; Lerta Home &gt; Zezwolenia</strong>.</Paragraph>
                <Paragraph>Jeśli przeglądasz tą aplikację za pomocą przeglądarki Chrome, kliknij na <strong>kłódkę</strong> przy adresie strony, a następnie na <strong>Ustawienia Witryny</strong>.</Paragraph>
            </div>
            <div>
            <Button onClick={onRetry}>Spróbuj ponownie</Button>
            {onManualEnter && <Button outlined small onClick={onManualEnter}>Wpisz kod ręcznie</Button>}
            </div>
        </>
        )
    };
