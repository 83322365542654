import * as React from 'react';
import './style.scss';
import classNames from 'classnames'

export interface DataHighlightProps {
    label: string;
    unit: string;
    color?: 'primary' | 'secondary';
    children?: React.ReactNode;
}

export const DataHighlight = ({ label, children, unit, color }: DataHighlightProps) => (
    <div className={classNames("DataHighlight-container", color)}>
        <div className="DataHighlight-title">{label}</div>
        <div className="DataHighlight-value">{children}<span className="DataHighlight-unit">{unit}</span></div>
    </div>
);
