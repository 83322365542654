import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { MenuItem } from '@material-ui/core';
import { UnreadNotificationsCountStore } from '../../../stores/UnreadNotificationsCountStore/UnreadNotificationsCountStore';
import { inject, observer } from 'mobx-react';
import { AppConfig } from '../../../stores/AppConfig/AppConfig';

const Logout = require('../../../static/icons/logout.svg');

export interface NavLinkProps {
    children?: React.ReactNode;
    to: string;
    logo: React.ReactNode;
}

export const NavLink = ({ logo, children, to }: NavLinkProps) => (
    <MenuItem>
        <Link className="Nav-link" to={to}>
            {logo}
            <span className="Nav-text">{children}</span>
        </Link>
    </MenuItem>
);

export interface NavButtonProps {
    children?: React.ReactNode;
    onClick(): void;
    logo: React.ReactNode;
}

export const NavButton = ({ logo, children, onClick }: NavButtonProps) => (
    <MenuItem>
        <div className="Nav-link" onClick={onClick} >
            {logo}
            <span className="Nav-text">{children}</span>
        </div>
    </MenuItem>
);

export interface NavProps {
    onLogout(): void;
    onClose(): void;
    unreadNotificationsCountStore?: UnreadNotificationsCountStore;
    appConfig?: AppConfig;
}

export const MenuContent = inject("appConfig", "unreadNotificationsCountStore")(observer(({ onLogout, onClose, unreadNotificationsCountStore, appConfig }: NavProps) => {
    const contentParams = {
        unreadNotifications: unreadNotificationsCountStore!.count
    }
    return <div className="Nav-container" onClick={onClose} >
        <div className="Nav-title">Menu</div>
        {appConfig!.getMenuModules().map(enabledModule => {
            const Icon = enabledModule.menu!.outlinedIcon || enabledModule.menu!.icon;
            return enabledModule.component &&
                <NavLink key={enabledModule.url} logo={<Icon className="Nav-logo" />} to={enabledModule.url}>
                    {enabledModule.menu!.content ?
                        enabledModule.menu!.content({ label: enabledModule.menu!.label, ...contentParams }) :
                        enabledModule.menu!.label}
                </NavLink>
        }
        )}
        <div className="Nav-divider" />
        <NavButton logo={<Logout className="Nav-logo" />} onClick={onLogout}>Wyloguj</NavButton>
    </div>
}
))
