import * as React from 'react';
import './style.scss';

export interface OverlayFrameProps {
    children?: React.ReactNode;
    title: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    onMaskClick?: () => void;
}

export const OverlayFrame = ({children, onCancel, onConfirm, onMaskClick, title}: OverlayFrameProps) => (
    <div className="OverlayFrame-container">
        <div className="OverlayFrame-titleAndContent">
            <div className="OverlayFrame-titleSection">
                {onCancel ? <span className="OverlayFrame-cancel" onClick={onCancel}>Anuluj</span> : <span className="OverlayFrame-fakeButton" />}
                <span className="OverlayFrame-title">{title}</span>
                {onConfirm ? <span className="OverlayFrame-confirm" onClick={onConfirm}>Gotowe</span> : <span className="OverlayFrame-fakeButton" />}
            </div>
            <div className="OverlayFrame-content">
                {children}
            </div>
        </div>
        <div className="OverlayFrame-mask" onClick={onMaskClick} />
    </div>
);
