import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { TemperatureData } from '../DeviceData/TemperatureData';
import { HumidityData } from '../DeviceData/HumidityData';
import { VocData } from '../DeviceData/VocData';
import { ReconnectBatteryDeviceSecondTimePrompt } from '../../components/ReconnectDevice/ReconnectDeviceSecondPrompt/ReconnectDeviceSecondPrompt';
import { ConnectVocSensorDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { ReconnectBatteryDevicePrompt } from '../../components/ReconnectDevice/ReconnectDevicePrompt/ReconnectBatteryDevicePrompt/ReconnectBatteryDevicePrompt';
import { ReconnectVocSensorInstruction, FactoryResetVocSensorInstruction } from '../../components/Instructions/ReconnectInstructions';
import { VocSensorInstructions } from '../../components/Instructions/Instructions';

export class VocSensorDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);

        this.temperature = '-';
        this.humidity = '-';
        this.voc = '-';
        this.airQuality = '-';
        this.temperatureData = new TemperatureData(httpService, urlProvider, this);
        this.humidityData = new HumidityData(httpService, urlProvider, this);
        this.vocData = new VocData(httpService, urlProvider, this);

        this.dictionary = {
            addDeviceFormName: "czujnik LZO",
            suggestedDeviceName: "Salon",
            connectDevicePrompt: ConnectVocSensorDevicePrompt,
            reconnectDevicePrompt: ReconnectBatteryDevicePrompt,
            reconnectDeviceSecondPrompt: ReconnectBatteryDeviceSecondTimePrompt,
            instructions: {
                instruction: VocSensorInstructions,
                reconnect: ReconnectVocSensorInstruction,
                factoryReset: FactoryResetVocSensorInstruction,
            },
            defaultName: "Czujnik lzo bez nazwy",
            deviceLogo: 'czujnik_jakosci_powietrza.svg',
        };
    }
}
