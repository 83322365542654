import * as React from 'react';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Header } from '../Text/Header/Header';
import { Button } from '../Button';
import { ReconnectDevicePromptProps } from '../ReconnectDevice/ReconnectDevicePrompt/ReconnectDevicePrompt';
import { ReconnectDeviceSecondPromptProps } from '../ReconnectDevice/ReconnectDeviceSecondPrompt/ReconnectDeviceSecondPrompt';

export const Battery = require('../../static/EMI_sonda_icon.svg');

export const ReconnectEMIMeterPrompt = ({onInstructionsClick}: ReconnectDevicePromptProps) => (
    <div className="ReconnectEMIMeterPrompt-container">
        <div>
            <Battery className="ReconnectEMIMeterPrompt-logo" />
            <Paragraph className="ReconnectEMIMeterPrompt-paragraph">Nie udało się połączyć z czujnikiem EMI.</Paragraph>
            <Header className="ReconnectEMIMeterPrompt-header" bold>Wyjmij i włóż ponownie baterię do urządzenia!</Header>
            <Button className="ReconnectEMIMeterPrompt-button" inline secondary small onClick={onInstructionsClick}>
                Jak to zrobić?
            </Button>
        </div>
    </div>
);

export const ReconnectEMIMeterSecondTimePrompt = ({onInstructionClick}: ReconnectDeviceSecondPromptProps) => (
    <>
        <Paragraph className="ReconnectEMIMeterPrompt-paragraph">Nie udało się połączyć z czujnikiem EMI.</Paragraph>
        <Header className="ReconnectEMIMeterPrompt-header" bold>Przywróć ustawienia fabryczne tego czujnika.</Header>
        <Button className="ReconnectEMIMeterPrompt-button" small inline secondary onClick={onInstructionClick}>Jak to zrobić?</Button>
        <Paragraph>Proces łączenia z czujnikiem zostanie ponownie uruchomiony. Może potrwać do 15 minut.</Paragraph>
    </>
);
