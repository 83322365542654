import * as React from 'react';
import { Loader } from './Loader';
import './AddGateway.scss';
import { observer, inject } from 'mobx-react';
import { GatewayRegisterStore } from '../../stores/GatewayRegisterStore/GatewayRegisterStore';
import { ConnectGateway } from './ConnectGateway';
import { Gateway as GatewayWifiSettings } from '../Settings/Gateway/Gateway';
import { Success } from './Success';
import { CableConnection } from './CableConnection';

export type Display = 'connect-gateway' | 'cable-connection' | 'wifi-connection' | 'concrete-mixer';

export interface Props {
    gatewayRegisterStore?: GatewayRegisterStore;
}
export interface state {
    display: Display;
}

export const GatewayConfiguration = inject('gatewayRegisterStore')(
    observer(class GatewayConfiguration extends React.Component<Props, state> {
        constructor(props: Props) {
            super(props);
            this.state = {
                display: 'connect-gateway',
            };
        }

        render() {
            switch (this.state.display) {
                case 'connect-gateway':
                    return (
                        <ConnectGateway
                            onCableClick={() => this.setState({ display: 'cable-connection' })}
                            onWifiClick={() => this.setState({ display: 'wifi-connection' })}
                        />
                    );
                case 'cable-connection':
                    return (
                        <CableConnection onClick={() => this.setState({ display: 'concrete-mixer' })} />
                    )
                case 'wifi-connection':
                    return (
                        <GatewayWifiSettings onConnectedViaWifi={() => this.setState({ display: 'concrete-mixer' })} />
                    )
                case 'concrete-mixer':
                    if (this.props.gatewayRegisterStore!.registeredGateway) {
                        return <Success />;
                    }
                    return <Loader />;
            }
        }
    })
);
