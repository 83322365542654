import { alpha } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import './styles.scss'

interface Props {
    color?: string;
    small?: boolean;
    variant?: 'outlined' | 'contained'
}

export const AnalysisChartDot = ({ color = "#000", small = false, variant = 'contained' }: Props) => (
    <div className={classNames("Analysis-dot", { small }, variant)} style={{ borderColor: alpha(color, 0.35) }}>
        <div style={{ borderColor: color }} />
    </div>
)