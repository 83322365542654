import * as React from 'react';
import {inject, observer} from 'mobx-react';
import { GatewayWifiConfigStore } from '../../../stores/GatewayWifiConfigStore/GatewayWifiConfigStore';
import { StartingInstruction } from './StartingInstruction';
import { Networks } from './Networks';
import { SingleNetwork } from './SingleNetwork';
import { EndingInstruction } from './EndingInstruction';
import './styles.scss';
import { UserStore } from '../../../stores/UserStore/UserStore';
import { NetworkConnectionError } from './NetworkConnectionError';
import { GatewayWizard } from './GatewayWizard';
import { GatewaysStore } from '../../../stores/GatewaysStore/GatewaysStore';
import { Loader } from '../../Loader/Loader';
import { NoGatewayError } from './NoGatewayError';

export interface GatewayProps {
	gatewayWifiConfigStore?: GatewayWifiConfigStore;
	gatewaysStore?: GatewaysStore;
	userStore?: UserStore;
	onConnectedViaWifi?(): void;
}

export interface GatewayState {
	display: Display;
}

export type Display = 'gatewayWizard' | 'startingInstruction' | 'networks' | 'singleNetwork' | 'networkConnectionError' | 'endingInstruction';

export const Gateway = inject('gatewayWifiConfigStore', 'userStore', 'gatewaysStore')(
    observer(class Gateway extends React.Component<GatewayProps, GatewayState> {

        constructor(props) {
            super(props);
            this.state = {
                display: 'gatewayWizard'
            };
        }

        changeDisplay = (display: Display) => () => this.setState({display});

        render() {
            const gatewayWifiConfigStore = this.props.gatewayWifiConfigStore!;
            const userStore = this.props.userStore!;
            const gatewaysStore = this.props.gatewaysStore!;

            if (gatewaysStore.hasGateway === false) {
                return <NoGatewayError />;
            }

            if (gatewaysStore.currentGateway === null) {
                return <Loader />;
            }

            switch (this.state.display) {
                case 'gatewayWizard':
                    return <GatewayWizard 
                        startFromScreen={this.props.onConnectedViaWifi ? 1 : 2}
                        urlBack={this.props.onConnectedViaWifi ? undefined : "/settings"} 
                        onClick={this.changeDisplay('startingInstruction')}
                    />;
                case 'startingInstruction':
                    return <StartingInstruction wifiCode={gatewaysStore.currentGateway.serialNumber.slice(-4)} onConfirmClick={this.changeDisplay('networks')} />;
                case 'networks':
                    return <Networks onNetworkSelected={this.changeDisplay('singleNetwork')} />;
                case 'singleNetwork':
                    return gatewayWifiConfigStore.selectedNetwork && 
                        <SingleNetwork 
                            network={gatewayWifiConfigStore.selectedNetwork} 
                            onNetworkSelected={this.changeDisplay('endingInstruction')}
                            onChangeNetwork={this.changeDisplay('networks')}
                            onConnectiongError={this.changeDisplay('networkConnectionError')}
                        />;
                case 'networkConnectionError':
                    return gatewayWifiConfigStore.selectedNetwork &&
                        <NetworkConnectionError 
                            networkName={gatewayWifiConfigStore.selectedNetwork.ssid}
                            onRetry={this.changeDisplay('singleNetwork')} 
                            onChangeNetwork={this.changeDisplay('networks')} 
                        />
                case 'endingInstruction':
                    return gatewayWifiConfigStore.selectedNetwork && 
                        <EndingInstruction 
                            wifiCode={gatewaysStore.currentGateway.serialNumber.slice(-4)}
                            onClick={this.props.onConnectedViaWifi}
                            urlBack={userStore.isLoggedInFromRegistration ? '/settings/meter' : '/'} 
                        />;
            }
        }
    })
);
