import calendarStore from './CalendarStore';
import gatewayStore from './GatewayStore';
import meterStore from './MeterStore';
import consumptionStore from './ConsumptionStore';
import { SmartDevicesStore } from './SmartDevicesStore/SmartDevicesStore';
import { UrlProvider, LocalDevUrlProvider } from '../services/UrlProvider';
import { HttpService, LocalDevelopmentHttpService } from '../services/HttpService';
import { WebSocketService } from '../services/WebSocketService';
import { UserStore } from './UserStore/UserStore';
import { tokenDecoder } from '../utils/tokenDecoder';
import { GatewayWifiConfigStore } from './GatewayWifiConfigStore/GatewayWifiConfigStore';
import { MeterConfigStore } from './MeterConfigStore/MeterConfigStore';
import { APIBaseProvider, ProductionAPIBaseProvider, DevelopmentAPIBaseProvider, LocalDevelopmentAPIBaseProvider } from '../services/APIBaseProvider';
import { AddDeviceStore } from './AddDeviceStore/AddDeviceStore';
import { DeviceTypeStore } from './DeviceTypeStore/DeviceTypeStore';
import { EnterDeviceManuallyStore } from './EnterDeviceManuallyStore/EnterDeviceManuallyStore';
import { ConnectingDeviceStore } from './ConnectingDeviceStore/ConnectingDeviceStore';
import { SupplyPointsStore } from './SupplyPointsStore/SupplyPointsStore';
import { MeterRegisterStore } from './MeterRegisterStore/MeterRegisterStore';
import { PushNotificationsStore } from './PushNotificationsStore/PushNotificationStore';
import { MetersStore } from './MetersStore/MetersStore';
import { NotificationsStore } from './NotificationsStore/NotificationStore';
import { DevicesStore } from './DevicesStore/DevicesStore';
import { createBrowserHistory } from 'history';
import { MqttService } from '../services/MqttService';
import { MagneticMeterInitialValuesForm } from './MagneticMeterInitalValuesForm/MagneticMeterInitialValuesForm';
import { SingleDeviceFactory } from './SingleDevice/SingleDeviceFactory';
import { AccountDetailsStore } from './AccountDetailsStore/AccountDetailsStore';
import { LocationsStore } from './LocationsStore/LocationsStore';
import { ConnectionStartDates } from './ConnectingDeviceStore/ConnectionStartDatesStore';
import { GatewayRegisterStore } from './GatewayRegisterStore/GatewayRegisterStore';
import { GatewaysStore } from './GatewaysStore/GatewaysStore';
import { UserRegistrationStore } from './UserStore/Registration';
import { NotificationsListStore } from './NotificationsListStore/NotificationsListStore';
import { UnreadNotificationsCountStore } from './UnreadNotificationsCountStore/UnreadNotificationsCountStore';
import { NotificationStore } from './NotificationStore/NotificationStore';
import { DashboardStore } from './DashboardStore/DashboardStore';
import { AvailableFeaturesStore } from './AvailableFeatures/AvailableFeaturesStore';
import { AppConfig } from './AppConfig/AppConfig';
import LocaleStore from './LocaleStore';
import { AnalysisStore } from './Statistics/AnalysisStore';
import { BalanceStore } from './Statistics/BalanceStore';

const history = createBrowserHistory();

let apiBaseProvider: APIBaseProvider;
let urlProvider: UrlProvider;
let httpService: HttpService;

if (process.env.REACT_APP_LOCAL) {
	apiBaseProvider = new LocalDevelopmentAPIBaseProvider(process.env.REACT_APP_LOCAL);
	urlProvider = new LocalDevUrlProvider(apiBaseProvider);
	httpService = new LocalDevelopmentHttpService(apiBaseProvider);
} else if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_HOST) {
	apiBaseProvider = new DevelopmentAPIBaseProvider(process.env.REACT_APP_HOST);
	urlProvider = new UrlProvider(apiBaseProvider);
	httpService = new HttpService();
} else {
	apiBaseProvider = new ProductionAPIBaseProvider();
	urlProvider = new UrlProvider(apiBaseProvider);
	httpService = new HttpService();
}

const webSocketService = new WebSocketService();

const userStore = new UserStore(
	httpService,
	urlProvider,
	localStorage,
	tokenDecoder,
	() => {
		const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		const isChromeLike = !!(window as any).chrome;
		return isChromeLike && !iOS;
	}
);

const accountDetailsStore = new AccountDetailsStore(httpService, urlProvider, userStore);
const locationsStore = new LocationsStore(httpService, urlProvider, userStore);
const unreadNotificationsCountStore = new UnreadNotificationsCountStore(httpService, urlProvider, userStore);
const notificationsListStore = new NotificationsListStore(httpService, urlProvider, userStore, unreadNotificationsCountStore);
const notificationStore = new NotificationStore(httpService, urlProvider, notificationsListStore);

const supplyPointsStore = new SupplyPointsStore(httpService, urlProvider, locationsStore);
const singleDeviceFactory = new SingleDeviceFactory(httpService, urlProvider, localStorage, supplyPointsStore);
const gatewaysStore = new GatewaysStore(httpService, urlProvider, locationsStore);
const smartDevicesStore = new SmartDevicesStore(
	urlProvider,
	httpService,
	webSocketService,
	localStorage,
	singleDeviceFactory,
	locationsStore,
);
const deviceTypeStore = new DeviceTypeStore(httpService, urlProvider);

const gatewayWifiConfigStore = new GatewayWifiConfigStore(httpService, urlProvider);

const meterConfigStore = new MeterConfigStore(httpService, urlProvider, userStore);

const addDeviceStore = new AddDeviceStore(
	httpService,
	urlProvider,
	smartDevicesStore,
	singleDeviceFactory,
	locationsStore,
	deviceTypeStore,
);
const enterDeviceManuallyStore = new EnterDeviceManuallyStore(deviceTypeStore, gatewaysStore);

const metersStore = new MetersStore(
	httpService,
	urlProvider,
	locationsStore,
	singleDeviceFactory,
);

const meterRegisterStore = new MeterRegisterStore(httpService, urlProvider, metersStore, locationsStore);

const notificationsStore = new NotificationsStore(7 * 1000);

const pushNotificationsStore = new PushNotificationsStore(apiBaseProvider, userStore, httpService, url => history.push(url), notificationsStore);

const devicesStore = new DevicesStore(smartDevicesStore, metersStore);

const mqttService = new MqttService(urlProvider.getMqttWebSocketUrl(), apiBaseProvider.getTenant(), localStorage);
const connectionStartDates = new ConnectionStartDates(localStorage);

const connectingDeviceStore = new ConnectingDeviceStore(
	10 * 60 * 1000,
	connectionStartDates,
	devicesStore,
	notificationsStore,
	mqttService,
	url => history.push(url),
	httpService,
	urlProvider,
);

const magneticMeterInitialValueForm = new MagneticMeterInitialValuesForm();
const gatewayRegisterStore = new GatewayRegisterStore(
	httpService,
	urlProvider,
	mqttService,
	deviceTypeStore,
	locationsStore,
	gatewaysStore,
);

const userRegistrationStore = new UserRegistrationStore(httpService, urlProvider)

const dashboardStore = new DashboardStore(httpService, urlProvider, locationsStore, supplyPointsStore, localStorage, smartDevicesStore)
const analysisStore = new AnalysisStore(httpService, urlProvider, locationsStore)
const balanceStore = new BalanceStore(httpService, urlProvider, locationsStore)

const availableFeaturesStore = new AvailableFeaturesStore(httpService, urlProvider, userStore)
const appConfig = new AppConfig(availableFeaturesStore)
const localeStore = new LocaleStore();

userStore.init();
locationsStore.init();
supplyPointsStore.init();
connectingDeviceStore.init();
connectionStartDates.init();
gatewaysStore.init();
smartDevicesStore.init();
gatewayRegisterStore.init();

export const createStores = () => {
	return {
		consumptionStore,
		calendarStore,
		gatewayStore,
		meterStore,
		userStore,
		accountDetailsStore,
		notificationsListStore,
		unreadNotificationsCountStore,
		smartDevicesStore,
		deviceTypeStore,
		gatewayWifiConfigStore,
		meterConfigStore,
		addDeviceStore,
		enterDeviceManuallyStore,
		connectingDeviceStore,
		supplyPointsStore,
		meterRegisterStore,
		pushNotificationsStore,
		metersStore,
		notificationsStore,
		devicesStore,
		history,
		magneticMeterInitialValueForm,
		gatewayRegisterStore,
		gatewaysStore,
		userRegistrationStore,
		notificationStore,
		dashboardStore,
		availableFeaturesStore,
		appConfig,
		localeStore,
		analysisStore,
		balanceStore,
	};
}