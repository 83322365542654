import { DeviceData, DeviceDataDisplay } from './DeviceData';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { temperatureFormatter } from '../../utils/formatters';
import { MinMaxAvg } from '../../components/DeviceData/DataHighlights/MinMaxAvg';
import { DateMode } from '../../domain/DateMode';

const Temp = require('../../static/icons/czujnik_temperatury.svg');

const display: DeviceDataDisplay = {
    routeName: 'temperature',
    displayName: 'Temperatura',
    tooltipName: 'temp',
    Logo: Temp,
    DataHighlights: MinMaxAvg,
    chartType: 'line',
    chartUnit: "°C",
    displayUnit: "°",
    chartRange: [0, 30],
    valueFormatter: temperatureFormatter,
    showAvg: true
};

export class TemperatureData extends DeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, display);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id, 
            'temperature', 
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }
}
