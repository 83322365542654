import {DeviceDataDisplay} from './DeviceData';
import {HttpService} from '../../services/HttpService';
import {UrlProvider} from '../../services/UrlProvider';
import {DeviceDataItem} from '../../infrastructure/DeviceDataApiResponse';
import {kiloWattFormatter, currencyFormatter} from '../../utils/formatters';
import {EnergyUsage} from '../../components/DeviceData/DataHighlights/EnergyUsage';
import {DateMode} from '../../domain/DateMode';
import { Stat, ConsumptionDeviceData } from './ConsumptionDeviceData';
import { observable, computed, makeObservable } from 'mobx';
import { fromPromise } from 'mobx-utils';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { startOfMonth, endOfMonth } from 'date-fns';
import { override } from 'mobx';

const dailyDisplay: DeviceDataDisplay = {
    routeName: 'analysis',
    displayName: "Energia elektryczna",
    DataHighlights: EnergyUsage,
    displayUnit: "kWh",
    valueFormatter: kiloWattFormatter,
    costFormatter: currencyFormatter,
    showAvg: false,
    tabName: 'Analiza',
};

const monthlyDisplay: DeviceDataDisplay = {
    ...dailyDisplay,
};

interface ComponentData {
    name: string;
    value: number;
}

interface ConsumptionAndStatsData {
    components: ComponentData[];
    stats: Stat[];
    unit: string;
}

export class AnalysisData extends ConsumptionDeviceData {

    private distributionForDate: Map<string, ConsumptionAndStatsData | 'fetching' | 'fetchingFailed'> = new Map();

    constructor(httpService: HttpService, private urlProvider: UrlProvider, public device: SingleDevice) {
        super(httpService, device, dailyDisplay, monthlyDisplay);

        makeObservable<AnalysisData, "distributionForDate">(this, {
            distributionForDate: observable,
            fetch: override,
            dateMode: override,
            disaggregationComponents: computed,
            stat: override,
            fetchingConsumptionAndStats: computed,
            disaggregationStat: computed,
            disaggregationUnit: computed,
            fetchingStatsFailed: override,
            fetched: override
        });
    }

    async fetch() {
        const currentDate = this.date;
        this.distributionForDate.set(currentDate.toUTCString(), 'fetching');
        try {
            this.getConsumptionAndStats();
            const distriubtionResponse = await this.consumptionAndStatsPromise;
            if (distriubtionResponse) {
                this.distributionForDate.set(currentDate.toUTCString(), distriubtionResponse);
            } else {
                this.distributionForDate.delete(currentDate.toUTCString());
            }
        } catch {
            this.distributionForDate.set(currentDate.toUTCString(), 'fetchingFailed');
        }
    }

    get dateMode(): DateMode {
        return DateMode.Monthly;
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id, 
            'energy', 
            dateStart.toISOString(),
            dateEnd.toISOString(),
            'day'
        );
    }

    normalizeDataResults(items: DeviceDataItem[]) {
        if (this.dateMode === DateMode.Daily) {
            return super.normalizeDataResults(items)
                .map(({value, time}) => ({value: value * 1000, time}));
        } else {
            return super.normalizeDataResults(items);
        }
    }

    getConsumptionDistributionUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getConsumptionDistributionUrl(
            this.device.id,
            dateStart.toISOString(),
            dateEnd.toISOString(),
        );
    }

    getConsumptionAndStats(): void {
        this.consumptionAndStatsPromise = fromPromise(this.httpService.get(this.getConsumptionDistributionUrl(startOfMonth(this.date), endOfMonth(this.date))))
    }

    get disaggregationComponents(): ComponentData[] | null {
        const distribution = this.distributionForDate.get(this.date.toUTCString());
        if (distribution && distribution !== 'fetching' && distribution !== 'fetchingFailed') {
            return distribution.components;
        }

        if (this.consumptionAndStatsPromise === null || this.consumptionAndStatsPromise.state !== 'fulfilled' || !this.consumptionAndStatsPromise.value) {
            return null;
        }

        return this.consumptionAndStatsPromise.value.components && this.consumptionAndStatsPromise.value.components.length > 0 ?
        this.consumptionAndStatsPromise.value.components
        : null;
    }
    
    get stat(): Stat | null {
        const distribution = this.distributionForDate.get(this.date.toUTCString());
        if (distribution && distribution !== 'fetching' && distribution !== 'fetchingFailed' && distribution.stats && distribution.stats.length > 0) {
            return this.filterStats(distribution.stats)[0];
        }

        if (this.consumptionAndStatsPromise === null || this.consumptionAndStatsPromise.state !== 'fulfilled' || !this.consumptionAndStatsPromise.value) {
            return null;
        }
        
        return this.consumptionAndStatsPromise.value.stats &&  this.consumptionAndStatsPromise.value.stats.length > 0 ?
        this.filterStats(this.consumptionAndStatsPromise.value.stats)[0]
        : null;
    } 

    getStatsUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceStatsUrl(
            this.device.id,
            dateStart.toISOString(),
            dateEnd.toISOString(),
        );
    }

    filterStats(stats: Stat[]): Stat[] {
        return stats.filter(({consumptionUnit}) => consumptionUnit === dailyDisplay.displayUnit);
    }
    
    get fetchingConsumptionAndStats(): boolean {
        return this.consumptionAndStatsPromise !== null && this.consumptionAndStatsPromise.state === 'pending'
    }
    
    get disaggregationStat(): Stat | null {
        if (this.consumptionAndStatsPromise === null || this.consumptionAndStatsPromise.state !== 'fulfilled' || !this.consumptionAndStatsPromise.value) {
            return null;
        }
        
        return this.consumptionAndStatsPromise.value.stats &&  this.consumptionAndStatsPromise.value.stats.length > 0 ?
        this.consumptionAndStatsPromise.value.stats[0]
        : null;
    }    
    
    get disaggregationUnit(): string | null {
        if (this.consumptionAndStatsPromise === null || this.consumptionAndStatsPromise.state !== 'fulfilled' || !this.consumptionAndStatsPromise.value) {
            return null;
        }
        
        return this.consumptionAndStatsPromise.value.unit;
    }

    get fetchingStatsFailed(): boolean {
        return this.distributionForDate.get(this.date.toUTCString()) === 'fetchingFailed';
    }

    get fetched(): boolean {
        const distribution = this.distributionForDate.get(this.date.toUTCString());
        return !!distribution && distribution !== 'fetching' && distribution !== 'fetchingFailed';
    }
}
