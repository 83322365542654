import * as React from 'react';
import { InputAdornment, FormControl, InputLabel, Input as MDInput, FormHelperText } from '@material-ui/core';
import './styles.scss';
import { InputProps } from '@material-ui/core/Input';
import { observer } from 'mobx-react';

const Eye = require('../../static/icons/pass_eye.svg');

export interface Props {
    autoFocus?: boolean;
    type?: string;
    value: string;
    label: string;
    InputProps?: Partial<InputProps>;
    onChange(value: string): void;
    placeholder?: string;
    error?: string | null;
    required?: boolean;
    rows?: number;
}

export interface State {
    showPasswordText: boolean;
}

export const Input = observer(class Input extends React.Component<Props, State> {
    private input: React.RefObject<HTMLInputElement> = React.createRef();
    constructor(props: Props) {
        super(props);
        this.state = {
            showPasswordText: false,
        }
    }

    toggleShowPasswordText = () => {
        this.setState(({ showPasswordText }) => ({ showPasswordText: !showPasswordText }));
        this.input.current && this.input.current.focus();
    };

    render() {
        const { value, onChange, label, autoFocus, type, placeholder, error, required, rows } = this.props;

        return (
            <FormControl className="Input-input" required={required}>
                <InputLabel
                    classes={{
                        root: "Input-label",
                        focused: "Input-label-focused",
                        error: "Input-error"
                    }}
                    error={!!error}
                >{label}</InputLabel>
                <MDInput
                    inputRef={this.input}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    type={type !== 'password' ? type : (this.state.showPasswordText ? 'text' : 'password')}
                    error={!!error}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    multiline={!!rows}
                    rows={rows}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                    endAdornment={type === 'password' ? <InputAdornment position="end">
                        <Eye onClick={this.toggleShowPasswordText} className="Input-passwordEye" />
                    </InputAdornment> : undefined}
                />
                {error && <FormHelperText classes={{ root: "Input-error" }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
});

