import { DeviceData, DeviceDataDisplay } from './DeviceData';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { temperatureFormatter } from '../../utils/formatters';
import { DateMode } from '../../domain/DateMode';
import { TemperatureControl } from '../../components/DeviceData/DataActions/TemperatureControl';
import { MinMaxAvg } from '../../components/DeviceData/DataHighlights/MinMaxAvg';

const Temp = require('../../static/icons/czujnik_temperatury.svg');

const display: DeviceDataDisplay = {
    routeName: 'temperature',
    displayName: 'Temperatura',
    tooltipName: 'rzeczywista',
    targetTooltipName: 'zadana',
    Logo: Temp,
    DataHighlights: MinMaxAvg,
    ActionsComponent: TemperatureControl,
    chartType: 'line',
    chartUnit: "°C",
    displayUnit: "°",
    chartRange: [0, 30],
    valueFormatter: temperatureFormatter,
    showAvg: true,
};

export class ThermostatData extends DeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, display);
    }

    isTargetData = true

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id,
            'temperature',
            dateStart.toISOString(),
            dateEnd.toISOString(),
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        )
    }

    getTargetDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id,
            'targetTemperature',
            dateStart.toISOString(),
            dateEnd.toISOString(),
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        )
    }

}
