export enum MeterType {
    Electricity = 'electricity',
    Gas = 'gas',
    Heat = 'heat',
    WaterWarm = 'water_warm',
    WaterCold = 'water_cold',
    Inverter = 'inverter',
    Water = 'water'
}

export const isMeterType = (s: string): s is MeterType => {
    return [
        MeterType.Electricity, 
        MeterType.Gas, 
        MeterType.Heat, 
        MeterType.WaterCold, 
        MeterType.WaterWarm,
        MeterType.Inverter,
        MeterType.Water
    ].some(type => type === s);
}
