import { DateManager } from "./DateManager";
import { observable, computed, makeObservable } from 'mobx';
import { subDays, addDays, isToday, startOfDay, endOfDay, isSameDay } from 'date-fns';

export class DailyDateManager implements DateManager {
    date: Date;

    constructor(date: Date = new Date()) {
        makeObservable(this, {
            date: observable,
            isNow: computed,
            previousDate: computed,
            nextDate: computed
        });

        this.date = date;
    }

    get isNow(): boolean {
        return isToday(this.date);
    }

    get previousDate(): Date {
        return subDays(this.date, 1);
    }

    get nextDate(): Date {
        return addDays(this.date, 1);
    }

    setDate(date: Date): boolean {
        if (isSameDay(date, this.date)) {
            return false;
        } else {
            this.date = date;
            return true;
        }
    }

    getDates() {
        const startDate = startOfDay(this.date);
        const endDate = endOfDay(this.date);

        return { startDate, endDate };
    }
}