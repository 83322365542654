import { observable, computed, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { isFetching, fetched, getValue, fetchingFailed } from '../../utils/PromiseBasedObservable';
import { CustomerNotification, NotificationsListStore } from '../NotificationsListStore/NotificationsListStore';

export class NotificationStore {

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private notificationsListStore: NotificationsListStore) {
        makeObservable<NotificationStore, "id">(this, {
            id: observable,
            notificationPromise: computed,
            notification: computed,
            isFetching: computed,
            isFailed: computed,
            didFetch: computed
        });
    }

    private id: string | null = null;

    get notificationPromise(): IPromiseBasedObservable<CustomerNotification> | null {
        if (this.id === null) {
            return null;
        }
        return fromPromise(
            this.httpService.get(this.urlProvider.getNotificationUrl(this.id)).then((notification: CustomerNotification) => {
                this.notificationsListStore.markAsReaded(notification)
                return notification
            })
        );
    }

    get notification(): CustomerNotification | null {
        return getValue<CustomerNotification>(this.notificationPromise);
    }

    get isFetching(): boolean {
        return isFetching(this.notificationPromise);
    }

    get isFailed(): boolean {
        return fetchingFailed(this.notificationPromise);
    }

    get didFetch(): boolean {
        return fetched(this.notificationPromise);
    }

    set notificationId(id: string) {
        if (id !== this.id) {
            this.id = id
        }
    }
}
