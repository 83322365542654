import { mapTo } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject, Observable } from 'rxjs';

export class WebSocketService {
    private sockets: {[url: string]: WebSocketSubject<any>} = {};

    getSocket<E>(url: string, initialPayload: any): Observable<E> {
        let currentSocket = this.sockets[url];
        if (currentSocket) {
            currentSocket.unsubscribe();
        }
        return this.createSocket(url, initialPayload);
    }

    private createSocket<E>(url: string, initialPayload: any) {
        const openObserver = new Subject<any>();
        let wsSubject: WebSocketSubject<E>;

        openObserver
            .pipe(mapTo(initialPayload))
            .subscribe(payload => wsSubject && wsSubject.next(payload as any));

        wsSubject = webSocket({
            url,
            openObserver
        });

        return wsSubject;
    }
}