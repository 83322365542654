import * as React from 'react';
import './style.scss';

interface Props {
    visible: boolean;
    content?: string | number;
    children?: React.ReactNode
}

export const Badge =
    ({ visible, content, children }: Props) =>
        <div className="Badge">
            {children}
            <div className={`Badge__Badge${!visible ? ' Badge__Badge--hidden' : ''}${content === undefined ? ' Badge__Badge--noContent' : ''}`}>
                <span>
                    {content && +content > 99 ? "99+" : content}
                </span>
            </div>
        </div>