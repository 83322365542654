import * as React from "react";
import { inject, observer } from "mobx-react";
import { List, ListItem } from "@material-ui/core";
import { GatewayWifiConfigStore, Network } from "../../../stores/GatewayWifiConfigStore/GatewayWifiConfigStore";
import './styles.scss';
import { Button } from "../../Button";
import { FullScreenContainer } from "../../FullScreenContainer/FullScreenContainer";
import { Loader } from "../../Loader/Loader";
import { Paragraph } from "../../Text/Paragraph/Paragraph";

const Icon = require('../../../static/icons/choose_wifi_icon.svg');

export interface NetworksProps {
	gatewayWifiConfigStore?: GatewayWifiConfigStore;
	onNetworkSelected(): void;
}

export const Networks = inject('gatewayWifiConfigStore')(observer(class Networks extends React.Component<NetworksProps> {

	componentDidMount() {
		this.props.gatewayWifiConfigStore!.getAvailableNetworks();
	}

	handleItemClick = (network: Network) => () => {
		this.props.gatewayWifiConfigStore!.selectedNetwork = network;
		this.props.onNetworkSelected();
	}

	render() {
		return (
			<FullScreenContainer gradient>
				<div className="Networks-content">
					<Paragraph>Wybierz sieć Wifi z listy poniżej, przez którą centralka ma się łaczyć z Internetem.</Paragraph>
					<Icon className="Networks-icon" />
					<List className="Networks-listContainer">
						{this.props.gatewayWifiConfigStore!.loadingAvailableNetworks && <Loader secondary/>}
						<div className="Networks-list">{this.props.gatewayWifiConfigStore!.availableNetworks.map((network, i) => (
								<ListItem
									button
									onClick={this.handleItemClick(network)}
									key={network.ssid}
									className="Networks-listItem"
								>
									{network.ssid}
								</ListItem>
							)
						)}</div>
						{this.props.gatewayWifiConfigStore!.noNetworksFound
							&& <Paragraph>Nie znaleziono żadnych sieci</Paragraph>}
					</List>
				</div>
				{!this.props.gatewayWifiConfigStore!.loadingAvailableNetworks &&
					<Button onClick={this.props.gatewayWifiConfigStore!.getAvailableNetworks}>Wyszukaj ponownie</Button>}
			</FullScreenContainer>
		);
	}
}));
