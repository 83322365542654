import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { LocationsStore } from "../LocationsStore/LocationsStore";
import { autorun, observable, computed, action, makeObservable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { GatewaysApiResponse, Gateway } from "../../infrastructure/GatewaysApiResponse";


export class GatewaysStore {
    private gatewaysPromise: IPromiseBasedObservable<GatewaysApiResponse> | null = null;

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private locationsStore: LocationsStore,
    ) {
        makeObservable<GatewaysStore, "gatewaysPromise">(this, {
            gatewaysPromise: observable,
            refetchGateways: action.bound,
            gateways: computed,
            currentGateway: computed,
            fetchingGatewaysFailed: computed,
            hasConnectedGateway: computed,
            hasGateway: computed
        });
    }

    init() {
        autorun(() => {
            this.fetchGateways();
        });
    }

    refetchGateways() {
        return this.fetchGateways();
    }

    get gateways(): Gateway[] | null {
        if (this.gatewaysPromise === null || this.gatewaysPromise.state !== 'fulfilled') {
            return null;
        }

        return this.gatewaysPromise.value.items || [];
    }

    get currentGateway(): Gateway | null {
        const gateways = this.gateways;

        if (gateways === null) {
            return null;
        }

        return gateways[0] || null;
    }

    get fetchingGatewaysFailed(): boolean {
        return this.gatewaysPromise !== null && this.gatewaysPromise.state === 'rejected';
    }

    get hasConnectedGateway(): boolean | null {
        if (this.locationsStore.locations === null) {
            return null;
        }

        if (this.locationsStore.locations[0] === undefined) {
            return false;
        }

        if (this.gatewaysPromise === null || this.gatewaysPromise.state !== 'fulfilled') {
            return null;
        }

        return this.gatewaysPromise.value.items !== undefined && 
            this.gatewaysPromise.value.items[0] !== undefined && this.gatewaysPromise.value.items[0].paired;
    }

    get hasGateway(): boolean | null {
        if (this.locationsStore.locations === null) {
            return null;
        }

        if (this.locationsStore.locations[0] === undefined) {
            return false;
        }

        if (this.gatewaysPromise === null || this.gatewaysPromise.state !== 'fulfilled') {
            return null;
        }

        return this.gatewaysPromise.value.items !== undefined && 
            this.gatewaysPromise.value.items[0] !== undefined;
    }

    public gatewayByExternalId(externalId: string): Gateway | null {
        if (this.gateways === null) {
            return null;
        }
        
        const gateway = this.gateways.find(gateway => gateway.externalId === externalId);

        return gateway || null;
    }

    private async fetchGateways() {
        if (this.locationsStore.locations !== null && this.locationsStore.locations[0]) {
            this.gatewaysPromise = fromPromise(
                this.httpService.get<GatewaysApiResponse>(
                    this.urlProvider.getGatewaysUrl(this.locationsStore.locations[0].id)
                )
            );
            await this.gatewaysPromise;
        }
    }
}