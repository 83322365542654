import { IPromiseBasedObservable } from "mobx-utils";

export const isFetching = (promise: IPromiseBasedObservable<any> | null): boolean => {
    if (promise === null || promise.state !== 'pending') {
        return false;
    }

    return true;
};

export const fetchingFailed = (promise: IPromiseBasedObservable<any> | null): boolean => {
    if (promise === null || promise.state !== 'rejected') {
        return false;
    }

    return true;
};

export const fetched = (promise: IPromiseBasedObservable<any> | null): boolean => {
    return promise !== null && promise.state === 'fulfilled';
};

export function getValue <A, B>(promise: IPromiseBasedObservable<A> | null, map: (a: A) => B): B | null;
export function getValue<A>(promise: IPromiseBasedObservable<A> | null): A | null;
export function getValue (promise: IPromiseBasedObservable<any> | null, map?: (a: any) => any): any | null {
    if (promise === null || promise.state !== 'fulfilled') {
        return null;
    }

    return map ? map(promise.value) : promise.value;
}