import * as React from 'react';
import { observer } from 'mobx-react';
import { DeviceData } from '../../../stores/DeviceData/DeviceData';
import { DataHighlights } from './index';

export interface MinMaxAvgProps {
    deviceData: DeviceData;
}

export const MinMaxAvgHelpLZO = observer(({deviceData}: MinMaxAvgProps) => (
    <DataHighlights
        unit={deviceData.display.displayUnit}
        valueFormatter={deviceData.display.valueFormatter}
        help="lzo"
        highlights={[
            { label: "Min", value: deviceData.getMinValue() },
            { label: "Średnia", value: deviceData.getAverageValue() },
            { label: "Max", value: deviceData.getMaxValue() },
        ]}
    />
));
