export class Timeout<T extends unknown[]> {
    private timeout: NodeJS.Timeout | null = null
    constructor(
        private callback: (...args: T) => void,
        private time: number
    ) { }

    set = (...args: T) => {
        this.clear()
        this.timeout = global.setTimeout(() => this.callback(...args), this.time)
    }

    setTime = (time: number) => {
        this.time = time;
    }

    private clear = () => {
        if (this.timeout) {
            global.clearTimeout(this.timeout)
        }
        this.timeout = null
    }
}