import { DeviceData, DeviceDataDisplay } from './DeviceData';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { humidityFormatter } from '../../utils/formatters';
import { MinMaxAvg } from '../../components/DeviceData/DataHighlights/MinMaxAvg';
import { DateMode } from '../../domain/DateMode';

const Humidity = require('../../static/icons/czujnik_wilgotnosci.svg');

const display: DeviceDataDisplay = {
    routeName: 'humidity',
    displayName: "Wilgotność",
    tooltipName: "wilg",
    Logo: Humidity,
    DataHighlights: MinMaxAvg,
    chartType: 'line',
    chartUnit: "%",
    displayUnit: "%",
    chartRange: [0, 100],
    valueFormatter: humidityFormatter,
    showAvg: true
};

export class HumidityData extends DeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, display);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id, 
            'humidity',
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }
}
