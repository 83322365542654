import * as React from 'react';
import { Button } from '../Button';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { MeterKind } from '../../domain/MeterKind';
import {
	CorrectOpticInstallInstruction,
	FirstOpticIncorrectInstallInstruction,
	SecondOpticIncorrectInstallInstruction,
	PreInstallInstruction
} from '../AddMeterInstruction/AddMeterInstruction';
import { MeterType } from '../../domain/MeterType';
import { Paragraph } from '../Text/Paragraph/Paragraph';

export interface Props extends AbstractInstructionProps {
	meterKind: MeterKind;
}

export const Instruction = ({ meterKind, meterType, onClick }: Props) => (
	meterKind === MeterKind.Optic ?
		<OpticInstruction onClick={onClick} meterType={meterType} /> :
		<MagneticInstruction onClick={onClick} meterType={meterType} />
);

export interface AbstractInstructionProps {
	onClick(): void;
	meterType: MeterType;
}

export const meterTypeToLabelMap = (meterType: MeterType): string => {
	switch (meterType) {
		case MeterType.Electricity:
			return 'energii elektrycznej';
		case MeterType.Gas:
			return 'gazu';
		case MeterType.Heat:
			return 'ciepła';
		case MeterType.WaterCold:
			return 'zimnej wody';
		case MeterType.WaterWarm:
			return 'ciepłej wody';
		case MeterType.Inverter:
			return 'produkcji energii';
		case MeterType.Water:
			return 'wody';
	}
};

export const OpticInstruction = ({ onClick, meterType }: AbstractInstructionProps) => (
	<FullScreenContainer gradient>
		<Header>Zainstaluj Lerta Meter na liczniku {meterTypeToLabelMap(meterType)}.</Header>
		<PreInstallInstruction />
		<CorrectOpticInstallInstruction />
		<div className="AddMeter-instructionBreak" />
		<FirstOpticIncorrectInstallInstruction />
		<SecondOpticIncorrectInstallInstruction />
		<Button onClick={onClick}>
			Gotowe
		</Button>
	</FullScreenContainer>
);

const MagneticInstallInstruction = require('../../static/enegry_counter_plus_LM.svg');

export const MagneticInstruction = ({ onClick, meterType }: AbstractInstructionProps) => (
	<FullScreenContainer gradient>
		<Header>Zainstaluj Lerta Meter na liczniku {meterTypeToLabelMap(meterType)}.</Header>
		<MagneticInstallInstruction className="AddMeter-magneticInstruction" />
		<Paragraph className="AddMeter-magneticInstructionParagraph">Przyklej Lerta Meter powierzchnią samoprzylepną pod panel pomiarowy.</Paragraph>
		<Button onClick={onClick}>
			Gotowe
		</Button>
	</FullScreenContainer>
);
