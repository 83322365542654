export interface SmartDevicesWSEvent {
    status: number;
    deviceId?: string;
    state?: string;
    key?: string;
    value?: string | number;
}

export const isTemperatureChange = (event: SmartDevicesWSEvent) =>
    withKeyAndValue(event) && event.key!.startsWith('temperature');

export const isTargetTemperatureChange = (event: SmartDevicesWSEvent) =>
    withKeyAndValue(event) && event.key!.startsWith('targetTemperature');

export const isHumidityChange = (event: SmartDevicesWSEvent) =>
    withKeyAndValue(event) && event.key!.startsWith('humidity');

export const isVocChange = (event: SmartDevicesWSEvent) =>
    withKeyAndValue(event) && event.key!.startsWith('voc');

export const isEnergyChange = (event: SmartDevicesWSEvent) =>
    withKeyAndValue(event) && event.key!.startsWith('energy_W_abs');

export const isSwitchChange = (event: SmartDevicesWSEvent) =>
    withState(event) && (event.state! === 'on' || event.state! === 'off');

export const isOpenedChange = (event: SmartDevicesWSEvent) =>
    withState(event) && (event.state! === 'opened' || event.state! === 'closed');

export const isAlarmChange = (event: SmartDevicesWSEvent) =>
    withState(event) && (event.state! === 'fire' || event.state! === 'flood' || event.state! === 'standby');

const withState = ({ state }: SmartDevicesWSEvent) => !!state;

const withKeyAndValue = ({ key, value }: SmartDevicesWSEvent) => !!key && !!value;
