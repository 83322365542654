import * as React from 'react';
import './styles.scss';

export interface Props {
    children?: React.ReactNode;
}

export const TileList = ({children}: Props) => (
    <div className="TileList-container">
        {children}
    </div>
)
