import {DeviceDataDisplay} from './DeviceData';
import {HttpService} from '../../services/HttpService';
import {UrlProvider} from '../../services/UrlProvider';
import {SingleDevice} from '../SingleDevice/SingleDevice';
import {currencyFormatter, metersCubedFormatter} from '../../utils/formatters';
import {EnergyUsage} from '../../components/DeviceData/DataHighlights/EnergyUsage';
import {DateMode} from '../../domain/DateMode';
import {ConsumptionDeviceData, Stat} from "./ConsumptionDeviceData";

const Energy = require('../../static/icons/zuzycie.svg');

const dailyDisplay: DeviceDataDisplay = {
    routeName: 'gas',
    displayName: "Zużycie gazu",
    tooltipName: 'zużycie',
    Logo: Energy,
    DataHighlights: EnergyUsage,
    chartType: 'bar',
    chartUnit: "m³",
    displayUnit: "m³",
    chartRange: [0, 0.001],
    valueFormatter: metersCubedFormatter,
    costFormatter: currencyFormatter,
    showAvg: false
};

const monthlyDisplay: DeviceDataDisplay = {
    ...dailyDisplay,
    chartRange: [0, 0.001],
};

export class GasUsageData extends ConsumptionDeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, dailyDisplay, monthlyDisplay);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {        
        return this.urlProvider.getDeviceDataUrl(
            this.device.id, 
            'gas',
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }

    getStatsUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceStatsUrl(
            this.device.id,
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
        );
    }

    getConsumptionDistributionUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getConsumptionDistributionUrl(
            this.device.id,
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
        );
    }
    
    filterStats(stats: Stat[]): Stat[] {
        return stats;
    }
}
