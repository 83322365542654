import './styles.scss';
import * as React from "react";

import ArrowBack from '../../static/icons/arrow_back.svg'

export interface Props {
    onNextButton?(): void;
}

export const Skip = ({onNextButton}: Props) => (
    <div className='SkipButton-wrapper'>
        {onNextButton &&
        <div onClick={onNextButton} className="SkipButton">
            Pomiń <div className="SkipButton-arrowForward"><ArrowBack/></div>
        </div>
        }
    </div>
);