import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { ThermostatData } from '../DeviceData/ThermostatData';

export class ThermostatDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);

        this.temperature = '-';
        this.targetTemperature = '-';
        this.temperatureWithTargetData = new ThermostatData(httpService, urlProvider, this);
        this.getCurrentValue = () => this.temperature


        this.dictionary = {
            addDeviceFormName: "termostat",
            suggestedDeviceName: "Termostat ...",
            connectDevicePrompt: () => null,
            reconnectDevicePrompt: () => null,
            reconnectDeviceSecondPrompt: () => null,
            instructions: {
                instruction: () => null,
                reconnect: () => null,
                factoryReset: () => null,
            },
            defaultName: "Termostat bez nazwy",
            deviceLogo: 'termostat-ikona.svg',
        };
    }
}
