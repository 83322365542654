import * as React from 'react';
import { Button } from '../../Button';
import './style.scss';

export interface Props {
	children?: React.ReactNode;
	onRetry(): void;
}

export const FetchingDevicesFailed = ({children, onRetry}: Props) => (
	<div className="FetchingDevicesFailed-container">
		<div className="FetchingDevicesFailed-error">{children}</div>
		<Button onClick={onRetry}>Spróbuj ponownie</Button>
	</div>
);
