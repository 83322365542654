import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { TemperatureData } from '../DeviceData/TemperatureData';
import { ConnectWindowSensorDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { ReconnectBatteryDeviceSecondTimePrompt } from '../../components/ReconnectDevice/ReconnectDeviceSecondPrompt/ReconnectDeviceSecondPrompt';
import { ReconnectBatteryDevicePrompt } from '../../components/ReconnectDevice/ReconnectDevicePrompt/ReconnectBatteryDevicePrompt/ReconnectBatteryDevicePrompt';
import { ReconnectWindowSensorInstruction, FactoryResetWindowSensorInstruction } from '../../components/Instructions/ReconnectInstructions';
import {  WindowSensorInstructions } from '../../components/Instructions/Instructions';

export class WindowSensorDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);

        this.temperature = '-';
        this.opened = '-';
        this.temperatureData = new TemperatureData(httpService, urlProvider, this);

        this.dictionary = {
            addDeviceFormName: "czujnik drzwi i okien",
            suggestedDeviceName: "Drzwi wejściowe",
            connectDevicePrompt: ConnectWindowSensorDevicePrompt,
            reconnectDevicePrompt: ReconnectBatteryDevicePrompt,
            reconnectDeviceSecondPrompt: ReconnectBatteryDeviceSecondTimePrompt,
            instructions: {
                instruction: WindowSensorInstructions,
                reconnect: ReconnectWindowSensorInstruction,
                factoryReset: FactoryResetWindowSensorInstruction,
            },
            defaultName: "Czujnik drzwi i okien bez nazwy",
            deviceLogo: 'czujnik_drzwi.svg',
        };
    }
}
