import { SmartDevicesItem } from "../../infrastructure/SmartDevicesApiResponse";
import { UrlProvider } from "../../services/UrlProvider";
import { HttpService } from "../../services/HttpService";
import { SingleDevice } from "./SingleDevice";
import { SmartplugDevice } from "./SmartplugDevice";
import { SmartcableDevice } from "./SmartcableDevice";
import { SmartbulbDevice } from "./SmartbulbDevice";
import { HumiditySensorDevice } from "./HumiditySensorDevice";
import { VocSensorDevice } from "./VocSensorDevice";
import { WindowSensorDevice } from "./WindowSensorDevice";
import { SmokeSensorDevice } from "./SmokeSensorDevice";
import { FloodSensorDevice } from "./FloodSensorDevice";
import { EMIMeterDevice } from "./EMIMeterDevice";
import { LertaMeterDevice } from "./LertaMeterDevice";
import { ThermostatDevice } from "./ThermostatDevice";
import { SupplyPointsStore } from "../SupplyPointsStore/SupplyPointsStore";
import { MeterType, isMeterType } from "../../domain/MeterType";

export class SingleDeviceFactory {
    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private storage: Storage,
        private supplyPointsStore: SupplyPointsStore,
    ) { }

    async fromDeviceApiResponse(response: SmartDevicesItem): Promise<SingleDevice> {
        const device = await this.fromDeviceClass(response.class, response.subclass, response.supplyPointId);

        if (/^\s+$/.test(response.name)) {
            device.name = null;
        } else {
            device.name = response.name;
        }

        device.roomType = response.roomType || null
        device.id = response.id;
        device.externalId = response.externalId;
        device.serialNumber = response.serialNumber;
        device.supplyPointId = response.supplyPointId;
        device.calibrationFactor = response.calibrationFactor ? response.calibrationFactor : null;
        device.calibrationOffset = response.calibrationOffset ? response.calibrationOffset : null;

        device.paired = response.paired;
        if (response.paired === false) {
            device.connection.initiateAsConnecting();
        }

        if (response.creationTime) {
            device.creationTime = new Date(response.creationTime);
        }

        return device;
    }

    async fromDeviceClass(
        deviceClass: string,
        deviceSubclass?: string,
        supplyPointId?: string,
    ): Promise<SingleDevice> {
        let device: SingleDevice;
        const { urlProvider, httpService, storage } = this;
        switch (deviceClass) {
            case 'smartplug':
                device = new SmartplugDevice(urlProvider, httpService, storage);
                break;
            case 'smartcable':
                device = new SmartcableDevice(urlProvider, httpService, storage);
                break;
            case 'smartbulb':
                device = new SmartbulbDevice(urlProvider, httpService, storage);
                break;
            case 'thermostat':
                device = new ThermostatDevice(urlProvider, httpService, storage);
                break;
            case 'sensor':
                if (deviceSubclass === "humidity_temperature") {
                    device = new HumiditySensorDevice(urlProvider, httpService, storage);
                    break;
                }
                if (deviceSubclass === "air_quality") {
                    device = new VocSensorDevice(urlProvider, httpService, storage);
                    break;
                }
                if (deviceSubclass === "window") {
                    device = new WindowSensorDevice(urlProvider, httpService, storage);
                    break;
                }
                if (deviceSubclass === "smoke") {
                    device = new SmokeSensorDevice(urlProvider, httpService, storage);
                    break;
                }
                if (deviceSubclass === "flood") {
                    device = new FloodSensorDevice(urlProvider, httpService, storage);
                    break;
                }
                device = new SingleDevice(urlProvider, httpService, storage);
                break;
            case 'meter':
                let type: MeterType | undefined;
                if (supplyPointId !== undefined) {
                    const supplyPoint = await this.supplyPointsStore.getSupplyPoint(supplyPointId);
                    if (supplyPoint !== null && isMeterType(supplyPoint.type)) {
                        type = supplyPoint.type;
                    }
                }
                const disaggregatableSupplyPointTypes = await this.supplyPointsStore.getDisaggregatableSupplyPointTypes();
                const displayAnalysis = !!disaggregatableSupplyPointTypes && disaggregatableSupplyPointTypes.length > 0 && !!type && disaggregatableSupplyPointTypes.indexOf(type) !== -1;
                if (deviceSubclass === "emi") {
                    device = new EMIMeterDevice(urlProvider, httpService, storage, displayAnalysis);
                    break;
                }

                device = new LertaMeterDevice(urlProvider, httpService, storage, type, displayAnalysis);
                break;
            case 'inverter':
                type = MeterType.Inverter
                device = new LertaMeterDevice(urlProvider, httpService, storage, type);
                break;
            default:
                device = new SingleDevice(urlProvider, httpService, storage);
        }

        device.class = deviceClass;
        device.subclass = deviceSubclass || null;
        return device;
    }

}