import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps, Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { PWABanner } from '../PWABanner/PWABanner';
import './Login.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import { Button } from '../Button';
import { Input } from '../Input';
import { config } from '../../utils/config';

const Logo = require('mainLogo.svg');

export interface LoginProps extends RouteComponentProps<any> {
	userStore?: UserStore;
}

export interface LoginState {
	loading: boolean;
}

const Login = inject('userStore')(observer(class Login extends React.Component<LoginProps, LoginState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false
		};
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		await this.props.userStore!.login.submit();
		this.setState({ loading: false });
	};

	render() {
		return (
			<div className="Login-container">
				{this.props.userStore!.isLoggedIn ? <Redirect to="/" /> : null}
				<Logo className="Login-logo" />
				<form onSubmit={this.handleSubmit} className="Login-form">
					<div className="Login-inputs">
						<Input
							autoFocus
							type="email"
							value={this.props.userStore!.login.mail.value}
							error={this.props.userStore!.login.mail.error}
							onChange={value => this.props.userStore!.login.mail.value = value}
							label="Login"
						/>
						<Input
							type="password"
							value={this.props.userStore!.login.password.value}
							error={this.props.userStore!.login.password.error}
							onChange={value => this.props.userStore!.login.password.value = value}
							label="Hasło"
						/>
						<div>
							{this.props.userStore!.login.loginDataHasErrors &&
								<div className="Login-error">Błędne dane logowania.</div>}
							{this.props.userStore!.login.authorizationFailed &&
								<div className="Login-error">Logowanie nie powiodło się. Upewnij się, że podałeś poprawny login i hasło.</div>}
						</div>
						<div className="Login-links">
							<Link to="/forgotPassword">Zapomniałem hasła</Link>
						</div>
					</div>
					<Button loading={this.state.loading}>Zaloguj</Button>
					{config.availableRoutes.register && <Link to="/register"><Button outlined>Zarejestruj</Button></Link>}
				</form>
				<PWABanner />
			</div>
		);
	}
}));

export default withRouter(Login);
