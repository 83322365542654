import { Module } from './../../Config';
import { config } from './../../utils/config';
import { AvailableFeaturesStore } from './../AvailableFeatures/AvailableFeaturesStore';

export class AppConfig {
    enabledModules: Module[] = [];

    constructor(public availableFeaturesStore: AvailableFeaturesStore) {
    }

    private get prepareEnabledModules(): Module[] {
        if (!this.availableFeaturesStore.availableFeatures) {
            return [];
        }
        return config.availableModules.all.filter((m) => {
            if (Array.isArray(m.availableFeatures)) {
                return !!m.availableFeatures.find((feature) => this.availableFeaturesStore!.availableFeatures!.availableFeatures.indexOf(feature) >= 0)
            }
            return this.availableFeaturesStore!.availableFeatures!.availableFeatures.indexOf(m.availableFeatures) >= 0;
        })
    }

    private get prepareMenuModules(): Module[] {
        const features = this.availableFeaturesStore.availableFeatures
        if (!features) {
            return [];
        }
        const availableModules = config.availableModules.all.filter(m => m.menu)
        return Object.values(features.availableFeatures.reduce<{ [name: string]: Module }>((menuModules, feature) => {
            availableModules
                .filter(module => module.availableFeatures.includes(feature))
                .forEach(module => menuModules[module.url] = module)
            return menuModules
        }, {}))
    }

    getEnabledModules(): Module[] {
        return this.prepareEnabledModules;
    }

    getBarModules(): Module[] {
        return this.prepareMenuModules.slice(0, 3);
    }

    getMenuModules(): Module[] {
        return this.prepareMenuModules.slice(3);
    }

    getDefaultPath(): string {
        return config.availableModules.default
    }

}

