const Fridge = require('../static/icons/fridge.svg')
const Oven = require('../static/icons/oven.svg')
const Dishwasher = require('../static/icons/dish_washer.svg')
const Washingmachine = require('../static/icons/washing_machine.svg')
const Microwave = require('../static/icons/microwave_oven.svg')
const Kettle = require('../static/icons/kettle.svg')
const Toaster = require('../static/icons/toaster.svg')
const Other = require('../static/icons/other_appliances.svg')

interface BalloonConfig {
    name: string, 
    color: string, 
    icon: string,
    className: string
}

export class BalloonConfigFactory {
    
    static balloonDictionary: { [key: string]: BalloonConfig; } = {
        'fridge':  {
            'name': 'Lodówka',
            'color': '#6f6bc8',
            'icon': Fridge,
            'className': 'ApplianceBalloon-icon-fridge'
        },
        'kettle': {
            'name': 'Czajnik elektryczny',
            'color': '#0f8aea',
            'icon': Kettle,
            'className': 'ApplianceBalloon-icon-kettle'
        },
        'cooker': {
            'name': 'Piekarnik',
            'color': '#b660eb',
            'icon': Oven,
            'className': 'ApplianceBalloon-icon-oven'
        },
        'dishwasher': {
            'name': 'Zmywarka',
            'color': '#60bdeb',
            'icon': Dishwasher,
            'className': 'ApplianceBalloon-icon-dishwasher'
        },
        'washing_machine': {
            'name': 'Pralka',
            'color': '#147db1',
            'icon': Washingmachine,
            'className': 'ApplianceBalloon-icon-machine'
        },
        'microwave': {
            'name': 'Mikrofalówka',
            'color': '#790974',
            'icon': Microwave,
            'className': 'ApplianceBalloon-icon-microwave'
        },
        'iron': {
            'name': 'Żelazko',
            'color': '',
            'icon': '',
            'className': ''
        },
        'boiler': {
            'name': 'Bojler',
            'color': '',
            'icon': '',
            'className': ''
        },
        'coffee_machine': {
            'name': 'Ekspres do kawy',
            'color': '',
            'icon': '',
            'className': ''
        },
        'computer': {
            'name': 'Komputer',
            'color': '',
            'icon': '',
            'className': ''
        },
        'laptop': {
            'name': 'Laptop',
            'color': '',
            'icon': '',
            'className': ''
        },
        'TV': {
            'name': 'Telewizor',
            'color': '',
            'icon': '',
            'className': ''
        },
        'aggregate': {
            'name': 'Agregat',
            'color': '',
            'icon': '',
            'className': ''
        },
        'cooktop': {
            'name': 'Płyta elektryczna',
            'color': '',
            'icon': '',
            'className': ''
        },
        'vacuum_cleaner': {
            'name': 'Odkurzacz',
            'color': '',
            'icon': '',
            'className': ''
        },
        'toaster': {
            'name': 'Toster',
            'color': '#FAB44A',
            'icon': Toaster,
            'className': 'ApplianceBalloon-icon-toaster'
        },
        'tumble_dryer': {
            'name': 'Szuszarka',
            'color': '',
            'icon': '',
            'className': ''
        },
        'other': {
            'name': 'Inne',
            'color': '#4a4a4a',
            'icon': Other,
            'className': 'ApplianceBalloon-icon-other'
        },
    };

    static getConfigByName(name: string): BalloonConfig {  
        return this.balloonDictionary[name]
    }
};
