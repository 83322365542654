import { observable, action, computed, autorun, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { Input } from "../Form/Input";
import { Form } from "../Form/Form";
import { notEmpty } from "../Form/validators";
import { UserStore } from "./UserStore";
import { TokenDecoder } from "../../domain/TokenDecoder";

export class Login {
    mail: Input = new Input(notEmpty('Adres e-mail nie może być pusty.'));
    password: Input = new Input(notEmpty('Hasło nie może być puste.'));
    private form: Form = new Form([this.mail, this.password]);

    authorizationFailed: boolean = false;

    get loginDataHasErrors(): boolean {
        return this.form.hasErrors;
    }

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private storageService: Storage,
        protected tokenDecoder: TokenDecoder,
        private userStore: UserStore
    ) {
        makeObservable<Login, "getTokenAndUserId">(this, {
            mail: observable,
            password: observable,
            authorizationFailed: observable,
            loginDataHasErrors: computed,
            submit: action.bound,
            logout: action.bound,
            getTokenAndUserId: action
        });

        autorun(() => {
            this.authorizationFailed = false;
            let mail = this.mail.value;
            let password = this.password.value;
            return mail && password
        });
    }

    async submit(fromRegistration: boolean = false, onSuccess: () => void = () => { }) {
        this.authorizationFailed = false;

        return this.form.submit(async () => {
            try {
                await this.getTokenAndUserId();
            } catch (e) {
                const mail = this.mail.value;
                this.form.resetForm();
                this.mail.value = mail;

                this.authorizationFailed = true;
                return;
            }

            this.userStore.isLoggedIn = true;
            this.userStore.isLoggedInFromRegistration = fromRegistration;

            onSuccess();

            this.form.resetForm();
        });
    }

    logout() {
        this.storageService.removeItem('auth_token');
        window.location.reload();
    }

    private async getTokenAndUserId() {
        const { token } = await this.httpService.makeRequest<{ token: string }>(
            this.urlProvider.getAuthorizationUrl(),
            'POST',
            undefined,
            { Authorization: this.urlProvider.getAuthorizationHeader(this.mail.value.trim(), this.password.value) }
        );

        this.storageService.setItem('auth_token', token);
        this.userStore.setToken(token);
    }
}