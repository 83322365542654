import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { AddDeviceStore } from '../../stores/AddDeviceStore/AddDeviceStore';
import { SmartDevicesStore } from '../../stores/SmartDevicesStore/SmartDevicesStore';
import { AddDeviceForm } from './AddDeviceForm/AddDeviceForm';
import { AddDeviceScanner } from './AddDeviceScanner/AddDeviceScanner';
import { AddDeviceSuccess } from './AddDeviceSuccess/AddDeviceSuccess';
import { WhereIsCode } from './WhereIsCode/WhereIsCode';
import { EnterDeviceManually } from './EnterDeviceManually/EnterDeviceManually';
import { GatewayRegisterStore } from '../../stores/GatewayRegisterStore/GatewayRegisterStore';
import { RouteComponentProps } from 'react-router';
import { Error } from '../AddGateway/Error';
import { GatewaysStore } from '../../stores/GatewaysStore/GatewaysStore';

export interface AddDeviceProps extends RouteComponentProps<{}> {
    addDeviceStore?: AddDeviceStore;
    smartDevicesStore?: SmartDevicesStore;
    gatewayRegisterStore?: GatewayRegisterStore;
    gatewaysStore?: GatewaysStore;
}

type Display = 'where-is-code' | 'scanner' | 'enter-device-manually' | 'device-form' | 'connect-device' | 'device-instruction' | 'device-success' | 'gateway-error';

export interface AddDeviceState {
    display: Display;
    showInvalidCodeMessage: boolean;
    errorType: string;
}

export class UndecoratedAddDevice extends React.Component<AddDeviceProps, AddDeviceState> {
    constructor(props: AddDeviceProps) {
        super(props);
        const display = this.props.smartDevicesStore!.devices.length === 0 ? 'where-is-code' : 'scanner';
        this.state = {
            display,
            errorType: 'default',
            showInvalidCodeMessage: false
        };
    }

    componentDidMount() {
        this.props.addDeviceStore!.resetForm();
    }

    handleScan = async (code: string) => {
        try {
            await this.props.gatewayRegisterStore!.setGatewayCode(code);
            this.registerGateway(code)
        } catch (err) {
            this.registerDevice(code);
        }
    };

    async registerGateway(code: string) {
        try {
            await this.props.gatewayRegisterStore!.registerGateway();
            this.props.history.push('/add_gateway/configuration');
        } catch (err) {
            this.setState({ display: 'gateway-error', errorType: this.getErrorType(err) });
        }
    }

    async registerDevice(code: string) {
        this.props.addDeviceStore!.originalCode = code;
        this.setState({ display: 'device-form' });
    }

    render() {
        const { addDeviceStore, gatewaysStore } = this.props;
        const Instruction = addDeviceStore!.addedDeviceDictionary ? addDeviceStore!.addedDeviceDictionary!.instructions.instruction : null;
        switch (this.state.display) {
            case 'where-is-code':
                return <WhereIsCode
                    onNextWasClicked={() => this.setState({ display: 'scanner' })}
                    onEnterManuallyWasClicked={() => this.setState({ display: 'enter-device-manually' })}
                />;
            case 'scanner':
                return (
                    <AddDeviceScanner
                        onEnterManuallyClick={() => this.setState({ display: 'enter-device-manually' })}
                        onScan={this.handleScan}
                        onShowWhereIsCode={() => this.setState({ display: 'where-is-code' })}
                    />
                );
            case 'enter-device-manually':
                return <EnterDeviceManually onSuccess={(enteredCode) => {
                    const code = `|${enteredCode.serialNumber}||${enteredCode.productNumber}`;
                    this.handleScan(code);
                }} />;

            case 'device-form':
                return <AddDeviceForm gateways={gatewaysStore!.gateways} onDeviceWasAdded={() => this.setState({ display: 'device-instruction' })} />;
            case 'device-instruction':
                return Instruction && <Instruction onNext={() => this.setState({ display: 'device-success' })} />;
            case 'device-success':
                return <AddDeviceSuccess onAddAnotherDeviceWasClicked={() => this.setState({ display: 'scanner' })} />;
            case 'gateway-error':
                return <Error
                    onRetry={() => this.setState({ display: 'scanner' })}
                    onClose={() => this.props.history.push('/devices/smart_home')}
                    errorType={this.state.errorType}
                />;
        }
    }

    private getErrorType(err: any): string {
        return err.status === 409 ? 'conflict' : 'default';
    }
}
export const AddDevice = inject('addDeviceStore', 'smartDevicesStore', 'gatewayRegisterStore', 'gatewaysStore')(observer(UndecoratedAddDevice))