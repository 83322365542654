import { autorun, observable, action } from 'mobx';

class MeterStore {
	@observable meterConfigIndex = 0;
	@action nextStep() {
		this.meterConfigIndex++
	}
	@action restartConfigIndex() {
		this.meterConfigIndex = 0;
	}
	@observable meterQrCode = null;
	@action setMeterQrCode(code) {
		this.meterQrCode = code;
	}
	@observable meterSuccess = false;
	@action setMeterSuccess(bool) {
		this.meterSuccess = bool;
	}
	@observable isFirstScan = true;
	@action setIsFirstScan(bool) {
		this.isFirstScan = bool;
	}
}

const meterStore = (window as any as { meterStore: MeterStore }).meterStore = new MeterStore();
export default meterStore;

autorun(() => {
	//TODO remove
});
