import * as React from 'react';
import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Table,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';
import * as queryString from "query-string";

interface Props {
	location: Location;
}
function Help({ location }: Props) {

	const content = [
		{
			question: <p>Jak uruchomić centralkę Lerta Home?</p>,
			key: 'gateway',
			answer: <p>To bardzo proste - ustaw urządzenie w dogodnym dla siebie miejscu (np. obok dekodera TV lub routera), a następnie postępuj zgodnie z instrukcjami wyświetlanymi w aplikacji mobilnej w trakcie pierwszego uruchomienia. Możesz też w każdej chwili dokonać ponownej konfiguracji poprzez opcję "Konfiguracja centralki" w aplikacji. Jeśli nie chcesz konfigurować sieci WiFi w urządzeniu, alternatywnie możesz je podłączyć przewodem Ethernet bezpośrednio do routera domowego.</p>
		},
		{
			question: <p>Jak zainstalować wtyczkę Lerta Plug?</p>,
			key: 'smartPlug',
			answer: <p>Inteligentną wtyczkę należy podłączyć między gniazdko domowe a urządzenie, które z niego korzysta. W przypadku urządzeń niepodłączonych na stałe (np. żelazko, suszarka do włosów itp.) sugerujemy by wtyczkę zamocować na stałe do przewodu zasilającego urządzenia.</p>
		},
		{
			question: <p>Jak zamocować czujnik otwarcia Lerta Door/Window Sensor?</p>,
			key: 'windowSensor',
			answer: <p>Czujnik otwarcia składa się z dwóch elementów - większej bazy sensora oraz mniejszego magnesu. Bazę należy zamocować na nieruchomej części drzwi lub okna (ramie), najlepiej w jej górnej części. Aby to zrobić, wyczyść dokładnie miejsce montażu dołączoną ściereczką, odklej warstwę wierzchnią taśmy dwustronnej z tyłu bazy, ustaw urządzenie tak, by strzałka na obudowie skierowana była w kierunku ruchomego skrzydła drzwi lub okna i dociśnij do wyczyszczonej powierzchni ramy. Następnie zamknij drzwi/okno i w identyczny sposób zamocuj magnes na ruchomej części jak najbliżej bazy. Odległość między elementami nie powinna być większa niż 0,5 cm.</p>
		},
		{
			question: <p>Jak zamocować czujnik wilgotności Lerta Humidity Sensor?</p>,
			key: 'humiditySensor',
			answer: <p>Czujnik ustaw w pomieszczeniu, które chcesz monitorować np. na półce lub komodzie. Jeśli zależy Ci na trwalszym sposobie montażu, użyj załączonych akcesoriów do montażu na ścianie.</p>
		},
		{
			question: <p>Co oznacza LZO?</p>,
			key: 'lzo',
			answer: (<div>Lotne Związki Organiczne (LZO) występują jako uboczne produkty w wielu procesach przemysłowych i stanowią źródło zanieczyszczeń środowiska. Niektóre z nich mogą powodować zarówno krótko, jak i długotrwałe niekorzystne skutki zdrowotne.
						<br /><Table style={{ textAlign: "left", marginTop: "10px" }}>
					<thead>
						<tr style={{ fontWeight: "bold", borderBottom: "1px solid lightgray", textAlign: "center" }}>
							<td style={{ paddingRight: "5px" }}>Poziom i czas ekspozycji</td>
							<td>Zalecenia</td>
						</tr>
					</thead>
					<tbody>
						<tr style={{ borderBottom: "1px solid lightgray" }}>
							<td style={{ paddingRight: "5px" }}><span style={{ color: "#D54939" }}>Niezdrowa</span><br />Godziny</td>
							<td>Przebywać tylko wtedy, gdy jest taka konieczność przy intensywnej wentylacji</td>
						</tr>
						<tr style={{ borderBottom: "1px solid lightgray" }}>
							<td style={{ paddingRight: "5px" }}><span style={{ color: "#F28D2D" }}>Kiepska</span><br />{"< 1 miesiąc"}</td>
							<td>Intensywna wentylacja oraz wietrzenie</td>
						</tr>
						<tr style={{ borderBottom: "1px solid lightgray" }}>
							<td style={{ paddingRight: "5px" }}><span style={{ color: "#FFC300" }}>Umiarkowana</span><br />{"< 1 rok"}</td>
							<td>Intensywna wentylacja oraz zalecane wietrzenie</td>
						</tr>
						<tr style={{ borderBottom: "1px solid lightgray" }}>
							<td style={{ paddingRight: "5px" }}><span style={{ color: "#94C356" }}>Dobra</span><br />Brak limitu</td>
							<td>Wentylacja oraz zalecane wietrzenie</td>
						</tr>
						<tr>
							<td style={{ paddingRight: "5px" }}><span style={{ color: "#5BB152" }}>Doskonała</span><br />Brak limitu</td>
							<td>Wartość docelowa</td>
						</tr>
					</tbody>
				</Table>
			</div>),
		},
	];

	const expand = queryString.parse(location.search).expand
	return (
		<div className="Help-container">
			{content.map((panel, i) => {
				return (
					<ExpansionPanel elevation={0} key={i} defaultExpanded={expand === panel.key}>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<div className="Help-question">{panel.question}</div>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div className="Help-answer">{panel.answer}</div>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)
			})}
		</div>
	)
}

export default Help;