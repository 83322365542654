import { differenceInMonths, endOfDay, startOfDay, isSameDay } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { fetched, fetchingFailed, getValue, isFetching } from "../../utils/PromiseBasedObservable";
import { LocationsStore } from "../LocationsStore/LocationsStore";

export type AnalysisData = {
    time: number;
    unit: string;
    production?: number;
    autoConsumption?: number;
    exportToGrid?: number;
    importFromGrid?: number;
}

export type AnalysisDataAPI = {
    time: string;
    unit: string;
    production?: number;
    autoConsumption?: number;
    exportToGrid?: number;
    importFromGrid?: number;
}
export enum AnalysisViewType {
    daily = 'daily',
    period = 'period',
}

export class AnalysisStore {

    from: Date = startOfDay(Date.now());
    to: Date = endOfDay(Date.now());

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private locationsStore: LocationsStore,
    ) {
        makeObservable(this, {
            from: observable,
            to: observable,
            promise: computed,
            data: computed,
            accuracy: computed,
            series: computed,
            viewType: computed,
            fetching: computed,
            failed: computed,
            fetched: computed,
            locationId: computed,
            setPeriod: action.bound,
        });
    }

    get viewType(): AnalysisViewType {
        return !!this.from && !!this.to && isSameDay(this.from, this.to)
            ? AnalysisViewType.daily
            : AnalysisViewType.period
    }

    get promise(): IPromiseBasedObservable<AnalysisDataAPI[]> | null {
        if (this.locationId === null) {
            return null
        }
        const url = this.urlProvider.getStatisticsAnalysisUrl(this.locationId, this.from, this.to, this.accuracy, this.series)
        return fromPromise(this.httpService.get(url))
    }

    get data(): AnalysisData[] | null {
        return getValue<AnalysisDataAPI[], AnalysisData[]>(this.promise, resp => resp?.map(dp => ({
            ...dp,
            time: new Date(dp.time).getTime(),
        })))
    }

    get fetching(): boolean {
        return isFetching(this.promise);
    }

    get failed(): boolean {
        return fetchingFailed(this.promise);
    }

    get fetched(): boolean {
        return fetched(this.promise);
    }

    get accuracy(): string {
        if (this.viewType === AnalysisViewType.daily) {
            return 'PT15M'
        }
        const diff = differenceInMonths(this.to, this.from)
        if (diff <= 0) {
            return 'P1D'
        }
        if (diff >= 12) {
            return 'P1M'
        }
        return 'P7D'
    }

    get series(): string {
        switch (this.viewType) {
            case AnalysisViewType.daily:
                return 'power'
            case AnalysisViewType.period:
                return 'energy'
            default:
                return ""
        }
    }

    get locationId() {
        const { locations } = this.locationsStore
        return locations && !!locations.length ? locations[0].id : null
    }

    setPeriod(from: Date, to: Date) {
        this.from = from
        this.to = to
    }

}
