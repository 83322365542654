import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import './style.scss';

export interface Props {
    secondary?: boolean;
    size?: number;
}

export const Loader = ({secondary, size}: Props) => (
    <div><CircularProgress className={`Loader-container ${secondary ? 'Loader-secondary' : ''}`} size={size ? size : 100} /></div>
);
