import React from 'react';
import './styles.scss'
import { AnalysisChartDot } from './AnalysisChartDot'
import { FormattedMessage } from 'react-intl';
import { AnalysisViewType } from '../../../stores/Statistics/AnalysisStore';
import { seriesColor } from '../../../SeriesColor';

interface Props {
    variant: AnalysisViewType;
}

export const AnalysisChartLegend = ({ variant }: Props) => (
    <div className="Analysis-legend">
        <div className="Analysis-legend__series">
            <AnalysisChartDot color={seriesColor('production')} variant={variant === AnalysisViewType.daily ? 'outlined' : 'contained'} />
            <FormattedMessage id="statistics.production" />
        </div>
        <div className="Analysis-legend__series">
            <AnalysisChartDot color={seriesColor('autoConsumption')} />
            <FormattedMessage id="statistics.autoConsumption" />
        </div>
        <div className="Analysis-legend__series">
            <AnalysisChartDot color={seriesColor('exportToGrid')} />
            <FormattedMessage id="statistics.exportToGrid" />
        </div>
        <div className="Analysis-legend__series">
            <AnalysisChartDot color={seriesColor('importFromGrid')} />
            <FormattedMessage id="statistics.importFromGrid" />
        </div>
    </div>
)