import * as React from 'react';
import './styles.scss';

export interface Props {
    children?: React.ReactNode;

    className?: string;

    bold?: boolean;
}

export const Header = ({children, className, bold}: Props) => (
    <h1 className={`Header-container ${bold ? 'Header-bold' : ''} ${className || ''}`}>{children}</h1>
)
