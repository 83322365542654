import * as React from "react";
import { Button } from "../../Button";
import { FullScreenContainer } from "../../FullScreenContainer/FullScreenContainer";
import { Paragraph } from "../../Text/Paragraph/Paragraph";
import { Header } from "../../Text/Header/Header";

export interface EndingInstructionProps {
	networkName: string;
	onRetry(): void;
	onChangeNetwork(): void;
}

export class NetworkConnectionError extends React.Component<EndingInstructionProps> {
	render() {
		return (
			<FullScreenContainer>
				<div>
					<Paragraph>
						Nie udało się połączyć z siecią
					</Paragraph>
					<Header>{this.props.networkName}</Header>
				</div>
				<div>
					<Button onClick={this.props.onRetry}>Spróbuj ponownie</Button>
					<Button small inline secondary onClick={this.props.onChangeNetwork}>Wybierz inną sieć</Button>
				</div>
			</FullScreenContainer>
		);
	}
}
