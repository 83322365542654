import * as React from 'react';
import { observer } from 'mobx-react';

export interface Props {
    component(openInstruction: () => void): React.ReactNode;
    instruction(closeInstruction: () => void): React.ReactNode;
}

export interface State {
    showInstruction: boolean;
}

export const WithInstruction = observer(class WithInstruction extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            showInstruction: false,
        }
    }

    render() {
        if (this.state.showInstruction) {
            return this.props.instruction(() => this.setState({showInstruction: false}));
        } else {
            return this.props.component(() => this.setState({showInstruction: true}));
        }
    }
});
