import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Button } from '../Button';
import { PlugGateway } from './PlugGateway';

const Instruction = require('../../static/icons/central_device_lan_connection.svg');

export interface Props {
    onCableClick(): void;
    onWifiClick(): void;
}

export interface State {
    choice: 'cable' | 'wifi' | null;
}

export class ConnectGateway extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            choice: null,
        };
    }

    render() {
        if (this.state.choice !== null) {
            return <PlugGateway
                onClick={() => this.state.choice === 'cable' ?
                    this.props.onCableClick() :
                    this.props.onWifiClick()
                }
            />;
        }

        return (
            <FullScreenContainer>
                <Header>Podłącz centralkę do Twojego routera za pomocą załączonego przewodu LAN.</Header>
                <Instruction className="AddGateway-instruction" />
                <Button onClick={() => this.setState({ choice: 'cable' })}>Gotowe</Button>
                <Paragraph className="AddGateway-connectGatewayParagraph">Twój router nie pozwala na podłączenie przewodu LAN?</Paragraph>
                <Button onClick={() => this.setState({ choice: 'wifi' })} small inline secondary>Skorzystaj z wifi</Button>
            </FullScreenContainer>
        );
    }
}
