import * as React from 'react';
import './styles.scss';
import { Paragraph } from '../../../Text/Paragraph/Paragraph';
import { Header } from '../../../Text/Header/Header';
import { Button } from '../../../Button';
import { ReconnectDevicePromptProps } from '../ReconnectDevicePrompt';

export const Battery = require('../../../../static/bateria.svg');

export const ReconnectBatteryDevicePrompt = ({deviceName, onInstructionsClick}: ReconnectDevicePromptProps) => (
    <div className="ReconnectBatteryDevicePrompt-container">
        <div>
            <Battery className="ReconnectBatteryDevicePrompt-logo" />
            <Paragraph className="ReconnectBatteryDevicePrompt-paragraph">Nie udało się połączyć z urządzeniem{deviceName ? '' : '.'}</Paragraph>
            <Paragraph className="ReconnectBatteryDevicePrompt-deviceName">{deviceName ? ` ${deviceName}` : ''}.</Paragraph>
            <Header className="ReconnectBatteryDevicePrompt-header" bold>Wyjmij i włóż ponownie baterię do urządzenia!</Header>
            <Button className="ReconnectBatteryDevicePrompt-button" inline secondary small onClick={onInstructionsClick}>
                Jak to zrobić?
            </Button>
        </div>
    </div>
);
