import * as React from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import { SingleDevice } from '../../../../../stores/SingleDevice/SingleDevice';
import classNames from 'classnames';

export interface Props {
    device: SingleDevice;
    children: React.ReactNode
}

export const TemperatureControl = observer(class TemperatureControl extends React.Component<Props> {
    private originalTemprature: number | '-' = '-';
    private controlTimeout?: NodeJS.Timeout;

    componentDidMount() {
        const { device } = this.props;
        this.originalTemprature = (device.targetTemperature && Math.round(parseFloat(device.targetTemperature.toString()) * 2) / 2) || '-';
    }

    componentDidUpdate(prevProps) {
        const { device } = this.props;
        if (device.targetTemperature !== prevProps.device.targetTemperature) {
            this.originalTemprature = (device.targetTemperature && Math.round(parseFloat(device.targetTemperature.toString()) * 2) / 2) || '-';
        }
    }

    render() {
        const { children } = this.props;

        return (
            <div className="TemperatureControl-container">
                <button className={classNames('button', 'minus')} onClick={this.changeTemperature('down')} />
                {children}
                <button className={classNames('button', 'plus')} onClick={this.changeTemperature('up')} />
            </div >)
    }

    changeTemperature = (direction: 'up' | 'down') => (event) => {
        event.preventDefault()
        if (this.controlTimeout) {
            clearTimeout(this.controlTimeout);
        }
        const { device } = this.props
        if (device.targetTemperature && (typeof device.targetTemperature === "number")) {

            direction === 'up' && (device.targetTemperature += 0.5)
            direction === 'down' && (device.targetTemperature -= 0.5)

            this.controlTimeout = global.setTimeout(
                () => {
                    this.props.device.setTemperature(+device.targetTemperature!)
                        .then(() => this.originalTemprature = device.targetTemperature!)
                        .catch(err => device.targetTemperature = this.originalTemprature)
                }, 1500
            )
        }
    }
});;