import { autorun, computed, observable, when, makeObservable } from "mobx";
import {HttpService} from "../../services/HttpService";
import {UrlProvider} from "../../services/UrlProvider";
import {SupplyPointsApiResponse} from "../../infrastructure/SupplyPointsApiResponse";
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import {LocationsStore} from "../LocationsStore/LocationsStore";
import {SupplyPointStore} from "./SupplyPointStore";

export class SupplyPointsStore {
    supplyPointsPromise: IPromiseBasedObservable<SupplyPointsApiResponse | null> | null = null;
    disaggregatableSupplyPointsPromise: IPromiseBasedObservable<string[]> | null = null;

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private locationsStore: LocationsStore) {
        makeObservable(this, {
            supplyPointsPromise: observable,
            disaggregatableSupplyPointsPromise: observable,
            supplyPoints: computed,
            disaggregatableSupplyPointTypes: computed
        });
    }

    init() {
        autorun(() => {
            if (this.locationsStore.locations !== null && this.locationsStore.locations[0]) {
                this.supplyPointsPromise = fromPromise(
                    this.httpService.get<SupplyPointsApiResponse>(
                        this.urlProvider.getSupplyPointsUrl(this.locationsStore.locations[0].id)
                    )
                );
                this.disaggregatableSupplyPointsPromise = fromPromise(this.httpService.get<string[]>(this.urlProvider.getDisaggregatableSupplyPointTypesUrl()));
            }
        });
    }

    get supplyPoints(): SupplyPointStore[] | null {
        if (this.locationsStore.locations !== null && this.locationsStore.locations.length === 0) {
            return [];
        }

        if (this.supplyPointsPromise === null || this.supplyPointsPromise.state !== 'fulfilled') {
            return null;
        }
        
        const value = this.supplyPointsPromise.value;

        if (!value || !value.items) {
            return [];
        }

        return value.items.map((SupplyPoint)=> new SupplyPointStore(SupplyPoint.consumptionData, SupplyPoint.href, SupplyPoint.id, SupplyPoint.type, this.httpService, this.urlProvider));
    }

    async getSupplyPoint(supplyPointId: string): Promise<SupplyPointStore | null> {
        await when(() => this.supplyPoints !== null);

        return this.supplyPoints!.find(({id}) => id === supplyPointId) || null;
    }

    get disaggregatableSupplyPointTypes(): string[] | null {
        if (this.disaggregatableSupplyPointsPromise === null || this.disaggregatableSupplyPointsPromise.state !== 'fulfilled') {
            return null;
        }

        return this.disaggregatableSupplyPointsPromise.value ? this.disaggregatableSupplyPointsPromise.value : [];
    }

    async getDisaggregatableSupplyPointTypes(): Promise<string[] | null> {
        await when(() => this.disaggregatableSupplyPointTypes !== null);
        return this.disaggregatableSupplyPointTypes;
    }
}
