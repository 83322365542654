import { Theme, makeStyles, MenuItem, FormControl, Select, InputLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import { endOfMonth, startOfMonth, subDays, subMonths, endOfDay, endOfQuarter, endOfISOWeek, endOfYear, isEqual, startOfDay, startOfQuarter, startOfISOWeek, startOfYear, subQuarters, subWeeks, subYears, differenceInDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';

export interface Shortcut {
    name: string;
    value: Interval;
}

interface Props {
    interval?: Interval;
    shortcuts?: Shortcut[];
    onClick: (shortcutValue: Interval) => void;
    oneDayView?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    shortcutsSelect: {
        width: '100%',
    },
    shortcut: {
        borderRadius: 0,
        fontWeight: 'normal',
        '&>span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            display: 'block',
        }
    },
    active: {
        fontWeight: 'bold'
    }
}))

const today = new Date();
export const ShortcutsMap = {
    TODAY: { name: 'TODAY', value: { start: startOfDay(today), end: endOfDay(today) } },
    YESTERDAY: { name: 'YESTERDAY', value: { start: startOfDay(subDays(today, 1)), end: endOfDay(subDays(today, 1)) } },
    THIS_WEEK: { name: 'THIS_WEEK', value: { start: startOfISOWeek(today), end: endOfISOWeek(today) } },
    LAST_WEEK: { name: 'LAST_WEEK', value: { start: startOfISOWeek(subWeeks(today, 1)), end: endOfISOWeek(subWeeks(today, 1)) } },
    THIS_MONTH: { name: 'THIS_MONTH', value: { start: startOfMonth(today), end: endOfMonth(today) } },
    LAST_MONTH: { name: 'LAST_MONTH', value: { start: startOfMonth(subMonths(today, 1)), end: endOfMonth(subMonths(today, 1)) } },
    THIS_QUARTER: { name: 'THIS_QUARTER', value: { start: startOfQuarter(today), end: endOfQuarter(today) } },
    LAST_QUARTER: { name: 'LAST_QUARTER', value: { start: startOfQuarter(subQuarters(today, 1)), end: endOfQuarter(subQuarters(today, 1)) } },
    THIS_YEAR: { name: 'THIS_YEAR', value: { start: startOfYear(today), end: endOfYear(today) } },
    LAST_YEAR: { name: 'LAST_YEAR', value: { start: startOfYear(subYears(today, 1)), end: endOfYear(subYears(today, 1)) } },
}
const defaultShortcuts: Shortcut[] = Object.values(ShortcutsMap);

const areIntervalsEqual = (a: Interval, b: Interval) => {
    return isEqual(a.start, b.start) && isEqual(a.end, b.end);
}

export const Shortcuts = ({ interval, shortcuts = defaultShortcuts, onClick, oneDayView }: Props) => {
    const classes = useStyles();
    const filteredShortcuts = oneDayView ? shortcuts.filter(s => differenceInDays(s.value.end, s.value.start) === 0) : shortcuts
    const value = useMemo(() => filteredShortcuts.find((shortcut) => interval && areIntervalsEqual(shortcut.value, interval)),
        [interval, filteredShortcuts]);

    return <>
        <FormControl className={classes.shortcutsSelect}>
            <InputLabel ><FormattedMessage id={"superDateRangePicker.shortcuts"} /></InputLabel>
            <Select
                onChange={(e: any) => onClick((filteredShortcuts || []).find((shortcut) => shortcut.name === e.target.value)?.value!)}
                value={value ? value.name : ""}
            >
                {filteredShortcuts.map(shortcut =>
                    <MenuItem value={shortcut.name} key={shortcut.name}><FormattedMessage id={`superDateRangePicker.shortcuts.${shortcut.name}`} /></MenuItem>
                )}
            </Select>
        </FormControl>
    </>
}