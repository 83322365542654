import * as React from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '../../stores/UserStore/UserStore';
import { App } from '../App';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import NewPassword from '../NewPassword';
import UserActivation from '../UserActivation';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { Router } from 'react-router';
import { config } from '../../utils/config';
import { History } from 'history';
import { Registration } from '../Registration/Registration';
import { TechnicalBreak } from '../TechnicalBreak/TechnicalBreak';
import { TermsOfUse } from '../TermsOfUse/TermsOfUse';

export interface LoginOrAppProps {
    history: History;
    userStore: UserStore;
}

export const LoginOrApp = observer(class LoginOrApp extends React.Component<LoginOrAppProps, any> {
    render() {
        return process.env.REACT_APP_UC === 'true' ? <TechnicalBreak /> : (
            <Router history={this.props.history}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/forgotPassword" component={ForgotPassword} />
                    <Route path="/passwords/reset" component={NewPassword} />
                    <Route path="/activate" component={UserActivation} />
                    {config.availableRoutes.register && <Route path="/register" component={Registration} />}
                    {config.termsOfUse && <Route path='/termsOfUse' component={TermsOfUse} />}
                    {this.props.userStore.isLoggedIn ?
                        <Route component={App} /> :
                        <Route render={() => <Redirect to="/login" />} />
                    }
                </Switch>
            </Router>
        );
    }
});
