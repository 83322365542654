import * as React from 'react';
import './AddMeter.scss';
import { Button } from '../Button';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';

export interface WhereIsCodeProps {
    onNextWasClicked(): void;
    onEnterManuallyWasClicked(): void;
}

export const WhereIsHANMeterCode = ({ onNextWasClicked, onEnterManuallyWasClicked }: WhereIsCodeProps) => (
    <FullScreenContainer gradient>
        <Header>Aby aktywować udostępnianie danych pomiarowych przez licznik AMI do urządzenia HAN należy:</Header>
        <ol className="WhereIsMeterCode-list">
            <li>Zalogować się na konto w systemie TAURON eLicznik.</li>
            <li>Wejść do zakładki Licznik / HANplus.</li>
            <li>Wybrać z PORT HAN opcję Aktywuj HAN.</li>
            <li>W ciągu jednego dnia roboczego na koncie Klienta w systemie TAURON eLicznik pojawi się informacja na temat adresu do komunikacji z licznikiem AMI oraz klucza szyfrującego.</li>
            <li>Parametry do komunikacji z licznikiem AMI można wprowadzić ręcznie do urządzenia HAN lub zeskanować za pomocą kodu QR (ciąg znaków oddzielony separatorem ‘;’) i wykonać próbę połączenia licznika AMI z urządzeniem HAN.</li>
            <li>Aktywacja usługi HAN w systemie TAURON eLicznik uruchomi wysyłanie danych z licznika AMI za pomocą interfejsu Wireless M-Bus do sieci HAN.</li>
        </ol>
        <div className="WhereIsMeterCode-paragraph">Kliknij przycisk <b>"SKANUJ"</b> aby rozpocząć skanowanie kodu.</div>
        <Button onClick={onNextWasClicked}>Skanuj</Button>
        <Button outlined small onClick={onEnterManuallyWasClicked}>Wprowadź kod ręcznie</Button>
    </FullScreenContainer>
);