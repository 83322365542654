import { FieldState, FormState } from "formstate/lib";
import {isInteger, nonNegativeNumericString, notEmpty, notWhitespaceOnly, positiveNumericString} from "../Form/validators";
import { action, computed, makeObservable } from "mobx";
import { CalibrationParameters } from "../../domain/CalibrationParameters";
import { stringToNumber } from "../../utils/stringToNumber";

export class MagneticMeterInitialValuesForm {
    calibrationOffsetInput = new FieldState("")
        .validators(
            notEmpty("Bieżące wskazanie nie może być puste"),
            notWhitespaceOnly("Bieżące wskazanie nie może być puste"),
            nonNegativeNumericString("Wartość musi być nieujemną wartością numeryczną")
        );
    impulsationInput = new FieldState("")
        .validators(
            notEmpty("Impulsacja nie może być pusta"),
            notWhitespaceOnly("Impulsacja nie może być pusta"),
            positiveNumericString("Wartość musi być dodatnią wartością numeryczną"),
            isInteger()
        );

    constructor() {
        makeObservable(this, {
            impulsation: computed,
            calibrationOffset: computed,
            submit: action.bound
        });
    }

    get impulsation(): number | null {
        const value = this.impulsationInput.$;
        return this.getNumberFromString(value);
    }

    get calibrationOffset(): number | null {
        const value = this.calibrationOffsetInput.$;
        return this.getNumberFromString(value);
    }

    form = new FormState([this.impulsationInput, this.calibrationOffsetInput]);

    async submit(onSuccess: (result: CalibrationParameters) => void) {
        const { hasError } = await this.form.validate();

        if (!hasError && this.impulsation !== null && this.calibrationOffset !== null) {
            onSuccess({
                impulsation: this.impulsation,
                calibrationOffset: this.calibrationOffset,
                multiplicand: 1
            });
            this.form.reset();
        }
    }

    private getNumberFromString(s: string): number | null {
        const n = stringToNumber(s);

        if (Number.isNaN(n)) {
            return null;
        } else {
            return n;
        }
    }
}