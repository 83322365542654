import { DeviceData, DeviceDataDisplay } from "./DeviceData";
import { action, computed, observable, makeObservable } from "mobx";
import { DeviceStatsApiResponse } from "../../infrastructure/DeviceStatsApiResponse";
import { IPromiseBasedObservable } from "mobx-utils";
import { HttpService } from "../../services/HttpService";
import { SingleDevice } from "../SingleDevice/SingleDevice";
import { override } from "mobx";

export interface Stat {
    type: string;
    netCost?: number;
    grossCost?: number;
    netVariableCost?: number;
    grossVariableCost?: number;
    netConstantCost?: number;
    grossConstantCost?: number;
    distributionVariableCost?: number;
    distributionConstantCost?: number;
    distributionTotalNetCost?: number;
    distributionTotalGrossCost?: number;
    salesVariableCost?: number;
    salesConstantCost?: number;
    salesTotalNetCost?: number;
    salesTotalGrossCost?: number;
    currency?: string;
    consumption: number;
    annualConsumption: number;
    consumptionUnit: string;
    production: number;
}

interface ComponentData {
    name: string;
    value: number;
}

interface ConsumptionAndStatsData {
    components: ComponentData[];
    stats: Stat[];
    unit: string;
}

export abstract class ConsumptionDeviceData extends DeviceData {

    private statsForDate: Map<string, Stat[] | 'fetching' | 'fetchingFailed'> = new Map();
    consumptionAndStatsPromise: IPromiseBasedObservable<ConsumptionAndStatsData> | null = null;


    constructor(
        protected httpService: HttpService,
        protected device: SingleDevice,
        dailyDisplay: DeviceDataDisplay,
        monthlyDisplay: DeviceDataDisplay | null = null
    ) {
        super(httpService, device, dailyDisplay, monthlyDisplay);

        makeObservable<ConsumptionDeviceData, "statsForDate">(this, {
            statsForDate: observable,
            consumptionAndStatsPromise: observable,
            fetch: override,
            fetchStats: action,
            fetchingStatsFailed: computed,
            stat: computed,
            fetched: computed
        });
    }


    async fetch() {
        super.fetch();
        this.fetchStats();
    }

    async fetchStats() {
        const currentDate = this.date;
        this.statsForDate.set(currentDate.toUTCString(), 'fetching');
        const { startDate, endDate } = this.dateManager.getDates();
        try {
            const statsUrl = this.getStatsUrl(startDate, endDate);
            const statsResponse = await this.httpService.get<DeviceStatsApiResponse>(statsUrl);

            if (statsResponse.items) {
                this.statsForDate.set(currentDate.toUTCString(), statsResponse.items);
            } else {
                this.statsForDate.delete(currentDate.toUTCString());
            }
        } catch {
            this.statsForDate.set(currentDate.toUTCString(), 'fetchingFailed');
        }
    }

    get fetchingStatsFailed(): boolean {
        return this.statsForDate.get(this.date.toUTCString()) === 'fetchingFailed';
    }

    get stat(): Stat | null {
        const stats = this.statsForDate.get(this.date.toUTCString());

        if (!stats || stats === 'fetching' || stats === 'fetchingFailed') {
            return null;
        }

        return this.filterStats(stats)[0];
    }

    get fetched(): boolean {
        const stats = this.statsForDate.get(this.date.toUTCString());
        return !!stats && stats !== 'fetching' && stats !== 'fetchingFailed';
    }

    getCost(): number {
        if (this.stat) {
            let cost = this.stat.grossCost;
            return cost ? cost : 0;
        }
        return 0
    }

    getCurrency(): string | null {
        return (this.stat && this.stat.currency) || null;
    }

    getUsage(): number {
        if (this.stat) {
            let consumption = this.stat.consumption;
            return consumption ? consumption : 0;
        }
        return 0
    }
    getProduction(): number {
        let production = 0
        if (this.data) {
            production = this.data.reduce((acc, d) => acc + d.value, 0)
        }
        return production
    }

    abstract getStatsUrl(dateStart: Date, dateEnd: Date): string;

    abstract getConsumptionDistributionUrl(dateStart: Date, dateEnd: Date): string;

    abstract filterStats(stats: Stat[]): Stat[];

}
