import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Button } from '../Button';
import { Link } from 'react-router-dom';
import { MeterAttachmentType } from '../../domain/MeterAttachmentType';

const LertaMeter = require('../../static/icons/lerta_meter_back.svg');
const EMIMeter = require('../../static/EMI_sonda_icon.svg')
const HANMeter = require('../../static/icons/han_meter_icon.svg');

export interface Props {
    onAddAnotherMeter(): void;
    meterType: MeterAttachmentType;
}

export const Success = ({ onAddAnotherMeter, meterType }: Props) => (
    <FullScreenContainer gradient>
        <div className="AddMeter-successContent">
            <Header bold>Gratulacje!</Header>
            <Paragraph>Dodano {getName(meterType)} Meter.</Paragraph>
            {getIcon(meterType)}
            <Paragraph>Czy chcesz dodać kolejny licznik?</Paragraph>
        </div>
        <div>
            <Button onClick={onAddAnotherMeter}>Tak</Button>
            <Link to="/devices/meters"><Button secondary>Nie</Button></Link>
        </div>
    </FullScreenContainer>
);

const getName = (type: string) => {
    switch (type) {
        case MeterAttachmentType.EMI:
            return 'EMI'
        case MeterAttachmentType.HAN:
            return 'HAN'
        default:
            return 'Lerta'
    }
}
const getIcon = (type: string) => {
    switch (type) {
        case MeterAttachmentType.EMI:
            return <EMIMeter className="AddMeter-successLogo" />
        case MeterAttachmentType.HAN:
            return <HANMeter className="AddMeter-successLogo" />
        default:
            return <LertaMeter className="AddMeter-successLogo" />
    }
}