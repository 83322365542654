import * as React from 'react';
import { Modal } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import { Button } from '../Button';
import './styles.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import Icon from '@material-ui/icons/MoreVert';

export interface PWABannerProps {
	userStore?: UserStore;
}

export const PWABanner = inject('userStore')(observer(class PWABanner extends React.Component<PWABannerProps> {

	handleClose = () => {
		this.props.userStore!.setFirstLoginToFalse();
	};

	render() {
		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={this.props.userStore!.firstLogin}
				onClose={this.handleClose}
				BackdropProps={{ style: { background: 'rgba(251, 251, 251, .9)' } }}
			>
				<div className="PWABanner-content">
					<div>
						<h1 className="PWABanner-header">
							Zainstaluj aplikację
						</h1>
						<p className="PWABanner-description">
							Naciśnij <Icon className="PWABanner-logo" /> (w przeglądarce Chrome)
							a następnie wybierz "Dodaj do ekranu głównego" by zainstalować aplikację.
						</p>
					</div>
					<div className="PWABanner-buttonContainer"><Button inline small onClick={this.handleClose}>Rozumiem</Button></div>
				</div>
			</Modal>
		);
	}
}));
