import * as React from 'react';
import './style.scss';
import { DotWave } from '../../DotWave/DotWave';

export const ConnectingDevicePrompt = () => (
    <div className="ConnectingDevicePrompt-container">
        <div className="ConnectingDevicePrompt-paragraph"><strong>Trwa łączenie z urządzeniem.</strong></div>
        <div className="ConnectingDevicePrompt-paragraph"><DotWave big /></div>
        <div className="ConnectingDevicePrompt-paragraph">Proces ten może potrwać do 10 minut.</div>
    </div>
);
