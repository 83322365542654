
import { observable, computed, action, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { required, validPassword, passwordMatch } from "../Form/validators";
import { UserStore } from "./UserStore";
import { FormState, FieldState } from "formstate";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { isFetching, fetchingFailed } from "../../utils/PromiseBasedObservable";

export class NewPassword {

    form = new FormState({
        password: new FieldState('').validators(
            required("Hasło nie może być puste."),
            validPassword("Hasło musi składać się z przynajmniej 8 znaków, 1 wielkiej litery oraz 1 cyfry.")),
        confirmPassword: new FieldState('').validators(
            required("Hasło nie może być puste."))

    }).validators(passwordMatch("Podane hasła muszą być identyczne."));

    private promise: IPromiseBasedObservable<void> | null = null;

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, protected userStore: UserStore) {
        makeObservable<NewPassword, "promise">(this, {
            promise: observable,
            setPassword: action.bound,
            didSetPassword: computed,
            isSettingPassword: computed,
            settingPasswordFailed: computed,
            activationTokenExpired: computed
        });
    }

    async setPassword(email: string, token: string) {
        const { hasError } = await this.form.validate();
        if (!hasError) {
            this.promise = fromPromise(
                this.httpService.makeRequest(
                    this.urlProvider.getNewPasswordUrl(),
                    'POST',
                    { "email": email, "activationCode": token, "password": this.form.$.password.$ },
                    { 'Content-Type': 'application/json' }
                )
            );
            this.form.reset();
        }
    }

    get didSetPassword() {
        return this.promise !== null && this.promise.state === 'fulfilled';
    }

    get isSettingPassword() {
        return isFetching(this.promise);
    }

    get settingPasswordFailed() {
        return fetchingFailed(this.promise);
    }

    get activationTokenExpired(): boolean {
        return this.promise !== null && this.promise.state === 'rejected' && (this.promise.value as {status: number}).status === 410;
    }
}
