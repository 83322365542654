import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps, Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { PWABanner } from '../PWABanner/PWABanner';
import './ForgotPassword.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import { Button } from '../Button';
import { Input } from '../Input';

const Logo = require('mainLogo.svg');

export interface ForgotPasswordProps extends RouteComponentProps<any> {
	userStore?: UserStore;
}

export interface ForgotPasswordState {
	loading: boolean;
	requestSucces: boolean;
}

const ForgotPassword = inject('userStore')(observer(
    class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
        constructor(props) {
            super(props);

            this.state = {
                loading: false,
                requestSucces: false
            };
        }

        handleSubmit = async (event) => {
            event.preventDefault();
            this.setState({ loading: true });
            await this.props.userStore!.forgotPassword.submit(() => this.setState({ requestSucces: true }));
            this.setState({ loading: false });
        };

        render() {
            return (
                <div className="ForgotPassword-container">
                    {this.props.userStore!.isLoggedIn ? <Redirect to="/" /> : null}
                    <Logo className="ForgotPassword-logo" />
                    {!this.state.requestSucces && <form onSubmit={this.handleSubmit} className="ForgotPassword-form">
                        <div className="ForgotPassword-inputs">
                            <Input
                                autoFocus
                                type="email"
                                value={this.props.userStore!.forgotPassword.mail.value}
                                error={this.props.userStore!.forgotPassword.mail.error}
                                onChange={value => this.props.userStore!.forgotPassword.mail.value = value}
                                label="Email"
                            />
                            <div>
                                {this.props.userStore!.forgotPassword.requestFailed &&
                                    <div className="ForgotPassword-error">Wysłanie wiadomości nie powiodło się.</div>}
                            </div>
                        </div>
                        <Button loading={this.state.loading}>Zresetuj</Button>
                    </form>}
                    {this.state.requestSucces &&
                        <div className="Header-container">
                            <div>Wysłano wiadomość email.</div>
                            <Link to="/login">
                                <div className="ForgotPassword-redirect">Przejdź do ekranu logowania</div>
                            </Link>
                        </div>}
                    <PWABanner />
                </div>
            );
        }
    }
));

export default withRouter(ForgotPassword);
