import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { Header } from '../Text/Header/Header';
import './styles.scss';
import { observer, inject } from 'mobx-react';
import { config } from '../../utils/config';
import { RouteComponentProps } from 'react-router';

export const TermsOfUse = inject('userStore')(
    observer(class TermsOfUse extends React.Component<RouteComponentProps<{}>> {

        render() {
            const { history } = this.props
            const { termsOfUse } = config;

            return (
                <FullScreenContainer>
                    <div>
                        <Header bold>Warunki użytkowania</Header>
                    </div>
                    <div className="TermsOfUse-content">
                        {termsOfUse && <termsOfUse.Text />}
                    </div>
                    <Button onClick={() => history.goBack()} secondary>Wróć</Button>
                </FullScreenContainer>
            );
        }
    })
);
