import { observable, action, autorun, makeObservable } from "mobx";

export interface NotificationButton {
    text: string;
    onClick(): void;
}

export interface Notification {
    text: string;
    button?: NotificationButton;
}

export class NotificationsStore {
    private closeToken: any | null = null;
    notification: Notification | null = null;

    constructor(protected closeNotificationTimeout: number) {
        makeObservable(this, {
            notification: observable,
            closeNotification: action.bound
        });

        autorun(() => {
            if (this.notification !== null) {
                if (this.closeToken) {
                    clearTimeout(this.closeToken);
                }
                this.closeToken = setTimeout(() => {
                    this.notification = null;
                    this.closeToken = null;
                }, closeNotificationTimeout);
            }
        });
    }

    setNotification(notification: Notification) {
        this.notification = notification;
    }

    closeNotification() {
        if (this.notification !== null) {
            this.notification.button && this.notification.button.onClick();
            this.notification = null;
            if (this.closeToken) {
                clearTimeout(this.closeToken);
                this.closeToken = null;
            }
        }
    }
}
