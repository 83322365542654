import * as React from 'react';
import { DataHighlight } from '../DataHighlight';
import './style.scss';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { Link } from 'react-router-dom';

export interface DataHighlightsProps {
    highlights: ({ label: string; value: number | string | null; color?: 'primary' | 'secondary' })[];
    valueFormatter(value: number): string | number;
    unit: string;
    help?: string;
}

export const DataHighlights = ({ highlights, valueFormatter, help, unit }: DataHighlightsProps) => (
    <div className="DataHighlights-container">
        {help && <Link to={`/help?expand=${help}`}><HelpOutline className="DataHighlights-container--helpButton" color="action" /></Link>}
        {highlights.map(({ label, value, color }) =>
            typeof value === 'number' 
            ? <DataHighlight key={label} label={label} unit={unit} color={color}>{valueFormatter(value)}</DataHighlight>
            : <React.Fragment />
        )}
    </div>
);
