import React, { useMemo } from 'react';
import './styles.scss'
import { AnalysisData, AnalysisViewType } from '../../../stores/Statistics/AnalysisStore';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box } from '@material-ui/core';
import { getAnalysisChartTooltip } from './AnalysisChartTooltip';
import { seriesColor } from '../../../SeriesColor';

interface Props {
    data: AnalysisData[]
}

export const AnalysisDailyChart = ({ data }: Props) => {

    const unit = useMemo(() => data[0].unit, [data])

    return (
        <Box className="Analysis-chart">
            <ResponsiveContainer>
                <ComposedChart data={data}>
                    <XAxis
                        dataKey="time"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickLine={false}
                        tick={false}
                    />
                    <YAxis
                        tickLine={false}
                        width={40}
                    />
                    <Tooltip
                        content={getAnalysisChartTooltip(AnalysisViewType.daily)}
                    />
                    <CartesianGrid
                        vertical={false}
                        strokeWidth={2}
                    />
                    <Area
                        dataKey="autoConsumption"
                        type="monotone"
                        stroke="none"
                        fill={seriesColor('autoConsumption')}
                        fillOpacity="1"
                        stackId="production"
                        unit={unit}
                    />
                    <Area
                        dataKey="exportToGrid"
                        type="monotone"
                        stroke="none"
                        fill={seriesColor('exportToGrid')}
                        stackId="production"
                        unit={unit}
                    />
                    <Area
                        dataKey="importFromGrid"
                        type="monotone"
                        stroke="none"
                        fill={seriesColor('importFromGrid')}
                        stackId="production"
                        unit={unit}
                    />
                    <Line
                        type="monotone"
                        dataKey="production"
                        strokeDasharray="5 5"
                        strokeWidth="3"
                        stroke={seriesColor('production')}
                        dot={false}
                        unit={unit}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            <div className="XAxisTicks">
                <span>{unit}</span>
                <div className="ticks">
                    {['00:00', '06:00', '12:00', '18:00', '24:00'].map(t => <span key={t}>{t}</span>)}
                </div>
            </div>
        </Box>
    )
}