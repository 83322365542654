import * as React from 'react';
import './AddMeterInstruction.scss';

const OpticPreInstall = require('../../static/LM_magnes_connection.svg');
const CorrectOpticInstall = require('../../static/lerta_meter_correct_install.svg');
const FirstOpticIncorrectInstall = require('../../static/lerta_meter_incorrect_install_1.svg');
const SecondOpticIncorrectInstall = require('../../static/lerta_meter_incorrect_install_2.svg');
const MagneticInstall = require('../../static/lerta_meter_install_right_and_wrong.svg');

const Tick = require('../../static/thick.svg');
const Wrong = require('../../static/wrong.svg');

export const PreInstallInstruction = () => (
    <div className="AddMeterInstruction-container"><OpticPreInstall /></div>
);

export const CorrectOpticInstallInstruction = () => (
    <div className="AddMeterInstruction-container"><CorrectOpticInstall /><Tick className="AddMeterInstruction-tick" /></div>
);

export const FirstOpticIncorrectInstallInstruction = () => (
    <div className="AddMeterInstruction-container"><FirstOpticIncorrectInstall /><Wrong className="AddMeterInstruction-tick" /></div>
);

export const SecondOpticIncorrectInstallInstruction = () => (
    <div className="AddMeterInstruction-container"><SecondOpticIncorrectInstall /><Wrong className="AddMeterInstruction-tick" /></div>
);

export const MagneticInstallInstruction = () => (
    <MagneticInstall />
);