import * as React from 'react';
import './styles.scss';

export interface Props {
    balloonSize: string;
    stringHeight: string;
    color: string;
    children?: React.ReactNode;
}

export const Balloon = ({ balloonSize, stringHeight, color, children }: Props) => {
    return (
        <div style={{height: `calc(${balloonSize} + ${stringHeight})`, width: balloonSize}} className="Balloon-container">
            <div style={{width: balloonSize, height: balloonSize, borderColor: color}} className="Balloon-circle">
                {children}
            </div>
            <div style={{
                backgroundImage: `linear-gradient(${color}, transparent)`
            }} className="Balloon-line" />
        </div>
    );
};
