import * as React from 'react';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '../../Button';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';
import { ConnectingDeviceStore } from '../../../stores/ConnectingDeviceStore/ConnectingDeviceStore';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { WithInstruction } from '../../Instructions/WithInstruction';
import { Loader } from '../../Loader/Loader';
import { SmartDevicesStore } from '../../../stores/SmartDevicesStore/SmartDevicesStore';

export interface ReconnectDeviceFirstTimeProps extends RouteComponentProps<any> {
    connectingDeviceStore?: ConnectingDeviceStore;
    smartDevicesStore?: SmartDevicesStore;
    device: SingleDevice;
    urlBack: string;
}

class _ReconnectDeviceFirstTime extends React.Component<ReconnectDeviceFirstTimeProps> {
    componentDidMount() {
        const { device, smartDevicesStore } = this.props
        smartDevicesStore!.fetchDeviceById(device.id)
    }

    async handleReconnect() {
        const { device, connectingDeviceStore, history } = this.props
        await connectingDeviceStore!.restartConnecting(device.id);
        history.push(`/devices/${device.id}`);
    }

    render() {
        const { device, connectingDeviceStore, smartDevicesStore, urlBack } = this.props
        const ReconnectDevicePrompt = device.dictionary.reconnectDevicePrompt;
        const ReconnectInstruction = device.dictionary.instructions.reconnect;
        const postingDeviceConnect = connectingDeviceStore!.postingDeviceConnect;
        return (<React.Fragment>
            <WithInstruction
                component={openInstruction => (
                    <FullScreenContainer className="ReconnectDeviceFirstTime-container">
                        <div />
                        <div className="ReconnectDeviceFirstTime-content">
                            <ReconnectDevicePrompt onInstructionsClick={openInstruction} deviceName={device.name} />
                        </div>
                        {smartDevicesStore!.isDeviceFetching && <div style={{}}><Loader size={30} /></div>}
                        {smartDevicesStore!.deviceFetched && device.paired && <Redirect to={`/devices/${device.id}`} />}
                        <div>
                            <Button className="ReconnectDeviceFirstTime-primaryAlarmButton" disabled={smartDevicesStore!.isDeviceFetching}
                                loading={postingDeviceConnect} onClick={this.handleReconnect.bind(this)}>Gotowe</Button>
                            <Link to={urlBack}>
                                <Button className="ReconnectDeviceFirstTime-secondaryAlarmButton" >Zrobię to później</Button>
                            </Link>
                        </div>
                    </ FullScreenContainer>
                )}

                instruction={closeInstruction => (
                    <ReconnectInstruction onClose={closeInstruction} />
                )}
            />
        </React.Fragment>
        );
    }
}
export const ReconnectDeviceFirstTime = inject('connectingDeviceStore', 'smartDevicesStore')(withRouter(observer(_ReconnectDeviceFirstTime)));
