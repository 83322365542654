import { DateManager } from "./DateManager";
import { observable, computed, makeObservable } from 'mobx';
import { startOfMonth, endOfMonth, subMonths, addMonths, isThisMonth, isSameMonth } from 'date-fns';

export class MonthlyDateManager implements DateManager {
    date: Date;

    constructor(date: Date = new Date()) {
        makeObservable(this, {
            date: observable,
            isNow: computed,
            previousDate: computed,
            nextDate: computed
        });

        this.date = date;
    }

    get isNow(): boolean {
        return isThisMonth(this.date);
    }

    get previousDate(): Date {
        return subMonths(this.date, 1);
    }

    get nextDate(): Date {
        return addMonths(this.date, 1);
    }

    setDate(date: Date): boolean {
        if (isSameMonth(date, this.date)) {
            return false;
        } else {
            this.date = date;
            return true;
        }
    }

    getDates() {
        const startDate = startOfMonth(this.date);
        const endDate = endOfMonth(this.date);

        return {startDate, endDate};
    }
}
