export const DashboardIcon = require('./static/icons/dashboard.svg');
export const DashboardOutlinedIcon = require('./static/icons/dashboard_outline.svg');
export const SmarthomeIcon = require('./static/icons/smarthome.svg');
export const SmarthomeOutlinedIcon = require('./static/icons/wtyczka.svg');
export const SettingsIcon = require('./static/icons/menu_settings.svg');
export const SettingsOutlinedIcon = require('./static/icons/settings_outlined.svg');
export const NotificationsListIcon = require('./static/icons/notifications_icon.svg');
export const NotificationsListOutlinedIcon = require('./static/icons/notifications_outlined.svg');
export const HelpIcon = require('./static/icons/help.svg');
export const HelpOutlinedIcon = require('./static/icons/help.svg');
export const AccountDetailsIcon = require('./static/icons/account_details.svg');
export const AccountDetailsOutlinedIcon = require('./static/icons/account_details_outlined.svg');
export const StatisticsIcon = require('./static/icons/statistics.svg');
export const StatisticsOutlinedIcon = require('./static/icons/statistics_outlined.svg');
export const MoreIcon = require('./static/icons/left_slide_menu.svg');
