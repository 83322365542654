import { Box, makeStyles, Theme, IconButton, Typography, DialogContent, Dialog, Button } from "@material-ui/core"
import {
    addDays, subDays, format, isSameYear, isSameMonth, lastDayOfWeek,
    subWeeks, isFirstDayOfMonth, isLastDayOfMonth, lastDayOfYear, endOfQuarter, startOfDay, endOfDay,
    addWeeks, isEqual, startOfQuarter, startOfWeek, startOfYear, isAfter, isSameDay
} from "date-fns"
import React, { useCallback, useState, useEffect, useMemo, useRef } from "react"
import { useIntl } from 'react-intl'
import { Calendars } from "./Calendars"
import { Shortcuts, Shortcut } from "./Shortcuts"
import { PeriodNavigation } from "./PeriodNavigation"
import { Timeout } from "../../utils/Timeout"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface CommonProps {
    required?: boolean;
    inputLabelId?: string;
    startDate: Date | null;
    shortcuts?: Shortcut[];
    setStartDate: (date: Date) => void;
    error?: string;
    smallPeriodNavigation?: boolean;
    minDate?: Date;
    maxDate?: Date;
    minTime?: string;
    maxTime?: string;
    noShortcuts?: boolean;
    setError?: (error: string) => void;
    fullHours?: boolean;
    onClear?: () => void;
    inputClassName?: string;
    navigationClassName?: string;
    noLabel?: boolean;
    leftIsMain?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    wrapped?: boolean;
}

type OneDayViewProps =
    | { oneDayView?: false; timePickers?: boolean; setEndDate: (date: Date) => void; endDate: Date | null }
    | { oneDayView: true; timePickers?: false; setEndDate?: (date: Date) => void; endDate?: Date | null }
    | { oneDayView: true; timePickers?: true; setEndDate: (date: Date) => void; endDate: Date | null }


type Props = CommonProps & OneDayViewProps

const useStyle = (fullWidth?: boolean) => makeStyles((theme: Theme) => {
    return {
        textField: {
            width: !fullWidth ? 320 : undefined,
        },
        input: {
            cursor: 'pointer'
        },
        dialogContent: {
            display: "flex",
            justifyContent: "center"
        },
        closeButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
        superDateRangePickerInput: {
            cursor: 'pointer'
        }
    }
})

export const SuperDateRangePicker = ({
    required,
    inputLabelId,
    timePickers,
    setStartDate,
    setEndDate,
    startDate,
    endDate = startDate ? endOfDay(startDate) : null,
    error,
    shortcuts,
    smallPeriodNavigation,
    oneDayView,
    minDate,
    maxDate,
    minTime,
    maxTime,
    noShortcuts,
    fullHours,
    onClear,
    inputClassName,
    noLabel,
    leftIsMain,
    disabled,
    fullWidth,
    navigationClassName,
    wrapped = true,
}: Props) => {

    const intl = useIntl()

    const [open, setOpen] = useState(false)
    const [today, setToday] = useState(new Date())


    const classes = useStyle(fullWidth)()
    const timeout = useRef<Timeout<[]> | null>(null);


    const setNewDate = useCallback(() => {
        if (document.hidden) {
            return
        }
        setToday(new Date())
        const timeToNextDay = +startOfDay(addDays(new Date(), 1)) - +new Date()
        if (!timeout.current) {
            timeout.current = new Timeout(setNewDate, timeToNextDay);
        }
        timeout.current.setTime(timeToNextDay);
        timeout.current.set();
    }, []);

    useEffect(() => {
        window.addEventListener('focus', setNewDate)
        setNewDate()
        document.addEventListener('visibilitychange', setNewDate, false);
        return () => {
            window.removeEventListener('focus', setNewDate)
            document.removeEventListener('visibilitychange', setNewDate, false);
        }
    }, [setNewDate, timeout])

    const inputsValues = useMemo(() => [
        { id: "day.today", value: { start: startOfDay(today), end: endOfDay(today) } },
        { id: "day.tomorrow", value: { start: startOfDay(addDays(today, 1)), end: endOfDay(addDays(today, 1)) } },
        { id: "day.yesterday", value: { start: startOfDay(subDays(today, 1)), end: endOfDay(subDays(today, 1)) } },
        { id: 'week.this', value: { start: startOfDay(startOfWeek(today, { weekStartsOn: 1 })), end: endOfDay(lastDayOfWeek(today, { weekStartsOn: 1 })) } },
        { id: 'week.next', value: { start: startOfDay(addWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1)), end: endOfDay(addWeeks(lastDayOfWeek(today, { weekStartsOn: 1 }), 1)) } },
        { id: 'week.last', value: { start: startOfDay(subWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1)), end: endOfDay(subWeeks(lastDayOfWeek(today, { weekStartsOn: 1 }), 1)) } }
    ], [today])



    const getValueId = useCallback(() => {
        if (startDate && endDate) {
            return inputsValues.find(v => isEqual(v.value.start, startDate) && isEqual(v.value.end, endDate))?.id
        }
        return null
    }, [startDate, endDate, inputsValues])

    const getTimeValues = useCallback(() => {
        let isStartDateTimeSet = false
        let isEndDateTimeSet = false
        let startTime = ''
        let endTime = ''
        let timeRange = ''
        if (startDate && endDate) {
            isStartDateTimeSet = !!timePickers && !isEqual(startOfDay(startDate), startDate)
            isEndDateTimeSet = !!timePickers && !isEqual(endOfDay(endDate), endDate)
            startTime = isStartDateTimeSet || isEndDateTimeSet ? ` ${format(startDate, 'HH:mm')}` : ''
            endTime = isEndDateTimeSet || isStartDateTimeSet ? ` ${format(endDate, 'HH:mm')}` : ''
            timeRange = isStartDateTimeSet || isEndDateTimeSet ? `${startTime} -${endTime}` : ''

        }
        return { isStartDateTimeSet, isEndDateTimeSet, startTime, endTime, timeRange }
    }, [startDate, endDate, timePickers]);

    const getValue = useCallback(() => {
        const getTranslatedValue = (id: string) => `${intl.formatMessage({ id: id })}`

        let value = ''
        if (startDate && endDate && (isSameDay(startDate, endDate) || isAfter(endDate, startDate))) {

            const { isStartDateTimeSet, isEndDateTimeSet, startTime, endTime, timeRange } = getTimeValues()

            value = `${format(startDate, 'dd.MM.yyyy')}${startTime} - ${format(endDate, 'dd.MM.yyyy')}${endTime}`


            // same day
            if (isSameDay(startDate, endDate)) {
                value = `${format(startDate, 'dd.MM.yyyy')}${timeRange}`
            } else {
                if (!(isStartDateTimeSet || isEndDateTimeSet)) {
                    if (isSameYear(startDate, endDate)) {
                        // same year
                        value = `${format(startDate, 'dd.MM')} - ${format(endDate, 'dd.MM')}.${format(startDate, 'yyyy')}`
                        //  same month and year
                        if (isSameMonth(startDate, endDate)) {
                            value = `${format(startDate, 'dd')} - ${format(endDate, 'dd')}.${format(startDate, 'MM.yyyy')}`
                            // month
                            if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)) {
                                value = `${intl.formatMessage({ id: `month.${format(startDate, 'MMM').toUpperCase()}.short` })} ${format(startDate, 'yyyy')}`
                            }
                        } else {
                            // year
                            if (isSameDay(startOfYear(startDate), startDate) && isSameDay(lastDayOfYear(endDate), endDate)) {
                                value = `${format(startDate, 'yyyy')}`
                            }

                            // quarter
                            if (isSameDay(startOfQuarter(startDate), startDate) && isSameDay(endOfQuarter(startDate), endDate)) {
                                value = `${format(startDate, 'QQQ yyyy')}`
                            }
                        }
                    }
                }
            }
            const id = getValueId()
            value = id ? getTranslatedValue(id) : value
        }
        return value
    }, [startDate, endDate, getTimeValues, getValueId, intl])

    const handleShortcut = (shortcutValue: Interval) => {
        setStartDate(new Date(shortcutValue.start));
        setEndDate?.(new Date(shortcutValue.end));
        setOpen(false)
    }

    const currentInterval = startDate && endDate ? { start: startDate, end: endDate } : undefined;

    const value = getValue();

    const superDateRangePickerInput = <Box className={classes.superDateRangePickerInput} >
        <Button variant="text" onClick={() => setOpen(true)} size="small">
            <Typography variant="body2">{value}</Typography>
        </Button>
    </Box>

    const periodNavigation = <Box className={navigationClassName} paddingBottom={!!error ? '25px' : 0} display="flex" alignItems="flex-end">
        <PeriodNavigation
            interval={startDate && endDate ? { start: startDate, end: endDate } : undefined}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            small={smallPeriodNavigation}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
        >
            {wrapped ? superDateRangePickerInput : undefined}
        </PeriodNavigation>
    </Box>
    const dialog = <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogContent className={classes.dialogContent}>
            <IconButton className={classes.closeButton} onClick={() => setOpen(false)} size="small">
                <CloseRoundedIcon />
            </IconButton>
            <Box display="flex" flexDirection="column">
                {!noShortcuts && <Box>
                    <Shortcuts shortcuts={shortcuts} interval={currentInterval} onClick={handleShortcut} oneDayView={oneDayView} />
                </Box>}
                <Box display="flex" justifyContent="center" flexDirection="column" paddingLeft={noShortcuts ? 0 : 1} maxWidth={oneDayView ? '284px' : 'unset'}>
                    <Box display="flex" paddingBottom={timePickers ? 3 : 0} justifyContent="center" alignItems="center" paddingLeft={oneDayView ? noShortcuts ? 2 : 3 : 0} paddingRight={oneDayView ? 2 : 0}>
                        <Calendars
                            interval={{ start: startDate, end: endDate }}
                            onStartDateChange={(date: Date) => {
                                setStartDate(date)
                                if (oneDayView && !timePickers) {
                                    setOpen(false)
                                }
                            }}
                            onEndDateChange={(date: Date, secondStep: boolean) => {
                                setEndDate?.(date);
                                if (!timePickers && secondStep) {
                                    setOpen(false)
                                }
                            }}
                            oneDayView={oneDayView}
                            minDate={minDate && startOfDay(minDate)}
                            maxDate={maxDate && endOfDay(maxDate)}
                            minTime={minTime}
                            maxTime={maxTime}
                            leftIsMain={leftIsMain}
                            fullHours={fullHours}
                            timePickers={timePickers}
                        />
                    </Box>
                </Box>
            </Box>
        </DialogContent>
    </Dialog >

    if (wrapped) {
        return <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
                {periodNavigation}
            </Box>
            {dialog}
        </Box>
    }

    return <>{superDateRangePickerInput}{periodNavigation} {dialog}</>
}