import * as React from 'react';
import './style.scss';
import { OverlayFrame } from '../OverlayFrame';
import { Carousel } from '../../Carousel';

export interface MonthlyProps {
    onCancel: () => void;
    onConfirm: (chosenDate: Date) => void;
    onClose?: () => void;
    initialDate?: Date;
}

export interface MonthlyState {
    chosenDate: Date;
}

export class Monthly extends React.Component<MonthlyProps, MonthlyState> {
    private today: Date = new Date();

    constructor(props) {
        super(props);

        this.state = {
            chosenDate: this.props.initialDate || this.today
        };

        this.confirmHandler = this.confirmHandler.bind(this);
    }

    confirmHandler() {
        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.chosenDate);
        }
    }

    render() {
        const { onCancel, onClose } = this.props;

        return (
            <OverlayFrame title="Miesięczny" onCancel={onCancel} onConfirm={this.confirmHandler} onMaskClick={onClose} >
                <div className="Monthly-content">
                    <Carousel
                        startingDate={this.props.initialDate || this.today}
                        onDateChange={chosenDate => this.setState({ chosenDate })}
                    />
                </div>
            </OverlayFrame>
        );
    }
}
