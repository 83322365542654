import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Header } from '../Text/Header/Header';
import { Paragraph } from '../Text/Paragraph/Paragraph';

const Gateway = require('../../static/icons/central.svg');

export const Success = () => (
    <FullScreenContainer gradient>
        <div>
            <Header bold>Gratulacje!</Header>
            <Header>Dodano centralkę.</Header>
        </div>
        <Gateway className=".AddGateway-instruction" />
        <Paragraph>Teraz będziesz mógł dodać czujniki i wtyczki zestawu Smart Home.</Paragraph>
        <Link to="/devices/smart_home"><Button>Dalej</Button></Link>
    </FullScreenContainer>
);
