import './styles.scss';

import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { AddDeviceStore } from '../../../stores/AddDeviceStore/AddDeviceStore';
import { Button } from '../../Button';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Paragraph } from '../../Text/Paragraph/Paragraph';

const SmartplugLogo = require('../../../static/podlacz_do_zasilania.svg');
const OtherLogo = require('../../../static/podlacz_baterie.svg');

export interface ConnectDevicePromptProps {
    onContinueWasClicked(): void;
    addDeviceStore?: AddDeviceStore;
}

export const ConnectDevicePrompt = inject('addDeviceStore')(observer(
    (props: ConnectDevicePromptProps) => {
        const dictionary = props.addDeviceStore!.addedDeviceDictionary;
        if (dictionary === null) {
            return null;
        }
        const ConnectDevicePrompt = dictionary.connectDevicePrompt;
        return <ConnectDevicePrompt {...props} />;
    }
));

export const ConnectOtherDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść urządzenie w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectSmokeSensorDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść czujnik dymu w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectFloodSensorDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść czujnik zalania w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectWindowSensorDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść czujnik drzwi i okien w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectHumiditySensorDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść czujnik wilgotności w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectVocSensorDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <OtherLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Włóż baterię i umieść czujnik jakości powietrza w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);

export const ConnectSmartplugDevicePrompt = ({ onContinueWasClicked }: ConnectDevicePromptProps) => (
    <FullScreenContainer gradient>
        <SmartplugLogo className="ConnectDevicePrompt-logo" />
        <Paragraph>Podłącz wtyczkę do zasilania w docelowym miejscu.</Paragraph>
        <Button onClick={onContinueWasClicked}>Dalej</Button>
    </FullScreenContainer>
);
