import { action, computed, observable, onBecomeObserved, when, makeObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';

interface DeviceTypeDictionary {
    href: string;
    size: number;
    items: DeviceTypeDictionaryEntry[];
}

interface DeviceTypeDictionaryEntry {
    key?: string;
    value: DeviceTypeValue;
}

interface DeviceTypeValue {
    class: string;
    subclass?: string;
    dataKeys?: string[];
    needsGateway: boolean;
}

export class DeviceTypeStore {

    private deviceTypeDictionaryPromise: IPromiseBasedObservable<DeviceTypeDictionary> | null = null;

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
    ) {
        makeObservable<DeviceTypeStore, "deviceTypeDictionaryPromise">(this, {
            deviceTypeDictionaryPromise: observable,
            deviceTypeDictionary: computed,
            fetchingDeviceTypesFailed: computed,
            isReady: computed,
            isValidDeviceType: action,
            isGatewayNeeded: action,
            getDeviceClass: action
        });

        onBecomeObserved(this, 'deviceTypeDictionaryPromise', () => {
            this.fetchDeviceTypesDictionary();
        });
    }

    get deviceTypeDictionary(): DeviceTypeDictionaryEntry[] | null {
        if (this.deviceTypeDictionaryPromise === null || this.deviceTypeDictionaryPromise.state !== 'fulfilled') {
            return null;
        }
        const { items } = this.deviceTypeDictionaryPromise.value;

        return items || [];
    }

    get fetchingDeviceTypesFailed(): boolean {
        return this.deviceTypeDictionaryPromise !== null && this.deviceTypeDictionaryPromise.state === 'rejected';
    }

    get isReady() {
        return this.deviceTypeDictionaryPromise !== null && this.deviceTypeDictionaryPromise.state === 'fulfilled' && this.deviceTypeDictionary;
    }

    async isValidDeviceType(productNumber: string): Promise<boolean> {
        this.fetchDeviceTypesDictionary();
        await when(
            () => this.deviceTypeDictionary !== null || this.fetchingDeviceTypesFailed
        );

        if (this.fetchingDeviceTypesFailed) {
            throw new Error('Fetching device types failed');
        }

        return this.deviceTypeDictionary!.find(({ key }) => key === productNumber) !== undefined;
    }

    isGatewayNeeded(productNumber: string): boolean {
        const deviceType = this.deviceTypeDictionary!.find(({ key }) => key === productNumber)
        return !!deviceType && deviceType.value.needsGateway
    }

    async getDeviceClass(productNumber: string): Promise<string | null> {
        this.fetchDeviceTypesDictionary();
        await when(
            () => this.deviceTypeDictionary !== null || this.fetchingDeviceTypesFailed
        );

        if (this.fetchingDeviceTypesFailed) {
            throw new Error('Fetching device types failed');
        }

        const deviceTypeEntry = this.deviceTypeDictionary!.find(({ key }) => key === productNumber);

        if (deviceTypeEntry === undefined) {
            return null;
        }

        return deviceTypeEntry.value.class;
    }

    private fetchDeviceTypesDictionary() {
        if (!this.deviceTypeDictionaryPromise || this.deviceTypeDictionaryPromise.state === 'rejected') {
            const url = this.urlProvider.getDeviceTypeDictionary();
            this.deviceTypeDictionaryPromise = fromPromise(this.httpService.get<DeviceTypeDictionary>(url));
        }
    }
}