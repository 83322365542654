import { autorun, observable, computed, action } from 'mobx';
import {format, getYear, subYears, subMonths, addMonths} from 'date-fns';
import plLocale from 'date-fns/locale/pl';

const currentDate = new Date();

class ConsumptionStore {
	@observable noData = false;
	@action setNoData(status) {
		this.noData = status;
	}
	@observable uiSwitchCounter: number = 0;
	@observable dataPoints: any[] = [];
	@observable currentMonthData: null | any[] = null;
	@observable xAxisTicks: number[] = [1, 16, 31];
	@action setXAxisTicks() {
		const middle = Math.floor(this.currentMonthData?.[this.currentMonthData?.length - 1].day/2);
		this.xAxisTicks = [this.currentMonthData?.[0]?.day, middle,this.currentMonthData?.[this.currentMonthData?.length - 1].day]
	}
	@observable currentMonthDataAverage: number = 0;
	@observable currentMonthDataMinimum: number = 0;
	@observable currentMonthDataMaximum: number = 0;
	@action setDataPoints(dataPoints) {
		this.dataPoints = dataPoints;
	}
	@computed get getDataPoints() {
		return this.dataPoints;
	}

	@observable switchDisable: boolean = false;

	//Date related
	@observable currentMonthIndex: number = currentDate.getMonth();
	@observable currentYear: number = getYear(Date.now());
	@observable displayedYear: number = getYear(Date.now());
	@observable selectedDate: number = Date.now();
	@observable yearIndex: number = 0;
	@observable displayedDate: string = format(
		Date.now(),
		'MMMM yyyy',
		{locale: plLocale}
	);
	@action subMonthsFromDisplayedDate(months = 1) {
		const dateToFormat = subMonths(this.selectedDate, Math.abs(months));
		this.selectedDate = +dateToFormat;
		// console.log('dateToFormat', dateToFormat);
		this.displayedDate = format(
			dateToFormat,
			'MMMM yyyy',
			{locale: plLocale}
		);
	}
	@action addMonthsFromDisplayedDate(months = 1) {
		const dateToFormat = addMonths(this.selectedDate, Math.abs(months));
		this.selectedDate = +dateToFormat;
		// console.log('dateToFormat', dateToFormat);
		this.displayedDate = format(
			dateToFormat,
			'MMMM yyyy',
			{locale: plLocale}
		);
	}
	@action resetDates() {
		this.currentMonthIndex = currentDate.getMonth();
		this.selectedDate = Date.now();
		this.displayedDate = format(
			Date.now(),
			'MMMM yyyy',
			{locale: plLocale}
		);
	}
	@action decrementDisplayedYearByMonths(months) {
		this.displayedYear = getYear(subMonths(new Date(), Math.abs(months)));
	}
	@action substractDisplayedYear(years) {
		this.displayedYear = getYear(subYears(new Date(), years));
	}
	@action setCurrentMonthIndex(index) {
		this.currentMonthIndex = index;
	}
	@action decrementMonthIndex() {
		this.currentMonthIndex--;
	}
	@action incrementMonthIndex() {
		this.currentMonthIndex++;
	}
	@action setYearIndex(year) {
		this.yearIndex = year;
	}
	@action incrementYearIndex() {
		this.yearIndex++;
	}
	@action decrementYearIndex() {
		this.yearIndex++;
	}

	@action setCurrentMonthData(data) {
		this.currentMonthData = data;
	}
	@action setCurrentMonthDataAverage() {
		if (this.currentMonthData) {
			console.log(this.currentMonthData);
			const allValues =this.currentMonthData.reduce((acc, currentValue) => {
				return acc += parseFloat(currentValue.Wh);
			}, 0);

			return Math.ceil(allValues/this.currentMonthData.length)
		}
	}
	@action setCurrentMonthDataMaximum() {
        if (this.currentMonthData) {
            const kWhArrayOnly = this.currentMonthData.filter((month) => {
					return month.kWh;
            }).map(item => {
            	return item.kWh;
			});

            this.currentMonthDataMaximum = Math.ceil(Math.max(...kWhArrayOnly));
        }
	}


	//Electricity consumption
	@observable electricityConsumption: number = 0;
    @observable electricityCost: number = 0;
    @observable startDate: string = "";
    @observable endDate: string = "";
    @action setElectricityConsumption(value) {
        this.electricityConsumption = value;
	}
	@action setElectricityCost(cost) {
    	this.electricityCost = cost;
	}
	@action setStartDate(date) {
    	this.startDate = format(date, 'DD.MM.YYYY');
	}
    @action setEndDate(date) {
        this.endDate = format(date, 'DD.MM.YYYY');
    }

}

const consumptionStore = (window as any as {consumptionStore: ConsumptionStore}).consumptionStore = new ConsumptionStore();
export default consumptionStore;

autorun(() => {
	//TODO remove
});
