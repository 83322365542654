import * as React from 'react';
import { DotWave } from '../../DotWave/DotWave';
import './style.scss';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';

export class ConnectingWithGateway extends React.Component {
    render() {
        return (
            <FullScreenContainer backUrl="/">
                <div className="ConnectingWithGateway-container">
                    <div className="ConnectingWithGateway-bigParagraph">Centralka jest dodana.</div>
                    <div className="ConnectingWithGateway-bigParagraph">Trwa łączenie centralki z serwerem<DotWave /></div>
                    <div className="ConnectingWithGateway-smallParagraph">Nie jesteś pewien czy centralka jest dobrze skonfigurowana?</div>
                    <Link to="/add_gateway/configuration">
                        <Button className="ConnectingWithGateway-button" small inline secondary>Przypomij jak to zrobić</Button>
                    </Link>
                </div>
            </FullScreenContainer>
        );
    }
}
