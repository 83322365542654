import * as React from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';

interface SideProps {
    icon: React.ReactElement;
    value: string | number;
}

interface Props {
    left: SideProps;
    right: SideProps;
    default?: string | null;
    onChange(value: any): void;
}

export const DashboardViewSelector = observer(class DashboardViewSelector extends React.Component<Props> {
    view: number = 0;
    views = ["left", "right"]

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            view: observable
        });
    }

    componentDidMount() {
        const { right, left } = this.props
        if (this.props.default) {
            this.view = [left.value, right.value].indexOf(this.props.default)
        }
    }

    render() {
        const { left, right } = this.props
        const hideLeft = !!this.view
        return <div className="DashboardViewSelector" onClick={() => this.onChange((this.view + 1) % 2)}>
            <div className={`left${hideLeft ? " hide" : ""}`}>{left.icon}</div>
            <div className="bar" />
            <div className="right">{right.icon}</div>
        </div>
    }

    private onChange(v: number) {
        this.view = v
        this.props.onChange(this.props[this.views[v]].value)
    }
});
