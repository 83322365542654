import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { PWABanner } from '../PWABanner/PWABanner';
import './NewPassword.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import { Button } from '../Button';
import { Input } from '../Input';
import * as qs from 'query-string';

const Logo = require('mainLogo.svg');

export interface ActivationProps extends RouteComponentProps<any> {
    userStore?: UserStore;
}

export interface ActivationState {
    loading: boolean;
}

const UserActivation = inject('userStore')(observer(
    class UserActivation extends React.Component<ActivationProps, ActivationState> {
        constructor(props) {
            super(props);

            this.state = {
                loading: false
            };
        }

        handleSubmit = async (event) => {
            event.preventDefault();
            const { email, token } = qs.parse(this.props.location.search);
            await this.props.userStore!.newPassword.setPassword(email as string, token as string);
        };

        render() {
            const formError = this.props.userStore!.newPassword.form.error;
            return (
                <div className="Activation-container">
                    {this.props.userStore!.isLoggedIn ? <Redirect to="/" /> : null}

                    <Logo className="Activation-logo" />
                    {!this.props.userStore!.newPassword.didSetPassword && <form onSubmit={this.handleSubmit} className="Activation-form">
                        <div className="Activation-inputs">

                            <Input
                                type="password"
                                value={this.props.userStore!.newPassword.form.$.password.value}
                                error={this.props.userStore!.newPassword.form.$.password.error}
                                onChange={value => {
                                    this.props.userStore!.newPassword.form.$.password.value = value;
                                    this.props.userStore!.newPassword.form.$.password.validate();
                                }}
                                label="Hasło"
                            />
                            <Input
                                type="password"
                                value={this.props.userStore!.newPassword.form.$.confirmPassword.value}
                                error={this.props.userStore!.newPassword.form.$.confirmPassword.error}
                                onChange={value => {
                                    this.props.userStore!.newPassword.form.$.confirmPassword.value = value;
                                    this.props.userStore!.newPassword.form.$.confirmPassword.validate();
                                }}
                                label="Powtórz hasło"
                            />
                            <div>
                                {this.props.userStore!.newPassword.activationTokenExpired &&
                                    <div className="Activation-error">Twój token aktywacyjny wygasł. Skontaktuj się z nami pod adresem <a href="mailto:support@lerta.energy">support@lerta.energy</a>.</div>}
                                {this.props.userStore!.newPassword.settingPasswordFailed && !this.props.userStore!.newPassword.activationTokenExpired &&
                                    <div className="Activation-error">Zmiana hasła nie powiodła się. Upewnij się, czy posiadasz prawidłowy link.</div>}
                                {formError === "Podane hasła muszą być identyczne." &&
                                    <div className="Activation-error">Podane hasła muszą być identyczne.</div>}
                            </div>
                        </div>
                        <Button loading={this.props.userStore!.newPassword.isSettingPassword}>Aktywuj</Button>
                    </form>}
                    {this.props.userStore!.newPassword.didSetPassword &&
                        <div className="Header-container">
                            <div>Zmiana hasła konta przebiegła pomyślnie.</div>
                            <Link to="/login">
                                <div className="Activation-redirect">Przejdź do ekranu logowania</div>
                            </Link>
                        </div>}
                    <PWABanner />
                </div>
            );
        }
    }
));

export default withRouter(UserActivation);
