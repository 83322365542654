import { LocationsStore } from './../LocationsStore/LocationsStore';
import { endOfDay, startOfDay } from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { fetched, fetchingFailed, getValue, isFetching } from "../../utils/PromiseBasedObservable";

export type BalanceData = {
    time: number;
    unit: string;
    production?: number;
    autoConsumption?: number;
    exportToGrid?: number;
    importFromGrid?: number;
}

export class BalanceStore {

    from: Date = startOfDay(Date.now());
    to: Date = endOfDay(Date.now());

    constructor(
        protected httpService: HttpService,
        protected urlProvider: UrlProvider,
        private locationsStore: LocationsStore,
    ) {
        makeObservable(this, {
            from: observable,
            to: observable,
            promise: computed,
            data: computed,
            setPeriod: action.bound,
            locationId: computed,
        });
    }

    get promise(): IPromiseBasedObservable<BalanceData> | null {
        if (this.locationId === null) {
            return null
        }
        const url = this.urlProvider.getStatisticsBalanceUrl(this.locationId, this.from, this.to)
        return fromPromise(this.httpService.get(url))
    }

    get data(): BalanceData | null {
        return getValue<BalanceData>(this.promise)
    }

    get fetching(): boolean {
        return isFetching(this.promise);
    }

    get failed(): boolean {
        return fetchingFailed(this.promise);
    }

    get fetched(): boolean {
        return fetched(this.promise);
    }

    get locationId() {
        const { locations } = this.locationsStore
        return locations && !!locations.length ? locations[0].id : null
    }

    setPeriod(from: Date, to: Date) {
        this.from = from
        this.to = to
    }


}