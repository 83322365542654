import { observable, action, makeObservable } from "mobx";
import { nonNegativeNumericString } from "../Form/validators";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { AccountDetailsStore, AccountDetails } from "./AccountDetailsStore";
import { FormState, FieldState } from "formstate";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { stringToNumber } from "../../utils/stringToNumber";


interface AccountDetailsBody {
    firstName?: string,
    surname?: string,
    address?: string,
    category?: string,
    area?: number,
    numberOfPeople?: number
}

export class AccountDetailsUpdate {
    updating = false;
    failed = false;

    form = new FormState({
        firstName: new FieldState(''),
        surname: new FieldState(''),
        address: new FieldState(''),
        category: new FieldState(''),
        area: new FieldState('').validators(nonNegativeNumericString("Wartość musi być nieujemną wartością numeryczną")),
        numberOfPeople: new FieldState('').validators(nonNegativeNumericString("Wartość musi być nieujemną wartością numeryczną"))
    });

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private accountDetails: AccountDetailsStore
    ) {
        makeObservable(this, {
            updating: observable,
            failed: observable,
            updateAccountDetailsPromise: observable,
            updateAccountDetails: action.bound
        });
    }

    updateAccountDetailsPromise: IPromiseBasedObservable<AccountDetails> | null = null;

    resetForm() {
        this.form.reset();
        this.updateAccountDetailsPromise = null;
        this.failed = false;
        this.form.$.firstName.value = this.accountDetails.data!.firstName || '';
        this.form.$.surname.value = this.accountDetails.data!.surname || '';
        this.form.$.address.value = this.accountDetails.data!.address || '';
        this.form.$.category.value = this.accountDetails.data!.category || '';
        this.form.$.area.value = (this.accountDetails.data!.area && this.accountDetails.data!.area.toString()) || '';
        this.form.$.numberOfPeople.value = (this.accountDetails.data!.numberOfPeople && this.accountDetails.data!.numberOfPeople.toString()) || '';
    }

    async updateAccountDetails(onSuccess: () => void = () => { }): Promise<any> {
        const { hasError } = await this.form.validate();

        if (!hasError) {
            try {
                this.updating = true;

                let body: AccountDetailsBody = {}
                Object.keys(this.form.$).forEach(k => {
                    if (this.form.$[k].dirty) {
                        if (["area", "numberOfPeople"].includes(k)) {
                            body[k] = stringToNumber(this.form.$[k].value)
                        } else {
                            body[k] = this.form.$[k].value
                        }
                    }
                })

                if (body !== {}) {
                    const url = this.urlProvider.getAccountDetailsUrl(this.accountDetails.data!.externalId);
                    this.updateAccountDetailsPromise = fromPromise(this.httpService.patch<AccountDetails>(url, body)
                        .then());

                    this.accountDetails.setFromApiResponse(this.updateAccountDetailsPromise)
                }
                await this.updateAccountDetailsPromise;
                onSuccess();

                this.resetForm();

            } catch (e) {
                this.failed = true;
            }
            this.updating = false;
        }
    }
}
