import { observable, action, makeObservable, computed } from "mobx";
import { Input } from "../Form/Input";
import { notWhitespaceOnly } from "../Form/validators";
import { Form } from "../Form/Form";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { SingleDevice } from "./SingleDevice";
import { SmartDevicesItem } from "../../infrastructure/SmartDevicesApiResponse";

export class SingleDeviceUpdate {
    updating = false;
    failed = false;

    name: Input = new Input(notWhitespaceOnly('Nazwa urządzenia nie może być pusta.'));
    roomType: Input = new Input(notWhitespaceOnly('Typ pomieszczenia nie może być pusty.'));
    private form: Form = new Form([this.name, this.roomType]);

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private device: SingleDevice) {
        makeObservable(this, {
            updating: observable,
            failed: observable,
            name: observable,
            roomType: observable,
            submit: action.bound,
            isRoomTypeEnabled: computed
        });
    }

    get isRoomTypeEnabled(): boolean {
        switch (this.device.class) {
            case 'meter':
            case 'inverter':
                return false
            default:
                return true
        }
    }

    resetForm() {
        this.form.resetForm();
        if (this.isRoomTypeEnabled) {
            this.form = new Form([this.name, this.roomType]);
            this.roomType.value = this.device.roomType || ''
        } else {
            this.form = new Form([this.name])
        }
        this.failed = false;
        this.name.value = this.device.name || '';

    }

    async submit(onSuccess: () => void = () => { }) {
        this.failed = false;
        return this.form.submit(async () => {
            try {
                this.updating = true;

                if (Object.keys(this.fielsToUpdate).length) {
                    const url = await this.urlProvider.getUpdateDeviceUrl(this.device.id);
                    await this.httpService.patch<SmartDevicesItem>(url, this.fielsToUpdate);
                    this.device.name = this.name.value;
                    if (this.isRoomTypeEnabled) {
                        this.device.roomType = this.roomType.value;

                    }
                }
                onSuccess();
            } catch (e) {
                this.failed = true;
            }
            this.updating = false;
        });
    }

    private get fielsToUpdate() {
        const obj: any = {}
        if (this.device.name !== this.name.value) {
            obj.name = this.name.value
        }
        if (this.isRoomTypeEnabled && this.device.roomType !== this.roomType.value) {
            obj.roomType = this.roomType.value
        }
        return obj
    }
}