import React from 'react';
import './styles.scss'
import { TooltipProps } from 'recharts';
import { AnalysisChartDot } from './AnalysisChartDot'
import { AnalysisViewType } from '../../../stores/Statistics/AnalysisStore';
import { format } from 'date-fns';

export const getAnalysisChartTooltip = (variant: AnalysisViewType, dateFormat = 'HH:mm') => ({ payload, label }: TooltipProps<number, string>) => (
    <div className="Analysis-tooltip">
        <div className="Analysis-tooltip__label">{label && format(label, dateFormat)}</div>
        {payload?.map(series => <div className="Analysis-tooltip__row" key={series.name}>
            <AnalysisChartDot
                variant={variant === AnalysisViewType.daily && series.name === 'production' ? 'outlined' : 'contained'}
                color={series.color}
                small
            />
            {`${series.value} ${series.unit}`}
        </div>)}
    </div>
)