import * as React from 'react';
import { SingleDevice } from '../../../../stores/SingleDevice/SingleDevice';
import './style.scss';

const Warning = require('../../../../static/icons/warning_trangle.svg');

export interface Props {
    device: SingleDevice;
}

export const FailedConnectionWarning = ({device}: Props) => (
    <div className={device.connection.attempts === 1 ? 'FailedConnectionWarning-firstWarning' : 'FailedConnectionWarning-secondWarning'}>
        <Warning />
    </div>
)