import * as React from 'react';
import './style.scss';

export interface SubheadingProps {
    title: string;
    value: React.ReactNode;
    alarm?: boolean;
    disconnected?: boolean;
}

export const Subheading = (props: SubheadingProps) => (
    <div className={`Subheading-container ${props.alarm ? 'Subheading-alarm' : ''} ${props.disconnected ? 'Subheading-disconnected' : ''}`}>
        <div className="Subheading-title">{props.title}</div>
        <div className="Subheading-value">{props.value}</div>
    </div>
);
