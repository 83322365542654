import { format } from "date-fns";

export interface ConnectionStartDatesJSON {
    [deviceId: string]: string;
}

export class ConnectionStartDates {
    static storageKey = 'ConnectingDeviceStoreData';

    constructor(private storage: Storage) {}

    init() {
        const connectionStartDatesString = this.storage.getItem(ConnectionStartDates.storageKey);

        if (!connectionStartDatesString) {
            this.storage.setItem(ConnectionStartDates.storageKey, JSON.stringify({}));
        }
    }

    getStartDateFor(deviceId: string): Date | null {
        const connectionStartDatesString = this.storage.getItem(ConnectionStartDates.storageKey);

        if (connectionStartDatesString === null) {
            throw new Error('Connection start dates were not initiated in local storage!');
        }

        const connectionStartDates: ConnectionStartDatesJSON = JSON.parse(connectionStartDatesString);

        if (connectionStartDates[deviceId] === undefined) {
            return null;
        }

        return new Date(connectionStartDates[deviceId]);
    }

    setStartDateFor(deviceId: string, date: Date): void {
        const connectionStartDatesString = this.storage.getItem(ConnectionStartDates.storageKey);

        if (connectionStartDatesString === null) {
            throw new Error('Connection start dates were not initiated in local storage!');
        }

        const connectionStartDates: ConnectionStartDatesJSON = JSON.parse(connectionStartDatesString);

        connectionStartDates[deviceId] = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

        this.storage.setItem(ConnectionStartDates.storageKey, JSON.stringify(connectionStartDates));
    }

}