import {HttpService} from "../../services/HttpService";
import {UrlProvider} from "../../services/UrlProvider";
import {fromPromise, IPromiseBasedObservable} from "mobx-utils";
import { observable, onBecomeObserved, makeObservable } from "mobx";
import {ApiCollectionResponse} from "../../infrastructure/ApiResponse";
import { Stat } from "../DeviceData/ConsumptionDeviceData";

export class SupplyPointStore {
    consumptionData: {
        href: string;
    };
    href: string;
    id: string;
    type: string;

    httpService: HttpService;
    urlProvider: UrlProvider;

    constructor(consumptionData: { href: string }, href: string, id: string, type: string, httpService: HttpService, urlProvider: UrlProvider) {
        makeObservable(this, {
            consumptionDistributionPromise: observable,
            supplyPointStatsPromise: observable
        });

        this.consumptionData = consumptionData;
        this.href = href;
        this.id = id;
        this.type = type;
        this.httpService = httpService;
        this.urlProvider = urlProvider;

        onBecomeObserved(this, 'consumptionDistributionPromise', () => {
            this.getConsumptionDistribution();
            this.getStats();
        })
    }

    consumptionDistributionPromise: IPromiseBasedObservable<ConsumptionDistributionData> | null = null;
    supplyPointStatsPromise: IPromiseBasedObservable<ApiCollectionResponse<Stat>> | null = null;

    getConsumptionDistribution() {
        this.consumptionDistributionPromise = fromPromise(this.httpService.get(this.urlProvider.getSupplyPointConsumptionDistributionUrl(this.id)));
    }

    getStats() {
        this.supplyPointStatsPromise = fromPromise(this.httpService.get(this.urlProvider.getSupplyPointStats(this.id, "2018-09-01T00:00:00.000%2B02:00", "2018-09-30T23:59:59.999%2B02:00")));
    }


}

interface ComponentData {
    name: string;
    value: number;
}

interface ConsumptionDistributionData {
    components: ComponentData[];
    unit: string;
}