import { observable, action } from 'mobx';

class GatewayStore {
	@observable gatewayConfigIndex = 0;
	@action nextStep() {
		this.gatewayConfigIndex++
	}
	@action stepBack() {
		this.gatewayConfigIndex--;
	}
	@action restartGatewayConfigIndex() {
		this.gatewayConfigIndex = 0;
	}

	@observable networkList = [];
	@action setNetworkList(networkList) {
		this.networkList = networkList;
	}

	@observable networkName = '';
	@action setNetworkName(name) {
		this.networkName = name;
	}
	@observable networkPassword = '';
	@action setNetworkPasswordFromOnChange(e) {
		this.networkPassword = e.target.value;
	}
	@observable selectedNetworkIndex = null;
	@action setSelectedNetworkIndex(index) {
		this.selectedNetworkIndex = index;
	}
	@observable hasNetworkConfig = false;
	@action setHasNetworkConfig(value) {
		this.hasNetworkConfig = value;
	}
	@observable noNetworks = false;
	@action setNoNetworks(bool) {
		this.noNetworks = bool;
	}
	@observable shouldShowLoading = true;
	@action setShouldShowLoading(bool) {
		this.shouldShowLoading = bool;
	}
	@observable isFromRegistration = false;
	@action setIsFromRegistration(bool) {
		this.isFromRegistration = bool;
	}

	@observable animateHeight = false;
	@action setAnimateHeight(bool) {
		this.animateHeight = bool;
	}
}

const gatewayStore = (window as any as { gatewayStore: GatewayStore }).gatewayStore = new GatewayStore();
export default gatewayStore;
