import * as React from 'react';
import { BrowserDatamatrixCodeReader } from "@zxing/library";
import { CodeScannerProps, CodeScanner, drawImageOnCanvas } from "./CodeScanner";

export class CroppingBrowserDatamatrixCodeReader extends BrowserDatamatrixCodeReader {
    drawImageOnCanvas(canvasElementContext: CanvasRenderingContext2D, videoElement: HTMLVideoElement) {
        drawImageOnCanvas(canvasElementContext, videoElement);
    }
}

export class DatamatrixScanner extends React.Component<CodeScannerProps> {
    render() {
        return <CodeScanner {...this.props} CodeReader={CroppingBrowserDatamatrixCodeReader} />;
    }
}
