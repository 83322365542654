import * as React from 'react';
import './styles.scss';

export interface Props {
    className?: string;
    children?: React.ReactNode;
}

export const Paragraph = ({children, className}: Props) => (
    <p className={`Paragraph-container ${className || ''}`}>{children}</p>
)
