import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { EnergyUsageData } from '../DeviceData/EnergyUsageData';
import { ConnectSmartplugDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { ReconnectSmartplugSecondTimePrompt } from '../../components/ReconnectDevice/ReconnectDeviceSecondPrompt/ReconnectDeviceSecondPrompt';
import { ReconnectSmartplugPrompt } from '../../components/ReconnectDevice/ReconnectDevicePrompt/ReconnectSmartplugPrompt/ReconnectSmartplugPrompt';
import { FactoryResetSmartplugInstruction } from '../../components/Instructions/ReconnectInstructions';
import { SmartPlugInstructions } from '../../components/Instructions/Instructions';
export class SmartplugDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);

        this.power = '-';
        this.switched = false;

        this.energyUsageData = new EnergyUsageData(httpService, urlProvider, this);

        this.dictionary = {
            addDeviceFormName: "wtyczkę",
            suggestedDeviceName: "Lodówka",
            connectDevicePrompt: ConnectSmartplugDevicePrompt,
            reconnectDevicePrompt: ReconnectSmartplugPrompt,
            reconnectDeviceSecondPrompt: ReconnectSmartplugSecondTimePrompt,
            instructions: {
                instruction: SmartPlugInstructions,
                reconnect: () => null,
                factoryReset: FactoryResetSmartplugInstruction,
            },
            defaultName: "Wtyczka bez nazwy",
            deviceLogo: 'wtyczka.svg',
        };
    }
}
