import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { PWABanner } from '../PWABanner/PWABanner';
import './UserActivation.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import { Button } from '../Button';
import { Input } from '../Input';
import * as qs from 'query-string';
import { config } from '../../utils/config';

const Logo = require('mainLogo.svg');

export interface ActivationProps extends RouteComponentProps<any> {
    userStore?: UserStore;
}

export interface ActivationState {
    loading: boolean;
}

const UserActivation = inject('userStore')(observer(
    class UserActivation extends React.Component<ActivationProps, ActivationState> {
        constructor(props) {
            super(props);

            this.state = {
                loading: false
            };
        }

        componentDidMount() {
            if (config.availableRoutes.register) {
                this.handleSubmit()
            }
        }

        handleSubmit = (event?) => {
            if (event) {
                event.preventDefault();
            }
            const { email, token } = qs.parse(this.props.location.search);
            this.props.userStore!.activate(email as string, token as string);
        };

        render() {
            const formError = this.props.userStore!.activationForm.error;

            return (
                <div className="Activation-container">
                    {this.props.userStore!.isLoggedIn ? <Redirect to="/" /> : null}

                    <Logo className="Activation-logo" />
                    {!this.props.userStore!.didActivate && <form onSubmit={this.handleSubmit} className="Activation-form">
                        <div className="Activation-inputs">
                            {!config.availableRoutes.register && <React.Fragment>
                                <Input
                                    type="password"
                                    value={this.props.userStore!.activationForm.$.password.value}
                                    error={this.props.userStore!.activationForm.$.password.error}
                                    onChange={value => {
                                        this.props.userStore!.activationForm.$.password.value = value;
                                        this.props.userStore!.activationForm.$.password.validate();
                                    }}
                                    label="Hasło"
                                />
                                <Input
                                    type="password"
                                    value={this.props.userStore!.activationForm.$.confirmPassword.value}
                                    error={this.props.userStore!.activationForm.$.confirmPassword.error}
                                    onChange={value => {
                                        this.props.userStore!.activationForm.$.confirmPassword.value = value;
                                        this.props.userStore!.activationForm.$.confirmPassword.validate();
                                    }}
                                    label="Powtórz hasło"
                                />
                            </React.Fragment>}
                            <div>
                                {this.props.userStore!.activationTokenExpired &&
                                    <div className="Activation-error">Twój token aktywacyjny wygasł. Skontaktuj się z nami pod adresem <a href="mailto:support@lerta.energy">support@lerta.energy</a>.</div>}
                                {this.props.userStore!.activationFailed && !this.props.userStore!.activationTokenExpired &&
                                    <div className="Activation-error">Aktywacja konta nie powiodła się. Upewnij się, czy posiadasz prawidłowy link.</div>}
                                {formError === "Podane hasła muszą być identyczne." &&
                                    <div className="Activation-error">Podane hasła muszą być identyczne.</div>}
                            </div>
                        </div>
                        {!config.availableRoutes.register && <Button loading={this.props.userStore!.isActivating}>Aktywuj</Button>}
                    </form>}
                    {this.props.userStore!.didActivate &&
                        <div className="Header-container">
                            <div>Aktywacja konta przebiegła pomyślnie.</div>
                            <Link to="/login">
                                <div className="Activation-redirect">Przejdź do ekranu logowania</div>
                            </Link>
                        </div>}
                    <PWABanner />
                </div>
            );
        }
    }
));

export default withRouter(UserActivation);
