import { observable, autorun, action, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { fromPromise } from 'mobx-utils';
import { UserStore } from '../UserStore/UserStore';

export interface CustomerNotification {
    count: number
}

export class UnreadNotificationsCountStore {

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private userStore: UserStore) {
        makeObservable(this, {
            count: observable,
            decreaseCount: action.bound
        });

        autorun(this.fetchCount)
    }

    count: number = 0;

    fetchCount = () => {
        if (this.userStore.userId) {
            fromPromise(
                this.httpService.get(this.urlProvider.getUnreadNotificationsCountUrl(this.userStore.userId))
            ).then((res: any) => {
                this.count = res.count
            });
        }
    }

    decreaseCount() {
        if (this.count > 0) {
            this.count -= 1
        }
    }
}
