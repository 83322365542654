import {DeviceDataDisplay} from './DeviceData';
import {HttpService} from '../../services/HttpService';
import {UrlProvider} from '../../services/UrlProvider';
import {SingleDevice} from '../SingleDevice/SingleDevice';
import {DeviceDataItem} from '../../infrastructure/DeviceDataApiResponse';
import {currencyFormatter, kiloWattFormatter} from '../../utils/formatters';
import {EnergyUsage} from '../../components/DeviceData/DataHighlights/EnergyUsage';
import {DateMode} from '../../domain/DateMode';
import {ConsumptionDeviceData, Stat} from "./ConsumptionDeviceData";

const dailyDisplay: DeviceDataDisplay = {
    routeName: 'energy',
    displayName: "Zużycie energii",
    tooltipName: 'zużycie',
    DataHighlights: EnergyUsage,
    chartType: 'bar',
    chartUnit: "kWh",
    displayUnit: "kWh",
    chartRange: [0, 0.001],
    valueFormatter: kiloWattFormatter,
    costFormatter: currencyFormatter,
    showAvg: false,
    tabName: "Pomiary",
};

const monthlyDisplay: DeviceDataDisplay = {
    ...dailyDisplay,
    chartRange: [0, 0.001],
};

export class MeterEnergyUsageData extends ConsumptionDeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, dailyDisplay, monthlyDisplay);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {        
        return this.urlProvider.getDeviceDataUrl(
            this.device.id, 
            'energy', 
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }

    getStatsUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceStatsUrl(
            this.device.id,
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
        );
    }


    getConsumptionDistributionUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getConsumptionDistributionUrl(
            this.device.id,
            dateStart.toISOString(), 
            dateEnd.toISOString(), 
        );
    }

    normalizeDataResults(items: DeviceDataItem[]) {
        return super.normalizeDataResults(
            items.filter(({unit}) => unit === dailyDisplay.chartUnit)
        );
    }

    filterStats(stats: Stat[]): Stat[] {
        return stats.filter(({consumptionUnit}) => consumptionUnit === dailyDisplay.displayUnit);
    }

}
