import * as React from 'react';
import {Button} from '../../Button';
import {FullScreenContainer} from '../../FullScreenContainer/FullScreenContainer';
import {Header} from '../../Text/Header/Header';
import { Paragraph } from '../../Text/Paragraph/Paragraph';

const Image = require('../../../static/pudelko_dataMatrix.svg');

export interface Props {
    onNextWasClicked(): void;
}

export const WhereIsCode = ({onNextWasClicked}: Props) => (
    <FullScreenContainer gradient>
        <div>
            <Header>Aby dodać urządzenie, musisz zeskanować jego kod.</Header>
            <Paragraph>Kod znajdziesz <strong>na pudełku</strong>.</Paragraph>
        </div>
        <Image />
        <Button onClick={onNextWasClicked}>Skanuj</Button>
    </FullScreenContainer>
);
