import * as React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { PlusButton } from '../../Button/PlusButton/PlusButton';

export interface Props {
    children?: string;
    url: string;
}

export const NoDevices = ({url, children}: Props) => (
    <div className="NoDevices-container">
        <p className="NoDevices-paragraph">{children}</p>
        <Link to={url}><PlusButton /></Link>
    </div>
);
