import * as React from 'react'
import './style.scss';
import { AppHeader, Button } from '../Header/New';
import { DeviceData as DeviceDataComponent } from '../DeviceData';
import { NavLink, Switch, Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { SingleDevice } from '../../stores/SingleDevice/SingleDevice';
import { DateOptionsOverlay } from '../DateOptionsOverlay';
import { DateMode } from '../../domain/DateMode';
import { observer } from 'mobx-react';
import { createDeviceDetailsUrl as createDeviceDetailsUrlWithFormattedDate } from '../../utils/urls';
import { ConnectingDevicePrompt } from './ConnectingDevicePrompt/ConnectingDevicePrompt';

const Calendar = require('../../static/icons/filter.svg');
const Settings = require('../../static/icons/settings.svg');

export interface DeviceDetailsProps extends RouteComponentProps<any> {
    device: SingleDevice;
    isEditable?: boolean;
    route: string;
    urlBack?: string;
    createDeviceDetailsUrl?(dateMode: DateMode, routeName: string, date?: Date): string;
}

export interface DeviceDetailsState {
    showDateOptions: boolean;
    showCalendar: boolean;
}

export class _DeviceDetails extends React.Component<DeviceDetailsProps, DeviceDetailsState> {

    constructor(props) {
        super(props);
        this.state = {
            showDateOptions: false,
            showCalendar: true,
        };

        this.toggleDateOptions = this.toggleDateOptions.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
    }

    toggleDateOptions() {
        this.setState(({ showDateOptions }) => ({ showDateOptions: !showDateOptions }));
    }

    showCalendar(enable: boolean) {
        this.setState({ showCalendar: enable });
    }


    render() {
        const { device, route, urlBack, isEditable } = this.props;

        const createDeviceDetailsUrl = this.props.createDeviceDetailsUrl || createDeviceDetailsUrlWithFormattedDate;

        const allDeviceData = device.getAllDeviceData();

        const additionalHeaderButtons: Button[] = [];

        this.state.showCalendar && allDeviceData.length !== 0 && additionalHeaderButtons.push({ Logo: Calendar, onClick: this.toggleDateOptions });
        isEditable && additionalHeaderButtons.push({ Logo: Settings, url: `/devices/${device.id}/settings` });

        return (
            <div className="DeviceDetails-container">
                <AppHeader urlBack={urlBack} additionalButtons={additionalHeaderButtons}>
                    {device.name || device.dictionary.defaultName}
                </AppHeader>

                {allDeviceData.length > 1 && <div className="DeviceDetails-menu">
                    {allDeviceData.map(({ display: { routeName, Logo, tabName }, date, dateMode }) => (
                        <NavLink
                            key={routeName}
                            to={`${route}${createDeviceDetailsUrl(dateMode, routeName, date)}`}
                            className="DeviceDetails-menuItem"
                            activeClassName="DeviceDetails-activeItem"
                        >
                            {Logo && <Logo />}
                            {tabName && <span className="LinkTabs-tab LinkTabs-tab-secondary">{tabName}</span>}
                        </NavLink>
                    ))}
                </div>}
                {allDeviceData.length !== 0 &&
                    <Switch>
                        <Redirect
                            key="redirect"
                            exact
                            from={`${route}/:dateMode?/:dataType?`}
                            to={`${route}${createDeviceDetailsUrl(allDeviceData[0].dateMode, allDeviceData[0].display.routeName, allDeviceData[0].date)}`}
                        />
                        {allDeviceData.map((deviceData) => (
                            <Route
                                key={deviceData.display.routeName}
                                path={`${route}${createDeviceDetailsUrl(deviceData.dateMode, deviceData.display.routeName)}`}
                                render={() => <DeviceDataComponent device={device} deviceData={deviceData} route={route} showCalendar={this.showCalendar} />}
                            />
                        ))}
                    </Switch>
                }
                {allDeviceData.length === 0 && (device.connection.exists ?
                    <div className="DeviceDetails-noData">Brak danych o tym urządzeniu</div> :
                    <ConnectingDevicePrompt />)
                }
                {this.state.showDateOptions && device.activeDeviceData &&
                    <DateOptionsOverlay
                        onDateWasChosen={(date, dateMode) => {
                            this.props.history.push(`${route}${createDeviceDetailsUrl(dateMode, device.activeDeviceData!.display.routeName, date)}`);
                        }}
                        onClose={this.toggleDateOptions}
                        initialDate={device.activeDeviceData.date}
                    />
                }
            </div>
        );
    }
}

export const DeviceDetails = withRouter(observer(_DeviceDetails));