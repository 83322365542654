import * as React from 'react';
import {FullScreenScanner} from '../../CodeScanner/FullScreen';
import {DatamatrixScanner} from '../../CodeScanner/DataMatrix';

export interface ScannerProps {
    onScan(code: string): void;
    onWhereIsCode(): void;
}

export class Scanner extends React.Component<ScannerProps>  {

    render() {
        const { onScan, onWhereIsCode } = this.props;

        return (
            <FullScreenScanner
                closeUrl="/devices/meters"
                onQuestionMarkWasClicked={onWhereIsCode}
                scanner={onError => (
                    <DatamatrixScanner onError={onError} onScan={onScan} />
                )}
            />
        );
    }
}
