import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import './styles.scss';
import { LabeledCheckbox } from '../LabeledCheckbox/LabeledCheckbox';
import { Redirect } from 'react-router-dom';
import { UserStore } from '../../stores/UserStore/UserStore';
import { observer, inject } from 'mobx-react';
import { config } from '../../utils/config';

export interface Props {
    userStore?: UserStore;
}

export interface State {
    agreed: boolean;
}

export const PersonalDataConsent = inject('userStore')(observer(class PersonalDataConsent extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            agreed: false
        };
    }
    
    render() {
        const userStore = this.props.userStore!;
        const { personalDataConsent } = config;

        if (userStore.acceptedPersonalDataConsent || personalDataConsent === undefined) {
            return <Redirect to="/" />;
        }

        return (
            <FullScreenContainer>
                <div>
                <personalDataConsent.Header />
                </div>
                <div className="PersonalDataConsent-content">
                    <personalDataConsent.Text />
                </div>
                <div className="PersonalDataConsent-controls">
                    <LabeledCheckbox onChange={agreed => this.setState({agreed})}><personalDataConsent.AcceptText /></ LabeledCheckbox>
                    <Button 
                        onClick={userStore.acceptPersonalDataConsent} 
                        loading={userStore.acceptingPersonalDataConsent}
                        disabled={!this.state.agreed} 
                        small
                    >Potwierdzam</Button>
                    <Button onClick={userStore.login.logout} small secondary>Rezygnuję</Button>
                </div>
            </FullScreenContainer>
        );
    }
}));
