import { action, observable, makeObservable } from 'mobx';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConnectOtherDevicePrompt, ConnectDevicePromptProps } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { DateMode } from '../../domain/DateMode';
import {
    isAlarmChange,
    isEnergyChange,
    isHumidityChange,
    isOpenedChange,
    isSwitchChange,
    isTemperatureChange,
    isTargetTemperatureChange,
    isVocChange,
    SmartDevicesWSEvent,
} from '../../infrastructure/SmartDevicesWSEvent';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { DeviceData } from '../DeviceData/DeviceData';
import { ReconnectDeviceSecondPromptProps } from '../../components/ReconnectDevice/ReconnectDeviceSecondPrompt/ReconnectDeviceSecondPrompt';
import { SingleDeviceUpdate } from './SingleDeviceUpdate';
import { SingleDeviceConnection } from '../ConnectingDeviceStore/SingleDeviceConnection';
import { ReconnectDevicePromptProps } from '../../components/ReconnectDevice/ReconnectDevicePrompt/ReconnectDevicePrompt';

export interface DeviceDictionary {
    addDeviceFormName: string;
    suggestedDeviceName: string;
    connectDevicePrompt: React.ComponentType<ConnectDevicePromptProps>;
    reconnectDevicePrompt: React.ComponentType<ReconnectDevicePromptProps>;
    reconnectDeviceSecondPrompt: React.ComponentType<ReconnectDeviceSecondPromptProps>;
    instructions: {
        instruction: React.ComponentType<{ onNext(): void }>;
        reconnect: React.ComponentType<{ onClose(): void }>;
        factoryReset: React.ComponentType<{ onClose(): void }>;
    }
    defaultName: string;
    deviceLogo: string;
}

export class SingleDevice {

    id: string = "";
    externalId: string = "";
    name: string | null = null;
    roomType: string | null = null;
    paired: boolean = false;

    class: string = "";
    subclass: string | null = null;

    calibrationFactor: number | null = null;
    calibrationOffset: number | null = null;

    temperature: number | '-' | null = null;
    temperatureData: DeviceData | null = null;
    targetTemperature: number | '-' | null = null;
    temperatureWithTargetData: DeviceData | null = null;

    valveLevel: number | '-' | null = null;
    valveLevelData: DeviceData | null = null;

    humidity: number | '-' | null = null;
    humidityData: DeviceData | null = null;

    voc: number | '-' | null = null;
    airQuality: string | '-' | null = null;
    vocData: DeviceData | null = null;

    power: number | '-' | null = null;
    powerUsageData: DeviceData | null = null;
    energyUsageData: DeviceData | null = null;
    gasUsageData: DeviceData | null = null;
    analysisData: DeviceData | null = null;
    inverterData: DeviceData | null = null;

    supplyPointId: string = "";

    opened: boolean | '-' | null = null;

    switched: boolean | null = null;

    uncontrolled: boolean = true;

    alarm: boolean = false;

    asyncAlarmSet: boolean = false;

    serialNumber: string = "";

    dateMode: DateMode = DateMode.Daily;
    lastSetDate: Date = new Date();

    creationTime: Date | null = null;

    dictionary: DeviceDictionary;

    activeDeviceData: DeviceData | null = null;

    connection: SingleDeviceConnection;

    update: SingleDeviceUpdate;

    private subscriptions: Subscription[] = [];

    constructor(private urlProvider: UrlProvider, private httpService: HttpService, storage: Storage) {
        makeObservable(this, {
            id: observable,
            externalId: observable,
            name: observable,
            roomType: observable,
            paired: observable,
            class: observable,
            subclass: observable,
            temperature: observable,
            targetTemperature: observable,
            valveLevel: observable,
            humidity: observable,
            voc: observable,
            airQuality: observable,
            power: observable,
            supplyPointId: observable,
            opened: observable,
            switched: observable,
            uncontrolled: observable,
            alarm: observable,
            asyncAlarmSet: observable,
            serialNumber: observable,
            dateMode: observable,
            lastSetDate: observable,
            creationTime: observable,
            dictionary: observable,
            toggle: action.bound,
            setTemperature: action.bound,
            setParameters: action.bound
        });

        this.update = new SingleDeviceUpdate(httpService, urlProvider, this);
        this.connection = new SingleDeviceConnection(this, storage);
        this.dictionary = {
            addDeviceFormName: '',
            suggestedDeviceName: '',
            connectDevicePrompt: ConnectOtherDevicePrompt,
            reconnectDevicePrompt: () => null,
            reconnectDeviceSecondPrompt: () => null,
            instructions: {
                instruction: () => null,
                reconnect: () => null,
                factoryReset: () => null,
            },
            defaultName: 'Urządzenie bez nazwy',
            deviceLogo: '',
        };
    }

    async toggle(): Promise<void> {
        this.switched = !this.switched;
        this.uncontrolled = false;

        try {
            const url = this.urlProvider.getToggleDeviceUrl(this.id, this.switched ? 'on' : 'off');
            await this.httpService.post(url, { eui: this.serialNumber });
        } catch (e) {
            this.switched = !this.switched;
            this.uncontrolled = true;
        }
    }

    async setTemperature(temperature?: number): Promise<void> {
        if (!temperature) return;
        const url = this.urlProvider.getSetTemperatureUrl(this.id);
        await this.httpService.post(url, { eui: this.serialNumber, value: temperature });
    }

    setParameters({ dateMode, date }: { dateMode?: DateMode, date?: Date }) {
        const dateModeChanged = dateMode !== undefined && this.setDateMode(dateMode);
        const dateChanged = date !== undefined && this.setDate(date);

        if ((dateModeChanged || dateChanged) && this.activeDeviceData) {
            return this.activeDeviceData.fetch();
        }

        return Promise.resolve();
    }

    subscribeToDeviceEvents(events: Observable<SmartDevicesWSEvent>): void {
        this.subscriptions = [
            events.pipe(filter(isSwitchChange)).subscribe(({ state }) => { this.switched = state === 'on'; this.uncontrolled = false }),
            events.pipe(filter(isOpenedChange)).subscribe(({ state }) => this.opened = state === 'opened'),
            events.pipe(filter(isAlarmChange)).subscribe(({ state }) => { this.alarm = (state === 'fire' || state === 'flood'); this.asyncAlarmSet = true }),
            events.pipe(filter(isTemperatureChange)).subscribe(({ value }) => this.temperature = parseInt(value as string, 10)),
            events.pipe(filter(isTargetTemperatureChange)).subscribe(({ value }) => this.targetTemperature = parseInt(value as string, 10)),
            events.pipe(filter(isHumidityChange)).subscribe(({ value }) => this.humidity = parseInt(value as string, 10)),
            events.pipe(filter(isVocChange)).subscribe(({ value }) => this.voc = parseInt(value as string, 10)),
            events.pipe(filter(isEnergyChange)).subscribe(({ value }) => this.power = value as number),
        ];
    }

    unsubscribeDeviceEvents() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    getAllDeviceData(): DeviceData[] {
        if (!this.paired && !this.connection.exists) {
            return [];
        }

        const deviceDatas: DeviceData[] = [];

        this.temperatureData && deviceDatas.push(this.temperatureData);
        this.temperatureWithTargetData && deviceDatas.push(this.temperatureWithTargetData);
        this.humidityData && deviceDatas.push(this.humidityData);
        this.vocData && deviceDatas.push(this.vocData);
        this.analysisData && deviceDatas.push(this.analysisData);
        this.powerUsageData && deviceDatas.push(this.powerUsageData);
        this.energyUsageData && deviceDatas.push(this.energyUsageData);
        this.gasUsageData && deviceDatas.push(this.gasUsageData);
        this.inverterData && deviceDatas.push(this.inverterData);

        return deviceDatas;
    }

    private setDate(date: Date): boolean {
        if (this.activeDeviceData) {
            return this.activeDeviceData.setDate(date);
        } else {
            this.lastSetDate = date;
            return false;
        }
    }

    private setDateMode(dateMode: DateMode): boolean {
        if (this.dateMode === dateMode) {
            return false;
        } else {
            this.dateMode = dateMode;

            for (let deviceData of this.getAllDeviceData()) {
                deviceData.setDate(new Date());
            }

            return true;
        }
    }

    getCurrentValue = (): string | number | null => null
}
