import { ButtonBase } from '@material-ui/core';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import React from 'react'
import { useHistory, useLocation } from 'react-router';
import { AppConfig } from 'src/stores/AppConfig/AppConfig';
import { MoreIcon } from '../../MenuIcons';

interface Props {
    menuState: boolean;
    onMenuClick: (state?: boolean) => void;
    appConfig?: AppConfig;
}

export const Navigation = inject("appConfig")(({ menuState, onMenuClick, appConfig }: Props) => {
    const history = useHistory()
    const location = useLocation()
    return (
        <div className="BottomNavigation">
            {appConfig!.getBarModules().map(module => {
                const Icon = module.menu!.icon
                return (
                    <ButtonBase
                        key={module.name}
                        className={classNames({ active: !menuState && location.pathname.startsWith(module.url) })}
                        onClick={() => {
                            onMenuClick(false)
                            history.push(module.url)
                        }}
                    >
                        <Icon />
                        {module.menu!.label}
                    </ButtonBase>
                )
            })}
            <ButtonBase
                onClick={() => onMenuClick()}
                className={classNames({ active: menuState })}
            >
                <MoreIcon />
                Więcej
            </ButtonBase>
        </div>
    )
})