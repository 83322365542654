import { SmartDevicesStore } from "../SmartDevicesStore/SmartDevicesStore";
import { MetersStore } from "../MetersStore/MetersStore";
import { SingleDevice } from "../SingleDevice/SingleDevice";
import { Observable, merge } from "rxjs";

export class DevicesStore {
    constructor(private smartDevicesStore: SmartDevicesStore, private metersStore: MetersStore) {}

    getDeviceById(id: string): SingleDevice | null {
        return this.smartDevicesStore.getDeviceById(id) || this.metersStore.getMeterById(id);
    }

    fetchedDevices(): Observable<SingleDevice> {
        return merge(
            this.smartDevicesStore.fetchedDevices(),
            this.metersStore.fetchedDevices()
        );
    }
}