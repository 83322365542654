import { APIBaseProvider } from "./APIBaseProvider";
import { MeterAttachmentType } from "../domain/MeterAttachmentType";

export class UrlProvider {
    constructor(protected apiBaseProvider: APIBaseProvider) { }

    getAvailableFeaturesUrl() {
        return `${this.apiBaseProvider.getAPIBase()}/availableFeatures`;
    }

    getActivateUserUrl() {
        return `${this.apiBaseProvider.getAPIBase()}/users/activate`;
    }

    getDevicesUrl(locationId: string, page: number = 0, limit: number = 0): string {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/smartDevices?page=${page}&limit=${limit}&classes=sensor,smartplug,smartbulb,smartcable,thermostat,inverter`;
    }

    getDevicesStateUrl(locationId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/smartDevices/state?classes=sensor,smartplug,smartbulb,smartcable,thermostat`;
    }

    getMetersUrl(locationId: string, page: number = 0, limit: number = 0): string {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/smartDevices?page=${page}&limit=${limit}&classes=meter,inverter`;
    }

    getDevicesWSUrl(): string {
        return `wss://${this.apiBaseProvider.getHost()}/api/v1/smartplugs/locations/events`;
    }

    getToggleDeviceUrl(deviceId: string, command: 'on' | 'off'): string {
        return `${this.apiBaseProvider.getAPIBase()}/smartplugs/devices/${deviceId}/commands/${command}`;
    }

    getSetTemperatureUrl(deviceId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/smartplugs/devices/${deviceId}/commands/settemperature`;
    }

    getDeviceUrl(deviceId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/devices/${deviceId}`;
    }

    getDeviceDataUrl(deviceId: string, type: string, start: string, end: string, accuracy: string, direction = 'consumption', page: number = 0, limit: number = 0): string {
        return `${this.apiBaseProvider.getAPIBase()}/smartDevices/${deviceId}/data/${type}?page=${page}&limit=${limit}&start=${start}&end=${end}&accuracy=${accuracy}&direction=${direction}`;
    }

    getDeviceStatsUrl(deviceId: string, start: string, end: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/smartDevices/${deviceId}/stats?start=${start}&end=${end}`;
    }

    getUpdateDeviceUrl(deviceId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/devices/${deviceId}`;
    }

    getConsumptionDistributionUrl(deviceId: string, start: string, end: string, page: number = 0, limit: number = 0): string {
        return `${this.apiBaseProvider.getAPIBase()}/devices/${deviceId}/consumptionDistribution?page=${page}&limit=${limit}&start=${start}&end=${end}`;
    }

    getAuthorizationUrl(): string {
        return `${this.apiBaseProvider.getAPIBase()}/auth/token`;
    }

    getAuthorizationHeader(mail: string, password: string): string {
        return 'Basic ' + window.btoa(mail + ":" + password);
    }

    getLocationsUrl(userId: string): string {
        const urlBase = this.apiBaseProvider.getAPIBase() + '/users';
        const url = `${urlBase}/${userId}/locations`;
        return url;
    }

    getPeriodicConsumptionDataUrl(consumptionDataHref: string, start: string, end: string, accuracy: string): string {
        return `${consumptionDataHref}?start=${start}&end=${end}&accuracy=${accuracy}`;
    }

    getAvailableNetworksUrl() {
        return 'https://gateway.lerta.biz/ssapi/config/wlan/scan';
    }

    getUsedNetworkUrl() {
        return 'https://gateway.lerta.biz/ssapi/config/wlan/sta';
    }

    getSetUsedNetworkUrl() {
        return 'https://gateway.lerta.biz/ssapi/config/wlan/sta';
    }

    getRegisterMeterUrl(userId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/users/${userId}/wmbusKeys`;
    }

    getRegistrationUrl() {
        return this.apiBaseProvider.getAPIBase() + '/users';
    }

    getResetPasswordUrl() {
        return this.apiBaseProvider.getAPIBase() + '/passwords/reset';
    }

    getNewPasswordUrl() {
        return this.apiBaseProvider.getAPIBase() + '/passwords/new';
    }

    getAddDeviceUrl(locationId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/registration/locations/${locationId}/smartDevices`;
    }

    getDeviceTypeDictionary(): string {
        return `${this.apiBaseProvider.getAPIBase()}/deviceTypes`;
    }

    getSupplyPointTypesUrl(): string {
        return `${this.apiBaseProvider.getAPIBase()}/supplyPointTypes`;
    }

    getDisaggregatableSupplyPointTypesUrl(): string {
        return `${this.apiBaseProvider.getAPIBase()}/disaggregatableSupplyPointTypes`
    }

    getSupplyPointsUrl(locationId: string, page: number = 0, limit: number = 0): string {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/supplyPoints?page=${page}&limit=${limit}`;
    }

    getUserDataUrl(userId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/users/${userId}`;
    }

    getPatchUserUrl(userId: string): string {
        return `${this.apiBaseProvider.getAPIBase()}/users/${userId}`;
    }

    getMeterRegisterUrl(meterAttachmentType: MeterAttachmentType): string {
        if (meterAttachmentType === MeterAttachmentType.EMI) {
            return `${this.apiBaseProvider.getAPIBase()}/registration/emis`;
        }

        if (meterAttachmentType === MeterAttachmentType.HAN) {
            return `${this.apiBaseProvider.getAPIBase()}/registration/hans`;
        }

        return `${this.apiBaseProvider.getAPIBase()}/registration/meterAttachments`;
    }

    getGatewaysUrl(locationId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/gateways`;
    }

    getAddGatewayUrl() {
        return `${this.apiBaseProvider.getAPIBase()}/registration/gateways`;
    }

    getMqttWebSocketUrl() {
        return `wss://${this.apiBaseProvider.getHost()}:9001/mqtt`;
    }

    getGatewayMqttTopic(gatewayId: string) {
        return `v1/gateways/${gatewayId}`;
    }

    getMeterAttachmentTypesUrl() {
        return `${this.apiBaseProvider.getAPIBase()}/meterAttachmentTypes`;
    }

    getScanGatewayUrl(gatewayId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/smartplugs/gateways/${gatewayId}/commands/scandevice`;
    }

    getDeviceConnectUrl(deviceId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/smartplugs/devices/${deviceId}/commands/adddevice`;
    }

    getSupplyPointConsumptionDistributionUrl(supplyPointId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/supplyPoints/${supplyPointId}/consumptionDistribution`;
    }

    getSupplyPointStats(supplyPointId: string, start: string, end: string) {
        return `${this.apiBaseProvider.getAPIBase()}/supplyPoints/${supplyPointId}/stats?start=${start}&end=${end}`;
    }

    getAccountDetailsUrl(userId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/accountDetails/${userId}`;
    }

    getNotificationsListUrl(userId: string, page: number, perPage: number) {
        return `${this.apiBaseProvider.getAPIBase()}/users/${userId}/sentNotifications?page=${page}&limit=${perPage}`;
    }

    getUnreadNotificationsCountUrl(userId: string) {
        return `${this.apiBaseProvider.getAPIBase()}/users/${userId}/sentNotifications/unreadCount`;
    }

    getNotificationUrl(id: string) {
        return `${this.apiBaseProvider.getAPIBase()}/sentNotifications/${id}`;
    }

    getDashboardUrl(id: string, meterless: boolean, period: { start: Date, end: Date }, accuracy: string, direction?: "production" | "consumption") {
        const params: string[] = []
        if (meterless) {
            params.push('meterless')
        }
        if (direction) {
            params.push('direction=' + direction)
        }
        params.push(`start=${period.start.toISOString()}`);
        params.push(`end=${period.end.toISOString()}`);
        params.push(`accuracy=${accuracy}`);
        const paramsStr = params.join('&')
        return `${this.apiBaseProvider.getAPIBase()}/locations/${id}/dashboard${paramsStr ? '?' + paramsStr : ''}`;
    }

    getStatisticsAnalysisUrl(locationId: string, from: Date, to: Date, accuracy: string, series: string) {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/analysis?start=${from.toISOString()}&end=${to.toISOString()}&accuracy=${accuracy}&series=${series}`;
    }

    getStatisticsBalanceUrl(locationId: string, from: Date, to: Date) {
        return `${this.apiBaseProvider.getAPIBase()}/locations/${locationId}/balance?start=${from.toISOString()}&end=${to.toISOString()}`;
    }
}

export class LocalDevUrlProvider extends UrlProvider {
    getAuthorizationUrl() {
        return `https://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz/api/v1/auth/token`;
    }

    getDevicesWSUrl(): string {
        return `wss://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz/api/v1/smartplugs/locations/events`;
    }

    getMqttWebSocketUrl() {
        return `wss://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz:9001/mqtt`;
    }

    getAddGatewayUrl() {
        return `https://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz/api/v1/registration/gateways`;
    }

    getMeterRegisterUrl(meterAttachmentType: MeterAttachmentType): string {
        if (meterAttachmentType === MeterAttachmentType.EMI) {
            return `https://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz/api/v1/registration/emis`;
        }
        if (meterAttachmentType === MeterAttachmentType.HAN) {
            return `http://localhost:8087/hans`;
        }

        return `https://${this.apiBaseProvider.getTenant()}.cp.dev.lerta.biz/api/v1/registration/meterAttachments`;
    }
}
