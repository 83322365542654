import './styles.scss';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '../../Button';
import { Input } from '../../Input';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { FormState, FieldState } from 'formstate';
import { notEmpty } from '../../../stores/Form/validators';


interface Props {
    onSubmit(code: string): void;
}

export const EnterHANManually = observer(class EnterHANManually extends React.Component<Props> {

    form = new FormState({
        encryptionKey: new FieldState('').validators(
            notEmpty('Klucz szyfrujący nie może być pusty'),
            (v: string) => /^[0-9A-F]{32}$/.test(v) ? null : "Klucz szyfrujący składa się z 32 znaków 0-9 oraz A-F"
        ),
        communicationAddress: new FieldState('').validators(
            notEmpty('Adres komunikacyjny nie może być pusty'),
            (v: string) => /^[0-9]{8}$/.test(v) ? null : "Adres komunikacyjny składa się z 8 znaków 0-9"
        ),
        meterSerialNumber: new FieldState('').validators(notEmpty('Numer seryjny nie może być pusty')),
    })

    render() {
        return (
            <FullScreenContainer>
                <div className="EnterHANManually-form">
                    <div>
                        <Input
                            value={this.form.$.meterSerialNumber.value}
                            label="Wpisz numer seryjny"
                            onChange={this.form.$.meterSerialNumber.onChange}
                            error={this.form.$.meterSerialNumber.error}
                        />
                        <Input
                            value={this.form.$.communicationAddress.value}
                            label="Wpisz adres komunikacyjny"
                            onChange={this.form.$.communicationAddress.onChange}
                            error={this.form.$.communicationAddress.error}
                        />
                        <Input
                            value={this.form.$.encryptionKey.value}
                            label="Wpisz klucz szyfrujący"
                            onChange={this.form.$.encryptionKey.onChange}
                            error={this.form.$.encryptionKey.error}
                            rows={2}
                        />
                    </div>
                    <Button onClick={this.handleClick}>Potwierdź</Button>
                </div>
            </FullScreenContainer>
        );
    }

    private handleClick = async () => {
        const { hasError } = await this.form.validate();

        if (!hasError) {
            this.props.onSubmit([this.form.$.encryptionKey.$, this.form.$.communicationAddress.$, this.form.$.meterSerialNumber.$].join(';'))
        }
    }
});