import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import './styles.scss';
import { Link } from 'react-router-dom';

export const Settings = () => (
    <div className="Settings-container">
        <MenuItem className="Settings-item"><Link to="/settings/gateway" className="Settings-text">Konfiguracja centralki</Link></MenuItem>
    </div>
);
