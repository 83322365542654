import * as React from 'react';
import './styles.scss';
import { Button } from '../Button';
import { NotificationsStore } from '../../stores/NotificationsStore/NotificationStore';
import { observer, inject } from 'mobx-react';

export interface Props {
    notificationsStore?: NotificationsStore;
}

export const Notifications = inject('notificationsStore')(observer(({notificationsStore}: Props) => (
    <div className={`Notifications-container ${notificationsStore!.notification !== null ? 'Notifications-visible' : ''}`}>
        <div>{notificationsStore!.notification && notificationsStore!.notification!.text}</div>
        {notificationsStore!.notification && notificationsStore!.notification!.button &&
        <Button onClick={notificationsStore!.closeNotification} className="Notifications-button">{
            notificationsStore!.notification && notificationsStore!.notification!.button!.text
        }</Button>}
    </div>
)));
