import * as React from 'react';
import './style.scss';
import { AppHeader } from '../Header/New';
import { SingleDevice } from "../../stores/SingleDevice/SingleDevice";
import { Input } from '../Input';
import { observer } from 'mobx-react';
import { roomTypes } from "../../stores/SingleDevice/RoomTypes";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Button } from '@material-ui/core';

export interface DeviceSettingsProps {
    device: SingleDevice;
    onGoBackClick(): void;
}

export const DeviceSettings = observer(class DeviceSettings extends React.Component<DeviceSettingsProps> {

    componentDidMount() {
        this.props.device.update.resetForm();
    }

    handleSubmit = () => {
        this.props.device.update.submit(this.props.onGoBackClick);
    };

    handleChange = (value: string) => {
        this.props.device.update.name.value = value;
    };

    render() {
        const { update } = this.props.device;

        return (
            <div className="DeviceSettings-container">
                <AppHeader onGoBackClick={this.props.onGoBackClick}>{'Edycja urządzenia'}</AppHeader>
                <form className="DeviceSettings-container__content-wrapper">
                    <div>
                        <Input
                            autoFocus
                            label="Opis"
                            value={update.name.value}
                            error={update.name.error}
                            onChange={this.handleChange}
                        />
                        {update.isRoomTypeEnabled && <FormControl error={!!update.roomType.error} className="DeviceSettings-container__select">
                            <InputLabel>{"Typ pomieszczenia"}</InputLabel>
                            <Select
                                className="DeviceSettings-container__select--input"
                                value={update.roomType.value}
                                onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                    update.roomType.value = e.target.value;
                                }}
                            >
                                {Object.keys(roomTypes).map(key => <MenuItem key={key} value={key}>{roomTypes[key]}</MenuItem>)}
                            </Select>
                            {<FormHelperText color="error">{update.roomType.error}</FormHelperText>}
                        </FormControl>}
                        {this.props.device.update.failed &&
                            <div className="DeviceSettings-error">Edycja nie powiodła się. <br />Spróbuj ponownie albo poczekaj i spróbuj później.</div>
                        }
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.handleSubmit()}
                            className="DeviceSettings-container__button"
                            fullWidth
                        >Zapisz</Button>
                    </div>
                </form>
            </div>
        )
    }
});
