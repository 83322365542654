import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { MeterRegisterStore } from '../../stores/MeterRegisterStore/MeterRegisterStore';
import { Tile } from '../Tile/Tile';
import { TileList } from '../TileList/TileList';
import { Loader } from '../Loader/Loader';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { when } from 'mobx';
import { MeterAttachmentType as MeterAttachmentTypeType } from '../../domain/MeterAttachmentType'

export interface Props {
    onTypeWasChosen(meterAttachmentType: MeterAttachmentTypeType): void;
    meterRegisterStore: MeterRegisterStore;
}

export const meterAttachmentTypeToName = (type: MeterAttachmentTypeType): string => {
    switch (type) {
        case MeterAttachmentTypeType.LertaMeter:
            return "Lerta Meter";
        case MeterAttachmentTypeType.EMI:
            return "Zewnętrzny zestaw pomiarowy";
        case MeterAttachmentTypeType.HAN:
            return "Licznik Han";
    }
};

export const meterAttachmentToIcon = (type: MeterAttachmentTypeType): React.ReactNode => {
    switch (type) {
        case MeterAttachmentTypeType.LertaMeter: {
            const Icon = require('../../static/EMI_lerta_meter_icon.svg');
            return <Icon className="AddMeter-meterTypeIcon" />;
        }
        case MeterAttachmentTypeType.EMI: {
            const Icon = require('../../static/EMI_sonda_icon.svg');
            return <Icon className="AddMeter-meterTypeIcon" />;
        }
        case MeterAttachmentTypeType.HAN: {
            const Icon = require('../../static/icons/han_meter_icon.svg');
            return <Icon className="AddMeter-meterTypeIcon" />;
        }
    }
}

export const MeterAttachmentType = inject('meterRegisterStore')(observer(class MeterAttachmentType extends React.Component<Props> {
    componentDidMount() {
        const {meterRegisterStore} = this.props;

        when(
            () => meterRegisterStore!.meterAttachmentTypes !== null && meterRegisterStore!.meterAttachmentTypes!.length === 1,
            () => this.props.onTypeWasChosen(meterRegisterStore!.meterAttachmentTypes![0])
        );
    }

    render() {
        const {onTypeWasChosen, meterRegisterStore} = this.props;

        return (
            <FullScreenContainer gradient>
                <Paragraph>Jakie urządzenie chcesz podłączyć do licznika?</Paragraph>
                {meterRegisterStore!.meterAttachmentTypes && <TileList>{meterRegisterStore!.meterAttachmentTypes!.map(meterAttachmentType => (
                    <Tile key={meterAttachmentType} primary onClick={() => onTypeWasChosen(meterAttachmentType)}>
                        {meterAttachmentToIcon(meterAttachmentType)}
                        <div>{meterAttachmentTypeToName(meterAttachmentType)}</div>
                    </Tile>
                ))}</TileList>}
                {meterRegisterStore!.fetchingMeterAttachmentTypes && <Loader secondary />}
                {!meterRegisterStore!.meterAttachmentTypes && !meterRegisterStore!.fetchingMeterAttachmentTypes && 
                    <>
                        <div>Nie udało się pobrać typów urządzeń. Spróbuj ponownie później.</div>
                        <Link to="/"><Button>Wróć</Button></Link>
                    </>
                }
            </FullScreenContainer>
        )
    }
}));

