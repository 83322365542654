import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { MeterRegisterStore } from '../../stores/MeterRegisterStore/MeterRegisterStore';
import { Tile } from '../Tile/Tile';
import { TileList } from '../TileList/TileList';
import { Loader } from '../Loader/Loader';
import { MeterType as MeterTypeType } from '../../domain/MeterType';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { when } from 'mobx';

export interface Props {
    onTypeWasChosen(meterType: MeterTypeType): void;
    meterRegisterStore: MeterRegisterStore;
}

export const meterTypeToIcon = (meterType: MeterTypeType): React.ReactNode => {
    let Icon: React.ComponentType<any> = () => null;
    switch (meterType) {
        case MeterTypeType.Electricity:
            Icon = require('../../static/icons/electricity_icon.svg');
            break;
        case MeterTypeType.Gas:
            Icon = require('../../static/icons/gas_icon.svg');
            break;
        case MeterTypeType.Heat:
            break;
        case MeterTypeType.WaterCold:
            break;
        case MeterTypeType.WaterWarm:
            break;
        case MeterTypeType.Inverter:
            Icon = require('../../static/icons/solar-panels-icon.svg');
            break;
        case MeterTypeType.Water:
            break;
    }

    return <Icon className="AddMeter-meterTypeIcon" />;
};

export const meterTypeToLabelMap = (meterType: MeterTypeType): string => {
    switch (meterType) {
        case MeterTypeType.Electricity:
            return 'Prąd';
        case MeterTypeType.Gas:
            return 'Gaz';
        case MeterTypeType.Heat:
            return 'Ciepło';
        case MeterTypeType.WaterCold:
            return 'Zimna woda';
        case MeterTypeType.WaterWarm:
            return 'Ciepła woda';
        case MeterTypeType.Inverter:
            return 'Instalacja fotowoltaiczna';
        case MeterTypeType.Water:
            return 'Woda'
    }
};

export const MeterType = inject('meterRegisterStore')(observer(class MeterType extends React.Component<Props> {
    componentDidMount() {
        const { meterRegisterStore } = this.props;

        when(
            () => meterRegisterStore!.meterTypes !== null && meterRegisterStore!.meterTypes!.length === 1,
            () => this.props.onTypeWasChosen(meterRegisterStore!.meterTypes![0])
        )
    }

    render() {
        const { onTypeWasChosen, meterRegisterStore } = this.props;
        return (
            <FullScreenContainer backUrl="/devices/meters" gradient>
                <Paragraph>Wybierz typ licznika</Paragraph>
                {meterRegisterStore!.meterTypes && <TileList>{meterRegisterStore!.meterTypes!.map(meterType => (
                    <Tile key={meterType} primary onClick={() => onTypeWasChosen(meterType)}>
                        {meterTypeToIcon(meterType)}
                        <div>{meterTypeToLabelMap(meterType)}</div>
                    </Tile>
                ))}</TileList>}
                {meterRegisterStore!.fetchingMeterTypes && <Loader secondary />}
                {!meterRegisterStore!.meterTypes && !meterRegisterStore!.fetchingMeterTypes &&
                    <>
                        <div>Nie udało się pobrać typów licznika. Spróbuj ponownie później.</div>
                        <Link to="/"><Button>Wróć</Button></Link>
                    </>
                }
            </FullScreenContainer>
        )
    }
}));

