import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Button } from '../../Button';
import { Paragraph } from '../../Text/Paragraph/Paragraph';

const Gateway = require('../../../static/icons/central_device_and_icons_1.svg');
const GatewayWithArrow = require('../../../static/icons/central_device_and_icons_and_arrow_1.svg');
const GatewayWithWifi = require('../../../static/icons/central_device_and_icons_3.svg');
const Power = require('../../../static/icons/power_icon.svg');
const Circle = require('../../../static/icons/dot_icon.svg');
const Wifi = require('../../../static/icons/wifi_icon.svg');
const Important = require('../../../static/icons/important_sign_icon.svg');

export interface Props {
    startFromScreen: 1 | 2 | 3;
    onClick(): void;
    urlBack?: string;
}

interface State {
    display: 1 | 2 | 3;
}

export class GatewayWizard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            display: this.props.startFromScreen,
        };
    }

    render() {
        switch (this.state.display) {
            case 1:
                return <FirstPanel urlBack={this.props.urlBack} onClick={() => this.setState({ display: 2 })} />
            case 2:
                return <SecondPanel urlBack={this.props.urlBack} onClick={() => this.setState({ display: 3 })} />
            case 3:
                return <ThirdPanel urlBack={this.props.urlBack} onClick={this.props.onClick} />
        }
    }
}

export interface PanelProps {
    onClick(): void;
    urlBack?: string;
}

export const FirstPanel = ({ onClick, urlBack }: PanelProps) => (
    <FullScreenContainer backUrl={urlBack} gradient>
        <Paragraph>
            Poczekaj aż dioda <div className="GatewayWizard-inlineIconGreen"><Power /></div> będzie
            świecić zielonym <strong>światłem ciągłym</strong>. <br />
            Diody <div className="GatewayWizard-inlineIcon"><Wifi /></div>, <div className="GatewayWizard-inlineIcon"><Important /></div> i <div className="GatewayWizard-inlineIcon"><Circle /></div> powinny być zgaszone.
        </Paragraph>
        <Gateway className="GatewayWizard-infographic" />
        <Paragraph>Oczekiwanie na zaświecenie się tej diody może potrwać do 3 minut.</Paragraph>
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
);

export const SecondPanel = ({ onClick, urlBack }: PanelProps) => (
    <FullScreenContainer backUrl={urlBack} gradient>
        <Paragraph>
            Naciśnij przycisk na centralce.
        </Paragraph>
        <GatewayWithArrow className="GatewayWizard-infographic" />
        <Paragraph>Po naciśnięciu przycisku centralka rozpocznie nadawanie tymczasowej sieci wifi potrzebnej do dalszej konfiguracji.</Paragraph>
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
);

export const ThirdPanel = ({ onClick, urlBack }: PanelProps) => (
    <FullScreenContainer backUrl={urlBack} gradient>
        <Paragraph>
            Poczekaj aż dioda <div className="GatewayWizard-inlineIconOrange"><Wifi /></div> zaświeci się na pomarańczowo <strong>światłem ciągłym</strong>.
        </Paragraph>
        <GatewayWithWifi className="GatewayWizard-infographic" />
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
);
