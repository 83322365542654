import { observable, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { notEmpty, notChecked, validPassword, validEmail } from "../Form/validators";
import { FormState, FieldState } from "formstate";

export class UserRegistrationStore {
    form = new FormState({
        email: new FieldState('').validators(notEmpty('Adres e-mail nie może być pusty.'), validEmail('Wprowadzono niepoprawny adres e-mail.')),
        password: new FieldState('').validators(notEmpty('Hasło nie może być puste.'), validPassword("Hasło musi składać się z przynajmniej 8 znaków, 1 wielkiej litery oraz 1 cyfry.")),
        repeatPassword: new FieldState('').validators(v => v !== this.form.$.password.value ? "Hasła muszą być identyczne." : null),
        firstName: new FieldState(''),
        surname: new FieldState(''),
        address: new FieldState(''),
        acceptTerms: new FieldState<boolean>(false).validators(notChecked('Nie zaakceptowano warunków użytkownika.')),
    });

    registering: boolean = false;
    error: boolean = false;

    constructor(private httpService: HttpService, private urlProvider: UrlProvider) {
        makeObservable(this, {
            registering: observable,
            error: observable
        });
    }

    async submit(onSuccess: () => void) {
        const { hasError } = await this.form.validate();
        if (!hasError) {
            this.registering = true;

            const url = this.urlProvider.getRegistrationUrl();
            const bodyFields = ['email', 'password', 'firstName', 'surname', 'address', 'acceptTerms'], body = {};
            bodyFields.forEach(k => { if (this.form.$[k].value) { body[k] = this.form.$[k].value } })

            try {
                await this.httpService.makeRequest(url, 'POST', body);
                onSuccess();
                this.registering = false;
            } catch (e) {
                this.registering = false;
                this.error = true;
            }
        }
    }
}
