import * as React from 'react';
import './style.scss';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { AppHeader } from './New';
import { Badge } from '../Badge/Badge';
import { inject, observer } from 'mobx-react';
import { UnreadNotificationsCountStore } from '../../stores/UnreadNotificationsCountStore/UnreadNotificationsCountStore';

export interface RoutedHeaderProps {
    unreadNotificationsCountStore?: UnreadNotificationsCountStore;
}

const RoutedHeader = inject("unreadNotificationsCountStore")(observer(
    class RoutedHeader extends React.Component<RoutedHeaderProps & RouteComponentProps<{ id: string }>> {

        render() {
            const { unreadNotificationsCountStore } = this.props;
            return (
                <Switch>
                    <Route exact path="/dashboard" render={() => <AppHeader>Pulpit</AppHeader>} />
                    <Route exact path="/statistics/balance" render={() => <AppHeader>Bilans</AppHeader>} />
                    <Route exact path="/statistics/analysis" render={() => <AppHeader>Analiza</AppHeader>} />
                    <Route exact path="/devices" render={() => <AppHeader>Urządzenia</AppHeader>} />
                    <Route exact path="/devices/smart_home" render={() => <AppHeader>Urządzenia</AppHeader>} />
                    <Route exact path="/devices/meters" render={() => <AppHeader>Urządzenia</AppHeader>} />
                    <Route exact path="/settings" render={() => <AppHeader>Ustawienia</AppHeader>} />
                    <Route exact path="/notifications" render={() => <div className="RoutedHeader-badgeContainer"><AppHeader hideBadge>
                        <Badge content={unreadNotificationsCountStore!.count} visible={unreadNotificationsCountStore!.count > 0}>Powiadomienia</Badge></AppHeader></div>} />
                    <Route path="/help" render={() => <AppHeader>Pomoc</AppHeader>} />
                    <Route path="/accountDetails" render={() => <AppHeader>Szczegóły konta</AppHeader>} />
                </Switch>
            )
        }
    }
));

export default withRouter(RoutedHeader);
