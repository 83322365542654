import { tokenDecoder } from "../utils/tokenDecoder";
import { APIBaseProvider } from "./APIBaseProvider";


export interface HeadersDictionary {
    [index: string]: string;
}

export class HttpService {
    get<D>(href: string): Promise<D> {
        return this.makeRequest(href, 'GET', undefined, this.getAuthorizationHeader());
    }

    post<D>(href: string, body?: any): Promise<D> {
        return this.makeRequest(href, 'POST', body, this.getAuthorizationHeader());
    }

    patch<D>(href: string, body?: any): Promise<D> {
        return this.makeRequest(href, 'PATCH', body, this.getAuthorizationHeader());
    }

    makeRequest<D>(
        href: string,
        method: 'GET' | 'POST' | 'PUT' | 'PATCH',
        body?: any,
        headersDict: HeadersDictionary = {},
        additionalOptions: RequestInit = {}
    ): Promise<D> {

        const headers = new Headers({
            'X-User-Location': 'Europe/Warsaw',
            'Content-Type': 'application/json; charset=utf-8',
            ...headersDict,
        });

        body = body !== undefined ? JSON.stringify(body) : body;

        return fetch(href, { method, headers, body, ...additionalOptions })
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    return response.json().catch(() => response);
                } else if (response.status === 202) {
                    return response;
                } else if (response.status === 403) {
                    localStorage.removeItem("auth_token")
                    window.location.reload();
                } else {
                    throw response;
                }
            }).catch(e => {
                console.error(e);
                throw e;
            });
    }

    protected getAuthorizationHeader(): HeadersDictionary {
        const accessToken = localStorage.getItem("auth_token");

        return { Authorization: `Bearer ${accessToken}` };
    }
}

export class LocalDevelopmentHttpService extends HttpService {
    constructor(private apiBaseProvider: APIBaseProvider) {
        super();
    }

    makeRequest<D>(
        href: string,
        method: 'GET' | 'POST' | 'PUT' | 'PATCH',
        body?: any,
        headersDict: HeadersDictionary = {},
        additionalOptions: RequestInit = {}
    ): Promise<D> {

        const headers = new Headers(headersDict);
        headers.append('X-Tenant', this.apiBaseProvider.getTenant());

        body = body !== undefined ? JSON.stringify(body) : body;

        return fetch(href, { method, headers, body, ...additionalOptions })
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    return response.json().catch(() => response);
                } else if (response.status === 202) {
                    return response;
                } else {
                    throw response;
                }
            }).catch(e => {
                console.error(e);
                throw e;
            });
    }

    protected getAuthorizationHeader(): HeadersDictionary {
        const accessToken = localStorage.getItem("auth_token");

        return {
            Authorization: `Bearer ${accessToken}`,
            'X-User-Location': 'Europe/Warsaw',
            'X-User': tokenDecoder(localStorage.getItem("auth_token")!).userId,
            'X-User-Data': '{"id":"5be324935e317e00010a5c6b", "externalId":"NvgFgzoATC-QXp5LdaSLSQ"}',
        };
    }
}
