import * as React from 'react';
import './styles.scss';

export interface Props {
    label?: React.ReactNode; 
    children?: React.ReactNode;
    labelAfter?: React.ReactNode;
}

export const WithLabel = (props: Props) => (
    <div className="WithLabel-container">
        {props.label && <span className="WithLabel-label">{props.label}</span>}
        <div className="WithLabel-input">{props.children}</div>
        {props.labelAfter && <span className="WithLabel-label">{props.labelAfter}</span>}
    </div>
);
