import * as React from 'react';
import './AddMeter.scss';
import { observer, inject } from 'mobx-react';
import { Scanner } from './Scanner';
import { MeterType as MeterTypeType } from '../../domain/MeterType';
import { WhereIsHANMeterCode } from './WhereIsHANMeterCode';
import { MeterName } from './MeterName';
import { GatewaysStore } from '../../stores/GatewaysStore/GatewaysStore';
import { ConnectGateway } from './AddEMI/ConnectGateway';
import { EnterHANManually } from './EnterHANManually/EnterHANManually';

export interface Props {
	meterType: MeterTypeType;
	onRegisterMeter(name: string, gatewayId: string): void;
	onCodeWasScanned(code: string): void;
	gatewaysStore?: GatewaysStore;
}

export interface State {
	display: Display;
	validCode: boolean
}

type Display = 'where-is-code' | 'scanner' | 'enter-manually' | 'meter-name';

export const AddHAN = inject('gatewaysStore')(observer(class AddHAN extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			display: 'where-is-code',
			validCode: true
		};
	}

	handleCode = (code: string) => {
		this.props.onCodeWasScanned(code);
		let validCode: boolean = this.validateCode(code)
		if (validCode) {
			this.setState({ display: 'meter-name', validCode });
		} else {
			this.setState({ validCode });
		}
	}

	handleName = (name: string) => {
		const gatewaysStore = this.props.gatewaysStore!;

		this.props.onRegisterMeter(name, gatewaysStore.currentGateway!.id)
	}

	render() {
		const gatewaysStore = this.props.gatewaysStore!;

		if (gatewaysStore.hasConnectedGateway === false) {
			return <ConnectGateway />;
		}

		switch (this.state.display) {
			case 'where-is-code':
				return (
					<WhereIsHANMeterCode
						onNextWasClicked={() => this.setState({ display: 'scanner' })}
						onEnterManuallyWasClicked={() => this.setState({ display: 'enter-manually' })}
					/>
				);
			case 'scanner':
				return (
					<Scanner
						wrongCode={!this.state.validCode}
						onShowWhereIsCode={() => this.setState({ display: 'where-is-code' })}
						onScan={this.handleCode}
						onEnterManuallyClick={() => this.setState({ display: 'enter-manually' })}
					/>
				);
			case 'enter-manually':
				return (
					<EnterHANManually
						onSubmit={this.handleCode}
					/>
				);
			case 'meter-name':
				return (
					<MeterName
						onSubmit={this.handleName}
					/>
				);
		}
	}

	validateCode(code: string) {
		return /^[0-9A-F]{32};[0-9]{8};.+$/.test(code);
	}
}));
