import * as React from 'react';
import { observer } from 'mobx-react';
import './style.scss';

interface Series {
    value: number;
    title: string;
    unit: string;
    error?: boolean;
}

interface Props {
    solid?: Series;
    dashed?: Series;
    max: number;
    color?: string;
}

export const CircleChart = observer(class CircleChart extends React.Component<Props> {

    render() {
        const { color = 'darkgray', solid, dashed, max } = this.props;
        const key = `${solid ? solid.value : ''}${dashed ? dashed.value : ''}${max}`
        return (
            <div className="CircleChart">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    {solid && <text textAnchor="middle" x="50" y="37" fontSize="6">{solid.title}</text>}
                    {solid && <text className={`${solid.error ? 'error' : ''}`} textAnchor="middle" x="50" y="52" fontSize="14">{solid.value.toFixed(2).replace('.', ',')}</text>}
                    {solid && <text className={`${solid.error ? 'error' : ''}`} textAnchor="middle" x="50" y="62" fontSize="6">{solid.unit}</text>}

                    {dashed && <text textAnchor="middle" x="50" y="72" fontSize="3">{dashed.title}</text>}
                    {dashed && <text className={`${dashed.error ? 'error' : ''}`} textAnchor="middle" x="50" y="80" fontSize="7">{dashed.value.toFixed(2).replace('.', ',')}</text>}
                    {dashed && <text className={`${dashed.error ? 'error' : ''}`} textAnchor="middle" x="50" y="85" fontSize="3">{dashed.unit}</text>}

                    <defs>
                        <mask id={"mainMask-" + key}>
                            <rect width="100%" height="100%" fill="white" />
                            <path id="sector" fill="black" stroke="none" d={this.getMaskD(130)} />
                        </mask>
                        {dashed && <mask id={"dashedMask-" + key}>
                            <rect width="100%" height="100%" fill="white" />
                            <path id="sector" fill="black" stroke="none" d={this.getMaskD(this.getStartAngle(dashed.value))} />
                        </mask>}
                        {solid && <mask id={"solidMask-" + key}>
                            <rect width="100%" height="100%" fill="white" />
                            <path id="sector" fill="black" stroke="none" d={this.getMaskD(this.getStartAngle(solid.value))} />
                        </mask>}
                    </defs>

                    <circle cx="50" cy="50" r="45" strokeDasharray="0.3,2.2" strokeDashoffset="10" strokeWidth="4" stroke="gray" fill="none" mask={`url(#mainMask-${key})`} />
                    {dashed && <circle cx="50" cy="50" r="45" strokeDasharray="0.5,2" strokeDashoffset="10" strokeWidth="4" stroke={color} fill="none" mask={`url(#dashedMask-${key})`} />}
                    {solid && <circle cx="50" cy="50" r="45" strokeWidth="4" stroke={color} fill="none" mask={`url(#solidMask-${key})`} />}
                </svg>
            </div>
        )
    }

    private getStartAngle(value) {
        const { max } = this.props
        return -130 + ((260 / (max || 1)) * (+(value > 0) && value)) + 1
    }

    private getMaskD(startAngle) {
        const cx = 50,
            cy = 50,
            r = 50,
            endAngle = 230,
            start = this.polarToCartesian(cx, cy, r, endAngle),
            end = this.polarToCartesian(cx, cy, r, startAngle),
            largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        return [
            "M", start.x, start.y,
            "A", r, r, 0, largeArcFlag, 0, end.x, end.y,
            "L", cx, cy,
            "Z"
        ].join(" ");
    }

    private polarToCartesian(cX, cY, r, ad) {
        var ar = (ad - 90) * Math.PI / 180.0;

        return {
            x: cX + (r * Math.cos(ar)),
            y: cY + (r * Math.sin(ar))
        };
    }
});
