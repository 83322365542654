import { observable, action, computed, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { DeviceDataApiResponse, DeviceDataItem } from '../../infrastructure/DeviceDataApiResponse';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { DateMode } from '../../domain/DateMode';
import { DateManager } from './DateManager/DateManager';
import { DailyDateManager } from './DateManager/DailyDateManager';
import { MonthlyDateManager } from './DateManager/MonthlyDateManager';
export interface Data {
    time: Date;
    value: number;
}

export interface DeviceDataDisplay {
    routeName: string;
    displayName: string;
    tooltipName?: string;
    targetTooltipName?: string;
    displayUnit: string;
    chartUnit?: string;
    chartType?: 'line' | 'bar';
    chartReferenceLines?: Array<{ value: number, label: string, color: string }>,
    valueFormatter(value: number): string | number;
    costFormatter?(value: number): string | number;
    Logo?: React.ComponentClass;
    DataHighlights: React.ComponentClass<{ device: SingleDevice, deviceData: DeviceData }> | React.StatelessComponent<{ device: SingleDevice, deviceData: DeviceData }>;
    ActionsComponent?: React.ComponentClass<{ device: SingleDevice }> | React.StatelessComponent<{ device: SingleDevice }>;
    chartRange?: [number, number];
    showAvg: boolean;
    tabName?: string;
}

export abstract class DeviceData {
    dailyDateManager: DateManager = new DailyDateManager();
    montlyDateManager: DateManager = new MonthlyDateManager();
    protected dataForDate: Map<string, Data[] | 'fetching' | 'fetchingFailed'> = new Map();
    protected targetDataForDate: Map<string, Data[] | 'fetching' | 'fetchingFailed'> = new Map();
    protected isTargetData?: boolean;

    get display(): DeviceDataDisplay {
        if (!this.monthlyDisplay) {
            return this.dailyDisplay;
        }

        if (this.device.dateMode === DateMode.Daily) {
            return this.dailyDisplay;
        }

        return this.monthlyDisplay;
    }

    constructor(
        protected httpService: HttpService,
        protected device: SingleDevice,
        private dailyDisplay: DeviceDataDisplay,
        private monthlyDisplay: DeviceDataDisplay | null = null
    ) {
        makeObservable<DeviceData, "dataForDate" | "targetDataForDate">(this, {
            dailyDateManager: observable,
            montlyDateManager: observable,
            dataForDate: observable,
            targetDataForDate: observable,
            display: computed,
            dateManager: computed,
            setAsActive: action.bound,
            fetch: action,
            fetchData: action,
            fetchTargetData: action,
            date: computed,
            previousDate: computed,
            nextDate: computed,
            dateMode: computed,
            setDate: action,
            fetchingFailed: computed,
            fetching: computed,
            data: computed,
            targetData: computed,
            isNow: computed
        });
    }

    get dateManager(): DateManager {
        if (this.device.dateMode === DateMode.Daily) {
            return this.dailyDateManager;
        } else {
            return this.montlyDateManager;
        }
    }

    setAsActive() {
        if (this.device.activeDeviceData === this) {
            return this.fetch();
        }

        this.device.activeDeviceData = this;
        this.dateManager.date = this.device.lastSetDate;
        return this.fetch();
    }

    async fetch() {
        await Promise.all([
            this.fetchTargetData(),
            this.fetchData()
        ])
    }

    async fetchData() {
        const currentDate = this.date;
        this.dataForDate.set(currentDate.toUTCString(), 'fetching');
        const { startDate, endDate } = this.dateManager.getDates();

        try {
            const dataUrl = this.getDataUrl(startDate, endDate);
            const dataResponse = await this.httpService.get<DeviceDataApiResponse>(dataUrl);
            if (dataResponse.items) {
                this.dataForDate.set(currentDate.toUTCString(), this.normalizeDataResults(dataResponse.items));
            } else {
                this.dataForDate.delete(currentDate.toUTCString());
            }
        } catch {
            this.dataForDate.set(currentDate.toUTCString(), 'fetchingFailed');
        }
    }

    async fetchTargetData() {
        const currentDate = this.date;
        const { startDate, endDate } = this.dateManager.getDates();
        const dataUrl = this.getTargetDataUrl(startDate, endDate);
        if (!dataUrl) {
            return
        }

        this.targetDataForDate.set(currentDate.toUTCString(), 'fetching');
        try {
            const dataResponse = await this.httpService.get<DeviceDataApiResponse>(dataUrl);

            if (dataResponse.items) {
                this.targetDataForDate.set(currentDate.toUTCString(), this.normalizeDataResults(dataResponse.items));
            } else {
                this.targetDataForDate.delete(currentDate.toUTCString());
            }
        } catch {
            this.targetDataForDate.set(currentDate.toUTCString(), 'fetchingFailed');
        }
    }

    get date(): Date {
        return this.dateManager.date;
    }

    get previousDate(): Date {
        return this.dateManager.previousDate;
    }

    get nextDate(): Date {
        return this.dateManager.nextDate;
    }

    get dateMode(): DateMode {
        return this.device.dateMode;
    }

    setDate(date: Date): boolean {
        return this.dateManager.setDate(date);
    }

    get fetchingFailed(): boolean {
        let isFailed = this.dataForDate.get(this.date.toUTCString()) === 'fetchingFailed'
        if (this.isTargetData) {
            isFailed = isFailed || this.targetDataForDate.get(this.date.toUTCString()) === 'fetchingFailed'
        }
        return isFailed
    }

    get fetching(): boolean {
        let isFetching = this.dataForDate.get(this.date.toUTCString()) === 'fetching'
        if (this.isTargetData) {
            isFetching = isFetching || this.targetDataForDate.get(this.date.toUTCString()) === 'fetching'
        }
        return isFetching
    }

    get data(): Data[] {
        const data = this.dataForDate.get(this.date.toUTCString());

        if (!data || data === 'fetching' || data === 'fetchingFailed') {
            return [];
        }

        return data;
    }

    get targetData(): Data[] | undefined {
        const targetTemperature = this.targetDataForDate.get(this.date.toUTCString());

        if (!targetTemperature || targetTemperature === 'fetching' || targetTemperature === 'fetchingFailed') {
            return undefined
        }

        return targetTemperature;
    }

    get isNow(): boolean {
        return this.dateManager.isNow;
    }

    getMinValue(): number {
        return Math.min(...this.data.map(({ value }) => value));
    }

    getMaxValue(): number {
        return Math.max(...this.data.map(({ value }) => value));
    }

    getUsage(): number {
        return this.data.map(({ value }) => (value)).reduce((sum, el) => sum + el, 0);
    }

    getAverageValue(): number {
        return this.getUsage() / this.data.length;
    }

    abstract getDataUrl(dateStart: Date, dateEnd: Date): string;
    getTargetDataUrl(dateStart: Date, dateEnd: Date): string | null {
        return null
    }

    protected normalizeDataResults(items: DeviceDataItem[]): Data[] {
        return items.map(({ time, value }) => ({ time: new Date(time), value }));
    }

}
