import * as React from 'react';
import { AddMeterPanel } from '../AddMeterPanel/AddMeterPanel';
import { Meter } from './Meter/Meter';
import { observer, inject } from 'mobx-react';
import { MetersStore } from '../../stores/MetersStore/MetersStore';
import './styles.scss';
import { Loader } from '../Loader/Loader';
import { FetchingDevicesFailed } from '../SmartDevices/FetchingDevicesFailed/FetchingDevicesFailed';
import { NoDevices } from '../SmartDevices/NoDevices/NoDevices';
import { TileList } from '../TileList/TileList';

export interface Props {
    metersStore: MetersStore;
}

export const Meters = inject('metersStore')(observer(class Meters extends React.Component<Props> {
    render() {
        const metersStore = this.props.metersStore;

        if (metersStore.fetchingMeterDevicesFailed) {
            return (
                <FetchingDevicesFailed onRetry={metersStore.retryFetchingMeterDevices}>
                    Nie udało się wyświetlić listy liczników. <br /> Upewnij się, że masz połączenie z Internetem.
                </FetchingDevicesFailed>
            );
        }

        return (
            <div className="Meters-container">
                {metersStore.meterDevices && metersStore.meterDevices.length !== 0
                    && <AddMeterPanel url="/add_supply_point" />}
                {metersStore.meterDevices && metersStore.meterDevices.length !== 0 &&
                    <TileList>{metersStore.meterDevices.map(device => <Meter key={device.id} device={device} />)}</TileList>}
                {metersStore.fetchingMeterDevices && <Loader />}
                {metersStore.meterDevices && metersStore.meterDevices.length === 0 &&
                    <NoDevices url="/add_supply_point">Nie masz jeszcze dodanych liczników. Kliknij "+" aby dodać pierwszy.</NoDevices>}
            </div>
        );
    }
}));
