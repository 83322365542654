import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { observable, computed, autorun, makeObservable } from "mobx";
import { MqttService } from "../../services/MqttService";
import { UrlProvider } from "../../services/UrlProvider";
import { HttpService } from "../../services/HttpService";
import { take } from "rxjs/operators";
import { DeviceTypeStore } from "../DeviceTypeStore/DeviceTypeStore";
import { parseProductNumber, parseSerialNumber } from "../../utils/productCodes";
import { GatewaysStore } from "../GatewaysStore/GatewaysStore";
import { LocationsStore } from "../LocationsStore/LocationsStore";

export interface RegisterGatewayApiResponse {
    id: string;
}

export class GatewayRegisterStore {
    constructor(
        private httpService: HttpService, 
        private urlProvider: UrlProvider, 
        private mqttService: MqttService,
        private devicesTypesStore: DeviceTypeStore,
        private locationsStore: LocationsStore,
        private gatewaysStore: GatewaysStore,
    ) {
        makeObservable<GatewayRegisterStore, "registeredGatewayPromise">(this, {
            registeredGatewayPromise: observable,
            registeredGateway: computed
        });
    }

    private serialNumber: string | null = null;
    gatewayId: string | null = null;

    private registeredGatewayPromise: IPromiseBasedObservable<void> | null = null;

    get registeredGateway(): boolean {
        return this.registeredGatewayPromise !== null && this.registeredGatewayPromise.state === 'fulfilled';
    }

    init() {
        autorun(() => {
            const gateway = this.gatewaysStore.currentGateway;

            if (gateway !== null && gateway.paired === false) {
                this.registeredGatewayPromise = fromPromise(
                    this.mqttService.onMessages(this.urlProvider.getGatewayMqttTopic(gateway.id))
                        .pipe(take(1))
                        .toPromise()
                        .then(() => this.gatewaysStore.refetchGateways())
                );
            }
        });
    }

    async setGatewayCode(code: string): Promise<void> {
        const productNumber = parseProductNumber(code);
        const serialNumber = parseSerialNumber(code);

        const deviceClass = await this.devicesTypesStore.getDeviceClass(productNumber);

        if (deviceClass !== 'gateway') {
            throw Error(`Wrong type of device. Should be 'gateway', was: '${deviceClass}'`);
        }

        this.serialNumber = serialNumber;
    }

    async registerGateway(): Promise<void> {
        const { serialNumber } = this;
        
        if (serialNumber === null) {
            throw new Error('Tried to register gateway before setting a code for it!');
        }

        const response = await this.httpService.post<RegisterGatewayApiResponse>(
            this.urlProvider.getAddGatewayUrl(),
            {
                serialNumber,
            }
        );

        this.gatewayId = response.id;
        
        await this.locationsStore.retryFetchingLocations();
        await this.gatewaysStore.refetchGateways();
    }
}