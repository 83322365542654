import * as React from 'react';
import {FullScreenScanner} from '../CodeScanner/FullScreen';
import {QRScanner} from '../CodeScanner/QR';

export interface ScannerProps {
    wrongCode?: boolean;
    onShowWhereIsCode(): void;
    onScan(code: string): void;
    onEnterManuallyClick?(): void;
}

export class Scanner extends React.Component<ScannerProps>  {

    render() {
        const { onShowWhereIsCode, onScan, onEnterManuallyClick, wrongCode } = this.props;

        return (
            <FullScreenScanner
                onQuestionMarkWasClicked={onShowWhereIsCode}
                closeUrl="/devices/meters"
                onSubtextWasClicked={onEnterManuallyClick}
                wrongCode={wrongCode}
                scanner={onError => (
                    <QRScanner onError={onError} onScan={onScan} />
                )}
            />
        );
    }
}
