import * as React from 'react';
import {observer} from 'mobx-react';
import {DataHighlight} from '../DataHighlight';
import {ConsumptionDeviceData} from "../../../stores/DeviceData/ConsumptionDeviceData";
import {Loader} from "../../Loader/Loader";
import { Button } from '../../Button';
import { Paragraph } from '../../Text/Paragraph/Paragraph';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';

export interface EnergyProductionProps {
    device: SingleDevice;
    deviceData: ConsumptionDeviceData;
}

export const EnergyProduction = observer(({deviceData}: EnergyProductionProps) => {

    return (
        <div className="EnergyUsageDataHighlight-container">
            {deviceData.fetched &&
            <>
                <DataHighlight
                    unit={deviceData.display.displayUnit}
                    label="Produkcja energii"
                >
                    {deviceData.display.valueFormatter(deviceData.getProduction())}
                </DataHighlight>
            </>
            }
            {deviceData && deviceData.fetching && <Loader secondary size={50}/>}
            {deviceData.fetchingStatsFailed && <div>
                <Paragraph className="EnergyUsageDataHighlight-error">Nie udało się pobrać danych statystycznych.</Paragraph>
                <Button onClick={deviceData.fetch} small inline secondary>Spróbuj ponownie</Button>
            </div>}
        </div>
    );
});
