import { makeStyles, Theme, Typography, useTheme, alpha } from '@material-ui/core'
import React, { useMemo, useRef } from 'react'
import {
    isAfter, getWeeksInMonth, startOfMonth, subDays, addDays, getMonth, format, isToday, isWeekend,
    getISODay, addMonths, subMonths, getYear, startOfDay, endOfDay, isWithinInterval, isSameDay, isBefore, differenceInCalendarMonths
} from 'date-fns';
import classNames from 'classnames'
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import { months, weekDaysISO } from './DatePickerTypes';
import { useIntl } from 'react-intl';
import { CalendarInterval } from './Calendars';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

const useStyle = makeStyles((theme: Theme) => {
    return {
        calendar: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gridTemplateRows: 'repeat(6, 1fr)'
        },
        calendarWrapper: {
            display: "flex",
            flexDirection: "column",
            flex: "1",
        },
        calendarHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: theme.spacing(2)
        },
        calendarLabel: {
            textAlign: "center",
            minWidth: 130,
        },
        header: {
            fontSize: '0.7rem',
            color: theme.palette.secondary.dark,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
        },
        buttonBase: {
            cursor: 'pointer',
            fontSize: '1rem',
            boxSizing: 'border-box',
            '&:hover': {
                background: alpha(theme.palette.secondary.main, 0.04),
            }
        },
        buttonDisabled: {
            color: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
            cursor: 'default',
            pointerEvents: 'none'
        },
        iconButton: {
            padding: theme.spacing(1.5),
            display: 'flex',
            borderRadius: '50%'
        },
        day: {
            position: 'relative',
            transition: 'none',
            padding: theme.spacing(0.5),
            textAlign: 'center',
            borderRadius: 0,
            fontWeight: 'normal',
            minWidth: 'unset',
            lineHeight: "1.5",
        },
        today: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        weekend: {
            color: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
        },
        notCurrentMonth: {
            color: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
        },
        isBeginOfInterval: {
            borderRadius: '20px 0 0 20px'
        },
        isEndOfInterval: {
            borderRadius: '0 20px 20px 0'
        },
        oneDayInterval: {
            borderRadius: '20px'
        },
        hover: {
            background: alpha(theme.palette.primary.main, 0.5)
        },
        flex: {
            display: "flex",
            alignItems: "center"
        }
    }
})
interface CalendarProps {
    currentDate: Date;
    onClick: (date: Date) => void;
    onHover: (date: Date) => void;
    interval: CalendarInterval;
    hoverInterval?: Interval;
    isWeekendsDisabled?: boolean;
    setDisplayedDate: (date: Date) => void;
    disabledPrevMonth?: boolean;
    disabledNextMonth?: boolean;
    maxDate?: Date;
    minDate?: Date;
}

interface RippleRef {
    start: (e: any) => void;
    stop: (e: any) => void;
}

export const Calendar = ({ currentDate, onClick, interval, hoverInterval, isWeekendsDisabled, setDisplayedDate, disabledPrevMonth, disabledNextMonth, minDate, maxDate, onHover }: CalendarProps) => {
    const classes = useStyle();
    const intl = useIntl();
    const theme = useTheme()

    const daysInMonth = useMemo(() => getDates(currentDate, interval), [currentDate, interval])
    const monthNames = useMemo(() => months.map(key => intl!.formatMessage({ id: `month.${key}` } || '')), [intl]);
    const weekdaysShort = useMemo(() => weekDaysISO.map(key => intl!.formatMessage({ id: `weekday.${key}.short` } || '')), [intl]);
    return (<div className={classes.calendarWrapper}>
        <div className={classes.calendarHeader}>
            <div className={classes.flex}>
                <div className={classNames(classes.buttonBase, classes.iconButton, {
                    [classes.buttonDisabled]: (disabledPrevMonth || (minDate && differenceInCalendarMonths(currentDate, minDate) === 0))
                })}
                    onClick={() => setDisplayedDate(subMonths(currentDate, 1))}>
                    <ArrowLeftRoundedIcon />
                </div>
                <div className={classes.calendarLabel}><Typography>{monthNames[getMonth(currentDate)]} {getYear(currentDate)}</Typography></div>
                <div className={classNames(classes.buttonBase, classes.iconButton, {
                    [classes.buttonDisabled]: (disabledNextMonth || (maxDate && differenceInCalendarMonths(maxDate, currentDate) === 0))
                })}
                    onClick={() => setDisplayedDate(addMonths(currentDate, 1))}>
                    <ArrowRightRoundedIcon />
                </div>
            </div>
        </div>
        <div className={classes.calendar}>
            {weekdaysShort.map((day) =>
                <div key={day} className={classes.header}>{day}</div>
            )}
            {daysInMonth.map((day) => {
                const isWeekend = isWeekendsDisabled && day.isWeekend;
                const isAfterMaxDate = (maxDate && isAfter(day.date, maxDate));
                const isBeforeMinDate = (minDate && isBefore(day.date, minDate));
                return <div key={day.key}
                    style={(isWeekendsDisabled && !day.isWeekend && day.color) || (day.color && !isWeekendsDisabled) ? {
                        background: theme.palette.primary.main, color: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.getContrastText(theme.palette.primary.main)
                    } : {}}
                    className={classNames(classes.day, classes.buttonBase, {
                        [classes.today]: day.isToday,
                        [classes.weekend]: isWeekend,
                        [classes.notCurrentMonth]: !day.isCurrentMonth,
                        [classes.isBeginOfInterval]: day.isBeginOfInterval,
                        [classes.isEndOfInterval]: day.isEndOfInterval && !hoverInterval,
                        [classes.oneDayInterval]: day.isBeginOfInterval && day.isEndOfInterval && !hoverInterval,
                        [classes.hover]: day.isCurrentMonth && hoverInterval && isWithinInterval(day.date, hoverInterval),
                        [classes.buttonDisabled]: !day.isCurrentMonth || (isWeekend || isAfterMaxDate || isBeforeMinDate)
                    })}
                    onClick={() => onClick(day.date)}
                    onMouseEnter={() => onHover(day.date)}
                >
                    <RippleWrapper>
                        <span>{day.dateString}</span>
                    </RippleWrapper>
                </div>
            }
            )}
        </div>
    </div >

    )
}

interface RippleWrapperProps {
    children?: React.ReactNode;
}
const RippleWrapper = ({ children, }: RippleWrapperProps) => {
    const rippleRef = useRef<RippleRef>(null);
    return <div
        onMouseDown={(e) => rippleRef?.current?.start(e)}
        onMouseUp={(e) => rippleRef?.current?.stop(e)}
        onDragLeave={(e) => rippleRef?.current?.stop(e)}
        onMouseLeave={(e) => rippleRef?.current?.stop(e)}
    >
        <TouchRipple ref={rippleRef} />
        {children}
    </div>

}

const getIntervalProps = (date: Date, selectedInterval: CalendarInterval) => {
    if (selectedInterval.start) {
        const interval = {
            start: startOfDay(selectedInterval.start),
            end: endOfDay(selectedInterval.end ? isBefore(selectedInterval.end, selectedInterval.start) ? selectedInterval.start : selectedInterval.end : selectedInterval.start),
            color: false
        }
        if (isWithinInterval(date, interval)) {
            return {
                isBeginOfInterval: isSameDay(date, interval.start),
                isEndOfInterval: isSameDay(date, interval.end),
                color: true
            };
        }
        return {}
    }
    return {};
}

interface Day {
    date: Date,
    key: string;
    dateString: string;
    isCurrentMonth: boolean;
    isToday: boolean;
    isWeekend: boolean;
    isBeginOfInterval: boolean | undefined;
    isEndOfInterval: boolean | undefined;
    color: boolean | undefined;
}

const getDates = (current: Date, interval: CalendarInterval) => {
    let startDate = startOfMonth(current)
    startDate = subDays(startDate, getISODay(startDate) - 1)
    const weeks = getWeeksInMonth(current, { weekStartsOn: 1 })
    const days = weeks * weekDaysISO.length;
    const allDays: Day[] = [];
    for (let i = 0; i < days; i++) {
        const date = addDays(startDate, i);
        const w = getMonth(date) - getMonth(current);
        const isDayWeekend = isWeekend(date);
        const intervalProps = getIntervalProps(date, interval);
        const isCurrentMonth = w === 0
        allDays.push({
            date,
            key: format(date, "Md"),
            dateString: format(date, "d"),
            isCurrentMonth: isCurrentMonth,
            isToday: isToday(date),
            isWeekend: isDayWeekend,
            isBeginOfInterval: intervalProps.isBeginOfInterval,
            isEndOfInterval: intervalProps.isEndOfInterval,
            color: isCurrentMonth && intervalProps.color,
        })
    }
    return allDays
}