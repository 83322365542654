import * as React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

export interface Props {
    tabs: ({ name: string; url: string; })[];
    chosenTab: number;
}

export const LinkTabs = ({tabs, chosenTab}: Props) => (
    <div className="LinkTabs-container">
        {tabs.map(({name, url}) => 
            <NavLink className="LinkTabs-tab" activeClassName="LinkTabs-active" key={url} to={url}>{name}</NavLink>
        )}
    </div>
);
