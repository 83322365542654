import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import './styles.scss';

export interface ButtonProps {
    loading?: boolean;
    onClick?(): void;
    children?: React.ReactNode;

    disabled?: boolean;
    inline?: boolean;
    small?: boolean;
    outlined?: boolean;
    circular?: boolean;
    secondary?: boolean;
    className?: string;
}

export const Button = (props: ButtonProps) => {
    const { loading, children, onClick, inline, small, circular, secondary, disabled, className, outlined } = props;
    return (
        <button
            disabled={loading || disabled}
            className={`Button-button ${small ? "Button-small" : ""} ${outlined ? "Button-outlined" : ""} ${inline ? "Button-inline" : ""} ${circular ? "Button-circular" : ""} ${secondary ? "Button-secondary" : ""} ${disabled ? "Button-disabled" : ""} ${className || ""}`}
            type="submit"
            onClick={onClick}
        >
            {loading ?
                <CircularProgress size={25} className="Button-spinner" /> :
                <span className="Button-content">{children}</span>
            }
        </button>
    );
};
