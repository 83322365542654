import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { DatamatrixScanner } from '../../CodeScanner/DataMatrix';

import { DeviceTypeStore } from '../../../stores/DeviceTypeStore/DeviceTypeStore';
import { AddDeviceStore } from '../../../stores/AddDeviceStore/AddDeviceStore';
import { FullScreenScanner } from '../../CodeScanner/FullScreen';
import { ScanError } from './ScanError';

export interface AddDeviceScannerState {
    scanError: 'invalid-code' | 'fail' | null;
}

export interface AddDeviceScannerProps {
    onScan(code: string): void;
    onShowWhereIsCode(): void;
    onEnterManuallyClick(): void;
    deviceTypeStore?: DeviceTypeStore;
    addDeviceStore?: AddDeviceStore;
}

export const AddDeviceScanner = inject('addDeviceStore')(observer(
    class AddDeviceScanner extends React.Component<AddDeviceScannerProps, AddDeviceScannerState> {

        constructor(props: AddDeviceScannerProps) {
            super(props);
            this.state = {
                scanError: null
            };
        }

        render() {
            const { onScan, onShowWhereIsCode, onEnterManuallyClick, addDeviceStore } = this.props;
            const { scanError } = this.state;

            if (scanError !== null) {
                return <ScanError error={scanError} onRetry={() => this.setState({ scanError: null })} />;
            }

            return (
                <FullScreenScanner
                    onQuestionMarkWasClicked={onShowWhereIsCode}
                    closeUrl="/devices"
                    onSubtextWasClicked={onEnterManuallyClick}
                    scanner={onError => (
                        <DatamatrixScanner onError={onError} onScan={async (code) => {
                            try {
                                const codeIsValid = await addDeviceStore!.isValidDeviceCode(code);

                                if (codeIsValid === false) {
                                    this.setState({ scanError: 'invalid-code' });
                                } else {
                                    onScan(code);
                                }

                            } catch {
                                this.setState({ scanError: 'fail' });
                            }
                        }} />
                    )}
                />
            );
        }
    }
));
