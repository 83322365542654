import * as React from 'react';
import { Config } from "./Config";
import { Header } from './components/Text/Header/Header';
import { createTheme } from '@material-ui/core';

import { Badge } from './components/Badge/Badge';
import { DashboardIcon, SmarthomeIcon, SettingsIcon, NotificationsListIcon, HelpIcon, AccountDetailsIcon, StatisticsIcon, AccountDetailsOutlinedIcon, HelpOutlinedIcon, NotificationsListOutlinedIcon, SettingsOutlinedIcon, SmarthomeOutlinedIcon, StatisticsOutlinedIcon, DashboardOutlinedIcon } from './MenuIcons';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Devices } from './components/Devices/Devices';
import { Settings } from './components/Settings';
import Help from './components/Help/Help';
import { AccountDetails } from './components/AccountDetails/AccountDetails';
import { DeviceSettingsContainer } from './components/DeviceSettings/Container';
import { DeviceDetailsContainer } from './components/DeviceDetails/Container';
import { AddDevice } from './components/AddDevice/AddDevice';
import { ReconnectDevice } from './components/ReconnectDevice/ReconnectDevice';
import { ReconnectMeter } from './components/ReconnectMeter/ReconnectMeter';
import { AddMeter } from './components/AddMeter/AddMeter';
import { GatewayConfiguration } from './components/AddGateway/GatewayConfiguration';
import { ConnectingWithGateway } from './components/SmartDevices/ConnectingWithGateway/ConnectingWithGateway';
import { Gateway } from './components/Settings/Gateway/Gateway';
import { NotificationDetails } from './components/NotificationDetails/NotificationDetails';
import { NotificationsList } from './components/NotificationsList/NotificationsList';
import { Statistics } from './components/Statistics/Statistics';

const personalDataConsentText = () => (<>
	<p style={{ textAlign: 'center' }}><strong><strong>&sect;1</strong></strong></p>
	<p style={{ textAlign: 'center' }}><strong>Definicje</strong></p>
	<ol>
		<li><strong>Operator </strong><span>&ndash; sp&oacute;łka pod firmą </span><strong>Lerta Sp&oacute;łka Akcyjna</strong><span> z siedzibą w Poznaniu (kod pocztowy 61-623), przy ul. Wilczak 49, wpisana do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań &ndash; Nowe Miasto i Wilda w Poznaniu, Wydział VIII KRS pod numerem 0000848411, posługująca się numerem NIP 9721266595 oraz numerem REGON 365392336, będąca właścicielem i operatorem Serwis&oacute;w oraz świadcząca Usługi;</span></li>
		<li><strong>Serwisy</strong><span> &ndash; serwisy internetowe dostępne z poziomu domen lerta.energy, lerta.biz oraz lerta.cloud (wraz z subdomenami), aplikacje mobilne udostępniane przez Operatora do pobrania z publicznie dostępnych repozytori&oacute;w i sklep&oacute;w z aplikacjami oraz konta Operatora prezentowane na portalach społecznościowych.</span></li>
		<li><strong>Usługi </strong><span>&ndash; świadczone przez Operatora, dostępne z poziomu Serwis&oacute;w usługi polegające np. na dostępie i przetwarzaniu danych pomiarowych, telemetrycznych oraz innych związanych z gospodarką energetyczną Użytkownika.</span></li>
		<li><strong>Użytkownik </strong><span>&ndash; będąca konsumentem w rozumieniu art. 22</span><span>1</span><span> kodeksu cywilnego, nieprowadząca działalności gospodarczej osoba fizyczna; osoba fizyczna prowadząca działalność gospodarczą; osoba prawna lub inna osoba nieposiadająca osobowości prawnej, kt&oacute;ra działając osobiście lub poprzez upoważnionego reprezentanta zaakceptowała regulamin i korzysta z jednego lub więcej Serwis&oacute;w.</span></li>
		<li><strong>Dni robocze</strong><span> &ndash; dni tygodnia od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy.</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;2</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Wymagania techniczne</strong></p>
	<ol>
		<li><span>Do korzystania z Serwis&oacute;w niezbędne jest posiadanie urządzenia z dostępem do sieci Internet, przeglądarki zasob&oacute;w internetowych, aktywnej skrzynki poczty elektronicznej (e-mail) oraz włączonej obsługi plik&oacute;w cookies.</span></li>
		<li><span>Do korzystania z Serwis&oacute;w będących aplikacjami mobilnymi Operatora, dodatkowo niezbędna jest przeglądarka internetowa:</span>
			<ul>
				<li>Google Chrome w wersji 73.0 lub nowsza</li>
				<li>Vivaldi w wersji 2.5 lub nowsza</li>
				<li>Microsoft Edge 45 lub nowsza</li>
				<li>FIrefox w wersji 67.0 lub nowsza</li>
				<li>Safari w wersji 12.1 lub nowsza</li>
			</ul>
		</li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;3</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Rejestracja użytkownika</strong></p>
	<ol>
		<li><span>Część z Serwis&oacute;w wymaga dokonania rejestracji przez Użytkownika.</span></li>
		<li><span>Rejestracja jest dokonywana przez serwis internetowy lub aplikację mobilną Operatora i polega na wypełnieniu formularza rejestracyjnego.</span></li>
		<li><span>Użytkownik może pobrać aplikacje mobilne operatora bezpośrednio z jego serwis&oacute;w internetowych lub ze sklep&oacute;w internetowych odpowiednich dla używanego systemu operacyjnego (np. Google Play, Apple Store).</span></li>
		<li><span>Dokonanie rejestracji jest jednoznaczne z akceptacją niniejszego Regulaminu i polityki prywatności oraz zawarciem Umowy między Operatorem a Użytkownikiem na świadczenie usług w ramach Serwis&oacute;w, zgodnie z zasadami określonymi w niniejszym Regulaminie.</span></li>
		<li><span>W chwili zawarcia Umowy, Operator udziela użytkownikowi niewyłącznej i nieodpłatnej licencji na korzystanie z Serwis&oacute;w. Licencji udziela się wyłącznie w celu korzystania z usług Serwis&oacute;w. Każde inne użycie, jak r&oacute;wnież dokonywanie zmian w Serwisach, ich dekompilacja, sprzedaż, rozpowszechnianie, użyczanie oraz udzielanie dalszych licencji są zakazane.</span></li>
		<li><span>Dokonując rejestracji, Użytkownik wyraża zgodę na otrzymywanie informacji o funkcjonowaniu Usług i Serwis&oacute;w.</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;4</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Prawa i obowiązki Użytkownika</strong></p>
	<ol>
		<li><span>W celu korzystania z niekt&oacute;rych Serwis&oacute;w, a w szczególności do pobrania i zainstalowania aplikacji mobilnych, Użytkownik musi posiadać urządzenie z dostępem do internetu oraz dokonać pełnej rejestracji w Serwisie.</span></li>
		<li><span>Użytkownik przyjmuje do wiadomości, że do realizacji Umowy konieczne jest podanie poprawnych i aktualnych danych osobowych, wykorzystywanych podczas korzystania z Usług.</span></li>
		<li><span>W celu realizacji Umowy, Użytkownik jest ponadto zobowiązany na bieżąco aktualizować swoje dane osobowe w Serwisach, niezwłocznie po zaistniałej zmianie. Obowiązek ten dotyczy szczególnie zmiany adresu e-mail lub przekazania obecnego adresu osobie trzeciej.</span></li>
		<li><span>W przypadku podejrzenia użycia Serwis&oacute;w przez osoby trzecie, utraty bądź kradzieży urządzenia z zainstalowaną aplikacją, Użytkownik jest zobowiązany niezwłocznie powiadomić Operatora o zaistniałym zdarzeniu.</span></li>
		<li><span>Hasło Użytkownika, które zostanie ustanowione podczas rejestracji lub zmienione w późniejszym czasie przez Użytkownika, nie może być przekazywane osobom trzecim i musi być chronione w sposób uniemożliwiający jego podgląd osobom trzecim. Jeśli wystąpi podejrzenie ze strony Użytkownika, iż dostęp do hasła uzyskała osoba trzecia, należy o tym fakcie niezwłocznie poinformować Operatora.</span></li>
		<li><span>W razie zgłoszenia przez Użytkownika sytuacji wskazanej w pkt. 4 lub 5 powyżej, Konto Użytkownika zostanie natychmiast zablokowane. Ponowne odblokowanie Konta Użytkownika będzie możliwe tylko i wyłącznie przez konsultanta BOK.</span></li>
		<li><span>Jeśli Użytkownik nie wywiąże się z wyżej wymienionych obowiązków i poprzez to narazi Operatora, jednostkę samorządu terytorialnego, inną firmę lub podmiot na straty, to poszkodowanemu przysługuje prawo do dochodzenia roszczeń bezpośrednio od Użytkownika. Operator jest ponadto upoważniony w takich przypadkach do zablokowania Konta Użytkownika.</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;6</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Prawa i obowiązki Operatora</strong></p>
	<ol>
		<li><span>Operator zobowiązany jest zapewnić ciągły dostęp do Serwis&oacute;w, jak i zapewnić bezpieczeństwo realizowanych za ich pomocą Usług.</span></li>
		<li><span>Operator nie ponosi odpowiedzialności za jakiekolwiek szkody poniesione przez Użytkownika w wyniku funkcjonowania urządzeń, z których Użytkownik korzysta przy dostępie do Serwis&oacute;w. Urządzenia muszą spełniać wymogi i standardy przewidziane dla sieci telekomunikacyjnej, w której są wykorzystywane i użytkowane zgodnie z zaleceniami producenta. Operator nie ponosi również odpowiedzialności względem Użytkownika niebędącego osobą fizyczną korzystającą z Serwis&oacute;w w celu niezwiązanym bezpośrednio z jej działalnością zawodową lub gospodarczą za jakiekolwiek szkody poniesione przez Użytkownika wskutek użycia Serwis&oacute;w, jak również korzystania z Usług, chyba że szkoda wynikła z winy umyślnej Operatora.</span></li>
		<li><span>Operator nie ponosi odpowiedzialności za funkcjonowanie interfejsów oraz łączy telekomunikacyjnych, które nie są jego własnością lub nie są przez niego obsługiwane, w szczególności interfejsów służących do korzystania z Serwis&oacute;w przez Użytkownika.</span></li>
		<li><span>Operator zastrzega sobie prawo do czasowego zawieszenia działania Serwis&oacute;w, w szczególności w celu przeprowadzenia koniecznej konserwacji, dokonania zmian lub zapobieżenia ewentualnym szkodom. O zawieszeniu działalności Serwis&oacute;w zobowiązany jest poinformować Użytkowników.</span></li>
		<li><span>W przypadku, gdy Operator poweźmie uzasadnione podejrzenie, iż Konto Użytkownika jest lub może zostać wykorzystane do nadużyć, lub niezgodnie z obowiązującymi przepisami prawa, lub z postanowieniami Umowy, Operator zastrzega sobie prawo do zablokowania Konta Użytkownika, co będzie oznaczać brak możliwości realizacji jakichkolwiek usług za pośrednictwem danego Konta Użytkownika. W przypadku podejrzenia popełnienia przestępstwa przez Użytkownika Serwis&oacute;w, po ustaleniu stanu faktycznego, Operator może złożyć zawiadomienie o podejrzeniu popełnienia przestępstwa do upoważnionych organów.</span></li>
		<li><span>W przypadku zablokowania Konta Użytkownika, na wezwanie Operatora, Użytkownik zobowiązany jest współdziałać z Operatorem celem wyjaśnienia nieprawidłowości, w szczególności dostarczyć wyjaśnienia i odpowiednie dokumenty.</span></li>
		<li><span>Operator rozpatruje reklamację Użytkownika w terminie nie dłuższym niż 14 dni od dnia jej doręczenia.</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;7</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Rozwiązanie Umowy</strong></p>
	<ol>
		<li><span>Użytkownik może w każdej chwili rozwiązać umowę ze skutkiem natychmiastowym poprzez usunięcie swojego konta Użytkownika oraz odinstalowanie aplikacji i zaprzestanie korzystania z Serwis&oacute;w.</span></li>
		<li><span>Użytkownik oraz Operator mają r&oacute;wnież prawo do wypowiedzenia Umowy poprzez złożenie oświadczenia woli w formie pisemnej lub elektronicznej ze skutkiem na koniec miesiąca kalendarzowego następującego po miesiącu, w kt&oacute;rym dostarczono oświadczenie drugiej stronie.</span></li>
		<li><span>Umowa może ulec rozwiązaniu bez wypowiedzenia w przypadkach przewidzianych niniejszym Regulaminem.&nbsp;</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><span><strong>&sect;8</strong></span></p>
	<p style={{ textAlign: 'center' }}><strong>Przetwarzanie i ochrona danych osobowych</strong></p>
	<ol>
		<li><span>Użytkownik przyjmuje do wiadomości, że do realizacji Umowy konieczne jest przetwarzanie danych osobowych na warunkach przewidzianych w ustawie z dnia 29.08.1997 r. o ochronie danych osobowych (Dz. U. z 2016 roku, poz. 922 ze zm.). Art.6 ust. 1 lit.b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679/UE z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1).</span></li>
		<li><span>Użytkownik rozumie i akceptuje fakt, iż Administratorem Danych Osobowych jest Operator.</span></li>
		<li><span>Szczeg&oacute;łowe zasady Polityki Prywatności stanowią załącznik do Regulaminu.</span></li>
	</ol>
	<p style={{ textAlign: 'center' }}><strong>&sect;9</strong></p>
	<p style={{ textAlign: 'center' }}><strong>Postanowienia końcowe</strong></p>
	<ol>
		<li><span>Operator jest uprawniony do jednostronnego wprowadzenia zmian lub uzupełnień w każdym czasie w niniejszym Regulaminie. Informacja o zmianach wraz z nowym brzmieniem Regulaminu oraz datą wejścia w życie będzie doręczana Użytkownikowi za pośrednictwem poczty elektronicznej na adres wskazany w procesie rejestracji w Serwisach. Ponadto, informacja o wprowadzonych zmianach będzie udostępniana w Serwisach (w tym applikacjach mobilnych oraz serwisach internetowych) oraz stronach internetowych Operatora, wraz ze wskazaniem daty wejścia w życie zmian.</span></li>
		<li><span>&nbsp;Jeżeli przed datą wejścia w życie proponowanych zmian Użytkownik nie zgłosi sprzeciwu do Operatora wobec tych zmian, uznaje się, że Użytkownik wyraził na nie zgodę. Zgłoszenie sprzeciwu jest równoznaczne z wypowiedzeniem Umowy przez Użytkownika ze skutkiem natychmiastowym.</span></li>
		<li><span>Promocje Operatora, programy lojalnościowe oraz inne działania o charakterze marketingowym prowadzone są wg zapisów odpowiedniego regulaminu.</span></li>
		<li><span>Operator oraz Użytkownik zobowiązują się do podjęcia wszelkich działań, mających na celu polubowne rozwiązanie ewentualnego sporu.</span></li>
		<li><span>We wszystkich sprawach dotyczących korzystania z Serwis&oacute;w nieuregulowanych niniejszym Regulaminem zastosowanie mają odpowiednie przepisy powszechnie obowiązującego prawa na terytorium Rzeczpospolitej Polskiej.</span></li>
		<li><span>Niniejszy Regulamin nie ma zastosowania na terenie innych krajów, gdzie Serwisy są dostępne. W takim przypadku obowiązuje oddzielny regulamin, którego zapisy dostosowane są do regulacji obowiązujących w danym kraju.</span></li>
	</ol>
	<p>&nbsp;</p>
	<p style={{ textAlign: 'center' }}><strong>Polityka Prywatności i pliki cookie</strong></p>
	<p><span>Ochrona danych osobowych, kt&oacute;re nam powierzasz jest dla nas bardzo ważna. Dlatego dokładamy wszelkich starań w celu zabezpieczenia Twojej prywatności podczas korzystania z naszych usług na www.lerta.energy oraz innych platformach internetowych i mobilnych.</span></p>
	<p><span>W tym dokumencie przeczytasz w jaki spos&oacute;b wykorzystujemy i przetwarzamy Twoje dane osobowe (dalej jako dane) oraz jak się z nami skontaktować w razie pytań dotyczących Twoich danych. Prezentujemy też ważne informacje o plikach cookie i podobnych stosowanych przez nas technologiach.</span></p>
	<p><span>Wszystkie informacje, kt&oacute;re przeczytasz poniżej dotyczą wszystkich naszych serwis&oacute;w internetowych, aplikacji mobilnych oraz kont Lerta SA prezentowanych na portalach społecznościowych (dalej jako &bdquo;Serwisy&rdquo;).</span></p>
	<p><span>Co ważne, jeśli nie akceptujesz naszej &bdquo;Polityki prywatności&rdquo;, prosimy o zaprzestanie korzystania z Serwis&oacute;w.</span></p>
	<p><span>Jeśli masz jakiekolwiek sugestie lub komentarze dotyczące tego dokumentu, napisz do nas korzystając z formularza kontaktowego.</span></p>
	<p><strong>Kto jest odpowiedzialny za przetwarzanie danych w Serwisach?</strong></p>
	<p><span>Przetwarzanie danych w Serwisach kontroluje Lerta SA z siedzibą w Poznaniu, ul. Wilczak 49 (61-623 Poznań).</span></p>
	<p><strong>Jakiego rodzaju dane zbieramy?</strong></p>
	<ul>
		<li><span>gdy korzystasz z naszych usług w Serwisach zbieramy w szczeg&oacute;lności: adresy IP, adres URL, informacje o stronach internetowych odwiedzanych w Serwisach, informacje o zachowaniach w Serwisach, kliknięcia w elementy stron w Serwisach, nazwy otwieranych plik&oacute;w, daty i godziny wejść do Serwis&oacute;w, nazwę Twojego dostawcy usług internetowych, informację o rodzaju Twojego systemu operacyjnego oraz o rodzaju i wersji Twojej przeglądarki;</span></li>
		<li><span>gdy pytasz o ofertę lub dokonujesz rejestracji użytkownika za pośrednictwem formularzy dostępnych w Serwisach, w zależności od rodzaju formularza, zbieramy także Twoje: imię, nazwisko, adres e-mail, numer telefonu;</span></li>
		<li><span>jeśli kontaktujesz się z nami poprzez media społecznościowe lub czat, zbieramy także informacje takie jak zdjęcia, dokumenty udostępniane przez Ciebie za pośrednictwem medi&oacute;w społecznościowych lub czatu;</span></li>
	</ul>
	<p><strong>W jaki spos&oacute;b pobieramy dane?</strong></p>
	<ul>
		<li><span>używając danych logowania w serwisie lerta.energy;</span></li>
		<li><span>używając plik&oacute;w cookie lub narzędzi analitycznych i badawczych, w szczeg&oacute;lności takich jak Hotjar, Google Analytics;</span></li>
		<li><span>z formularzy kontaktowych zamieszczonych w Serwisach;</span></li>
		<li><span>z komunikator&oacute;w dostępnych na portalach społecznościowych i czata, o ile wybierzesz taką formę kontaktu z nami.</span></li>
	</ul>
	<p><strong>Do czego używamy Twoich danych?</strong></p>
	<ul>
		<li><span>do realizacji na Twoją rzecz usług świadczonych drogą elektroniczną;</span></li>
		<li><span>do realizacji naszych usług, z kt&oacute;rych korzystasz;</span></li>
		<li><span>do dostarczenia zam&oacute;wionych produkt&oacute;w lub usług;</span></li>
		<li><span>do obsługi Twojego żądania, zlecenia, wniosku lub zapytania;</span></li>
		<li><span>do działań marketingowych, tj. do przesyłania Ci powiadomień o produktach i usługach;</span></li>
		<li><span>do cel&oacute;w analitycznych, aby poprawić jakość Serwis&oacute;w;</span></li>
		<li><span>do cel&oacute;w rozwoju, naprawy lub test&oacute;w r&oacute;żnych funkcjonalności Serwis&oacute;w;</span></li>
		<li><span>Twoje dane mogą być też przetwarzane w naszym imieniu przez podmioty, z kt&oacute;rymi wsp&oacute;łpracujemy, w następujących celach:</span></li>
		<ul>
			<li><span>realizacji naszej usługi, z kt&oacute;rej korzystasz;</span></li>
			<li><span>realizacji Twojego zam&oacute;wienia i dostarczenia produkt&oacute;w lub usług;</span></li>
			<li><span>realizacji wybranego przez Ciebie sposobu płatności;</span></li>
			<li><span>analizy rynku i opinii;</span></li>
			<li><span>marketingowych.</span></li>
		</ul>
	</ul>
	<p><strong>Jak możesz kontrolować przekazane przez Ciebie dane?</strong></p>
	<ul>
		<li><span>masz prawo dostępu do informacji o Tobie, kt&oacute;re przechowujemy w Serwisach;</span></li>
		<li><span>na Twoją prośbę dostarczymy Ci kopię Twoich danych, kt&oacute;re przetwarzamy.</span></li>
		<li><span>masz prawo do żądania sprostowania (poprawienia, uzupełnienia) Twoich danych przetwarzanych w Serwisach, ograniczenia ich przetwarzania lub usunięcia,</span></li>
		<li><span>masz prawo złożenia sprzeciwu wobec wykorzystywania danych na cele marketingowe, badania rynku i opinii, analizy i monitorowania w Serwisach,</span></li>
		<li><span>masz prawo żądania przeniesienia danych, kt&oacute;re przetwarzamy tj. możesz je otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego oraz masz prawo przesłać te dane innemu administratorowi. Jeżeli jest to technicznie możliwe, masz prawo żądania, aby dane osobowe zostały przesłane przez nas bezpośrednio innemu administratorowi</span></li>
	</ul>
	<p><span>Aby skorzystać z ww. praw możesz się z nami kontaktować jak niżej.</span></p>
	<p><strong>W jaki spos&oacute;b możesz się z nami skontaktować?</strong></p>
	<ul>
		<li><span>telefonicznie &ndash; pod następującym numerem: +48 733 621&nbsp;621,</span></li>
		<li><span>poprzez formularz kontaktowy/e-mail: </span><a href="mailto:info@lerta.energy"><span>info@lerta.energy</span></a><span>,</span></li>
		<li><span>poprzez opiekuna biznesowego &ndash; jeśli został Ci przydzielony.</span></li>
	</ul>
	<p><strong>Pliki cookie</strong></p>
	<p><span>Gdy korzystasz z naszych Serwis&oacute;w, gromadzimy informacje o Twojej wizycie i sposobie poruszania się w Serwisach. W tym celu stosujemy pliki cookie. Plik cookie zawiera dane, kt&oacute;re są umieszczone w przeglądarce Twojego komputera lub urządzenia mobilnego. Pliki cookie używane w Serwisach wykorzystywane są między innymi do zapamiętywania Twoich preferencji czy zabezpieczenia stron internetowych oraz do bieżącej optymalizacji Serwis&oacute;w dla naszych Klient&oacute;w. Niekt&oacute;re funkcje Serwis&oacute;w nie będą działały w sytuacji, gdy nie wyrazisz zgody na instalowanie plik&oacute;w cookie.</span></p>
	<p>&nbsp;</p>
	<p><span>Instalowanie plik&oacute;w cookie lub uzyskiwanie do nich dostępu nie powoduje zmian w Twoim urządzeniu ani w oprogramowaniu zainstalowanym na tym urządzeniu. Stosujemy dwa rodzaje plik&oacute;w cookie: sesyjne i trwałe. Pliki sesyjne wygasają po zakończonej sesji, kt&oacute;rej czas trwania i dokładne parametry wygaśnięcia określa używana przez Ciebie przeglądarka oraz nasze systemy analityczne. Trwałe pliki cookie nie są kasowane w momencie zamknięcia okna przeglądarki, gł&oacute;wnie po to by informacje o wyborach użytkownika nie zostały utracone. Pliki cookie aktywne długookresowo wykorzystywane są, aby pom&oacute;c nam określić nowego i powracającego użytkownika Serwis&oacute;w.</span></p>
	<p><span>Dodatkowo wykorzystujemy także inne technologie podobne do plik&oacute;w cookie takie jak web beacons zwane pikselami. Są to niewielkie pliki graficzne pobierane przez Tw&oacute;j komputer lub urządzenie mobilne po otwarciu danej strony internetowej w Serwisach, za pośrednictwem reklamy, wiadomości e-mail czy aplikacji mobilnej. Wykorzystujemy je po to, by zapamiętywać Twoje preferencje, a także do cel&oacute;w marketingowych.</span></p>
	<p><span>Niekt&oacute;re pliki cookie są tworzone przez podmioty, z kt&oacute;rych usług korzystamy. Stosujemy pliki cookie w celach określonych w nawiasach: Google Inc. (Google Analytics, Double Click i Google Adwords), Facebook.</span></p>
	<p><strong>Do czego wykorzystujemy pliki cookie?</strong></p>
	<ul>
		<li><span>pliki cookies techniczne: niezbędne do poprawnego funkcjonowania Serwis&oacute;w, pozwalają na utrzymanie poprawności wyświetlania Serwis&oacute;w w czasie sesji;</span></li>
		<li><span>pliki cookies analityczne: przede wszystkim do cel&oacute;w statystycznych, aby sprawdzić jak często odwiedzane są poszczeg&oacute;lne strony Serwis&oacute;w. Dane te wykorzystujemy do optymalizacji Serwis&oacute;w pod kątem odwiedzających nas użytkownik&oacute;w. Pliki te wykorzystujemy r&oacute;wnież, aby zapamiętywać spos&oacute;b korzystania przez Ciebie z Serwis&oacute;w i ułatwić Ci ich przeglądanie. Dzięki temu, możemy lepiej dopasować prezentowane treści oraz funkcjonalności do Twoich potrzeb;</span></li>
		<li><span>pliki cookies marketingowe: w celach marketingowych, aby dopasować przekaz marketingowy w Serwisach lub innych serwisach zewnętrznych do Twoich preferencji. Umożliwiają także ograniczenie liczby reklam produkt&oacute;w i usług, z kt&oacute;rymi już wcześniej miałeś styczność;</span></li>
		<li><span>pliki cookies społecznościowe: w celach marketingowych, aby dopasować przekaz marketingowy w serwisach społecznościowych do Twoich preferencji. Dla przykładu, pozwolą one na wyświetlenie reklam produkt&oacute;w i usług Lerta podczas korzystania przez Ciebie z innych serwis&oacute;w lub medi&oacute;w społecznościowych. Umożliwiają także ograniczenie liczby reklam produkt&oacute;w i usług, z kt&oacute;rymi już wcześniej miałeś styczność;</span></li>
	</ul>
	<p>&nbsp;</p>
	<p><strong>W jaki spos&oacute;b możesz nie wyrazić zgody na instalowanie plik&oacute;w cookie za pomocą ustawień przeglądarki?</strong></p>
	<p>&nbsp;</p>
	<p><span>Zdecydowana większość przeglądarek domyślnie akceptuje pliki cookie w celu zapewnienia użytkownikom wygody korzystania ze stron internetowych i niezakł&oacute;conego wyświetlania ich zawartości.</span></p>
	<p><span>Jeśli nie chcesz, by pliki cookie były instalowane na Twoim urządzeniu, możesz nie wyrazić zgody na instalowanie plik&oacute;w cookie w następujący spos&oacute;b:</span></p>
	<ul>
		<li><span>W przeglądarce Internet Explorer należy: wybrać z menu gł&oacute;wnego pozycję "Narzędzia/Opcje internetowe", a następnie w sekcji "Prywatność" zaznaczyć "Zaawansowane" i wyłączyć pliki cookies.</span></li>
	</ul>
	<ul>
		<li><span>W przeglądarce Mozilla Firefox należy: wybrać z menu pozycję "Narzędzia", a następnie pozycję "Opcje" i określić poziom prywatności w sekcji "Prywatność".</span></li>
		<li><span>W przeglądarce Opera należy: wybrać z menu pozycję "Narzędzia" a następnie z pozycji "Preferencje" wybrać "Zaawansowane", a potem "Ciasteczka".</span></li>
		<li><span>W przeglądarce Google Chrome należy: z menu po prawej stronie należy wybrać pozycję "Opcje", a następnie "Dla zaawansowanych" oraz "Ustawienia plik&oacute;w cookies".</span></li>
		<li><span>W przeglądarce Safari: menu Ustawienia należy wybrać pozycję "Safari", a następnie w sekcji "Prywatność i ochrona" wybrać "Blokuj cookie".</span></li>
	</ul>
	<p><span>Są to przykładowe typy przeglądarek. W większości przeglądarek tego typu informacje dotyczące plik&oacute;w cookie można znaleźć w menu "Narzędzia" lub "Opcje&rdquo;.</span></p>
	<p><span>Pamiętaj jednak, że ograniczenia w stosowaniu plik&oacute;w cookie mogą utrudnić lub uniemożliwić korzystanie z Serwis&oacute;w.</span></p>
	<p><strong>Hotjar</strong></p>
	<p><span>W Serwisach wykorzystujemy narzędzie Hotjar, kt&oacute;re monitoruje oraz zapisuje zachowanie użytkownika w Serwisie. Narzędzie to rejestruje dane takie jak: nawigacja, przewijanie strony, ruch kursora. Zbierane są także informacje na temat lokalizacji, używanego urządzenia, systemu operacyjnego, przeglądarki czy plik&oacute;w cookie.</span></p>
	<p><span>Hotjar nie zbiera ani nie przechowuje innych danych. Więcej informacji na temat polityki prywatności narzędzia Hotjar znajduje się pod linkiem: https://www.hotjar.com/privacy.</span></p>
	<p><span>Jeżeli nie chcesz, aby Twoja aktywność w Serwisie była mierzona przez Hotjar, skorzystaj z poniższego linku: https://www.hotjar.com/opt-out. Pamiętaj, że jeśli po skorzystaniu z tej funkcji usuniesz pliki cookie w swojej przeglądarce i wr&oacute;cisz do naszych Serwis&oacute;w, monitorowanie Hotjar włączy się ponownie.</span></p>
	<p><strong>Google Analytics</strong></p>
	<p><span>W Serwisach wykorzystujemy r&oacute;wnież narzędzie Google Analytics. do analizy zachowań użytkownik&oacute;w. Narzędzie to zbiera dane o plikach cookie. Potrzebujemy ich przede wszystkim do cel&oacute;w statystycznych, aby sprawdzić jak często odwiedzane są poszczeg&oacute;lne Serwisy. Dane te wykorzystujemy r&oacute;wnież do optymalizacji i rozwoju Serwis&oacute;w.</span></p>
</>)

const getTheme = () => {
	const theme = createTheme({
		palette: {
			type: 'light',
			primary: {
				light: '#00CFB5',
				main: '#00CFB5',
				dark: '#00CFB5',
			},
			secondary: {
				light: '#334b81',
				main: '#001E62',
				dark: '#00123b',
			},
			background: {
				default: 'rgb(244,244,244)',
				paper: 'rgb(255,255,255)'
			},
			error: {
				main: '#FF4531',
				light: '#ffdad5',
				'A400': '#e56174'
			},
			grey: {
				"50": '#ffffff',
				"100": '#ccd2e0',
				"200": '#99a5c0',
				"300": '#6678a1',
				"400": '#334b81',
				"500": '#001E62', // secondary color
				"600": '#00184e',
				"700": '#00123b',
				"800": '#000c27',
				"900": '#000614',
				A100: '#ccd2e0',
				A200: '#334b81',
				A400: '#00184e',
				A700: '#000614',
			},
			text: {
				primary: '#00184e',
				secondary: '#001E62',
				disabled: '#99a5c0',
				hint: '#99a5c0',
			},
			action: {
				active: '#001e62',
				disabled: '#99a5c0',
				disabledBackground: '#edeff4',
				selected: '#00CFB5',
			}
		},
		typography: {
			fontFamily: 'SourceSansPro-Regular',
			fontSize: 16,
			h5: {
				fontSize: '1.45rem'
			}
		},
		overrides: {
			MuiButton: {
				root: {
					borderRadius: 35,
					textTransform: 'none',
					fontWeight: 'bold',
				},
				containedPrimary: {
					color: 'rgb(255, 255, 255)'
				},
				outlined: {
					borderColor: 'rgb(0,30,98)',
				}
			},
			MuiListItemText: {
				primary: {
					color: 'rgb(0,30,98)'
				}
			}
		},
		props: {
			MuiPaper: {
				elevation: 8,
			},
			MuiCircularProgress: {
				thickness: 1,
			}
		},
		shape: {
			borderRadius: 8,
		}
	});

	theme.overrides!.MuiInput = {
		root: {
			color: theme.palette.primary.main,
		},
		underline: {
			'&::before': {
				borderBottom: `1px solid ${theme.palette.secondary.main}`
			},
		},
	};
	return theme
}

const lertaConfig: Config = {
	availableRoutes: {
		register: true
	},
	availableModules: {
		default: '/dashboard',
		all: [{
			url: "/dashboard",
			name: "dashboard",
			menu: {
				label: "Pulpit",
				icon: DashboardIcon,
				outlinedIcon: DashboardOutlinedIcon
			},
			availableFeatures: ['dashboard'],
			component: Dashboard,
		},
		{
			url: "/statistics",
			name: "statistics",
			menu: {
				label: "Statystyki",
				icon: StatisticsIcon,
				outlinedIcon: StatisticsOutlinedIcon
			},
			availableFeatures: ['pv'],
			component: Statistics,
		},
		{
			url: "/devices",
			exact: true,
			name: 'devices',
			menu: {
				label: 'Urządzenia',
				icon: SmarthomeIcon,
				outlinedIcon: SmarthomeOutlinedIcon
			},
			availableFeatures: ['smartHome', 'supplyPoints'],
			component: Devices,
		},
		{
			url: "/devices/smart_home",
			exact: true,
			availableFeatures: ['smartHome'],
			component: Devices,
		},
		{
			url: "/devices/meters",
			exact: true,
			availableFeatures: ['supplyPoints'],
			component: Devices,
		},
		{
			url: "/devices/:deviceId/settings",
			exact: true,
			availableFeatures: ['smartHome', 'supplyPoints'],
			component: DeviceSettingsContainer,
		},
		{
			url: "/devices/:deviceId/:dateMode?/:dataType?/:currentDate?",
			availableFeatures: ['smartHome', 'supplyPoints'],
			component: DeviceDetailsContainer,
		},
		{
			url: "/add_device",
			availableFeatures: ['smartHome'],
			component: AddDevice,
		},
		{
			url: "/reconnect_device/:deviceId",
			availableFeatures: ['smartHome', 'supplyPoints'],
			component: ReconnectDevice,
		},
		{
			url: "/reconnect_meter/:deviceId",
			availableFeatures: ['supplyPoints'],
			component: ReconnectMeter,
		},
		{
			url: "/add_supply_point",
			availableFeatures: ['supplyPoints'],
			component: AddMeter,
		},
		{
			url: "/add_gateway/configuration",
			availableFeatures: ['smartHome'],
			component: GatewayConfiguration,
		},
		{
			url: "/add_gateway/notPaired",
			availableFeatures: ['smartHome'],
			component: ConnectingWithGateway,
		},
		{
			url: "/settings/gateway",
			availableFeatures: ['smartHome'],
			component: Gateway,
		},
		{
			url: "/settings",
			name: 'settings',
			menu: {
				label: 'Ustawienia',
				icon: SettingsIcon,
				outlinedIcon: SettingsOutlinedIcon
			},
			availableFeatures: ['smartHome'],
			component: Settings,
		},
		{
			url: "/notifications/:id",
			availableFeatures: ['notifications'],
			component: NotificationDetails,
		},
		{
			url: "/notifications",
			name: 'notifications',
			menu: {
				label: 'Powiadomienia',
				icon: NotificationsListIcon,
				outlinedIcon: NotificationsListOutlinedIcon,
				content: ({ unreadNotifications, label }) => {
					return <Badge content={unreadNotifications} visible={unreadNotifications > 0}>{label}</Badge>
				}
			},
			availableFeatures: ['notifications'],
			component: NotificationsList,
		},
		{
			url: "/help",
			name: 'help',
			menu: {
				label: 'Pomoc',
				icon: HelpIcon,
				outlinedIcon: HelpOutlinedIcon
			},
			availableFeatures: ['help'],
			component: Help,
		},
		{
			url: "/accountDetails",
			name: 'accountDetails',
			menu: {
				label: 'Szczegóły konta',
				icon: AccountDetailsIcon,
				outlinedIcon: AccountDetailsOutlinedIcon
			},
			availableFeatures: ['accountDetails'],
			component: AccountDetails,
		},]
	},
	sideNav: {
		analysis: false
	},
	htmlHeader: {
		title: "Lerta Home",
		manifestPath: "/manifest.lerta.json"
	},
	colorPalette: {
		energyConsumption: "#5482f5",
		energyProduction: "#72688A"
	},
	personalDataConsent: {
		Header: () => <Header bold>Regulamin i warunki korzystania z usług Lerta</Header>,
		AcceptText: () => <span>Akceptuję</span>,
		Text: personalDataConsentText
	},
	termsOfUse: {
		Text: personalDataConsentText
	},
	theme: getTheme()
};

module.exports = lertaConfig;