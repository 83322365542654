export interface APIBaseProvider {
    getAPIBase(): string;
    getHost(): string;
    getTenant(): string;
}

export class ProductionAPIBaseProvider implements APIBaseProvider {
    readonly TENANT_REGEX = /(www\.)?([^.]+)/;

    getAPIBase(): string {
        const host = this.getHost();
        const protocol = window.location.protocol;

        return `${protocol}//${host}/api/v1`;
    }

    getHost(): string {
        return window.location.host;
    }

    getTenant() {
        const host = window.location.host;
        const matches = host.match(this.TENANT_REGEX);
        if (!matches) {
            throw Error('Did not found tenant in app url');
        }
        return matches[2];
    }
}

export class DevelopmentAPIBaseProvider implements APIBaseProvider {
    constructor(private tenant: string = 'cerulean-magnolia') {}

    getAPIBase(): string {
        const host = this.getHost();
        // return `https://home.lerta.biz/api/v1`;
        return `https://${host}/api/v1`;
    }

    getHost(): string {
        return `${this.getTenant()}.cp.dev.lerta.biz`;
    }

    getTenant(): string {
        return this.tenant;
    }
}

export class LocalDevelopmentAPIBaseProvider implements APIBaseProvider {
    constructor(private tenant: string) {}

    getAPIBase(): string {
        const host = this.getHost();

        return `http://${host}`;
    }

    getHost(): string {
        return `localhost:8085`;
    }

    getTenant() {
        return this.tenant;
    }
}
