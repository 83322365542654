import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Header } from '../../Text/Header/Header';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';

const Gateway = require('../../../static/icons/central.svg');

export const ConnectGateway = () => (
    <FullScreenContainer gradient>
        <div>
            <Header>To urządzenie wymaga podłączenia centralki.</Header>
            <Gateway className="AddMeter-infographic" />
        </div>
        <div>
            <Link className="AddMeter-connectGatewayButton" to="/devices/smart_home"><Button>Rozpocznij konfigurację centralki</Button></Link>
            <Link to="/devices/meters"><Button secondary>Anuluj</Button></Link>
        </div>
    </FullScreenContainer>
)
