import * as React from 'react';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '../../Button';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';
import { ConnectingDeviceStore } from '../../../stores/ConnectingDeviceStore/ConnectingDeviceStore';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { WithInstruction } from '../../Instructions/WithInstruction';
import { Loader } from '../../Loader/Loader';
import { SmartDevicesStore } from '../../../stores/SmartDevicesStore/SmartDevicesStore';

const Fabric = require('../../../static/ustawienia_fabryczne.svg');
export interface ReconnectDeviceSecondTimeProps extends RouteComponentProps<any> {
    connectingDeviceStore?: ConnectingDeviceStore;
    smartDevicesStore?: SmartDevicesStore;
    device: SingleDevice;
    urlBack: string;
}

class _ReconnectDeviceSecondTime extends React.Component<ReconnectDeviceSecondTimeProps> {

    componentDidMount() {
        const { device, smartDevicesStore } = this.props
        smartDevicesStore!.fetchDeviceById(device.id)
    }

    async handleReconnect() {
        const { device, connectingDeviceStore, history } = this.props
        await connectingDeviceStore!.restartConnecting(device.id);
        history.push(`/devices/${device.id}`);
    }

    render() {
        const { device, connectingDeviceStore, smartDevicesStore, urlBack } = this.props
        const ReconnectDevicePrompt = device.dictionary.reconnectDeviceSecondPrompt;
        const FactoryResetInstruction = device.dictionary.instructions.factoryReset;
        const postingDeviceConnect = connectingDeviceStore!.postingDeviceConnect;
        return (<React.Fragment>
            <WithInstruction
                component={openInstruction => (
                    <FullScreenContainer className="ReconnectDeviceSecondTime-container">
                        <div className="ReconnectDeviceSecondTime-content">
                            <Fabric className="ReconnectDeviceSecondTime-icon" />
                            <div className="ReconnectDeviceSecondTime-prompt">
                                <ReconnectDevicePrompt onInstructionClick={openInstruction} deviceName={device.name} />
                            </div>
                        </div>
                        {smartDevicesStore!.isDeviceFetching && <div style={{}}><Loader size={30} /></div>}
                        {smartDevicesStore!.deviceFetched && device.paired && <Redirect to={`/devices/${device.id}`} />}
                        <div>
                            <Button className="ReconnectDeviceSecondTime-primaryAlarmButton" disabled={smartDevicesStore!.isDeviceFetching}
                                loading={postingDeviceConnect} onClick={this.handleReconnect.bind(this)}>Połącz</Button>
                            <Link to={urlBack}>
                                <Button className="ReconnectDeviceSecondTime-secondaryAlarmButton">Zrobię to później</Button>
                            </Link>
                        </div>
                    </ FullScreenContainer>
                )}

                instruction={closeInstruction => (
                    <FactoryResetInstruction onClose={closeInstruction} />
                )}
            />
        </React.Fragment>
        );
    }
}

export const ReconnectDeviceSecondTime = inject('connectingDeviceStore', 'smartDevicesStore')(withRouter(observer(_ReconnectDeviceSecondTime)));
