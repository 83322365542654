import React, { useMemo } from 'react';
import './styles.scss'
import { AnalysisData, AnalysisViewType } from '../../../stores/Statistics/AnalysisStore';
import { BarChart, ResponsiveContainer, Bar, YAxis, Label, XAxis, Tooltip, CartesianGrid } from 'recharts';
import { Box } from '@material-ui/core';
import { seriesColor } from '../../../SeriesColor';
import { format } from 'date-fns';
import { getAnalysisChartTooltip } from './AnalysisChartTooltip';

interface Props {
    data: AnalysisData[];
    dateFormat: string;
}

export const AnalysisPeriodChart = ({ data, dateFormat }: Props) => {

    const unit = useMemo(() => data[0].unit, [data])

    return (<Box position="relative">
        <div className="Analysis-yAxis" />

        <Box className="Analysis-chart Analysis-chart-period">
            <ResponsiveContainer minWidth="100%" width={data.length * 45} ref={(ref: any) => {
                if (ref) {
                    const yAxis = ref.current.querySelector(".yAxis")
                    if (yAxis) {
                        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
                        svg.appendChild(yAxis.cloneNode(true))
                        document.querySelector(".Analysis-yAxis")?.appendChild(svg)
                    }
                }
            }}>
                <BarChart data={data} barGap={0} barCategoryGap={30}>
                    <XAxis
                        dataKey="time"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickLine={false}
                        tickFormatter={tick => format(tick, dateFormat)}
                        interval={0}
                        ticks={data.map(dp => dp.time)}
                        padding={{ left: 20, right: 20 }}
                        allowDataOverflow
                    />
                    <YAxis
                        domain={['dataMin', 'dataMax']}
                        tickLine={false}
                        width={40}
                    ><Label value={unit} position="insideBottom" offset={-20} /></YAxis>
                    <Tooltip
                        content={getAnalysisChartTooltip(AnalysisViewType.period, dateFormat)}
                    />
                    <CartesianGrid
                        vertical={false}
                        strokeWidth={2}
                    />
                    <Bar
                        dataKey="production"
                        type="monotone"
                        fill={seriesColor('production')}
                        unit={unit}
                        barSize={5}
                    />
                    <Bar
                        dataKey="autoConsumption"
                        type="monotone"
                        fill={seriesColor('autoConsumption')}
                        unit={unit}
                        barSize={5}
                    />
                    <Bar
                        dataKey="importFromGrid"
                        type="monotone"
                        fill={seriesColor('importFromGrid')}
                        unit={unit}
                        barSize={5}
                    />
                    <Bar
                        dataKey="exportToGrid"
                        type="monotone"
                        fill={seriesColor('exportToGrid')}
                        unit={unit}
                        barSize={5}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Box >
    </Box>)
}