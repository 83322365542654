import { observable, action, onBecomeObserved, computed, makeObservable } from "mobx";
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { WebSocketService } from '../../services/WebSocketService';
import { SingleDevice } from "../SingleDevice/SingleDevice";
import { filter } from 'rxjs/operators';
import { SmartDevicesWSEvent } from "../../infrastructure/SmartDevicesWSEvent";
import { SmartDevicesApiResponse, SmartDevicesItem } from "../../infrastructure/SmartDevicesApiResponse";
import { WithClass, classAndSubclassEquals } from "../../domain/WithClass";
import { SingleDeviceFactory } from "../SingleDevice/SingleDeviceFactory";
import { Subject, Observable } from "rxjs";
import { LocationsStore } from "../LocationsStore/LocationsStore";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { getValue, isFetching, fetched } from "../../utils/PromiseBasedObservable";

export interface DeviceState {
    online?: boolean,
    temperature?: number,
    targetTemperature?: number,
    valveLevel?: number,
    humidity?: number,
    voc?: number,
    power?: number,
    state?: "on" | "off" | "opened" | "closed" | "fire" | "flood"
}

export interface DevicesStateList {
    [serialNumber: string]: DeviceState;
}

export class SmartDevicesStore {
    private fetchedDevicesSubject: Subject<SingleDevice> = new Subject();

    constructor(
        private urlProvider: UrlProvider,
        private httpService: HttpService,
        private webSocketService: WebSocketService,
        private storage: Storage,
        private singleDeviceFactory: SingleDeviceFactory,
        private locationsStore: LocationsStore,
    ) {
        makeObservable(this, {
            fetching: observable,
            fetchingFailed: observable,
            devices: observable,
            alreadyFetched: observable,
            deviceByIdPromise: observable,
            isDeviceFetching: computed,
            deviceFetched: computed,
            fetchDeviceById: action.bound,
            refetchDevices: action.bound,
            fetchDevices: action.bound,
            fetchDevicesState: action.bound
        });
    }

    init() {
        onBecomeObserved(this, 'devices', () => {
            this.fetchDevices();
        });
    }

    fetching: boolean = false;
    fetchingFailed: boolean = false;

    devices: SingleDevice[] = [];

    alreadyFetched: boolean = false;

    deviceByIdPromise: IPromiseBasedObservable<SmartDevicesItem> | null = null;

    get isDeviceFetching(): boolean {
        return isFetching(this.deviceByIdPromise);
    }

    get deviceFetched(): boolean {
        return fetched(this.deviceByIdPromise);
    }

    async fetchDeviceById(deviceId: string) {
        this.deviceByIdPromise = fromPromise(this.httpService.get<SmartDevicesItem>(this.urlProvider.getDeviceUrl(deviceId)))
        await this.deviceByIdPromise
        const smartDevicesItem = getValue<SmartDevicesItem>(this.deviceByIdPromise)

        if (smartDevicesItem) {
            const updatedDevice = await this.singleDeviceFactory.fromDeviceApiResponse(smartDevicesItem)
            updatedDevice.connection.failConnecting()
            const index = this.devices.findIndex(device => device.id === deviceId);
            if (index >= 0) {
                this.devices.splice(index, 1, updatedDevice);
            }
        }
    }

    async refetchDevices() {
        this.alreadyFetched = false;
        await this.fetchDevices();
    }

    async fetchDevices(): Promise<void> {
        if (this.alreadyFetched) {
            return Promise.resolve();
        }

        this.fetchingFailed = false;
        this.fetching = true;
        this.devices = [];
        try {
            const currentLocationId = await this.locationsStore.getCurrentLocationId();

            if (currentLocationId === null) {
                this.devices = [];
                this.alreadyFetched = true;
            } else {
                const url = this.urlProvider.getDevicesUrl(currentLocationId);
                const response = await this.httpService.get<SmartDevicesApiResponse>(url);
                if (response.items) {
                    const devices = await Promise.all(response.items
                        .map(item => this.singleDeviceFactory.fromDeviceApiResponse(item)));
                    this.devices = devices.sort(SmartDevicesStore.sortByClassAndSubclass([...devices]));
                    devices.forEach(device => this.fetchedDevicesSubject.next(device));

                    await this.connectToWebSocket();
                }
                this.alreadyFetched = true;
                this.fetchDevicesState();
            }
        } catch (e) {
            this.fetchingFailed = true;
        }
        this.fetching = false;
    }

    async fetchDevicesState() {
        const currentLocationId = await this.locationsStore.getCurrentLocationId();

        if (currentLocationId !== null) {
            const url = this.urlProvider.getDevicesStateUrl(currentLocationId);
            const devicesStatesList = await this.httpService.get<DevicesStateList | null>(url);

            if (devicesStatesList) {
                for (const sn of Object.keys(devicesStatesList)) {
                    const currentState = devicesStatesList[sn];
                    const currentDevice = this.getDeviceBySN(sn);
                    if (currentDevice !== null) {
                        this.setDeviceState(currentState, currentDevice);
                    }
                }
            }
        }
    }

    setDeviceState(state: DeviceState, device: SingleDevice) {
        if (state.online === false) {
            device.uncontrolled = true;
        }

        if (state.temperature && device.temperature === '-') {
            device.temperature = state.temperature!;
        }

        if (state.targetTemperature && device.targetTemperature === '-') {
            device.targetTemperature = state.targetTemperature!;
        }

        if (state.humidity && device.humidity === '-') {
            device.humidity = state.humidity!;
        }

        if (state.voc && device.voc === '-') {
            device.voc = state.voc!;
        }
        if (state.state && device.airQuality === '-') {
            device.airQuality = state.state!;
        }

        if (state.power && device.power === '-') {
            device.power = state.power!;
        }

        if (state.state && (state.state === 'on' || state.state === 'off') && device.uncontrolled === true) {
            device.switched = state.state === 'on';
            device.uncontrolled = false;
        }

        if (state.state && (state.state === 'opened' || state.state === 'closed') && device.opened === '-') {
            device.opened = state.state === 'opened';
        }

        if (state.state && (state.state === 'fire' || state.state === 'flood') && device.asyncAlarmSet === false) {
            device.alarm = true;
        }
    }

    getDeviceBySN(serialNumber: string): SingleDevice | null {
        const device = this.devices.find(device => device.serialNumber === serialNumber);

        if (device) {
            return device;
        }

        return null;
    }

    getDeviceById(id: string): SingleDevice | null {
        const device = this.devices.find(device => device.id === id);

        if (device) {
            return device;
        }

        return null;
    }

    fetchedDevices(): Observable<SingleDevice> {
        return this.fetchedDevicesSubject;
    }

    private async connectToWebSocket() {
        const url = this.urlProvider.getDevicesWSUrl();

        const webSocket = this.webSocketService.getSocket<SmartDevicesWSEvent>(url, {
            jwt: this.storage.getItem('auth_token'),
            locationId: await this.locationsStore.getCurrentLocationId()
        });

        this.devices.forEach(device => device.subscribeToDeviceEvents(
            webSocket.pipe(filter(({ deviceId }) => deviceId === device.id))
        ));
    }

    private static readonly devicesOrder: WithClass[] = [
        { class: 'smartplug', subclass: null },
        { class: 'smartcable', subclass: null },
        { class: 'smartbulb', subclass: null },
        { class: 'thermostat', subclass: null },
        { class: 'sensor', subclass: 'smoke' },
        { class: 'sensor', subclass: 'window' },
        { class: 'sensor', subclass: 'air_quality' },
        { class: 'sensor', subclass: 'humidity_temperature' }
    ];

    private static sortByClassAndSubclass = (originalArray: SingleDevice[]) => (a: SingleDevice, b: SingleDevice) => {
        const indexA = SmartDevicesStore.devicesOrder.findIndex(classAndSubclassEquals(a));
        const indexB = SmartDevicesStore.devicesOrder.findIndex(classAndSubclassEquals(b));

        if (indexA === indexB) {
            if (originalArray.indexOf(a) < originalArray.indexOf(b)) return -1;
            if (originalArray.indexOf(a) > originalArray.indexOf(b)) return 1;
            return 0;
        }

        if (indexA === -1) {
            return 1;
        }

        if (indexB === -1) {
            return -1;
        }

        if (indexA < indexB) {
            return -1;
        }

        if (indexB < indexA) {
            return 1;
        }

        return 0;
    };
}
