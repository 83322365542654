import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Button } from '../../Button';
import { Input } from '../../Input';
import { WithLabel } from '../../WithLabel';
import { Header } from '../../Text/Header/Header';
import { MagneticMeterInitialValuesForm } from '../../../stores/MagneticMeterInitalValuesForm/MagneticMeterInitialValuesForm';
import { observer, inject } from 'mobx-react';
import { CalibrationParameters } from '../../../domain/CalibrationParameters';
import { Circle } from './Circle';

const Instruction = require('../../../static/EMI_initial_counter_value.svg')

export interface Props {
    magneticMeterInitialValueForm?: MagneticMeterInitialValuesForm;
    onSubmit(result: CalibrationParameters): void;
}

export const MeterInitialValue = inject('magneticMeterInitialValueForm')(observer(
    ({onSubmit, magneticMeterInitialValueForm}: Props) => (
        <FullScreenContainer>
            <Header className="AddMeter-initialValuesHeader">Wpisz początkową wartość licznika</Header>
            <Instruction className="AddMeter-initialValueInstructionIcon" />
            <div className="AddMeter-initialValuesForm">
                <WithLabel label={<Circle>1</Circle>} labelAfter="kWh">
                    <Input 
                        autoFocus 
                        value={magneticMeterInitialValueForm!.calibrationOffsetInput.value} 
                        onChange={magneticMeterInitialValueForm!.calibrationOffsetInput.onChange}
                        error={magneticMeterInitialValueForm!.calibrationOffsetInput.error}
                        label="Bieżące wskazanie licznika" 
                    />
                </WithLabel>
                <WithLabel label={<Circle>2</Circle>} labelAfter="imp/kWh">
                    <Input 
                        value={magneticMeterInitialValueForm!.impulsationInput.value} 
                        onChange={magneticMeterInitialValueForm!.impulsationInput.onChange}
                        error={magneticMeterInitialValueForm!.impulsationInput.error}
                        label="Impulsacja"
                    />
                </WithLabel>
            </div>
            <Button onClick={() => magneticMeterInitialValueForm!.submit(onSubmit)}>Potwierdź</Button>
        </FullScreenContainer>
    )
));
