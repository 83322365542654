import * as React from "react";
import './style.scss';
const Logo = require('mainLogo.svg');

export class TechnicalBreak extends React.Component {
    render() {
        return (
            <div className="TechnicalBreak">
				<Logo className="logo" />
                <div className="header">Przerwa techniczna.</div>
                <p className="content">Przepraszamy za niedogodności związane z chwilową przerwą w funkcjonowaniu naszego serwisu. Dokładamy starań, by wszystko jak najszybciej wróciło do normy.</p>
            </div>
        );
    }
}
