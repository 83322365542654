import firebase from "firebase/app";
import 'firebase/firebase-messaging';
import { APIBaseProvider } from '../../services/APIBaseProvider';
import { UserStore } from '../UserStore/UserStore';
import { HttpService } from '../../services/HttpService';
import { NotificationsStore } from '../NotificationsStore/NotificationStore';

export interface NotificationData {
	body: string;
	title: string;
	notificationId: string;
}

export interface PushNotification {
	collapse_key: string;
	data: NotificationData;
	from: string;
}

export class PushNotificationsStore {
	private messaging: firebase.messaging.Messaging | null = null;
	readonly config = {
		apiKey: "AIzaSyBGRQuDbl183rd2GoiPw9vUXilkQouqmmo",
		authDomain: "lerta-cp-notifier.firebaseapp.com",
		databaseURL: "https://lerta-cp-notifier.firebaseio.com",
		projectId: "lerta-cp-notifier",
		storageBucket: "lerta-cp-notifier.appspot.com",
		messagingSenderId: "212409053547",
		appId: '1:212409053547:web:f337cb3418e001c05d9c2e'
	};

	constructor(
		private apiBaseProvider: APIBaseProvider,
		private userStore: UserStore,
		private httpService: HttpService,
		private redirect: (url: string) => void,
		private notificationsStore: NotificationsStore
	) {
		if (!firebase.apps.length && firebase.messaging.isSupported()) {
			firebase.initializeApp(this.config);
			this.messaging = firebase.messaging();
		}
	}

	getPermission() {
		if (firebase.messaging.isSupported()) {
			Notification.requestPermission()!
				.then(() => {
					return this.messaging?.getToken();
				})
				.then((token) => {
					this.sendTokenToServer(token);
					this.messaging?.onTokenRefresh(this.onTokenRefresh);
					this.messaging?.onMessage((payload: PushNotification) => {
						console.log('Push Notification: ', payload);
						this.notificationsStore.setNotification(
							{
								text:  `${payload.data.title}: \n${payload.data.body}`,
								button: {
									text: `Sprawdź`,
									onClick: () => this.redirect(`/notifications/${payload.data.notificationId}`)
								}
							}
						)
					});
				})
				.catch(function (err) {
					console.error('Unable to get permission to notify.', err);
				});
		}
	}

	private onTokenRefresh = () => {
		let self = this
		this.messaging?.getToken()!
			.then(function (refreshedToken) {
				self.sendTokenToServer(refreshedToken);
			})
			.catch(function (err) {
				console.error('Unable to retrieve refreshed token ', err);
			});
	}

	private sendTokenToServer(token) {
		const url = `${this.apiBaseProvider.getAPIBase()}/users/${this.userStore.userId}/registrationToken`;
		const data = { token };
		return this.httpService.post(url, data);
	}

}
