import { observable, action, computed, autorun, when, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { LocationsApiResponse, LocationItem } from "../../infrastructure/LocationsApiResponse";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { UserStore } from "../UserStore/UserStore";

export class LocationsStore {
    locationsPromise: IPromiseBasedObservable<LocationsApiResponse> | null = null;

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private userStore: UserStore) {
        makeObservable<LocationsStore, "fetchingLocationsFailed">(this, {
            locationsPromise: observable,
            locations: computed,
            retryFetchingLocations: action,
            fetchingLocationsFailed: computed
        });
    }

    init() {
        autorun(() => {
            this.fetchLocations();
        });
    }

    get locations(): LocationItem[] | null {
        if (this.locationsPromise === null || this.locationsPromise.state !== 'fulfilled') {
            return null;
        }

        return this.locationsPromise.value.items || [];
    }

    async retryFetchingLocations() {
        this.fetchLocations();
    }

    async getCurrentLocationId(): Promise<string | null> {
        if (this.fetchingLocationsFailed) {
            this.fetchLocations();
        }

        await when(() => this.locations !== null || this.fetchingLocationsFailed);

        if (this.locations !== null) {
            if (this.locations[0]) {
                return this.locations[0].id;
            } else {
                return null;
            }
        } else {
            throw Error('Could not fetch locations!');
        }
    }

    private fetchLocations() {
        if (this.userStore.userId !== null) {
            this.locationsPromise = fromPromise(this.httpService.get<LocationsApiResponse>(
                this.urlProvider.getLocationsUrl(this.userStore.userId)
            ));
        }
    }

    private get fetchingLocationsFailed(): boolean {
        return this.locationsPromise !== null && this.locationsPromise.state === 'rejected';
    }
}
