import { Theme, makeStyles, Box, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { isBefore, isAfter, differenceInDays, subDays, addDays, addMonths, subMonths, differenceInMonths, isFirstDayOfMonth, isLastDayOfMonth, setDate, getDate, endOfMonth } from 'date-fns';
import { useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        minWidth: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
}))

interface Props {
    small?: boolean;
    interval?: Interval;
    setStartDate: (date: Date) => void;
    setEndDate?: (date: Date) => void;
    maxDate?: Date;
    minDate?: Date;
    disabled?: boolean;
    children?: JSX.Element
}

export const PeriodNavigation = ({ small, interval, setStartDate, setEndDate, minDate, maxDate, disabled, children }: Props) => {
    const classes = useStyles()
    const intl = useIntl();

    const isPrevPeriodDisabled = useCallback(() => {
        if (!interval) {
            return false
        }
        if (isFirstDayOfMonth(interval.start) && isLastDayOfMonth(interval.end)) {
            const monthCount = differenceInMonths(interval.end, interval.start) + 1;
            const startDate = subMonths(interval.start, monthCount);
            if (minDate) {
                return isBefore(startDate, minDate)
            }
        } else {
            const daysCount = differenceInDays(interval.end, interval.start) + 1;
            if (minDate) {
                let prevStartDate = subDays(interval.start, daysCount)
                return isBefore(prevStartDate, minDate)
            }
        }
        return false
    }, [interval, minDate])

    const isNextPeriodDisabled = useCallback(() => {
        if (!interval) {
            return false
        }
        if (isFirstDayOfMonth(interval.start) && isLastDayOfMonth(interval.end)) {
            const monthCount = differenceInMonths(interval.end, interval.start) + 1;
            let endDate = addMonths(interval.end, monthCount);
            endDate = setDate(endDate, getDate(endOfMonth(endDate)));
            if (maxDate) {
                return isAfter(endDate, maxDate)
            }
        } else {
            const daysCount = differenceInDays(interval.end, interval.start) + 1;
            if (maxDate) {
                let nextEndDate = addDays(interval.end, daysCount)
                return isAfter(nextEndDate, maxDate)
            }
        }
        return false
    }, [interval, maxDate])

    const previousPeriod = () => {
        if (!interval) {
            return
        }
        if (isFirstDayOfMonth(interval.start) && isLastDayOfMonth(interval.end)) {
            const monthCount = differenceInMonths(interval.end, interval.start) + 1;
            const startDate = subMonths(interval.start, monthCount);
            let endDate = subMonths(interval.end, monthCount);
            endDate = setDate(endDate, getDate(endOfMonth(endDate)));
            setStartDate(startDate);
            setEndDate?.(endDate);
        } else {
            const daysCount = differenceInDays(interval.end, interval.start) + 1;
            setStartDate(subDays(interval.start, daysCount));
            setEndDate?.(subDays(interval.end, daysCount));
        }
    }

    const nextPeriod = () => {
        if (!interval) {
            return
        }
        if (isFirstDayOfMonth(interval.start) && isLastDayOfMonth(interval.end)) {
            const monthCount = differenceInMonths(interval.end, interval.start) + 1;
            const startDate = addMonths(interval.start, monthCount);
            let endDate = addMonths(interval.end, monthCount);
            endDate = setDate(endDate, getDate(endOfMonth(endDate)));
            setStartDate(startDate);
            setEndDate?.(endDate);
        } else {
            const daysCount = differenceInDays(interval.end, interval.start) + 1;
            setStartDate(addDays(interval.start, daysCount));
            setEndDate?.(addDays(interval.end, daysCount));
        }
    }

    return <Box marginLeft={1} display="flex" alignItems="center">
        <Tooltip title={(small || !isPrevPeriodDisabled()) ? intl.formatMessage({ id: 'superDateRangePicker.periodNavigation.previous' }) : ''}>
            <IconButton disabled={disabled || isPrevPeriodDisabled()} color="secondary" size="small" className={classes.button} onClick={previousPeriod}>
                <ChevronLeftRoundedIcon />
            </IconButton>
        </Tooltip>
        {children}
        <Tooltip title={(small || !isNextPeriodDisabled()) ? intl.formatMessage({ id: 'superDateRangePicker.periodNavigation.next' }) : ''}>
            <IconButton disabled={disabled || isNextPeriodDisabled()} color="secondary" size="small" className={classes.button} onClick={nextPeriod}>
                <ChevronRightRoundedIcon />
            </IconButton>
        </Tooltip>
    </Box>
}