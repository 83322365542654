import { differenceInMilliseconds } from "date-fns";
import { observable, makeObservable } from "mobx";
import { SingleDevice } from "../SingleDevice/SingleDevice";
import { NotificationsStore } from "../NotificationsStore/NotificationStore";
import { filter, timeout, catchError, take } from "rxjs/operators";
import { DevicesStore } from "../DevicesStore/DevicesStore";
import { MqttService } from "../../services/MqttService";
import { throwError } from "rxjs";
import { ConnectionStartDates } from "./ConnectionStartDatesStore";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { MeterAttachmentType } from "../../domain/MeterAttachmentType";

enum TimeoutError {
    TimedOutBeforeApplicationStart = 'TimedOutBeforeApplicationStart',
    TimedOutDuringApplicationLife = 'TimedOutDuringApplicationLife'
}

export class ConnectingDeviceStore {
    static storageKey = 'ConnectingDeviceStoreData'
    postingDeviceConnect: boolean = false;

    constructor(
        private connectingTimeout: number,
        private connectionStartDates: ConnectionStartDates,
        private devicesStore: DevicesStore,
        private notificationsStore: NotificationsStore,
        private mqttService: MqttService,
        private redirect: (url: string) => void,
        private httpService: HttpService,
        private urlProvider: UrlProvider,
    ) {
        makeObservable(this, {
            postingDeviceConnect: observable
        });
    }

    init() {
        this.devicesStore.fetchedDevices()
            .pipe(filter(device => device.connection.exists === false))
            .subscribe(this.handleConnectingDevice);
    }

    async restartConnecting(deviceId: string): Promise<void> {
        const device = this.devicesStore.getDeviceById(deviceId);
        if (device) {
            if ((device.class === 'meter' && device.subclass === MeterAttachmentType.EMI)
                || device.class !== 'meter') {
                this.postingDeviceConnect = true;
                await this.httpService.post(this.urlProvider.getDeviceConnectUrl(device.id));
                this.postingDeviceConnect = false;
            }
            this.connectionStartDates.setStartDateFor(device.id, new Date());
            device.connection.startConnecting();
            this.handleConnectingDevice(device);
        }
    }

    startConnecting(device: SingleDevice): Promise<void> {
        const connectionStartDate = this.getConnectionStartDate(device);

        const difference = differenceInMilliseconds(Date.now(), connectionStartDate);

        if (difference > this.connectingTimeout) {
            return Promise.reject(new Error(TimeoutError.TimedOutBeforeApplicationStart));
        } else {
            const timeLeft = this.connectingTimeout - difference;

            return this.mqttService.onMessages(`v1/devices/${device.id}`)
                .pipe(
                    timeout(timeLeft),
                    catchError(() => throwError(new Error(TimeoutError.TimedOutDuringApplicationLife))),
                    take(1)
                ).toPromise();
        }
    }

    private getConnectionStartDate(device: SingleDevice): Date {
        const date = this.connectionStartDates.getStartDateFor(device.id);

        if (date === null) {
            const creationTime = device.creationTime || new Date();
            this.connectionStartDates.setStartDateFor(device.id, creationTime);
            return creationTime;
        }

        return date;
    }

    private handleConnectingDevice = async (device: SingleDevice): Promise<void> => {
        this.mqttService.onMessages(`v1/devices/${device.id}`)
            .pipe(
                take(1)
            )
            .subscribe(() => device.connection.succeedConnecting());

        try {
            await this.startConnecting(device);
        } catch (e) {
            device.connection.failConnecting();

            if (e.message === TimeoutError.TimedOutDuringApplicationLife) {
                this.notificationsStore.setNotification({
                    text: `Nie udało się połączyć z urządzeniem ${device.name || ''}`,
                    button: {
                        text: `Sprawdź`,
                        onClick: () => {
                            this.redirect(`/devices/${device.id}`);
                        }
                    }
                })
            }
        }
    }
}