import * as React from "react";
import { observer } from "mobx-react";
import { Network } from "../../../stores/GatewayWifiConfigStore/GatewayWifiConfigStore";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { FullScreenContainer } from "../../FullScreenContainer/FullScreenContainer";
import { Paragraph } from "../../Text/Paragraph/Paragraph";
import { Header } from "../../Text/Header/Header";
import { Loader } from "../../Loader/Loader";

export interface SingleNetworkProps {
	network: Network;
	onNetworkSelected(): void;
	onChangeNetwork(): void;
	onConnectiongError(): void;
}

export const SingleNetwork = observer(class SingleNetwork extends React.Component<SingleNetworkProps> {
	handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.props.network.setAsUsed(this.props.onNetworkSelected, this.props.onConnectiongError);
	};

	render() {
		if (this.props.network.settingAsUsed) {
			return <SingleNetworkLoading networkName={this.props.network.ssid} />;
		}

		return (
			<FullScreenContainer gradient>
			<form onSubmit={this.handleSubmit} className="SingleNetwork-container">
				<div>
					<Paragraph>
						Podaj hasło do sieci Wifi
					</Paragraph>
					<Header>{this.props.network.ssid}</Header>
				</div>
				<div>
					<Input
						type="password"
						label="Hasło"
						autoFocus
						value={this.props.network.password.value}
						error={this.props.network.password.error}
						onChange={value => this.props.network.password.value = value}
						/>
					{this.props.network.wrongPassword && <p className="SingleNetwork-error">Podane hasło nie jest poprawne.</p>}
				</div>
				<div>
					<Button loading={this.props.network.checkingPassword}>Potwierdź</Button>
					<Button small inline secondary onClick={this.props.onChangeNetwork}>Wybierz inną sieć</Button>
				</div>
			</form>
			</FullScreenContainer>
		);
	}
});

export interface SingleNetworkLoadingProps {
	networkName: string;
}

export const SingleNetworkLoading = ({networkName}: SingleNetworkLoadingProps) => (
	<FullScreenContainer gradient>
		<div>
			<Paragraph>
				Oczekiwanie na połączenie centralki z siecią Wifi
			</Paragraph>
			<Header>{networkName}</Header>
			<Loader secondary/>
		</div>
	</FullScreenContainer>
);
