import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { UserStore } from "../UserStore/UserStore";
export class MeterConfigStore {
    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private userStore: UserStore) { }

    async registerMeter(code: string) {
        if (this.userStore.userId) {
            const url = this.urlProvider.getRegisterMeterUrl(this.userStore.userId);
            return this.httpService.post(url, { code });
        }

        throw new Error('No userId in userStore. Can not make request to register energy meter');
    }
}
