import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { Input } from '../Input';
import { Header } from '../Text/Header/Header';
import { observer } from 'mobx-react';
import { FieldState, FormState } from 'formstate';
import { notEmpty } from '../../stores/Form/validators';


interface Props {
    onSubmit(name: string): void;
}

export const MeterName = observer(class MeterName extends React.Component<Props> {

    form = new FormState({
        name: new FieldState('').validators(notEmpty('Nazwa licznika nie może być pusta')),
    })

    render() {
        return < FullScreenContainer >
            <Header>Podaj nazwę licznika</Header>
            <div className="AddMeter-initialValuesForm">
                <Input
                    autoFocus
                    label="Nazwa licznika"
                    value={this.form.$.name.value}
                    onChange={this.form.$.name.onChange}
                    error={this.form.$.name.error}
                />
            </div>
            <Button onClick={this.handleClick}>Potwierdź</Button>
        </FullScreenContainer >
    }

    private handleClick = async () => {
        const { hasError } = await this.form.validate();

        if (!hasError) {
            this.props.onSubmit(this.form.$.name.$)
        }
    }
});
