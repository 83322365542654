import * as React from 'react';
import './style.scss';
import { CircularProgress } from "@material-ui/core";
import { ApplianceBalloon } from '../Balloon/ApplianceBalloon';
import { AnalysisData } from '../../stores/DeviceData/AnalysisData';
import { observer } from 'mobx-react';

export interface DeviceDataAnalysisProps {
    analysisData: AnalysisData;
}

export const DeviceDataAnalysis = observer(
    class DeviceDataAnalysis extends React.Component<DeviceDataAnalysisProps, any> {

        render() {
            const { analysisData } = this.props;

            return (
                <div className='DeviceData-balloons'>
                    {analysisData.fetchingConsumptionAndStats &&
                        <CircularProgress className="DeviceData-loader" size={50} />}
                    {!analysisData.fetchingConsumptionAndStats && !analysisData.disaggregationComponents &&
                        <div className="DeviceData-noData">Brak danych</div>}
                    {analysisData.disaggregationComponents && analysisData.disaggregationComponents.map((item, i) =>
                        <ApplianceBalloon
                            key={i}
                            applianceName={item.name.toLowerCase()}
                            appliancePercentage={item.value}
                            minBalloonSize={6}
                            maxPercentage={100}
                            maxBalloonSize={13} />
                    )}
                </div>
            );
        }
    }
);
