import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { ConnectOtherDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';

export class SmartbulbDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);

        this.switched = false;

        this.dictionary = {
            addDeviceFormName: "żarówka",
            suggestedDeviceName: "Żarówka ...",
            connectDevicePrompt: ConnectOtherDevicePrompt,
            reconnectDevicePrompt: () => null,
            reconnectDeviceSecondPrompt: () => null,
            instructions: {
                instruction: () => null,
                reconnect: () => null,
                factoryReset: () => null,
            },
            defaultName: "Żarówka bez nazwy",
            deviceLogo: 'inteligentna_zarowka.svg',
        };
    }
}
