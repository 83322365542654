import { observable, computed, action, makeObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

export type LocaleType = 'pl'
class LocaleStore {
    private STORAGE_KEY = 'locale'

    availableLocales = ['pl']
    locale: LocaleType = this.getDefaultLocale();
    messages?: Record<string, string>;

    constructor() {
        makeObservable(this, {
            locale: observable,
            messages: observable,
            messagesPromise: computed,
            isReady: computed,
            changeLocale: action.bound,
            changeLocaleButtonLabel: computed
        });
    }

    get messagesPromise(): IPromiseBasedObservable<object> | null {
        return fromPromise(fetch(
            `/locale/${this.locale}.json`,
        ).then(async res => {
            this.messages = await res.json()
            return res
        }));
    }

    get isReady() {
        return this.messagesPromise !== null && this.messagesPromise.state === 'fulfilled';
    }

    changeLocale(l: LocaleType) {
        this.locale = l
        localStorage.setItem(this.STORAGE_KEY, this.locale)

    }

    get changeLocaleButtonLabel() {
        return this.availableLocales[+!this.availableLocales.indexOf(this.locale)].toUpperCase()
    }

    getDefaultLocale(): LocaleType {

        const storageLocale = localStorage.getItem(this.STORAGE_KEY)
        if (storageLocale && this.availableLocales.indexOf(storageLocale) >= 0) {
            return storageLocale as LocaleType
        }

        const browserLocale = navigator.language.slice(0, 2)
        if (this.availableLocales.indexOf(browserLocale) >= 0) {
            return browserLocale as LocaleType
        }

        return 'pl'
    }
}

export default LocaleStore;