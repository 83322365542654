import { Box, makeStyles, Typography, Theme } from "@material-ui/core"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { format, setHours, setMinutes } from "date-fns"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

interface Props {
    date: Date | null;
    onTimeChange?: (Date: Date) => void;
    defaultTime: string;
    oneDayView?: boolean;
    minTime?: string;
    maxTime?: string;
    fullHours?: boolean;
    labelId: string;
    errorLabelId?: string;
    setIsInvalidTime: (status: boolean) => void
}

const useStyle = makeStyles((theme: Theme) => {
    return {
        picker: {
            width: 110
        },
        text: {
            paddingRight: theme.spacing(2),
            color: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
            fontSize: '1rem'
        },
        error: {
            right: 16,
            position: 'absolute',
            bottom: -theme.spacing(3)
        },
        keyboardButton: {
            display: 'none'
        }
    }
})

export const TimePicker = ({ date, onTimeChange, defaultTime, oneDayView, minTime, maxTime, fullHours, labelId, errorLabelId, setIsInvalidTime }: Props) => {
    const intl = useIntl()
    const classes = useStyle()

    const [isError, setIsError] = useState(false)

    useEffect(() => {
        return () => setIsInvalidTime(false)
    }, [setIsInvalidTime])


    const isValid = (val: string) => {
        const regexp = /^\d{0,2}?:?\d{0,2}$/;

        const [hoursStr, minutesStr] = val.split(':');

        if (!regexp.test(val)) {
            return false;
        }

        const hours = Number(hoursStr);
        const minutes = Number(minutesStr);

        const isValidHour = (hour: number) => Number.isInteger(hour) && hour >= 0 && hour < 24;
        const isValidMinutes = (minutes: number) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);


        if (!isValidHour(hours) || !isValidMinutes(minutes)) {
            return false;
        }

        if (Number(hoursStr[0]) > 2) {
            return false
        }

        if (minTime && hoursStr.length === 2 && hours < +minTime?.split(':')[0]) {
            return false
        }

        if (maxTime && hoursStr.length === 2 && hours > +maxTime?.split(':')[0]) {
            return false
        }

        if (minTime && minutesStr && minutesStr.length === 2 && minutes < +minTime?.split(':')[1]) {
            return false
        }

        if (maxTime && minutesStr && minutesStr.length === 2 && minutes > +maxTime?.split(':')[1]) {
            return false
        }

        if (fullHours && minutes > 0) {
            return false
        }

        if (minutes < 10 && Number(minutesStr[0]) > 5) {
            return false;
        }

        const valArr = val.indexOf(':') !== -1
            ? val.split(':')
            : [val];

        // check mm and HH
        if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
            return false;
        }

        if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
            return false;
        }

        return true;
    }



    const onChangeValue = (val: string) => {

        if (isValid(val)) {
            if (val.length === 5) {
                setIsError(false)
                setIsInvalidTime(false)
                if (date && format(date, 'HH:mm') === val) {
                    return
                }
                const [hours, minutes] = val.split(':')
                if (date) {
                    let newDate = setHours(date, +hours)
                    newDate = setMinutes(newDate, +minutes)
                    onTimeChange?.(newDate)
                }
            }
        } else {
            setIsError(true)
            setIsInvalidTime(true)
        }
    }

    const getDefaultDate = () => {
        return setMinutes(setHours(new Date(), +defaultTime.split(":")[0]), +defaultTime.split(":")[1])
    }

    return (
        <Box position="relative" flex={1} marginTop={1}>
            <Box display="flex" alignItems="flex-end" justifyContent="space-evenly" >
                {!oneDayView && <Typography gutterBottom className={classes.text}>
                    {date && `${format(date, 'dd')}  ${intl.formatMessage({ id: `month.${format(date, 'MMM').toUpperCase()}.short` })} ${format(date, 'yyyy')}`}
                </Typography>}
                <KeyboardTimePicker
                    label={intl.formatMessage({ id: labelId })}
                    value={date || getDefaultDate()}
                    onChange={(date: Date, value) => {
                        if (!!date?.getDate() && value) {
                            onChangeValue(value)
                        } else {
                            setIsError(true)
                            setIsInvalidTime(true)
                        }
                    }}
                    variant="inline"
                    ampm={false}
                    invalidDateMessage=""
                    disabled={!date}
                    error={isError}
                    placeholder={defaultTime}
                    className={classes.picker}
                    KeyboardButtonProps={{ className: classes.keyboardButton }}
                />
            </Box>
            {isError && <Typography className={classes.error} color="error" variant="caption" > {intl.formatMessage({ id: errorLabelId || "superDateRangePicker.hour.error" })}</Typography>}
        </Box>
    )
}
