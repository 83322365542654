import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Button } from '../Button';

const Gateway = require('../../static/icons/central_device_and_icons_2.svg');
const Power = require('../../static/icons/power_icon.svg');
const Circle = require('../../static/icons/dot_icon.svg');
const Wifi = require('../../static/icons/wifi_icon.svg');
const Important = require('../../static/icons/important_sign_icon.svg');

export interface Props {
    onClick(): void;
}

export const CableConnection = ({ onClick }: Props) => (
    <FullScreenContainer gradient>
        <Paragraph>
            Poczekaj aż diody <div className="GatewayWizard-inlineIconGreen"><Power /></div> oraz <div className="GatewayWizard-inlineIconGreen"><Circle /></div>
            będą świecić zielonym <strong>światłem ciągłym</strong>.
            Diody <div className="GatewayWizard-inlineIcon"><Wifi /></div> i <div className="GatewayWizard-inlineIcon"><Important /></div> powinny być zgaszone.
        </Paragraph>
        <Gateway className="GatewayWizard-infographic" />
        <Paragraph>Oczekiwanie na zaświecenie się tych diod może potrwać do 3 minut.</Paragraph>
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
)
