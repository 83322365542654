import * as React from 'react';
import { Paragraph } from '../../Text/Paragraph/Paragraph';
import { Header } from '../../Text/Header/Header';
import './styles.scss';
import { Button } from '../../Button';

export interface ReconnectDeviceSecondPromptProps {
    deviceName: string | null;
    onInstructionClick(): void;
}

export const ReconnectSmartplugSecondTimePrompt = ({deviceName, onInstructionClick}: ReconnectDeviceSecondPromptProps) => (
    <div className="ReconnectedDeviceSecondPrompt-container">
        <Paragraph>Nie udało się połączyć z wtyczką{deviceName ? '' : '.'}</Paragraph>
        <Paragraph className="ReconnectedDeviceSecondPrompt-deviceName">{deviceName ? ` ${deviceName}` : ''}.</Paragraph>
        <Header className="ReconnectedDeviceSecondPrompt-header" bold>Przywróć ustawienia fabryczne tej wtyczki.</Header>
        <Button className="ReconnectedDeviceSecondPrompt-button" small inline secondary onClick={onInstructionClick}>Jak to zrobić?</Button>
        <Paragraph>Proces łączenia z wtyczką zostanie ponownie uruchomiony. Może potrwać do 15 minut.</Paragraph>
    </div>
);

export const ReconnectBatteryDeviceSecondTimePrompt = ({deviceName, onInstructionClick}: ReconnectDeviceSecondPromptProps) => (
    <div className="ReconnectedDeviceSecondPrompt-container">
        <Paragraph>Nie udało się połączyć z urządzeniem{deviceName ? '' : '.'}</Paragraph>
        <Paragraph className="ReconnectedDeviceSecondPrompt-deviceName">{deviceName ? ` ${deviceName}` : ''}.</Paragraph>
        <Header className="ReconnectedDeviceSecondPrompt-header" bold>Przywróć ustawienia fabryczne tego urządzenia.</Header>
        <Button className="ReconnectedDeviceSecondPrompt-button" small inline secondary onClick={onInstructionClick}>Jak to zrobić?</Button>
        <Paragraph>Proces łączenia z urządzeniem zostanie ponownie uruchomiony. Może potrwać do 15 minut.</Paragraph>
    </div>
);
