import * as React from 'react';
import './styles.scss';

export interface DotWaveProps {
    big?: boolean;
}

export const DotWave = ({big}: DotWaveProps) => (
    <span className={`DotWave-container ${big ? "DotWave-big" : ""}`}>
        <span className="DotWave-dot"></span>
        <span className="DotWave-dot"></span>
        <span className="DotWave-dot"></span>
    </span>
);
