import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { ConnectOtherDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { DateMode } from '../../domain/DateMode';
import { MeterEnergyUsageData } from '../DeviceData/MeterEnergyUsageData';
import { ReconnectEMIMeterPrompt, ReconnectEMIMeterSecondTimePrompt } from '../../components/ReconnectMeter/ReconnectEMIMeterPrompt';
import { ReconnectEMIMeterInstruction, FactoryResetEMIMeterInstruction } from '../../components/Instructions/ReconnectInstructions';
import { Instruction } from '../../components/AddMeter/AddEMI/Instruction'
import { AnalysisData } from '../DeviceData/AnalysisData';

export class EMIMeterDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage, displayAnalysis?: boolean) {
        super(urlProvider, httpService, storage);

        this.dateMode = DateMode.Monthly;

        this.energyUsageData = new MeterEnergyUsageData(httpService, urlProvider, this);
        if (displayAnalysis) {
            this.analysisData = new AnalysisData(httpService, urlProvider, this);
        }

        this.dictionary = {
            addDeviceFormName: "",
            suggestedDeviceName: "",
            connectDevicePrompt: ConnectOtherDevicePrompt,
            reconnectDevicePrompt: ReconnectEMIMeterPrompt,
            reconnectDeviceSecondPrompt: ReconnectEMIMeterSecondTimePrompt,
            instructions: {
                instruction: Instruction,
                reconnect: ReconnectEMIMeterInstruction,
                factoryReset: FactoryResetEMIMeterInstruction,
            },
            defaultName: 'Licznik energii elektrycznej',
            deviceLogo: 'electricity_meter_icon.svg',
        };
    }
}
