import './styles.scss';

import { inject, observer } from 'mobx-react';
import * as React from 'react';

import {
    EnterDeviceManuallyResult,
    EnterDeviceManuallyStore,
} from '../../../stores/EnterDeviceManuallyStore/EnterDeviceManuallyStore';
import { Button } from '../../Button';
import { Input } from '../../Input';
import { DeviceTypeStore } from '../../../stores/DeviceTypeStore/DeviceTypeStore';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { WithLabel } from '../../WithLabel';


const Image = require('../../../static/pudelko_sn.svg');

interface EnterDeviceManuallyProps {
    onSuccess(enteredCode: EnterDeviceManuallyResult): void;
    enterDeviceManuallyStore?: EnterDeviceManuallyStore;
    deviceTypeStore?: DeviceTypeStore;
}

export const EnterDeviceManually = inject('enterDeviceManuallyStore')(observer(
    class EnterDeviceManually extends React.Component<EnterDeviceManuallyProps> {

        render() {
            const { onSuccess, enterDeviceManuallyStore } = this.props;

            return (
                <FullScreenContainer>
                    <Image />
                    <form className="EnterDeviceManually-form" onSubmit={(e) => {
                        e.preventDefault();
                        enterDeviceManuallyStore!.save(onSuccess);
                    }} >
                        <div>
                            <WithLabel label="P/N">
                                <Input
                                    value={enterDeviceManuallyStore!.form.$.productNumber.value}
                                    error={enterDeviceManuallyStore!.form.$.productNumber.error}
                                    label="Wpisz numer produktu"
                                    onChange={enterDeviceManuallyStore!.form.$.productNumber.onChange}
                                />
                            </WithLabel>
                            <WithLabel label="S/N">
                                <Input
                                    value={enterDeviceManuallyStore!.form.$.serialNumber.value}
                                    error={enterDeviceManuallyStore!.form.$.serialNumber.error}
                                    label="Wpisz numer seryjny"
                                    onChange={enterDeviceManuallyStore!.form.$.serialNumber.onChange}
                                />
                            </WithLabel>
                        </div>
                        <Button>Potwierdź</Button>
                    </form>
                </FullScreenContainer>
            );
        }
    }
));