import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { InstructionsList } from './InstructionsList';

export interface Props {
    onClose(): void;
}

const SmokeSensorReconnectInstruction1 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_dymu_ryc1.svg');
const SmokeSensorReconnectInstruction2 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_dymu_ryc2.svg');
const SmokeSensorReconnectInstruction3 = require('../../static/instructions/parowanie_montaz_czujnik_dymu_ryc4.svg');

export const ReconnectSmokeSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterię - czujnik dymu</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Odłącz czujnik dymu od podstawy montażowej, obracając go w kierunku przeciwnym do ruchu wskazówek zegara.</p>
                    <SmokeSensorReconnectInstruction3 />
                </>,
                <>
                    <p>Wyjmij i włóż ponownie baterię, zwracając uwagę na umiejscowienie jej biegunów.</p>
                    <SmokeSensorReconnectInstruction1 />
                </>,
                <>
                    <p>Załóż czujnik na podstawie montażowej, przekręcając go zgodnie z kierunkiem ruchu wskazówek zegara aż usłyszysz kliknięcie.</p>
                    <SmokeSensorReconnectInstruction2 />
                </>,
            ]}
        />
    </FullScreenContainer>
);

const HumidityAndVocSensorReconnectInstruction1 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_wilgotnosci_ryc3.svg');
const HumidityAndVocSensorReconnectInstruction2 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_wilgotnosci_ryc2.svg');
const HumidityAndVocSensorReconnectInstruction3 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_wilgotnosci_ryc1.svg');

export const ReconnectHumiditySensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterię - czujnik wilgotności</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę czujnika wilgotności.</p>
                    <HumidityAndVocSensorReconnectInstruction1 />
                </>,
                <>
                    <p>Wyjmij baterie.</p>
                    <HumidityAndVocSensorReconnectInstruction2 />
                </>,
                <>
                    <p>Włóż baterie ponownie, zwracając uwagę na poprawne umiejscowienie ich biegunów.</p>
                    <HumidityAndVocSensorReconnectInstruction3 />
                </>,
                <p>Zamknij obudowę czujnika.</p>
            ]}
        />
    </FullScreenContainer>
);

export const ReconnectVocSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterię - czujnik jakości powietrza</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę czujnika jakości powietrza.</p>
                    <HumidityAndVocSensorReconnectInstruction1 />
                </>,
                <>
                    <p>Wyjmij baterie.</p>
                    <HumidityAndVocSensorReconnectInstruction2 />
                </>,
                <>
                    <p>Włóż baterie ponownie, zwracając uwagę na poprawne umiejscowienie ich biegunów.</p>
                    <HumidityAndVocSensorReconnectInstruction3 />
                </>,
                <p>Zamknij obudowę czujnika.</p>
            ]}
        />
    </FullScreenContainer>
);

const WindowSensorReconnectInstruction1 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_drzwi_i_okien_ryc1.svg');

export const ReconnectWindowSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterię - czujnik drzwi i okien</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę urządzenia przyciskiem znajdującym się w jej górnej części. Zdejmij przedni panel.</p>
                    <WindowSensorReconnectInstruction1 />
                </>,
                <p>Wyjmij baterie, po czym umieść je ponownie w urządzeniu pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>,
                <p>Zamknij obudowę.</p>,
            ]}
        />
    </FullScreenContainer>
);

const EMIMeterReconnectInstruction1 = require('../../static/instructions/parowanie_pierwsze_kroki_emi_ryc1.svg');
const EMIMeterReconnectInstruction2 = require('../../static/instructions/parowanie_pierwsze_kroki_emi_ryc2.svg');

export const ReconnectEMIMeterInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterie miernika EMI</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę miernika EMI.</p>
                    <EMIMeterReconnectInstruction1 />
                </>,
                <p>Wyjmij baterie.</p>,
                <>
                    <p>Włóż baterie ponownie, zwracając uwagę na poprawne umiejscowienie ich biegunów.</p>
                    <EMIMeterReconnectInstruction2 />
                </>,
                <p>Zamknij obudowę miernika</p>
            ]}
        />
    </FullScreenContainer>
);

const FloodSensorReconnectInstruction1 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc1.svg');
const FloodSensorReconnectInstruction2 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc2.svg');

export const ReconnectFloodSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Wyjmij i włóż baterię - czujnik zalania</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Odłącz od czujnika pięcioramienną nakrętkę przekręcając ją w kierunku przeciwnym do ruchu wskazówek zegara.</p>
                    <FloodSensorReconnectInstruction1 />
                </>,
                <>
                    <p>Zdejmij plastikowy dysk z czujnika tak, by mieć dostęp do miejsca na baterię.</p>
                    <FloodSensorReconnectInstruction2 />
                </>,
                <p>Wyjmij baterię z czujnika i włóż ją ponownie pamiętając o prawidłowym umiejscowieniu biegunów baterii.</p>,
                <p>Połóż z powrotem plastikowy dysk na czujnik.</p>,
                <p>Przymocuj do czujnika pięcioramienną nakrętkę przekręcając ją zgodnie z ruchem wskazówek zegara.</p>
            ]}
        />
    </FullScreenContainer>
);

const SmartplugFactoryResetInstruction1 = require('../../static/instructions/parowanie_umiejscowienie_urzadzenia_wtyczka_ryc2_2.svg');
const SmartplugFactoryResetInstruction2 = require('../../static/instructions/parowanie_tryby_urzadzenia_wtyczka_ryc3.svg');

export const FactoryResetSmartplugInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przywrócenie ustawień fabrycznych wtyczki</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Podłącz wtyczkę do gniazda zasilającego</p>
                    <SmartplugFactoryResetInstruction2 />
                </>,
                <>
                    <p>
                        Jeżeli dioda LED na wtyczce świeci zielonym światłem, wówczas naciśnij raz przycisk na wtyczce, aby wyłączyć wtyczkę.
                        Zielone światło powinno zgasnąć.
                    </p>
                    <SmartplugFactoryResetInstruction1 />
                </>,
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk na wtyczce.</p>
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco:</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem</li>
                        <li>potem mignie 2 razy czerwonym światłem</li>
                        <li>następnie zacznie migać w sposób ciągły czerwonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

const SmokeSensorFactoryResetInstruction1 = require('../../static/instructions/parowanie_ustawienia_fabryczne_czujnik_dymu_ryc1.svg');
const SmokeSensorFactoryResetInstruction2 = require('../../static/instructions/parowanie_montaz_czujnik_dymu_ryc4.svg');

export const FactoryResetSmokeSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przwrócenie ustawień fabrycznych czujnika dymu</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Zdejmij czujnik dymu z podstawy mocującej.</p>
                    <SmokeSensorFactoryResetInstruction2 />
                </>,
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk na czujniku znajdujący się w pobliżu baterii.</p>
                    <SmokeSensorFactoryResetInstruction1 />
                </>,
                <>
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco:</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem</li>
                        <li>potem mignie 2 razy czerwonym światłem</li>
                        <li>następnie zacznie migać w sposób ciągły czerwonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

const WindowSensorFactoryResetInstruction1 = require('../../static/instructions/parowanie_ustawienia_fabryczne_czujnik_drzwi_i_okien_ryc2.svg');

export const FactoryResetWindowSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przywrócenie ustawień fabrycznych czujnika drzwi i okien</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk znajdujący się na przedniej obudowie czujnika.</p>
                    <WindowSensorFactoryResetInstruction1 />
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco:</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem</li>
                        <li>potem mignie 2 razy czerwonym światłem</li>
                        <li>następnie zacznie migać w sposób ciągły czerwonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

const HumidityAndVocSensorFactoryResetInstruction1 = require('../../static/instructions/parowanie_wloz_wyjmij_baterie_czujnik_wilgotnosci_ryc3.svg');
const HumidityAndVocSensorFactoryResetInstruction2 = require('../../static/instructions/parowanie_ustawienia_fabryczne_czujnik_wilgotnosci_ryc2.svg');

export const FactoryResetHumiditySensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przywrócenie ustawień fabrycznych czujnika wilgotności</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę czujnika wilgotności.</p>
                    <HumidityAndVocSensorFactoryResetInstruction1 />
                </>,
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk.</p>
                    <HumidityAndVocSensorFactoryResetInstruction2 />
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco::</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem,</li>
                        <li>potem mignie 2 razy czerwonym światłem,</li>
                        <li>następnie zacznie migać w sposób ciągły czerwonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

export const FactoryResetVocSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przywrócenie ustawień fabrycznych czujnika jakości powietrza</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę czujnika jakości powietrza.</p>
                    <HumidityAndVocSensorFactoryResetInstruction1 />
                </>,
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk.</p>
                    <HumidityAndVocSensorFactoryResetInstruction2 />
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco::</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem,</li>
                        <li>potem mignie 2 razy czerwonym światłem,</li>
                        <li>następnie zacznie migać w sposób ciągły czerwonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

const EMIMeterFactoryResetInstruction1 = require('../../static/instructions/parowanie_pierwsze_kroki_emi_ryc1.svg');
const EMIMeterFactoryResetInstruction2 = require('../../static/instructions/parowanie_resetowanie_urzadzenia_emi_ryc2.svg');
const EMIMeterFactoryResetInstruction3 = require('../../static/instructions/parowanie_resetowanie_urzadzenia_emi_ryc3.svg');

export const FactoryResetEMIMeterInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Reset fabryczny miernika EMI</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Otwórz obudowę miernika EMI.</p>
                    <EMIMeterFactoryResetInstruction1 />
                </>,
                <>
                    <p>Wciśnij i <strong>przytrzymaj</strong> przycisk.</p>
                    <EMIMeterFactoryResetInstruction2 />
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco:</p>
                    <ul>
                        <li>najpierw mignie 1 raz zielonym światłem,</li>
                        <li>następnie zacznie migać w sposób ciągły zielonym światłem.
                            W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).
                        </li>
                    </ul>
                    <EMIMeterFactoryResetInstruction3 />
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem zielonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);

const FloodSensorFactoryResetInstruction1 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc1.svg');
const FloodSensorFactoryResetInstruction2 = require('../../static/instructions/parowanie_montaz_czujnik_zalania_ryc2.svg');
const FloodSensorFactoryResetInstruction3 = require('../../static/instructions/parowanie_ustawienia_fabryczne_czujnik_zalania_ryc1.svg')

export const FactoryResetFloodSensorInstruction = ({ onClose }: Props) => (
    <FullScreenContainer onBackButton={onClose}>
        <Header bold>Przywrócenie ustawień fabrycznych czujnika zalania</Header>
        <InstructionsList
            instructions={[
                <>
                    <p>Odłącz od czujnika pięcioramienną nakrętkę przekręcając ją w kierunku przeciwnym do ruchu wskazówek zegara.</p>
                    <FloodSensorFactoryResetInstruction1 />
                </>,
                <>
                    <p>Zdejmij plastikowy dysk z czujnika tak, by mieć dostęp do miejsca na baterię.</p>
                    <FloodSensorFactoryResetInstruction2 />
                </>,
                <>
                    <p>Na czujniku są dwa przyciski:</p>
                    <ul>
                        <li>bardziej wystający, bliżej baterii - służy do testowania alarmu</li>
                        <li>mniej wystający, dalej od baterii - <strong>służy do przywracania ustawień fabrycznych</strong></li>
                    </ul>
                    <p>Mocno (np. paznokciem) wciśnij i <strong>przytrzymaj</strong> mniej wystający przycisk na czujniku.</p>
                    <p>Gdy będziesz przytrzymywać przycisk, dioda LED zachowa się następująco:</p>
                    <ul>
                        <li>najpierw mignie 1 raz czerwonym światłem</li>
                        <li>potem mignie 2 razy czerwonym światłem</li>
                    </ul>
                    <p>następnie zacznie migać w sposób ciągły czerwonym światłem. W tym momencie <strong>puść przycisk</strong> (należy puścić przycisk zanim dioda przestanie migać).</p>
                    <FloodSensorFactoryResetInstruction3 />
                </>,
                <p>Po prawidłowym przywróceniu ustawień fabrycznych dioda powinna migać światłem czerwonym co około 1 sekundę.</p>
            ]}
        />
    </FullScreenContainer>
);
