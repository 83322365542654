import { observable, action } from 'mobx';

class CalendarStore {
	@observable calendarOpen = false;
	@action setCalendarOpen(bool) {
		this.calendarOpen = bool;
	}

}

const calendarStore = (window as any as {calendarStore: CalendarStore}).calendarStore = new CalendarStore();
export default calendarStore;