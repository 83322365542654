import { observable, computed, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { Input } from "../Form/Input";
import { Form } from "../Form/Form";
import { notEmpty } from "../Form/validators";
import { UserStore } from "./UserStore";

export class ForgotPassword {
    mail: Input = new Input(notEmpty('Adres e-mail nie może być pusty.'));
    private form: Form = new Form([this.mail]);

    reseting: boolean = false;
    requestFailed: boolean = false;

    get formErrors(): boolean {
        return this.form.hasErrors;
    }


    constructor(private httpService: HttpService, private urlProvider: UrlProvider, protected userStore: UserStore) {
        makeObservable(this, {
            mail: observable,
            reseting: observable,
            requestFailed: observable,
            formErrors: computed
        });
    }

    async submit(onSuccess: () => void) {
        return this.form.submit(async () => {
            this.reseting = true;
            const url = this.urlProvider.getResetPasswordUrl();

            try {
                await this.httpService.makeRequest(url, 'POST', {
                    email: this.mail.value
                });

                onSuccess()
                this.reseting = false;
            } catch (e) {
                this.requestFailed = true;
                this.reseting = false;
            }
        });
    }
}
