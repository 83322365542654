import * as React from 'react';
import './style.scss';
import { DeviceData as DeviceDataStore } from '../../stores/DeviceData/DeviceData';
import { observer } from 'mobx-react';
import { format as dateFnsFormat } from 'date-fns';
import { DateMode } from '../../domain/DateMode';
import { Link } from 'react-router-dom';
import { createDeviceDetailsUrl } from '../../utils/urls';
import { DeviceDataAnalysis } from '../DeviceDataAnalysis';
import { AnalysisData } from '../../stores/DeviceData/AnalysisData';
import { DeviceDataChart } from '../DeviceDataChart';
import { SingleDevice } from '../../stores/SingleDevice/SingleDevice';

import ArrowBack from '../../static/icons/arrow_back.svg'

export interface DeviceDataProps {
    device: SingleDevice;
    deviceData: DeviceDataStore;
    route: string;
    format?(date: Date, form: string): string;
    showCalendar(enable: boolean): void;
}

export const DeviceData = observer(class DeviceData extends React.Component<DeviceDataProps, any> {
    componentDidMount() {
        this.props.deviceData.setAsActive();
        let isAnalysisData;
        isAnalysisData = this.props.deviceData instanceof AnalysisData;
        this.props.showCalendar(!isAnalysisData);
    }

    render() {
        const { route } = this.props;
        let deviceData, isAnalysisData;
        isAnalysisData = this.props.deviceData instanceof AnalysisData;

        deviceData = this.props.deviceData
        const device = this.props.device

        if (isAnalysisData) {
            deviceData = this.props.deviceData as AnalysisData;
        }

        const { DataHighlights, ActionsComponent } = deviceData.display;
        const format = this.props.format || dateFnsFormat;

        return (
            <div className="DeviceData-container">
                <div className="DeviceData-chartContainer">
                    <div className="DeviceData-header">{deviceData.display.displayName}</div>
                    <div className="DeviceData-date">
                        <Link to={`${route}${createDeviceDetailsUrl(deviceData.dateMode, deviceData.display.routeName, deviceData.previousDate)}`} className="DeviceData-dateArrow"><ArrowBack /></Link>
                        {deviceData.dateMode === DateMode.Daily ?
                            format(deviceData.date, 'dd.MM.yyyy')
                            : format(deviceData.date, 'MM.yyyy')
                        }
                        {deviceData.isNow ?
                            <div className="DeviceData-dateArrowPlaceholder" /> /* aby data zawsze była wyśrodkowana */ :
                            <Link to={`${route}${createDeviceDetailsUrl(deviceData.dateMode, deviceData.display.routeName, deviceData.nextDate)}`} className="DeviceData-dateArrow DeviceData-dateArrowBack">
                                <ArrowBack />
                            </Link>
                        }
                    </div>
                    {!isAnalysisData && <DeviceDataChart deviceData={deviceData} />}

                    {isAnalysisData && <DeviceDataAnalysis analysisData={deviceData} />}
                </div>
                {!isAnalysisData && deviceData.data.length > 0 && !deviceData.fetching &&
                    <div className="DeviceData-highlights"><DataHighlights device={device} deviceData={deviceData} /></div>
                }
                {isAnalysisData && !deviceData.fetching &&
                    <div className="DeviceData-highlights"><DataHighlights device={device} deviceData={deviceData} /></div>
                }
                {ActionsComponent && !deviceData.fetching &&
                    <div className="DeviceData-actions"><ActionsComponent device={device} /></div>
                }
            </div>
        );
    }
});
