import { observable, autorun, computed, action, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { UserStore } from '../UserStore/UserStore';
import { isFetching, fetched, getValue, fetchingFailed } from '../../utils/PromiseBasedObservable';
import { ApiCollectionResponse } from '../../infrastructure/ApiResponse';
import { UnreadNotificationsCountStore } from '../UnreadNotificationsCountStore/UnreadNotificationsCountStore';

export interface CustomerNotification {
    id: string;
    message: string;
    read: boolean;
    readAt: string;
    recipientId: string;
    sentAt: string;
    source: {
        name: string;
        sourceObjectId: string;
        type: string;
    };
    tenantId: string;
    title: string;
}

export class NotificationsListStore {

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private userStore: UserStore, private unreadNotificationsCountStore: UnreadNotificationsCountStore) {
        makeObservable<NotificationsListStore, "notificationsListPromise" | "currentCount" | "currentNotifications">(this, {
            page: observable,
            perPage: observable,
            count: observable,
            notifications: observable,
            notificationsListPromise: computed,
            currentCount: computed,
            currentNotifications: computed,
            isFetching: computed,
            isFailed: computed,
            didFetch: computed,
            markAsReaded: action.bound,
            clear: action.bound
        });

        autorun(() => {
            if (this.currentCount !== null) {
                this.count = this.currentCount;
            }
        });
        autorun(() => {
            if (this.currentNotifications !== null) {
                this.notifications = this.notifications.concat(this.currentNotifications)
            }
        });
    }

    page: number | null = null;
    perPage: number = 10;
    count: number = 0;
    notifications: CustomerNotification[] = [];

    private get notificationsListPromise(): IPromiseBasedObservable<ApiCollectionResponse<CustomerNotification>> | null {
        if (this.page === null || this.userStore.userId === null) {
            return null;
        }
        return fromPromise(
            this.httpService.get(this.urlProvider.getNotificationsListUrl(this.userStore.userId, this.page, this.perPage))
        );
    }

    private get currentCount(): number | null {
        return getValue<ApiCollectionResponse<CustomerNotification>, number>(this.notificationsListPromise, response => response.size);
    }

    private get currentNotifications(): CustomerNotification[] | null {
        return getValue<ApiCollectionResponse<CustomerNotification>, CustomerNotification[]>(this.notificationsListPromise, response => response.items || []);
    }

    get isFetching(): boolean {
        return isFetching(this.notificationsListPromise);
    }

    get isFailed(): boolean {
        return fetchingFailed(this.notificationsListPromise);
    }

    get didFetch(): boolean {
        return fetched(this.notificationsListPromise);
    }

    markAsReaded(notification: CustomerNotification) {
        const index = this.notifications.map(n => n.id).indexOf(notification.id)
        if (this.notifications[index]) {
            if(!this.notifications[index].read) {
                this.notifications[index].read = notification.read
                this.notifications[index].readAt = notification.readAt
                this.unreadNotificationsCountStore.decreaseCount()
            }
        }else {
            this.unreadNotificationsCountStore.fetchCount()
        }
    }

    clear() {
        this.page = 1;
        this.perPage = 10;
        this.count = 0;
        this.notifications = [];
    }
}
