import * as React from 'react';
import './AddMeter.scss';
import { inject, observer } from 'mobx-react';
import { MeterType } from './MeterType';
import { MeterRegisterStore } from '../../stores/MeterRegisterStore/MeterRegisterStore';
import { Success } from './Success';
import { Loader } from '../Loader/Loader';
import { Fail } from './Fail';
import { MeterType as MeterTypeType } from '../../domain/MeterType';
import { MeterAttachmentType as MeterAttachmentTypeType } from '../../domain/MeterAttachmentType';
import { CalibrationParameters } from '../../domain/CalibrationParameters';
import { MeterAttachmentType } from './MeterAttachmentType';
import { AddLertaMeter } from './AddLertaMeter';
import { AddHAN } from './AddHAN';
import { AddEMI } from './AddEMI/AddEMI';

export interface Props {
	meterRegisterStore?: MeterRegisterStore;
}

export interface State {
	code: string | null;
	meterType: MeterTypeType;
	meterAttachmentType: MeterAttachmentTypeType;
	display: Display;
	calibrationParameters: CalibrationParameters | null;
	name: string | null;
	gatewayId: string | null;
}

type Display = 'meter-type' | 'meter-attachment-type' | 'add-meter' | 'result';

export const AddMeter = inject('meterRegisterStore')(observer(class AddMeter extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			meterType: MeterTypeType.Electricity,
			meterAttachmentType: MeterAttachmentTypeType.LertaMeter,
			display: 'meter-type',
			calibrationParameters: null,
			name: null,
			gatewayId: null
		};
	}

	registerMeter = (calibrationParameters?: CalibrationParameters) => {
		if (calibrationParameters) {
			this.setState({ calibrationParameters });
		}

		if (this.state.code === null || this.state.meterType === null) {
			return;
		}

		const meterRegisterStore = this.props.meterRegisterStore!;

		meterRegisterStore.registerMeter(
			this.state.code,
			this.state.meterAttachmentType,
			this.state.meterType,
			calibrationParameters || this.state.calibrationParameters || undefined,
		);
		this.setState({ display: 'result' });
	};

	registerHANMeter = (name?: string, gatewayId?: string) => {
		if (name) {
			this.setState({ name })
		}
		if (gatewayId) {
			this.setState({ gatewayId })
		}
		if (this.state.code === null || this.state.meterType === null || !(this.state.gatewayId !== null || gatewayId) || !(this.state.name !== null || name)) {
			return;
		}

		const meterRegisterStore = this.props.meterRegisterStore!;
		meterRegisterStore.registerHANMeter(this.state.code, this.state.name || name!, this.state.meterAttachmentType, this.state.gatewayId || gatewayId!);
		this.setState({ display: 'result' });
	};

	handleChoiceOfMeterType = (meterType: MeterTypeType) => {
		if (meterType === MeterTypeType.Electricity) {
			this.setState({ meterType, display: 'meter-attachment-type' });
		} else {
			this.setState({ meterType, meterAttachmentType: MeterAttachmentTypeType.LertaMeter, display: 'add-meter' });
		}
	}

	resetComponent = () => {
		this.setState({
			code: null,
			meterType: MeterTypeType.Electricity,
			meterAttachmentType: MeterAttachmentTypeType.LertaMeter,
			display: 'meter-type',
			calibrationParameters: null,
		});
	}

	render() {
		const meterRegisterStore = this.props.meterRegisterStore!;

		switch (this.state.display) {
			case 'meter-type':
				return (
					<MeterType
						onTypeWasChosen={this.handleChoiceOfMeterType}
						meterRegisterStore={this.props.meterRegisterStore!}
					/>
				);
			case 'meter-attachment-type':
				return (
					<MeterAttachmentType
						onTypeWasChosen={(meterAttachmentType) => this.setState({ meterAttachmentType, display: 'add-meter' })}
						meterRegisterStore={this.props.meterRegisterStore!}
					/>
				);
			case 'add-meter':
				if (this.state.meterAttachmentType === MeterAttachmentTypeType.LertaMeter) {
					return <AddLertaMeter
						onRegisterMeter={this.registerMeter}
						meterType={this.state.meterType!}
						onCodeWasScanned={code => this.setState({ code })}
					/>;
				} else if (this.state.meterAttachmentType === MeterAttachmentTypeType.HAN) {
					return <AddHAN
						onRegisterMeter={this.registerHANMeter}
						meterType={this.state.meterType!}
						onCodeWasScanned={code => this.setState({ code })}
					/>;
				} else {
					return <AddEMI
						onRegisterMeter={this.registerMeter}
						meterRegisterStore={this.props.meterRegisterStore!}
						onCodeWasScanned={code => this.setState({ code })}
					/>
				}
			case 'result':
				if (meterRegisterStore.registeringMeter) {
					return <Loader secondary />;
				}
				if (meterRegisterStore.registeringMeterFailed) {
					const retry: any = this.state.meterAttachmentType === MeterAttachmentTypeType.HAN ? this.registerHANMeter : this.registerMeter
					return <Fail
						onRetry={() => retry()}
						meterWithIdAlreadyExists={meterRegisterStore.meterWithIdAlreadyExists}
					/>;
				}
				return <Success onAddAnotherMeter={this.resetComponent} meterType={this.state.meterAttachmentType} />;
		}
	}
}));
