import * as React from 'react';
import './styles.scss';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Skip} from "./Skip";
import {Button} from "../Button";

import ArrowBack from '../../static/icons/arrow_back.svg'
import Quit from '../../static/icons/quit.svg'

export interface Props {
    children?: React.ReactNode;
    backUrl?: string;
    onBackButton?(): void;
    onNextButton?(): void;
    closeUrl?: string;
    gradient?: boolean;
    className?: string;
}


export class FullScreenContainer extends React.Component<Props> {
    render() {
        const {children, gradient, className, backUrl, onBackButton, onNextButton, closeUrl} = this.props;
        const canGoBack = !onNextButton && onBackButton;
        const canSkip = onNextButton && !onBackButton;
        return (
            <>
                {canSkip && <Skip onNextButton={onNextButton}/>}
                <div className={classNames(
                    'FullScreenContainer-container',
                    className,
                    {
                        'FullScreenContainer-gradient': gradient,
                        'FullScreenContainer-withButtons': backUrl || onBackButton || closeUrl,
                    }
                )}>
                    {backUrl && <Link to={backUrl} className="FullScreenContainer-arrowBack"><ArrowBack/></Link>}
                    {canGoBack &&
                    <div onClick={onBackButton} className="FullScreenContainer-arrowBack"><ArrowBack/></div>}
                    {closeUrl && <Link to={closeUrl} className="FullScreenContainer-closeButton"><Quit/></Link>}
                    {children}
                    {canSkip && <Button onClick={onNextButton}>Dalej</Button>}
                </div>
            </>
        )
    }
}