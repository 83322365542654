import * as React from 'react';
import './style.scss';
import Calendar from 'react-calendar';
import { OverlayFrame } from '../OverlayFrame';
import { format } from 'date-fns';

import ArrowBack from '../../../static/icons/arrow_back.svg'

const ArrowRightDouble = () => {
    return (<div className="OverlayFrame-right-double-arrow">
        <ArrowBack className="OverlayFrame-arrowback" />
        <ArrowBack className="OverlayFrame-arrowback" />
    </div>)
};

const ArrowLeftDouble = () => {
    return (<div className="OverlayFrame-left-double-arrow">
        <ArrowBack className="OverlayFrame-arrowback" />
        <ArrowBack className="OverlayFrame-arrowback" />
    </div>)
};

export interface DailyProps {
    onCancel: () => void;
    onConfirm: (chosenDate: Date) => void;
    onClose?: () => void;
    initialDate?: Date;
}

export interface DailyState {
    chosenDate: Date;
}

export class Daily extends React.Component<DailyProps, DailyState> {
    private today: Date = new Date();

    constructor(props) {
        super(props);

        this.state = {
            chosenDate: this.props.initialDate || this.today
        };

        this.confirmHandler = this.confirmHandler.bind(this);
    }

    confirmHandler() {
        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.chosenDate);
        }
    }

    render() {
        const { onCancel, onClose } = this.props;

        return (
            <OverlayFrame title="Dzienny" onCancel={onCancel} onConfirm={this.confirmHandler} onMaskClick={onClose} >
                <div className="OverlayFrame-currentDate">{format(this.state.chosenDate, 'dd.MM.yyyy')}</div>
                <Calendar
                    locale="PL-pl"
                    onChange={date => this.setState({ chosenDate: date as Date})}
                    value={this.state.chosenDate}
                    nextLabel={<div><ArrowBack className="OverlayFrame-arrow-next" /></div>}
                    next2Label={<ArrowRightDouble />}
                    prevLabel={<div><ArrowBack className="OverlayFrame-arrow-prev" /></div>}
                    prev2Label={<ArrowLeftDouble />}
                />
            </OverlayFrame>
        );
    }
}
