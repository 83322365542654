import * as React from "react";
import { inject, observer } from "mobx-react";
import { SmartDevices } from "../SmartDevices/SmartDevices";
import './style.scss';
import { LinkTabs } from "../LinkTabs/LinkTabs";
import { Redirect, Route, Switch } from "react-router";
import { Meters } from "../Meters/Meters";
import { SmartDevicesStore } from "../../stores/SmartDevicesStore/SmartDevicesStore";
import { Loader } from "../Loader/Loader";
import { AvailableFeaturesStore } from "../../stores/AvailableFeatures/AvailableFeaturesStore";

interface DevicesProps {
    availableFeaturesStore?: AvailableFeaturesStore;
    smartDevicesStore?: SmartDevicesStore;
}
export const UndecoratedDevices = inject('smartDevicesStore', 'availableFeaturesStore')(observer(class UndecoratedDevices extends React.Component<DevicesProps> {

    private tabs: { name: string; url: string, component: any }[] = []
    private multipleTabs: boolean = false;

    constructor(props: DevicesProps) {
        super(props);
        const { availableFeaturesStore } = this.props
        if (availableFeaturesStore!.hasAvailableFeature('supplyPoints') === true) {
            this.tabs.push({ name: 'Liczniki', url: '/devices/meters', component: Meters })
        }
        if (availableFeaturesStore!.hasAvailableFeature('smartHome') === true) {
            this.tabs.push({ name: 'Smart home', url: '/devices/smart_home', component: SmartDevices })
        }

        this.multipleTabs = this.tabs.length > 1;
    }

    render() {
        const { smartDevicesStore } = this.props;
        const defaultTab = this.multipleTabs && smartDevicesStore!.devices.length > 0 ?
            '/devices/smart_home' :
            this.tabs[0] && this.tabs[0].url;
        if (!smartDevicesStore!.alreadyFetched && !smartDevicesStore!.fetchingFailed) {
            return (
                <div className="Devices-container">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="Devices-container">
                {this.tabs && this.tabs.length > 1 && <LinkTabs
                    chosenTab={0}
                    tabs={this.tabs}
                />}
                <Switch>
                    {this.tabs.map((tab) =>
                        <Route key={tab.url} path={tab.url} component={tab.component} />
                    )}
                    {defaultTab && <Redirect to={defaultTab} />}
                </Switch>
            </div>
        );
    }
}))

export const Devices = UndecoratedDevices