import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { Analysis } from './Analysis/Analysis';
import { Balance } from './Balance/Balance';
import { Switch } from '../Switch/Switch';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

export const Statistics = () => {

    const history = useHistory()
    const location = useLocation()
    const intl = useIntl()

    return <Box display="flex" flexDirection="column" alignItems="center" flexGrow="1">
        <Box>
            <Switch
                value={location.pathname.split('/')[2]}
                onChange={value => history.push(`/statistics/${value}`)}
                items={[
                    { label: intl.formatMessage({ id: "statistics.balance" }), value: "balance" },
                    { label: intl.formatMessage({ id: "statistics.analysis" }), value: "analysis" },
                ]}
            />
        </Box>
        <Route path="/statistics/balance" component={Balance} />
        <Route path="/statistics/analysis" component={Analysis} />
        <Route exact path="/statistics" render={() => <Redirect to="/statistics/balance" />} />
    </Box>
}