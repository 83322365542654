import * as React from 'react';
import { Loader as LoaderComponent } from '../Loader/Loader';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { Paragraph } from '../Text/Paragraph/Paragraph';

export const Loader = () => (
    <FullScreenContainer className="AddGateway-loaderContainer" gradient closeUrl="/devices/smart_home">
        <Header className="AddGateway-loaderHeader">Trwa łączenie centralki z serwerem...</Header>
        <LoaderComponent secondary />
        <Paragraph className="AddGateway-loaderParagraph">Może to potrwać do 90 sekund.</Paragraph>
    </FullScreenContainer>
);
