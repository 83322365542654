import * as React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

export interface Props {
    children?: React.ReactNode;

    onClick?(): void;
    href?: string;
    primary?: boolean;
}

export const Tile = ({children, onClick, href, primary}: Props) => {
    if (href) {
        return (
            <Link to={href} onClick={onClick} className={`Tile-container ${primary ? 'Tile-primary' : ''}`}>
                {children}
            </Link>
        )
    }

    return (
        <div onClick={onClick} className={`Tile-container ${primary ? 'Tile-primary' : ''}`}>
            {children}
        </div>
    );
};
