import { endOfDay, endOfWeek, startOfDay, startOfWeek, endOfMonth, startOfMonth, startOfYear, endOfYear, subYears, addMonths } from 'date-fns';
import { observable, computed, action, when, autorun, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { isFetching, fetched, getValue, fetchingFailed } from '../../utils/PromiseBasedObservable';
import { LocationsStore } from '../LocationsStore/LocationsStore';
import { SupplyPointsStore } from '../SupplyPointsStore/SupplyPointsStore';
import { SmartDevicesStore } from '../SmartDevicesStore/SmartDevicesStore';

export interface DashboardData {
    name: string;
    color: string;
    prediction: number;
    data: Array<{
        unit: string;
        time: string;
        value: number;
        key: string;
    }>
}

interface DashboardDataFetchers {
    meter: DashboardDataFetcher;
    meterless: DashboardDataFetcher;
    production?: DashboardDataFetcher;
}
interface DashboardBalanceDataFetchers {
    balanceProduction?: DashboardDataFetcher;
    balanceConsumption?: DashboardDataFetcher;
}

export enum Accuracy {
    day = 'day',
    hour = 'hour',
    hour_halves = 'hour_halves',
    hour_quarters = 'hour_quarters',
    week = 'week',
    month = 'month',
    quarter = 'quarter',
    year = 'year',
    none = 'none',
}

export enum TimeDomains {
    day = "DAY",
    week = "WEEK",
    month = "MONTH",
    year = "YEAR"
}

const getPeriod = (timeDomain?: TimeDomains) => {
    switch (timeDomain) {
        case TimeDomains.day: return {
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
        }
        case TimeDomains.week: return {
            start: startOfWeek(new Date()),
            end: endOfWeek(new Date()),
        }
        case TimeDomains.month: return {
            start: startOfMonth(new Date()),
            end: endOfMonth(new Date()),
        }
        case TimeDomains.year: return {
            start: startOfYear(new Date()),
            end: endOfYear(new Date()),
        }
        default: return {
            start: startOfMonth(subYears(addMonths(new Date(), 1), 1)),
            end: endOfMonth(new Date())
        }
    }
}
const getAccuracy = (timeDomain?: TimeDomains) => {
    switch (timeDomain) {
        case TimeDomains.day: return Accuracy.hour_quarters;
        case TimeDomains.week: return Accuracy.day;
        case TimeDomains.month: return Accuracy.day;
        case TimeDomains.year: return Accuracy.month;
        default: return Accuracy.month
    }
}

export class DashboardStore {

    view: 'meterless' | 'meter' | null = null;
    activeGauge: number = 0;
    private viewKey = 'dashboard-view';
    fetchers?: DashboardDataFetchers;
    balanceFetchers?: DashboardBalanceDataFetchers;
    activeTimeDomain: TimeDomains = TimeDomains.day;

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private locationsStore: LocationsStore,
        private supplyPointsStore: SupplyPointsStore,
        private localStorage: Storage,
        private smartDevicesStore: SmartDevicesStore
    ) {
        makeObservable(this, {
            view: observable,
            activeGauge: observable,
            fetchers: observable,
            balanceFetchers: observable,
            activeTimeDomain: observable,
            consumption: computed,
            production: computed,
            balanceProduction: computed,
            balanceConsumption: computed,
            chartData: computed,
            balanceData: computed,
            locationId: computed,
            changeView: action.bound,
            isSupplyPoint: computed,
            isInverter: computed,
            setDefaultView: action.bound,
            resetData: action.bound
        });

        this.view = localStorage.getItem(this.viewKey) as 'meterless' | 'meter' || 'meter'
        autorun(() => {
            if (this.locationId === null || this.isSupplyPoint === null || this.smartDevicesStore.fetching) {
                return
            }
            const period = getPeriod();
            const accuracy = getAccuracy();
            let fetchers: DashboardDataFetchers | undefined;
            if (this.isInverter) {
                fetchers = {
                    meter: new DashboardDataFetcher(() => fromPromise(
                        this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, false, period, accuracy, 'consumption'))
                    )),
                    meterless: new DashboardDataFetcher(() => fromPromise(
                        this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, true, period, accuracy, 'consumption'))
                    )),
                    production: new DashboardDataFetcher(() => fromPromise(
                        this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, false, period, accuracy, 'production'))
                    )),
                }
            } else {
                fetchers = {
                    meter: new DashboardDataFetcher(() => fromPromise(
                        this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, false, period, accuracy))
                    )),
                    meterless: new DashboardDataFetcher(() => fromPromise(
                        this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, true, period, accuracy))
                    )),
                }
            }
            this.fetchers = fetchers
        })
        autorun(() => {
            if (this.locationId === null || this.isSupplyPoint === null || this.smartDevicesStore.fetching) {
                return
            }
            const period = getPeriod(this.activeTimeDomain);
            const accuracy = getAccuracy(this.activeTimeDomain);
            this.balanceFetchers = {
                balanceConsumption: new DashboardDataFetcher(() => fromPromise(
                    this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, true, period, accuracy, 'consumption'))
                )),
                balanceProduction: new DashboardDataFetcher(() => fromPromise(
                    this.httpService.get(this.urlProvider.getDashboardUrl(this.locationId!, false, period, accuracy, 'production'))
                )),
            }
        })
    }

    get consumption() {
        if (!this.fetchers || !this.view) {
            return null
        }
        return this.fetchers[this.view]
    }

    get production() {
        if (!this.fetchers || !this.view || !this.isInverter) {
            return null
        }
        return this.fetchers.production || null
    }

    get balanceProduction() {
        if (!this.balanceFetchers || !this.view || !this.isInverter) {
            return null
        }
        return this.balanceFetchers.balanceProduction || null
    }
    get balanceConsumption() {
        if (!this.balanceFetchers || !this.view || !this.isInverter) {
            return null
        }
        return this.balanceFetchers.balanceConsumption || null
    }

    get chartData() {
        switch (this.activeGauge) {
            case 0:
                return this.consumption
            case 1:
                return this.production
        }
        return null
    }
    get balanceData() {
        return [this.balanceProduction, this.balanceConsumption];
    }

    get locationId() {
        const { locations } = this.locationsStore
        return locations && !!locations.length ? locations[0].id : null
    }

    changeView(v: 'meterless' | 'meter') {
        this.view = v
        this.localStorage.setItem(this.viewKey, v)
    }

    get isSupplyPoint(): boolean | null {
        return this.supplyPointsStore.supplyPoints && !!this.supplyPointsStore.supplyPoints.length
    }

    get isInverter() {
        return !!(this.smartDevicesStore.devices && this.smartDevicesStore.devices.find((d) => {
            return d.class === 'inverter'
        }))
    }

    async setDefaultView() {
        await when(() => this.isSupplyPoint !== null)
        this.view = this.isSupplyPoint ? this.localStorage.getItem(this.viewKey) as 'meterless' | 'meter' || 'meter' : 'meterless'
    }

    resetData() {
        if (!this.fetchers) {
            return
        }
        this.fetchers.meter.promise = null
        this.fetchers.meterless.promise = null
        if (this.fetchers.production) {
            this.fetchers.production.promise = null
        }
    }

}

export class DashboardDataFetcher {

    promise: IPromiseBasedObservable<DashboardData> | null = null;

    constructor(
        private getPromise: () => IPromiseBasedObservable<DashboardData>
    ) {
        makeObservable(this, {
            promise: observable,
            data: computed,
            isFetching: computed,
            didFailed: computed,
            didFetch: computed
        });

        autorun(() => {
            if (!this.promise) {
                this.promise = this.getPromise()
            }
        })
    }

    get data(): DashboardData | null {
        if (!this.promise) {
            return null
        }
        return getValue<DashboardData>(this.promise);
    }

    get isFetching(): boolean {
        return isFetching(this.promise);
    }

    get didFailed(): boolean {
        return fetchingFailed(this.promise);
    }

    get didFetch(): boolean {
        return fetched(this.promise);
    }

}