import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Button } from '../Button';
import { MeterKind } from '../../domain/MeterKind';

const MeterAttachment = require('../../static/LM_green_sticker.svg');

export interface Props {
    meterKind: MeterKind;
    onClick(): void;
}

export const ChooseMeterAttachment = ({onClick, meterKind}: Props) => (
    <FullScreenContainer gradient>
        <div>
            <Paragraph>Weź Lerta Meter</Paragraph>
            <Paragraph><strong>{
                meterKind === MeterKind.Optic ? 
                    'z zieloną naklejką' : 
                    'z żółtą naklejką'
            }</strong></Paragraph>
        </div>
        <MeterAttachment className={`AddMeter-meterAttachment ${meterKind === MeterKind.Magnetic ? 'AddMeter-meterAttachmentMagnetic' : ''}`} />
        <Button onClick={onClick}>Gotowe</Button>
    </FullScreenContainer>
)
