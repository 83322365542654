import * as React from 'react';
import './style.scss';
import { DashboardData } from '../../../stores/DashboardStore/DashboardStore';
import { format, startOfYear, isSameMonth } from 'date-fns';
import { yearly } from '../../Chart/configs';
import { ResponsiveContainer, BarChart, XAxis, Tooltip, Bar, ReferenceLine, LabelList, Label } from 'recharts';
import { getShortMonthName } from '../../../utils/getShortMonthName';

interface Props {
    dashboardData: DashboardData;
    color?: string;
}

interface ChartData {
    value?: number;
    time: Date;
}

const textOffset = 5;
const barFormatter = (label) => (+label).toFixed(1)

export class DashboardChart extends React.Component<Props> {

    render() {
        const { color } = this.props
        const { prediction, data } = this.props.dashboardData
        const minValue = Math.min(...data.slice(0, 11).map(month => month.value))
        const maxValue = Math.max(...data.slice(0, 11).map(month => month.value))
        const unit = data[0].unit;
        const currentMonth = data[data.length - 1].value;

        return this.preparedData && this.preparedData.length && <React.Fragment>
            <p className="axisLabel" style={{ paddingLeft: textOffset }}>{unit}</p>
            <ResponsiveContainer className="Chart-container" >
                <BarChart
                    data={this.preparedData.map(dataPoint => ({ ...dataPoint, time: dataPoint.time.getTime() }))} margin={{ left: 0, right: 0, top: 30 }}>

                    <pattern id="pattern-stripe"
                        width="2" height="4"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(90)">
                        <rect width="1" height="4" transform="translate(0,0)" fill="white"></rect>
                    </pattern>
                    <mask id="mask-stripe">
                        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
                    </mask>

                    <XAxis
                        padding={{ left: 50 - textOffset, right: 50 }}
                        domain={yearly.getDomain(this.preparedData)}
                        type="number"
                        dataKey="time"
                        ticks={yearly.getTicks(this.preparedData)}
                        interval={0}
                        tickLine={false}
                        tick={(props: any) => {
                            const { x, y, payload } = props;
                            const date = new Date(payload.value);
                            return (
                                <g transform={`translate(${x},${y})`}>
                                    <text x={0} y={0} dy={8} textAnchor="end" fill="#666" >
                                        <tspan textAnchor="middle" x="0">{getShortMonthName(payload.value)}</tspan>
                                        {(isSameMonth(date, this.preparedData[0].time) || isSameMonth(date, startOfYear(date))) &&
                                            <tspan textAnchor="middle" x="0" dy={12}>'{format(date, "yy")}</tspan>}
                                    </text>
                                </g>
                            );
                        }}
                    >
                        <Label value="m-c" position="insideRight" offset={textOffset} />
                    </XAxis>

                    <Tooltip
                        separator=": "
                        formatter={(val: number) => {
                            return val !== prediction - currentMonth ?
                                `${val.toFixed(2)}${unit}` :
                                `${prediction.toFixed(2)}${unit}`
                        }}
                        labelFormatter={yearly.tooltipFormatter}
                        itemStyle={{ textAlign: "left" }}
                    />
                    <ReferenceLine y={minValue} strokeDasharray="3 3" label={<ReferenceLineLabel text="Min" textOffset={textOffset} />} />
                    <ReferenceLine y={maxValue} strokeDasharray="3 3" label={<ReferenceLineLabel text={`Max: ${maxValue.toFixed(1)}`} textOffset={textOffset} />} />

                    <Bar barSize={5} animationDuration={500} stackId="a" dataKey="currentMonth" name="zużycie" fill={color} />
                    <Bar barSize={5} animationDuration={500} stackId="a" dataKey="prediction" name="prognozowane" fill={color} shape={<StripeBar />} ><LabelList dataKey={"prediction"} formatter={() => prediction && prediction.toFixed(1)} position="top" /></Bar>
                    <Bar barSize={5} animationDuration={500} stackId="a" dataKey="min" name="zużycie" fill="black" ><LabelList dataKey={"min"} formatter={barFormatter} position={minValue < 0 ? "insideTopRight" : "top"} /></Bar>
                    <Bar barSize={5} animationDuration={500} stackId="a" dataKey="max" name="zużycie" fill="black" ><LabelList dataKey={"max"} formatter={barFormatter} position="top" /></Bar>
                    <Bar barSize={5} animationDuration={500} stackId="a" dataKey="value" name="zużycie" fill="#88959e" ><LabelList dataKey={"value"} formatter={barFormatter} position="top" /></Bar>
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    }

    private get preparedData() {
        const { data, prediction } = this.props.dashboardData;
        const chartData = data.map(({ value, time }) => ({ value: value, time: new Date(time) }))
        const minValue = Math.min(...data.slice(0, 11).map(month => month.value))
        const maxValue = Math.max(...data.slice(0, 11).map(month => month.value))
        const currentMonth = data[data.length - 1].value;

        chartData.sort((a, b) => +a.time - +b.time);
        (chartData as ChartData[]).forEach((element) => {
            if (element.hasOwnProperty("value") && element.value === currentMonth) {
                Object.defineProperty(element, 'currentMonth', { value: currentMonth, enumerable: true })
                Object.defineProperty(element, 'prediction', { value: prediction - currentMonth, enumerable: true })
                delete element["value"]
            }

            if (element.hasOwnProperty("value") && element.value === maxValue) {
                Object.defineProperty(element, "max", Object.getOwnPropertyDescriptor(element, "value")!)
                delete element["value"]
            }

            if (element.hasOwnProperty("value") && element.value === minValue) {
                Object.defineProperty(element, "min", Object.getOwnPropertyDescriptor(element, "value")!)
                delete element["value"]
            }
        })
        return chartData
    }
}

const ReferenceLineLabel = (props: any) => {
    const { text, textOffset } = props;
    return (
        <g>
            <text x={props.viewBox.x + textOffset} y={props.viewBox.y - textOffset} className="referenceLineLabel">{text}</text>
        </g>
    );
};

const StripeBar = (props) => {
    const {
        x: oX,
        y: oY,
        width: oWidth,
        height: oHeight,
        fill
    } = props;

    let x = oX;
    let y = oHeight < 0 ? oY + oHeight : oY;
    let width = oWidth;
    let height = Math.abs(oHeight);

    return (
        <rect fill={fill}
            mask='url(#mask-stripe)'
            x={x}
            y={y}
            width={width}
            height={height} />
    );
};