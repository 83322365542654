import * as React from 'react';
import { inject, observer } from "mobx-react";
import { DeviceSettings } from './index';
import { Redirect, RouteComponentProps } from 'react-router';
import { DevicesStore } from '../../stores/DevicesStore/DevicesStore';

export interface DeviceSettingsContainerProps extends RouteComponentProps<{ deviceId: string; }> {
    devicesStore: DevicesStore;
}

export const DeviceSettingsContainer = inject('devicesStore')(observer(
    class DeviceSettingsContainer extends React.Component<DeviceSettingsContainerProps> {
        render() {
            const { deviceId } = this.props.match.params;

            const device = this.props.devicesStore.getDeviceById(deviceId);

            if (!device) {
                return <Redirect to={`/devices/${deviceId}`} />;
            }

            return <DeviceSettings device={device} onGoBackClick={() => this.props.history.goBack()} />;
        }
    }
));
