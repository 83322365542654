import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BalanceData } from 'src/stores/Statistics/BalanceStore';
import { formatNumber } from './BalanceChart';

interface Props {
    balanceData: BalanceData
}


export const BalanceDetails = ({ balanceData }: Props) => {
    const value = (balanceData.exportToGrid || 0) - (balanceData.importFromGrid || 0);

    return <div className="Balance-details">
        <div className="Balance-details-import-export">
            <div>
                <Typography variant="subtitle1" >
                    <FormattedMessage id="statistics.exportToGrid" />
                </Typography>
                <Typography className="bold" variant="h5" >
                    {formatNumber(balanceData.exportToGrid)} {balanceData.unit}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle1" >
                    <FormattedMessage id="statistics.importFromGrid" />
                </Typography>
                <Typography className="bold" variant="h5">
                    {formatNumber(balanceData.importFromGrid)} {balanceData.unit}
                </Typography>
            </div>
        </div>
        <div className="Balance-details-balance">
            <div className="Balance-details-balance-wrapper">
                <Typography variant="subtitle1" >
                    <FormattedMessage id="statistics.balance" />
                </Typography>
                <Typography className="bold" variant="h5">
                    {Math.sign(value) >= 0 ? '+' : '-'} {formatNumber(Math.abs(value))} {balanceData.unit}
                </Typography>
            </div>

        </div>
    </div>
}