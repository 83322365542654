import * as React from "react";
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Button } from "../../Button";
import { FullScreenContainer } from "../../FullScreenContainer/FullScreenContainer";
import { Paragraph } from "../../Text/Paragraph/Paragraph";
import { Header } from "../../Text/Header/Header";

export interface EndingInstructionProps {
	wifiCode: string;
	urlBack?: string;
	onClick?(): void;
}

export const EndingInstruction = observer(class EndingInstruction extends React.Component<EndingInstructionProps> {
	render() {
		return (
			<FullScreenContainer gradient>
				<div>
					<Paragraph>Odłącz smartfon od sieci Wifi</Paragraph>
					<Header>Lerta-{this.props.wifiCode}</Header>
					<Paragraph>Połącz się do domowej sieci Wifi</Paragraph>
				</div>
				{this.props.onClick ?
					<Button onClick={this.props.onClick}>Ok</Button> :
					this.props.urlBack &&
					<Link to={this.props.urlBack}><Button>Ok</Button></Link>
				}
			</FullScreenContainer>
		);
	}
});
