import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { MeterKind as MeterKindType } from '../../domain/MeterKind';
import { MeterType } from '../../domain/MeterType';

const Pig = require('../../static/plug.png');
const Meter = require('../../static/LM_bg.svg');

export interface Props {
    meterType: MeterType;
    onKindWasChosen(meterKind: MeterKindType): void;
}

export const meterTypeToLabelMap = (meterType: MeterType): string => {
    switch (meterType) {
        case MeterType.Electricity:
            return 'prądu';
        case MeterType.Gas:
            return 'gazu';
        case MeterType.Heat:
            return 'ciepła';
        case MeterType.WaterCold:
            return 'zimnej wody';
        case MeterType.WaterWarm:
            return 'ciepłej wody';
        case MeterType.Inverter:
            return 'produkcji energii';
        case MeterType.Water:
            return 'wody';
    }
}

export const MeterKind = ({ onKindWasChosen, meterType }: Props) => (
    <FullScreenContainer gradient>
        <div>
            <Paragraph>Czy Twój licznik {meterTypeToLabelMap(meterType)} posiada taki lub podobny element?</Paragraph>
        </div>
        <div className="AddMeter-pigMeter">
            <Meter />
            <img className="AddMeter-pig" src={Pig} alt="add-meter" />
        </div>
        <div>
            <Button onClick={() => onKindWasChosen(MeterKindType.Optic)}>Tak</Button>
            <Button onClick={() => onKindWasChosen(MeterKindType.Magnetic)} secondary>Nie</Button>
        </div>
    </FullScreenContainer>
);
