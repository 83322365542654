import * as React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Label, CartesianGrid, TooltipProps, Bar, BarChart, ReferenceLine } from 'recharts';
import './style.scss';
import { identity } from '../../utils/identity';
import classNames from "classnames";

export interface ChartData {
    time: Date;
    value: number;
}

export interface ChartProps {
    type: 'line' | 'bar';
    timeDomain: TimeDomainConfig;
    data: ChartData[];
    targetData?: ChartData[];
    range: [number, number];
    unit: string;
    name?: string;
    tooltipLabel: string;
    targetTooltipLabel?: string;
    valueFormatter?: (value: number) => string | number;
    margin?: {top?: number, rigth?: number, bottom?: number, left?: number},
    referenceLines?: Array<{ label: string, value: number, color: string }>,
}

export interface TimeDomainConfig {
    getTicks(data: ChartData[]): number[];
    tickFormatter(tick: string): string;
    getDomain(data: ChartData[]): [number, number];
    tooltipFormatter(tick: any): string;
}

export const Chart = ({ data, targetData, unit, margin, range, tooltipLabel, targetTooltipLabel, valueFormatter = identity, type, timeDomain, referenceLines }: ChartProps) => {
    const ChartComponent = type === 'line' ? LineChart : BarChart;

    const minValue = Math.min(...data.map(({ value }) => value), range[0]);
    let maxValue = Math.max(...data.map(({ value }) => value), range[1]);
    if (referenceLines) {
        let i = 0
        while (referenceLines[i]) {
            if (referenceLines[i].value > maxValue) {
                maxValue = referenceLines[i].value
                break;
            }
            i++
        }
    }

    const preparedData = () => {
        const dataMap = {}
        data.forEach(i => {
            dataMap[+i.time] = { time: i.time.getTime(), value: i.value }
        })
        if (targetData) {
            targetData.forEach(i => {
                if (!dataMap[+i.time]) {
                    dataMap[+i.time] = {
                        time: i.time.getTime()
                    }
                }
                dataMap[+i.time].target = i.value
            })
        }
        return Object.values(dataMap)
    }

    return (<ResponsiveContainer className="Chart-container" >
        <ChartComponent data={preparedData() as any} margin={margin || { top: 30, left: 5, right: 20 }}>
            <CartesianGrid vertical={false} />
            <XAxis
                domain={timeDomain.getDomain(data)}
                type="number"
                dataKey="time"
                axisLine={false}
                tickLine={false}
                ticks={timeDomain.getTicks(data)}
                tickFormatter={timeDomain.tickFormatter}
                interval={0}
            />
            <YAxis
                domain={[minValue, maxValue]}
                unit={unit}
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                ticks={[minValue, (maxValue - minValue) / 2, maxValue]}
                tickFormatter={tick => valueFormatter ? valueFormatter(tick) : tick.toFixed(2)}
            />
            {referenceLines && referenceLines.map(
                (line, key) => <ReferenceLine key={key} y={line.value} stroke={line.color} >
                    <Label value={line.label} fill={line.color} offset={-10} position="insideBottomRight" />
                </ReferenceLine>
            )}
            <Tooltip
                separator=": "
                formatter={(val: number) => `${valueFormatter(val)}${unit}`}
                labelFormatter={timeDomain.tooltipFormatter}
                content={<CustomTooltip />}
            />
            {type === 'line' && <Line animationDuration={500} type="monotone" name={tooltipLabel} dataKey="value" dot={false} stroke="secondary" />}
            {type === 'bar' && <Bar barSize={2} animationDuration={500} name={tooltipLabel} dataKey="value" fill="secondary" />}
            {targetData && <Line className="target-line" animationDuration={500} name={targetTooltipLabel} stroke="primary" type="stepAfter" strokeDasharray="2 2" dataKey="target" dot={false} connectNulls />}
        </ChartComponent>
    </ResponsiveContainer>);
};

const CustomTooltip = ({ active, payload, label, labelFormatter, separator, formatter }: TooltipProps<string, number>) => {
    if (!active || !payload) {
        return null;
    }
    return (
        <div className="Chart-tooltip">
            <p className="label">{labelFormatter ? labelFormatter(label || '', payload) : label}</p>
            {payload.map((p, i) =>
                <p key={p.name} className={classNames("row", { [p.color!]: p.color })}>{`${p.name}${separator}${formatter ? formatter(p.value as string, p.name, p, i) : p.value}`}</p>
            )}
        </div>
    );
};