import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { ConnectOtherDevicePrompt } from '../../components/AddDevice/ConnectDevicePrompt/ConnectDevicePrompt';
import { GasUsageData } from '../DeviceData/GasUsageData';
import { DateMode } from '../../domain/DateMode';
import { MeterType } from '../../domain/MeterType';
import { MeterEnergyUsageData } from '../DeviceData/MeterEnergyUsageData';
import { AnalysisData } from '../DeviceData/AnalysisData';
import { InverterEnergyProductionData } from '../DeviceData/InverterEnergyProductionData';

const meterTypeToDefaultName = (meterType?: MeterType): string => {
    switch (meterType) {
        case MeterType.Electricity:
            return 'Licznik energii elektrycznej';
        case MeterType.Gas:
            return 'Licznik gazu';
        case MeterType.Heat:
            return 'Licznik ciepła';
        case MeterType.WaterCold:
            return 'Licznik zimnej wody';
        case MeterType.WaterWarm:
            return 'Licznik ciepłej wody';
        case MeterType.Inverter:
            return 'Instalacja fotowoltaiczna';
        case MeterType.Water:
            return 'Woda';
        case undefined:
            return 'Licznik bez nazwy';
    }
};

const meterTypeToDeviceLogo = (meterType?: MeterType): string => {
    switch (meterType) {
        case MeterType.Electricity:
            return 'electricity_meter_icon.svg';
        case MeterType.Gas:
            return 'gas_meter_icon.svg';
        case MeterType.Heat:
            return '';
        case MeterType.WaterCold:
            return '';
        case MeterType.WaterWarm:
            return '';
        case MeterType.Inverter:
            return 'solar-panels-icon.svg';
        case MeterType.Water:
            return '';
        case undefined:
            return '';
    }
}

export class LertaMeterDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage, meterType?: MeterType, displayAnalysis?: boolean) {
        super(urlProvider, httpService, storage);

        this.dateMode = DateMode.Monthly;

        if (meterType === MeterType.Electricity) {
            this.energyUsageData = new MeterEnergyUsageData(httpService, urlProvider, this);
        }
        if (meterType === MeterType.Gas) {
            this.gasUsageData = new GasUsageData(httpService, urlProvider, this);
        }
        if (meterType === MeterType.Inverter) {
            this.inverterData = new InverterEnergyProductionData(httpService, urlProvider, this);
        }
        if (displayAnalysis) {
            this.analysisData = new AnalysisData(httpService, urlProvider, this);
        }

        this.dictionary = {
            addDeviceFormName: "",
            suggestedDeviceName: "",
            connectDevicePrompt: ConnectOtherDevicePrompt,
            reconnectDevicePrompt: () => null,
            reconnectDeviceSecondPrompt: () => null,
            instructions: {
                instruction: () => null,
                reconnect: () => null,
                factoryReset: () => null,
            },
            defaultName: meterTypeToDefaultName(meterType),
            deviceLogo: meterTypeToDeviceLogo(meterType),
        };
    }
}
