import { DeviceDataDisplay } from './DeviceData';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { DeviceDataItem } from '../../infrastructure/DeviceDataApiResponse';
import { currencyFormatter, wattFormatter } from '../../utils/formatters';
import { EnergyUsage } from '../../components/DeviceData/DataHighlights/EnergyUsage';
import { DateMode } from '../../domain/DateMode';
import { ConsumptionDeviceData, Stat } from "./ConsumptionDeviceData";

const Energy = require('../../static/icons/zuzycie.svg');

const display: DeviceDataDisplay = {
    routeName: 'energy',
    displayName: "Zużycie energii",
    tooltipName: 'zużycie',
    Logo: Energy,
    DataHighlights: EnergyUsage,
    chartType: 'bar',
    chartUnit: "Wh",
    displayUnit: "Wh",
    chartRange: [0, 0.001],
    valueFormatter: wattFormatter,
    costFormatter: currencyFormatter,
    showAvg: false
};

export class EnergyUsageData extends ConsumptionDeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, display);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id,
            'energy',
            dateStart.toISOString(),
            dateEnd.toISOString(),
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }

    getStatsUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceStatsUrl(
            this.device.id,
            dateStart.toISOString(),
            dateEnd.toISOString(),
        );
    }

    getConsumptionDistributionUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getConsumptionDistributionUrl(
            this.device.id,
            dateStart.toISOString(),
            dateEnd.toISOString(),
        );
    }

    getCost(): number {
        if (this.stat) {
            let cost = this.stat.grossVariableCost;
            return cost ? cost : 0;
        }
        return 0
    }

    normalizeDataResults(items: DeviceDataItem[]) {
        return super.normalizeDataResults(
            items.filter(({ unit }) => unit === display.chartUnit)
        );
    }

    filterStats(stats: Stat[]): Stat[] {
        return stats.filter(({ consumptionUnit }) => consumptionUnit === display.displayUnit);
    }

}
