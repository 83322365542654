import * as React from 'react';
import './styles.scss';
import { BrowserCodeReader } from '@zxing/library';

export interface CodeScannerProps {
	onScan(data: string): void;
	onError(): void;
}

export interface AbstractCodeScannerProps extends CodeScannerProps {
	CodeReader: new() => BrowserCodeReader;
}

export class CodeScanner extends React.Component<AbstractCodeScannerProps> {
	private codeReader: BrowserCodeReader;
	video = React.createRef<HTMLVideoElement>();

	constructor(props: AbstractCodeScannerProps) {
		super(props);

		this.codeReader = new this.props.CodeReader();
	}

	componentDidMount() {
		this.waitForCode();
	}

	getSnapshotBeforeUpdate() {
		this.waitForCode();
	}

	waitForCode() {
		return this.codeReader.decodeFromInputVideoDevice(undefined, this.video.current || undefined)
			.then(
				result => {
					const text = result.getText();
					if (text) {
						this.props.onScan(text);
					}
				},
				err => this.props.onError()
			);
	}

	componentWillUnmount() {
		this.codeReader.reset();
	}

	render() {
		return (
			<div className="CodeScanner-container">
				<div className="CodeScanner-mask">
					<div />
					<div className="CodeScanner-maskCenter">
						<div />
						<div className="CodeScanner-maskView" />
						<div />
					</ div>
					<div />
				</div>
				<video className="CodeScanner-video" ref={this.video} />
			</div>
		);
	}
}

export const cropFn = (videoWidth: number, videoHeight: number) => {
	const scanWidth = videoWidth / 2;
	const scanHeight = videoHeight / 2;

	const xOffset = (videoWidth - scanWidth) / 2;
	const yOffset = (videoHeight - scanHeight) / 2;

	return {scanWidth, scanHeight, xOffset, yOffset};
}

export function drawImageOnCanvas(canvasElementContext: CanvasRenderingContext2D, videoElement: HTMLVideoElement): void {
	const videoHeight = videoElement.videoHeight;
	const videoWidth = videoElement.videoWidth;

	const {
		xOffset, 
		yOffset, 
		scanWidth, 
		scanHeight, 
	} = cropFn(videoWidth, videoHeight);

	canvasElementContext.drawImage(videoElement,
		xOffset, 
		yOffset, 
		scanWidth, 
		scanHeight, 
		0, 
		0, 
		videoWidth, 
		videoHeight
	);
}
