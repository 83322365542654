import * as React from 'react';
import './style.scss';
import { RouteComponentProps, Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { NotificationsListStore } from '../../stores/NotificationsListStore/NotificationsListStore';
import { Loader } from '../Loader/Loader';
import { MenuItem } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import { UnreadNotificationsCountStore } from '../../stores/UnreadNotificationsCountStore/UnreadNotificationsCountStore';

const Drafts = require('../../static/icons/round-drafts-24px.svg');
const MarkUnread = require('../../static/icons/round-markunread-24px.svg')

export interface NotificationsListProps extends RouteComponentProps<{}> {
    notificationsListStore: NotificationsListStore;
    unreadNotificationsCountStore?: UnreadNotificationsCountStore;
}

export class UndecoratedNotificationsList extends React.Component<NotificationsListProps> {

    componentDidMount() {
        if (this.props.notificationsListStore.page === null) {
            this.props.notificationsListStore.page = 1;
        }
    }

    render() {
        const { notificationsListStore } = this.props;
        const notifications = notificationsListStore!.notifications;
        const infinityThreshold = 2;

        return (
            <div className="NotificationsList-container">
                {!!notificationsListStore!.notifications && !!notificationsListStore!.notifications!.length &&
                    notifications!.map((notification, index) =>
                        <React.Fragment key={index} >
                            <MenuItem className="NotificationsList-item">
                                <Link className="NotificationsList-link" to={`/notifications/${notification.id}`}>
                                    {notification.read ? <Drafts className="NotificationsList-icon" /> : <MarkUnread className="NotificationsList-icon" />}
                                    <p className="NotificationsList-text">{`${notification.title}`}</p>
                                </Link>
                            </MenuItem>

                            {index === notifications!.length - infinityThreshold &&
                                index < notificationsListStore!.count - infinityThreshold &&
                                <Waypoint onEnter={() => notificationsListStore!.page! += 1} />}
                        </React.Fragment>)
                }

                {notificationsListStore!.isFetching && <Loader />}
                {!notificationsListStore!.notifications.length && <p className="NotificationsList-text">Brak powiadomień</p>}
            </div>
        )
    }
}

export const NotificationsList = inject('notificationsListStore', 'unreadNotificationsCountStore')(observer(UndecoratedNotificationsList))
