import { FieldState, FormState } from 'formstate/lib';
import { action, makeObservable } from 'mobx';

import { DeviceTypeStore } from '../DeviceTypeStore/DeviceTypeStore';
import { invalidNoWhitespacesLength, notEmpty, notRegex } from '../Form/validators';
import { GatewaysStore } from '../GatewaysStore/GatewaysStore';

export interface EnterDeviceManuallyResult {
    productNumber: string;
    serialNumber: string;
}

export class EnterDeviceManuallyStore {

    form = new FormState({
        productNumber: new FieldState('').validators(
            notEmpty('Numer produktu nie może być pusty'),
            notRegex(new RegExp(/^[A-Za-z0-9\s]+$/), 'Numer produktu zawiera niedozwolone znaki'),
            invalidNoWhitespacesLength(10, 'Numer produktu powinien zawierać dokładnie 10 znaków'),
            (value: string) => {
                if (!(this.gatewaysStore.gateways && this.gatewaysStore.gateways.length) && this.deviceTypeStore.isGatewayNeeded(value)) {
                    return 'Niepoprawny numer centralki'
                }
                return null
            }
        ),
        serialNumber: new FieldState('').validators(
            notEmpty('Numer seryjny nie może być pusty'),
            notRegex(new RegExp(/^[A-Fa-f0-9\s]+$/), 'Numer seryjny zawiera niedozwolone znaki'),
            invalidNoWhitespacesLength(16, 'Numer seryjny powinien zawierać dokładnie 16 znaków'),
        ),
    });

    constructor(
        private deviceTypeStore: DeviceTypeStore,
        private gatewaysStore: GatewaysStore
    ) {
        makeObservable(this, {
            save: action
        });
    }

    async save(onSuccess: (enteredCode: EnterDeviceManuallyResult) => void) {
        const { hasError } = await this.form.validate();

        if (!hasError) {
            const productNumber = this.form.$.productNumber.$.toUpperCase().replace(/ /g, '');
            const serialNumber = this.form.$.serialNumber.$.toUpperCase().replace(/ /g, '');
            const isValidDeviceType = await this.deviceTypeStore.isValidDeviceType(productNumber);

            if (isValidDeviceType) {
                this.form.reset();
                onSuccess({
                    productNumber: productNumber,
                    serialNumber: serialNumber
                });
            } else {
                this.form.$.productNumber.error = "Niepoprawny numer produktu"
            }
        }
    }


}