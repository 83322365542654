import * as React from 'react';
import './styles.scss';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AddDeviceStore } from '../../../stores/AddDeviceStore/AddDeviceStore';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';

export interface AddDeviceSuccessProps {
    onAddAnotherDeviceWasClicked(): void;
    addDeviceStore?: AddDeviceStore;
}

export const AddDeviceSuccess = inject('addDeviceStore')(observer(
    ({ onAddAnotherDeviceWasClicked, addDeviceStore }: AddDeviceSuccessProps) => (
        <FullScreenContainer>
            <div className="AddDeviceSuccess-content">
                <div className="AddDeviceSuccess-header">Udało się!</div>
                <div>Dodano nowy element zestawu Smart Home:</div>
                <div><strong>{addDeviceStore!.addedDeviceName}</strong></div>
            </div>
            <div>
                <div className="AddDeviceSuccess-question">Czy chcesz dodać kolejne urządzenie?</div>

                <Button onClick={onAddAnotherDeviceWasClicked}>Tak</Button>
                <Link to="/devices"><Button secondary>Nie</Button></Link>
            </div>
        </FullScreenContainer>
    )
));
