import * as React from 'react'
import {
	Route,
	Switch,
	Redirect
} from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import '../../styles/index.scss';
import './style.scss';
import { UserStore } from '../../stores/UserStore/UserStore';
import RoutedHeader from '../Header/RoutedHeader';
import { PushNotificationsStore } from '../../stores/PushNotificationsStore/PushNotificationStore';
import { PersonalDataConsent } from '../PersonalDataConsent/PersonalDataConsent';
import { hasPersonalDataConsentForm } from '../../utils/config';
import { Notifications } from '../Notifications/Notifications';
import { AvailableFeaturesStore } from '../../stores/AvailableFeatures/AvailableFeaturesStore';
import { Loader } from '../Loader/Loader';
import { AppConfig } from '../../stores/AppConfig/AppConfig';
import { Typography } from '@material-ui/core';
import { configure } from "mobx"
import { Navigation } from '../Navigation/Navigation';
import { MainMenu } from '../Navigation/MainMenu';

configure({
	enforceActions: "never",
})
export interface AppProps {
	availableFeaturesStore: AvailableFeaturesStore;
	appConfig: AppConfig;
	userStore: UserStore;
	consumptionStore?: any;
	pushNotificationsStore?: PushNotificationsStore;
}

export interface AppState {
	menuOpen: boolean;
	showFooter: boolean;
	navigationHeader?: any;
	isUserLoggedIn?: boolean;
}

export const App = inject('availableFeaturesStore', 'appConfig', 'userStore', 'consumptionStore', 'pushNotificationsStore')(observer(class App extends React.Component<AppProps, AppState> {
	private prevHeight: number = window.innerHeight;
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false,
			showFooter: true,
		};
	};

	componentDidMount() {
		if (this.props.userStore!.isLoggedIn) {
			this.props.pushNotificationsStore!.getPermission();
		}

		window.addEventListener('resize', () => {
			if (window.innerHeight >= this.prevHeight) {
				this.setState({ showFooter: true });
			} else {
				this.setState({ showFooter: false });
			}

			this.prevHeight = window.innerHeight;
		});
	}

	handleMenuOpenChange = (state?: boolean) => {
		this.setState({ menuOpen: state ?? !this.state.menuOpen });
	};

	logoutHandler = () => {
		this.props.userStore!.login.logout();
	};

	render() {
		const { availableFeaturesStore, userStore } = this.props;
		return (userStore.isLoggedIn && (
			<React.Fragment>
				{availableFeaturesStore.fetchingAvailableFeatures && this.getConcreteMixer()}
				{availableFeaturesStore.failedAvailableFeaturesFetch && this.getErrorMessageComponent()}
				{availableFeaturesStore.didAvailableFeaturesFetch && this.main()}
			</React.Fragment>
		));
	}

	main() {
		const { appConfig } = this.props;
		const enabledModuleObjects = appConfig.getEnabledModules();
		return (
			<div className="appWraper">
				<div className="app">
					<Notifications />
					<RoutedHeader />
					<Switch>
						{enabledModuleObjects.map(enabledModule =>
							enabledModule.component && <Route path={enabledModule.url} exact={enabledModule.exact} key={enabledModule.url}
								component={enabledModule.component} />
						)}
						<Route path="/consent" component={PersonalDataConsent} />
						{hasPersonalDataConsentForm
							&& this.props.userStore!.acceptedPersonalDataConsent === false
							&& <Redirect to="/consent" />
						}
						<Redirect to={appConfig.getDefaultPath()} />
					</Switch>
				</div>
				<MainMenu
					menuState={this.state.menuOpen}
					onMenuChange={this.handleMenuOpenChange}
					logoutHandler={this.logoutHandler}
				/>
				<Navigation
					menuState={this.state.menuOpen}
					onMenuClick={this.handleMenuOpenChange}
				/>
				{/* {this.state.showFooter && <RoutedFooter />} */}
			</div>
		);
	}

	private getErrorMessageComponent() {
		return <div className="available-features-error">
			<Typography variant="subtitle1">Skontaktuj się z administratorem aplikacji</Typography>
		</div >
	}

	private getConcreteMixer() {
		return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
			<Loader size={160} />
		</div >
	}
}));

export const routeComponentWrapper = (C: React.ComponentType<any>) => (p: any) => <C {...p} />
