export interface RoomTypes {
    "Bedroom": string;
    "Living Room": string;
    "Kitchen": string;
    "Bathroom": string;
    "Entrance / Hall": string;
    "Basement": string;
    "Utility Room": string;
    "Patio": string;
    "Office": string;
}

export const roomTypes: RoomTypes = {
    "Bedroom": "Sypialnia",
    "Living Room": "Salon",
    "Kitchen": "Kuchnia",
    "Bathroom": "Łazienka",
    "Entrance / Hall": "Wejście / Korytarz",
    "Basement": "Piwnica",
    "Utility Room": "Pomieszczenie gospodarcze",
    "Patio": "Patio",
    "Office": "Biuro"
}