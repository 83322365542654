import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Button } from '../Button';
import { match as Match, Redirect } from 'react-router-dom';
import { Header } from '../Text/Header/Header';
import './style.scss';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { MetersStore } from '../../stores/MetersStore/MetersStore';
import { observer, inject } from 'mobx-react';
import { ConnectingDeviceStore } from '../../stores/ConnectingDeviceStore/ConnectingDeviceStore';
import {
    CorrectOpticInstallInstruction,
    FirstOpticIncorrectInstallInstruction, MagneticInstallInstruction,
    SecondOpticIncorrectInstallInstruction
} from '../AddMeterInstruction/AddMeterInstruction';
import { MeterKind } from "../../domain/MeterKind";
import { SingleDevice } from "../../stores/SingleDevice/SingleDevice";
import { MeterAttachmentType } from '../../domain/MeterAttachmentType';
import { ReconnectDeviceFirstTime } from '../ReconnectDevice/ReconnectDeviceFirstTime/ReconnectDeviceFirstTime';
import { ReconnectDeviceSecondTime } from '../ReconnectDevice/ReconnectDeviceSecondTime/ReconnectDeviceSecondTime';

export interface ReconnectMeterProps {
    meterKind: MeterKind;
    metersStore?: MetersStore;
    match: Match<{ deviceId: string }>;
}

export interface Props {
    connectingDeviceStore?: ConnectingDeviceStore;
    device: SingleDevice;
}

export const ReconnectMeter = inject('metersStore')(observer(({ metersStore, meterKind, match }: ReconnectMeterProps) => {
    const device = metersStore!.getMeterById(match.params.deviceId);

    if (!device || !device.connection.failed) return <Redirect to={`/devices/${match.params.deviceId}`} />;

    const isEMI = device.subclass === MeterAttachmentType.EMI;

    if (isEMI) {
        if (device.connection.attempts === 1) {
            return <ReconnectDeviceFirstTime device={device} urlBack="/devices/meters" />;
        } else {
            return <ReconnectDeviceSecondTime device={device} urlBack="/devices/meters" />;
        }
    }

    const isHAN = device.subclass === 'han';

    if (isHAN) {
        return <ReconnectHANMeter device={device} />
    }

    const isOptic = device.calibrationOffset === null && device.calibrationFactor === null;

    return isOptic ?
        <ReconnectOpticMeter device={device} /> :
        <ReconnectMagneticMeter device={device} />;
}));

const ReconnectOpticMeter = inject('metersStore', 'connectingDeviceStore')(observer(({ connectingDeviceStore, device }: Props) => {

    if (!device || !device.connection.failed) return <Redirect to={`/devices/${device.id}`} />;

    return (
        <FullScreenContainer>
            <div className="ReconnectMeter-content">
                <Paragraph>Łączenie z Lerta Meter trwa zbyt długo?</ Paragraph>
                <Header>Sprawdź poprawność zamontowania Lerta Meter na liczniku.</Header>
                <Paragraph className="ReconnectMeter-smallParagraph">Poprawny montaż urządzenia:</Paragraph>
                <CorrectOpticInstallInstruction />
                <Paragraph className="ReconnectMeter-smallParagraph">Niepoprawny montaż urządzenia:</Paragraph>
                <FirstOpticIncorrectInstallInstruction />
                <SecondOpticIncorrectInstallInstruction />
            </div>
            <Button onClick={() => connectingDeviceStore!.restartConnecting(device.id)}>Gotowe</Button>
        </FullScreenContainer>
    );
}));

const ReconnectMagneticMeter = inject('metersStore', 'connectingDeviceStore')(observer(({ connectingDeviceStore, device }: Props) => {

    if (!device || !device.connection.failed) return <Redirect to={`/devices/${device.id}`} />;

    return (
        <FullScreenContainer>
            <div className="ReconnectMeter-content">
                <Paragraph>Łączenie z Lerta Meter trwa zbyt długo?</ Paragraph>
                <Header>Sprawdź poprawność zamontowania Lerta Meter na liczniku.</Header>
                <Paragraph className="ReconnectMeter-smallParagraphCentered">Lerta Meter powinien być przyklejony powierzchnią samoprzylepną pod panelem pomiarowym:</Paragraph>
                <MagneticInstallInstruction />
            </div>
            <Button onClick={() => connectingDeviceStore!.restartConnecting(device.id)}>Gotowe</Button>
        </FullScreenContainer>
    );
}));

const ReconnectHANMeter = inject('metersStore', 'connectingDeviceStore')(observer(({ connectingDeviceStore, device }: Props) => {

    if (!device || !device.connection.failed) return <Redirect to={`/devices/${device.id}`} />;

    return (
        <FullScreenContainer>
            <div className="ReconnectMeter-content">
                <Paragraph>Łączenie z HAN Meter trwa zbyt długo?</ Paragraph>
                <Header>Jeśli to możliwe przenieś centralkę bliżej licznika oraz sprawdź czy opcja HAN jest włączona w systemie Tauron e-bok.</Header>
            </div>
            <Button onClick={() => connectingDeviceStore!.restartConnecting(device.id)}>Gotowe</Button>
        </FullScreenContainer>
    );
}));