import * as React from 'react';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';
import { Paragraph } from '../Text/Paragraph/Paragraph';
import { Button } from '../Button';
import { Link } from 'react-router-dom';

export interface Props {
    onRetry(): void;
    meterWithIdAlreadyExists: boolean;
}

export const Fail = ({onRetry, meterWithIdAlreadyExists}: Props) => (
    <FullScreenContainer gradient>
        <div>
            <Header bold>Wystąpił błąd!</Header>
            <Paragraph>Nie udało się dodać nowego licznika.</Paragraph>
            {meterWithIdAlreadyExists && <Paragraph>Licznik został już wcześniej dodany.</Paragraph>}
        </div>
        <div>
            {!meterWithIdAlreadyExists && <Button onClick={onRetry}>Spróbuj ponownie</Button>}
            <Link to="/devices/meters"><Button secondary>Wróć do listy</Button></Link>
        </div>
    </FullScreenContainer>
)
