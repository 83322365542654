import './styles.scss';

import { CircularProgress, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { AddDeviceStore } from '../../../stores/AddDeviceStore/AddDeviceStore';
import { Button } from '../../Button';
import { Input } from '../../Input';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Gateway } from '../../../infrastructure/GatewaysApiResponse';
import { roomTypes } from '../../../stores/SingleDevice/RoomTypes';

export interface Props {
    gateways: Gateway[] | null;
    onDeviceWasAdded(): void;
    addDeviceStore?: AddDeviceStore;
}

export const AddDeviceForm = inject('addDeviceStore')(observer(class AddDeviceForm extends React.Component<Props> {

    componentDidMount() {
        const { addDeviceStore, gateways } = this.props;
        const g = (gateways || []).filter(g => g.paired)
        if (g && g.length === 1) {
            addDeviceStore!.form.$.gatewayId.value = g[0].id
        }

    }

    componentWillUnmount() {
        this.props.addDeviceStore!.resetForm();
    }

    render() {
        const { onDeviceWasAdded, addDeviceStore, gateways } = this.props;

        const dictionary = addDeviceStore!.addedDeviceDictionary;

        if (dictionary !== null) {
            return (<FullScreenContainer>
                <p className="AddDeviceForm-header">Nowe urządzenie!</p>
                <p className="AddDeviceForm-paragraph">
                    Rozpoznano<br />
                    <strong className="AddDeviceForm-serialNumber">{dictionary.addDeviceFormName}</strong>
                    <br /><br />
                    o numerze seryjnym: <br />
                    <span className="AddDeviceForm-serialNumber">{addDeviceStore!.code.serialNumber}</span>
                </p>
                <form className="AddDeviceForm-form" onSubmit={(e) => {
                    e.preventDefault();
                    addDeviceStore!.addDevice(onDeviceWasAdded);
                }} >
                    <Input
                        value={addDeviceStore!.form.$.name.value}
                        placeholder={`Np. ${dictionary.suggestedDeviceName}`}
                        error={addDeviceStore!.form.$.name.error}
                        label="Nazwa urządzenia"
                        onChange={addDeviceStore!.form.$.name.onChange}
                    />

                    <FormControl error={!!addDeviceStore!.form.$.roomType.error}>
                        <InputLabel>{"Typ pomieszczenia"}</InputLabel>
                        <Select
                            value={addDeviceStore!.form.$.roomType.value}
                            onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                addDeviceStore!.form.$.roomType.value = e.target.value;
                                addDeviceStore!.form.$.roomType.onChange(e.target.value);
                            }}
                        >
                            {Object.keys(roomTypes).map(key => <MenuItem key={key} value={key}>{roomTypes[key]}</MenuItem>)}
                        </Select>
                        {<FormHelperText color="error">{addDeviceStore!.form.$.roomType.error}</FormHelperText>}
                    </FormControl>

                    {gateways && <FormControl error={!!addDeviceStore!.form.$.gatewayId.error}>
                        <InputLabel>{"Centralka"}</InputLabel>
                        <Select
                            value={addDeviceStore!.form.$.gatewayId.value}
                            onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                addDeviceStore!.form.$.gatewayId.value = e.target.value;
                                addDeviceStore!.form.$.gatewayId.onChange(e.target.value);
                            }}
                        >
                            {gateways.filter(g => g.paired).map((item, key) => <MenuItem key={key} value={item.id}>{item.serialNumber}</MenuItem>)}
                        </Select>
                        {<FormHelperText color="error">{addDeviceStore!.form.$.gatewayId.error}</FormHelperText>}
                    </FormControl>}

                    {addDeviceStore!.triedToAddSameDevice &&
                        <div className="AddDeviceForm-error">To urządzenie jest już dodane.</div>}
                    {addDeviceStore!.addingDeviceFailed && !addDeviceStore!.triedToAddSameDevice &&
                        <div className="AddDeviceForm-error">Nie udało się dodać urządzenia.</div>}
                    <Button loading={addDeviceStore!.addingDevice}>Dalej</Button>
                </form>
            </FullScreenContainer>);
        }

        return (<CircularProgress size={25} className="Button-spinner" />);
    }
}));
