import { observable, computed, action, makeObservable } from 'mobx';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { AccountDetailsUpdate } from './AccountDetailsUpdate';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { UserStore } from '../UserStore/UserStore';
import { isFetching, fetched, getValue, fetchingFailed } from '../../utils/PromiseBasedObservable';

export interface AccountDetails {
    userId: string;
    externalId: string;
    firstName: string;
    surname: string;
    address: string;
    category: string;
    area: number;
    numberOfPeople: number;
}

export class AccountDetailsStore {

    accountDetailsPromise: IPromiseBasedObservable<AccountDetails> | null = null;

    update: AccountDetailsUpdate;

    constructor(private httpService: HttpService, private urlProvider: UrlProvider, private userStore: UserStore) {
        makeObservable(this, {
            accountDetailsPromise: observable,
            isFetching: computed,
            isFailed: computed,
            didFetch: computed,
            data: computed,
            availableCategories: computed,
            setFromApiResponse: action.bound
        });

        this.update = new AccountDetailsUpdate(httpService, urlProvider, this);
    }

    get isFetching(): boolean {
        return isFetching(this.accountDetailsPromise);
    }

    get isFailed(): boolean {
        return fetchingFailed(this.accountDetailsPromise);
    }

    get didFetch(): boolean {
        return fetched(this.accountDetailsPromise);
    }

    get data(): AccountDetails | null {
        if (!this.accountDetailsPromise) {
            return null
        }
        return getValue<AccountDetails>(this.accountDetailsPromise) || null;
    }

    get availableCategories(): string[] {
        let availableCategories = ['', 'dom', 'mieszkanie'];
        this.data && this.data!.category && (availableCategories.indexOf(this.data!.category) === -1) && availableCategories.push(this.data!.category);
        return availableCategories;
    }

    async fetchAccountDetails(): Promise<any> {
        if (this.userStore.userId !== null) {
            this.accountDetailsPromise = fromPromise(this.httpService.get<AccountDetails>(
                this.urlProvider.getAccountDetailsUrl(this.userStore.userId)
            ));
            return this.accountDetailsPromise;
        }
        return {}
    }

    setFromApiResponse(promise: IPromiseBasedObservable<AccountDetails>) {
        this.accountDetailsPromise = promise;
    }
}
