import { Button, ButtonGroup } from '@material-ui/core';
import React from 'react';
import './styles.scss'

interface Props {
    items: {
        label: string;
        value: string;
    }[];
    value: string;
    onChange(value: string): void;
}

export const Switch = ({ items, value, onChange }: Props) => {
    return (
        <ButtonGroup color="primary" className="Switch-btnGroup">
            {items.map(item => <Button
                key={item.value}
                variant={item.value === value ? "contained" : "outlined"}
                onClick={() => onChange(item.value)}
            >{item.label}</Button>)}
        </ButtonGroup>
    )
}