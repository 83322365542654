import { DeviceData, DeviceDataDisplay } from './DeviceData';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { SingleDevice } from '../SingleDevice/SingleDevice';
import { vocFormatter } from '../../utils/formatters';
import { MinMaxAvgHelpLZO } from '../../components/DeviceData/DataHighlights/MinMaxAvgHelpLZO';
import { DateMode } from '../../domain/DateMode';

const Voc = require('../../static/icons/voc_icon.svg');

const display: DeviceDataDisplay = {
    routeName: 'voc',
    displayName: "Poziom LZO",
    tooltipName: "lzo",
    Logo: Voc,
    DataHighlights: MinMaxAvgHelpLZO,
    chartType: 'line',
    chartUnit: "ppb",
    chartReferenceLines: [{
        value: 65,
        label: "Doskonała",
        color: '#5BB152',
    }, {
        value: 220,
        label: "Dobra",
        color: "#94C356",
    }, {
        value: 660,
        label: "Umiarkowana",
        color: "#FFC300",
    }, {
        value: 2200,
        label: "Kiepska",
        color: "#F28D2D",
    }, {
        value: 5500,
        label: "Niezdrowa",
        color: "#D54939",
    }],
    displayUnit: "ppb",
    chartRange: [0, 100],
    valueFormatter: vocFormatter,
    showAvg: true,
};

export class VocData extends DeviceData {

    constructor(httpService: HttpService, private urlProvider: UrlProvider, device: SingleDevice) {
        super(httpService, device, display);
    }

    getDataUrl(dateStart: Date, dateEnd: Date) {
        return this.urlProvider.getDeviceDataUrl(
            this.device.id,
            'voc',
            dateStart.toISOString(),
            dateEnd.toISOString(),
            this.dateMode === DateMode.Daily ? 'hour_halves' : 'day'
        );
    }
}
