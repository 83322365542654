import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MeterRegisterStore } from '../../../stores/MeterRegisterStore/MeterRegisterStore';
import { Instruction } from './Instruction';
import { CalibrationParameters } from '../../../domain/CalibrationParameters';
import { Scanner } from './Scanner';
import { MeterInitialValue } from './MeterInitialValue';
import { GatewaysStore } from '../../../stores/GatewaysStore/GatewaysStore';
import { ConnectGateway } from './ConnectGateway';
import { WhereIsCode } from './WhereIsCode';

export interface Props {
	meterRegisterStore?: MeterRegisterStore;
	gatewaysStore?: GatewaysStore;
	onRegisterMeter(calibrationParameters: CalibrationParameters): void;
	onCodeWasScanned(code: string): void;
}

export interface State {
	display: Display;
	calibrationParameters: CalibrationParameters | null;
}

type Display = 'where-is-code' | 'scanner' | 'initial-value' | 'instruction';

export const AddEMI = inject('meterRegisterStore', 'gatewaysStore')(observer(class AddEMI extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			display: 'where-is-code',
			calibrationParameters: null,
		};
	}

	handleScan = (code: string) => {
		this.props.onCodeWasScanned(code);
		this.setState({ display: 'initial-value' });
	}

	handleInitialValue = (calibrationParameters: CalibrationParameters) => {
		this.setState({ calibrationParameters, display: 'instruction' });
	}

	handleInstructionClick = () => {
		if (!this.state.calibrationParameters) {
			return;
		}
		this.props.onRegisterMeter(this.state.calibrationParameters);
	}

	render() {
		const gatewaysStore = this.props.gatewaysStore!;

		if (gatewaysStore.hasConnectedGateway === false) {
			return <ConnectGateway />;
		}

		switch (this.state.display) {
			case 'where-is-code':
				return (
					<WhereIsCode onNextWasClicked={() => this.setState({ display: 'scanner' })} />
				);
			case 'scanner':
				return (
					<Scanner
						onScan={this.handleScan}
						onWhereIsCode={() => this.setState({ display: 'where-is-code' })}
					/>
				);
			case 'initial-value':
				return <MeterInitialValue onSubmit={this.handleInitialValue} />;
			case 'instruction':
				return <Instruction onNext={this.handleInstructionClick} />;
		}
	}
}));
