import * as React from 'react';
import './style.scss';
import { AppHeader } from '../Header/New';
import { RouteComponentProps } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { NotificationStore } from '../../stores/NotificationStore/NotificationStore';
import { Loader } from '../Loader/Loader';

interface Props extends RouteComponentProps<{ id: string }> {
    notificationStore?: NotificationStore;
}

export const NotificationDetails = inject('notificationStore')(observer(class NotificationDetails extends React.Component<Props> {

    componentDidMount() {
        const { notificationStore } = this.props;
        notificationStore!.notificationId = this.props.match.params.id
    }

    render() {
        const { notificationStore, history } = this.props;
        return (
            <React.Fragment>
                <AppHeader onGoBackClick={() => history.push("/notifications")}>
                    {(notificationStore!.notification && notificationStore!.notification!.title)}
                </AppHeader>
                <div className="NotificationsList-container">

                    {notificationStore!.isFetching && <Loader />}

                    {notificationStore!.isFailed && <p>Brak danych</p>}

                    {notificationStore!.didFetch && notificationStore!.notification && notificationStore!.notification!.message}

                </div>
            </React.Fragment>
        )
    }
}));
