import * as React from 'react';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';
import { Tile } from '../../Tile/Tile';
import { DotWave } from '../../DotWave/DotWave';
import './styles.scss';
import { observer } from 'mobx-react';

export interface Props {
    device: SingleDevice;
}

export const Meter = observer(({ device }: Props) => {
    const MeterIcon = (className: string) => {
        if (device.dictionary.deviceLogo !== '') {
            const Icon = require(`../../../static/icons/${device.dictionary.deviceLogo}`)
            return <Icon className={className} />
        }
        return <></>
    }
    return (
        <Tile href={`/devices/${device.id}`}>
            {MeterIcon(`Meter-logo ${device.connection.exists === false ? 'Meter-connectingLogo' : ''}`)}
            <div>{device.name || device.dictionary.defaultName}</div>
            {device.connection.attempting && <div className="Meter-connecting">Trwa łączenie<DotWave /></div>}
            {device.connection.failed && <div className="Meter-connecting">Nie udało się połączyć</div>}
        </Tile>
    )
});
