import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { AvailableNetwork, AvailableNetworksApiResponse } from "../../infrastructure/AvailableNetworksApiResponse";
import { observable, action, computed, autorun, makeObservable } from "mobx";
import { wait } from "../../utils/Promise";
import { Input } from "../Form/Input";
import { notWhitespaceOnly } from "../Form/validators";
import { Form } from "../Form/Form";

export class Network implements AvailableNetwork {
    ssid: string;
    signalStrength: number;
    encryption: string;

    wrongPassword: boolean = false;

    settingAsUsed: boolean = false;
    checkingPassword: boolean = false;

    password: Input = new Input(notWhitespaceOnly('Hasło nie może być puste.'));
    private form: Form = new Form([this.password]);

    constructor(
        { ssid, signalStrength, encryption }: AvailableNetwork,
        private httpService: HttpService,
        private urlProvider: UrlProvider
    ) {
        makeObservable(this, {
            ssid: observable,
            signalStrength: observable,
            encryption: observable,
            wrongPassword: observable,
            settingAsUsed: observable,
            checkingPassword: observable,
            password: observable
        });

        this.ssid = ssid;
        this.signalStrength = signalStrength;
        this.encryption = encryption;

        autorun(() => {
            let password = this.password.value;
            this.wrongPassword = false;
            return password
        })
    }

    async setAsUsed(onSuccess: () => void = () => { }, onFail: () => void = () => { }) {
        return this.form.submit(async () => {
            this.wrongPassword = false;
            this.checkingPassword = true;

            const setUrl = this.urlProvider.getSetUsedNetworkUrl();
            try {
                await this.httpService.makeRequest(
                    setUrl,
                    'PUT',
                    {
                        autoConnect: true,
                        connected: false,
                        encryption: this.encryption,
                        password: this.password.value,
                        signalStrength: 0,
                        ssid: this.ssid
                    },
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    { mode: 'cors' }
                );
                this.checkingPassword = false;
            } catch (e) {
                this.form.resetForm();
                this.wrongPassword = true;
                this.checkingPassword = false;
                return;
            }

            this.settingAsUsed = true;

            const getUrl = this.urlProvider.getUsedNetworkUrl();

            let numberOfConsecutiveSuccesses = 0;
            for (let madeRequests = 0; madeRequests < 12; madeRequests++) {
                try {
                    const response = await this.httpService.makeRequest<{ connected: boolean }>(
                        getUrl,
                        'GET',
                        undefined,
                        {
                            'Accept': 'application/json',
                        },
                        { mode: 'cors' }
                    )

                    if (response.connected) {
                        numberOfConsecutiveSuccesses++;
                    } else {
                        numberOfConsecutiveSuccesses = 0;
                    }

                    if (numberOfConsecutiveSuccesses === 3) {
                        this.settingAsUsed = false;
                        onSuccess();
                        return;
                    }
                } catch (e) {
                    numberOfConsecutiveSuccesses = 0;
                }
                await wait(5000);
            }
            onFail();
            this.settingAsUsed = false;
        });
    }

    static fromAvailableNetwork(httpService: HttpService, urlProvider: UrlProvider) {
        return (availableNetwork: AvailableNetwork) => new Network(availableNetwork, httpService, urlProvider);
    }
}

export class GatewayWifiConfigStore {

    networkPassword: string = '';

    selectedNetwork: Network | null = null;

    availableNetworks: Network[] = [];
    loadingAvailableNetworks: boolean = false;

    get errors(): string[] {
        if (this.networkPassword === '') {
            return ['Hasło nie może być puste.'];
        }

        return [];
    }

    get noNetworksFound(): boolean {
        return !this.loadingAvailableNetworks && this.availableNetworks.length === 0;
    }

    constructor(private httpService: HttpService, private urlProvider: UrlProvider) {
        makeObservable(this, {
            networkPassword: observable,
            selectedNetwork: observable,
            availableNetworks: observable,
            loadingAvailableNetworks: observable,
            errors: computed,
            noNetworksFound: computed,
            getAvailableNetworks: action.bound
        });
    }

    async getAvailableNetworks() {
        this.loadingAvailableNetworks = true;
        this.availableNetworks = [];
        const url = this.urlProvider.getAvailableNetworksUrl();

        for (let madeRequests = 0; madeRequests < 3; madeRequests++) {
            try {
                const result = await this.httpService
                    .makeRequest<AvailableNetworksApiResponse>(url, 'GET', undefined, {
                        'Accept': 'application/json',
                    }, { mode: 'cors' });
                if (result.length !== 0) {
                    this.availableNetworks = result.map(Network.fromAvailableNetwork(this.httpService, this.urlProvider));
                    this.loadingAvailableNetworks = false;
                    return;
                }
            } catch (e) { }
            await wait(5000);
        }

        this.loadingAvailableNetworks = false;
    }
}
