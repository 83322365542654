import * as React from 'react';
import {FullScreenContainer} from '../FullScreenContainer/FullScreenContainer';
import {Button} from '../Button';
import {Paragraph} from '../Text/Paragraph/Paragraph';

export interface Props {
    onRetry(): void;

    onClose(): void;

    errorType: string;
}

export class Error extends React.Component<Props> {

    render() {
        const {onRetry, onClose, errorType} = this.props;
        switch (errorType) {
            case 'conflict':
                return (
                    <FullScreenContainer>
                        <Paragraph>Ta centralka jest już zarejestrowana.</Paragraph>
                        <div>
                            <Button onClick={onRetry}>Spróbuj ponownie</Button>
                            <Button onClick={onClose} secondary>Przejdź do listy urządzeń</Button>
                        </div>
                    </FullScreenContainer>
                );
            default:
                return (
                    <FullScreenContainer>
                        <Paragraph>
                            Nie udało się zarejestrować centralki. <br/>
                            Upewnij się, że skanujesz odpowiedni kod i masz połączenie z internetem.
                        </Paragraph>
                        <Button onClick={onRetry}>Spróbuj ponownie</Button>
                    </FullScreenContainer>
                );
        }
    }
}
