import { Input } from "./Input";
import { computed, makeObservable } from "mobx";

export class Form {
    constructor(private inputs: Input[]) {
        makeObservable(this, {
            hasErrors: computed
        });
    }

    get hasErrors() {
        return this.inputs.some(input => input.error !== null);
    }

    resetForm() {
        this.inputs.forEach(input => input.validated = false);
        this.inputs.forEach(input => input.value = '');
    }

    async submit(submitAction: () => Promise<void>) {
        this.inputs.forEach(input => input.validated = true);

        if (!this.hasErrors) {
            return submitAction();
        }
    }
}
