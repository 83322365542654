import * as React from 'react';
import { Button } from '../../Button';
import './styles.scss';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';

const Image = require('../../../static/pudelko_dataMatrix.svg');

export interface WhereIsCodeProps {
    onNextWasClicked(): void;
    onEnterManuallyWasClicked(): void;
}

export const WhereIsCode = ({ onNextWasClicked, onEnterManuallyWasClicked }: WhereIsCodeProps) => (
    <FullScreenContainer backUrl="/devices/smart_home" gradient>
        <div className="WhereIsCode-header">Gdzie znaleźć kod urządzenia?</div>
        <Image />
        <div className="WhereIsCode-paragraph">Aby dodać urządzenie musisz zeskanować kod załączony na jego opakowaniu.</div>
        <Button onClick={onNextWasClicked}>Skanuj</Button>
        <Button outlined small onClick={onEnterManuallyWasClicked}>Wprowadź kod ręcznie</Button>
    </FullScreenContainer>
);