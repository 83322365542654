import * as React from 'react';
import './style.scss';
import { OverlayFrame } from './OverlayFrame';
import { Daily } from './Daily';
import { Monthly } from './Monthly';
import { DateMode } from '../../domain/DateMode';

export interface DateOptionsOverlayProps {
    onClose?: (chosenDate?: Date) => void;
    onDateWasChosen: (date: Date, dateMode: DateMode) => void;
    initialDate?: Date;
}

export interface DateOptionsOverlayState {
    display: 'main' | 'daily' | 'monthly' ;
}

export class DateOptionsOverlay extends React.Component<DateOptionsOverlayProps, DateOptionsOverlayState> {
    constructor(props) {
        super(props);

        this.state = {
            display: 'main'
        };

        this.goToMain = this.goToMain.bind(this);
        this.goToDaily = this.goToDaily.bind(this);
        this.goToMonthly = this.goToMonthly.bind(this);
        this.confirmDaily = this.confirmDaily.bind(this);
        this.confirmMonthly = this.confirmMonthly.bind(this);
    }

    goToMain() {
        this.setState({display: 'main'});
    }

    goToDaily() {
        this.setState({display: 'daily'}); 
    }

    goToMonthly() {
        this.setState({display: 'monthly'});  
    }

    confirmDaily(chosenDate: Date) {
        this.props.onClose && this.props.onClose();
        this.props.onDateWasChosen(chosenDate, DateMode.Daily);
    }

    confirmMonthly(chosenDate: Date) {
        this.props.onClose && this.props.onClose();
        this.props.onDateWasChosen(chosenDate, DateMode.Monthly);
    }

    render() {
        const {onClose, initialDate} = this.props;

        switch (this.state.display) {
            case 'main':
                return (
                    <OverlayFrame title="Przedział czasowy" onCancel={onClose} onMaskClick={onClose} >
                        <div className="DateOptionsOverlay-option" onClick={this.goToDaily}>Dzienny</div>
                        <div className="DateOptionsOverlay-option" onClick={this.goToMonthly}>Miesięczny</div>
                    </OverlayFrame>
                );
            case 'monthly':
                return <Monthly onCancel={this.goToMain} onConfirm={this.confirmMonthly} initialDate={initialDate} onClose={onClose} />
            case 'daily':
                return <Daily onCancel={this.goToMain} onConfirm={this.confirmDaily} initialDate={initialDate} onClose={onClose} />
        }
    }
}
