import * as React from 'react';
import { match as Match, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { SmartDevicesStore } from '../../stores/SmartDevicesStore/SmartDevicesStore';
import { ReconnectDeviceFirstTime } from './ReconnectDeviceFirstTime/ReconnectDeviceFirstTime';
import { ReconnectDeviceSecondTime } from './ReconnectDeviceSecondTime/ReconnectDeviceSecondTime';

export interface ReconnectDeviceProps {
    match: Match<{ deviceId: string }>;
    smartDevicesStore?: SmartDevicesStore;
}

export const ReconnectDevice = inject('smartDevicesStore')(observer(
    ({ match, smartDevicesStore }: ReconnectDeviceProps) => {
        const device = smartDevicesStore!.getDeviceById(match.params.deviceId);
        if (!device || device.paired || !device.connection.failed) {
            return <Redirect to={`/devices/${match.params.deviceId}`} />;
        }
        if (device.connection.attempts === 1) {
            return <ReconnectDeviceFirstTime device={device} urlBack="/devices/smart_home" />;
        } else {
            return <ReconnectDeviceSecondTime device={device} urlBack="/devices/smart_home" />;
        }
    }
));
