import { observable, action, computed, makeObservable } from "mobx";
import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { required, validPassword, passwordMatch } from "../Form/validators";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { fetchingFailed, isFetching } from "../../utils/PromiseBasedObservable";
import { UserDataApiResponse } from "../../infrastructure/UserDataApiResponse";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { NewPassword } from "./NewPassword";
import { TokenDecoder } from "../../domain/TokenDecoder";
import { FieldState, FormState } from 'formstate';
import { config } from '../../utils/config';
import { configure } from "mobx"

configure({
    enforceActions: "never",
})
export interface PWAIsSupportedByThisBrowser {
    (): boolean;
}

export class UserStore {
    login: Login;
    userId: string | null = null;
    isLoggedIn: boolean = false;
    firstLogin: boolean = false;
    isLoggedInFromRegistration: boolean = false;
    forgotPassword: ForgotPassword;
    newPassword: NewPassword;

    activationForm = new FormState({
        password: new FieldState('').validators(
            required("Hasło nie może być puste."),
            validPassword("Hasło musi składać się z przynajmniej 8 znaków, 1 wielkiej litery oraz 1 cyfry.")),
        confirmPassword: new FieldState('').validators(
            required("Hasło nie może być puste."))

    }).validators(passwordMatch("Podane hasła muszą być identyczne."));

    private activationPromise: IPromiseBasedObservable<void> | null = null;

    async activate(email: string, token: string) {
        const body: any = {
            "email": email,
            "activationCode": token,
        }
        if (!config.availableRoutes.register) {
            const { hasError } = await this.activationForm.validate();
            if (!hasError) {
                body.password = this.activationForm.$.password.$
                this.sendRequest(body)
            }
        } else {
            this.sendRequest(body)
        }

    }

    private sendRequest(body: any) {
        this.activationPromise = fromPromise(
            this.httpService.makeRequest(
                this.urlProvider.getActivateUserUrl(),
                'POST',
                body,
                { 'Content-Type': 'application/json' }
            )
        );
        this.activationForm.reset();
    }

    get didActivate() {
        return this.activationPromise !== null && this.activationPromise.state === 'fulfilled';
    }

    get isActivating() {
        return isFetching(this.activationPromise);
    }

    get activationFailed() {
        return fetchingFailed(this.activationPromise);
    }

    get activationTokenExpired(): boolean {
        return this.activationPromise !== null && this.activationPromise.state === 'rejected' && (this.activationPromise.value as {status: number}).status === 410;
    }

    get userDataPromise(): IPromiseBasedObservable<UserDataApiResponse> | null {
        if (this.userId === null) {
            return null;
        }

        return fromPromise(this.httpService.get(this.urlProvider.getUserDataUrl(this.userId)));
    }

    get userData(): UserDataApiResponse | null {
        if (this.acceptPersonalDataConsentPromise !== null && this.acceptPersonalDataConsentPromise.state === 'fulfilled') {
            return this.acceptPersonalDataConsentPromise.value;
        }

        if (this.userDataPromise === null || this.userDataPromise.state !== 'fulfilled') {
            return null;
        }

        return this.userDataPromise.value;
    }

    get acceptedPersonalDataConsent(): boolean | null {
        if (this.userData === null) {
            return null;
        }

        return this.userData.acceptedPersonalDataConsent || false;
    }

    get acceptingPersonalDataConsent(): boolean {
        if (this.acceptPersonalDataConsentPromise !== null && this.acceptPersonalDataConsentPromise.state === 'pending') {
            return true;
        }

        return false;
    }

    acceptPersonalDataConsentPromise: IPromiseBasedObservable<UserDataApiResponse> | null = null;

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private storageService: Storage,
        private tokenDecoder: TokenDecoder,
        private pwaIsSupportedByThisBrowser: PWAIsSupportedByThisBrowser
    ) {
        makeObservable<UserStore, "activationPromise">(this, {
            login: observable,
            userId: observable,
            isLoggedIn: observable,
            firstLogin: observable,
            isLoggedInFromRegistration: observable,
            forgotPassword: observable,
            newPassword: observable,
            activationPromise: observable,
            activate: action.bound,
            didActivate: computed,
            isActivating: computed,
            activationFailed: computed,
            activationTokenExpired: computed,
            userDataPromise: computed,
            userData: computed,
            acceptedPersonalDataConsent: computed,
            acceptingPersonalDataConsent: computed,
            acceptPersonalDataConsentPromise: observable,
            acceptPersonalDataConsent: action.bound,
            setFirstLoginToFalse: action.bound,
            noReloadLogout: action.bound
        });

        this.login = new Login(httpService, urlProvider, storageService, tokenDecoder, this);
        this.forgotPassword = new ForgotPassword(httpService, urlProvider, this);
        this.newPassword = new NewPassword(httpService, urlProvider, this);
    }

    init() {
        const token = this.storageService.getItem('auth_token');
        if (token) {
            this.setToken(token);
            this.isLoggedIn = true;
        }

        const firstLoginValue = this.storageService.getItem('firstLogin');

        if (this.pwaIsSupportedByThisBrowser() && firstLoginValue === null) {
            this.firstLogin = true;
        }
    }

    acceptPersonalDataConsent() {
        if (this.userId === null) {
            return;
        }

        this.acceptPersonalDataConsentPromise = fromPromise(
            this.httpService.patch(this.urlProvider.getPatchUserUrl(this.userId), {
                acceptedPersonalDataConsent: true
            })
        );
    }

    setFirstLoginToFalse() {
        this.firstLogin = false;
        this.storageService.setItem('firstLogin', 'false');
    }

    setToken(token: string) {
        const { userId } = this.tokenDecoder(token);
        this.userId = userId;
    }

    noReloadLogout() {
        this.storageService.removeItem('auth_token');
        this.isLoggedIn = false;
    }
}
