import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { BalanceData } from 'src/stores/Statistics/BalanceStore';
import { seriesColor } from '../../../SeriesColor';

const ProductionIcon = require('../../../static/icons/production.svg');
const ImportToGridIcon = require('../../../static/icons/exportToGrid.svg');
const AutoConsumptionIcon = require('../../../static/icons/autoConsumption.svg');
const ExportFromGridIcon = require('../../../static/icons/importFromGrid.svg');


interface Props {
    balanceData: BalanceData
}

export const formatNumber = (value = 0) => {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

const percentageOfValue = (value: number = 0, max: number) => {
    return value && max ? (Math.round((value / max) * 100)) : 0;
}

export const BalanceChart = ({ balanceData }: Props) => {
    const max = (balanceData.autoConsumption || 0) + Math.max((balanceData.importFromGrid || 0), (balanceData.exportToGrid || 0))

    return <div className="Balance-chart">
        <div className="Balance-legend">
            <div className="Balance-legend-item">
                <Typography variant="caption" >
                    <FormattedMessage id="statistics.production" />
                </Typography>
                <Typography className="bold">
                    {formatNumber(balanceData.production)} {balanceData.unit}
                </Typography>
                <div>
                    <ProductionIcon style={{ color: seriesColor("production") }} />
                </div>
            </div>
            <div className="Balance-legend-item">
                <Typography variant="caption" >
                    <FormattedMessage id="statistics.exportToGrid" />
                </Typography>
                <Typography className="bold" >
                    {formatNumber(balanceData.exportToGrid)} {balanceData.unit}
                </Typography>
                <Typography className="sub-value" display="block" variant="caption" >
                    {formatNumber(percentageOfValue(balanceData.exportToGrid, max))}%
                </Typography>
                <div>
                    <ImportToGridIcon style={{ color: seriesColor("exportToGrid") }} />
                </div>
            </div>
        </div>

        <div className="Balance-charts">
            <div className="chart">
                <div className="autoConsumption" style={{ background: seriesColor("autoConsumption"), width: `${percentageOfValue(balanceData.autoConsumption, max)}%` }}></div>
                <div className="exportToGrid" style={{ background: seriesColor("exportToGrid"), width: `${percentageOfValue(balanceData.exportToGrid, max)}%` }}></div>
                <div className="production" style={{ borderColor: seriesColor("production"), width: `${percentageOfValue(balanceData.production, max)}%` }}></div>
            </div>
            <div className="chart">
                <div className="autoConsumption" style={{ background: seriesColor("autoConsumption"), width: `${percentageOfValue(balanceData.autoConsumption, max)}%` }}></div>
                <div className="importFromGrid" style={{ background: seriesColor("importFromGrid"), width: `${percentageOfValue(balanceData.importFromGrid, max)}%` }}></div>
            </div>
        </div>
        <div className="Balance-legend">
            <div className="Balance-legend-item">
                <div>
                    <AutoConsumptionIcon style={{ color: seriesColor("autoConsumption") }} />
                </div>
                <Typography variant="caption" >
                    <FormattedMessage id="statistics.autoConsumption" />
                </Typography>
                <Typography className="bold">
                    {formatNumber(balanceData.autoConsumption)} {balanceData.unit}
                </Typography>
                <Typography className="sub-value" display="block" variant="caption" >
                    {formatNumber(percentageOfValue(balanceData.autoConsumption, max))}%
                </Typography>
            </div>
            <div className="Balance-legend-item">
                <div>
                    <ExportFromGridIcon style={{ color: seriesColor("importFromGrid") }} />
                </div>
                <Typography variant="caption" >
                    <FormattedMessage id="statistics.importFromGrid" />
                </Typography>
                <Typography className="bold">
                    {formatNumber(balanceData.importFromGrid)} {balanceData.unit}
                </Typography>
                <Typography className="sub-value" display="block" variant="caption" >
                    {formatNumber(percentageOfValue(balanceData.importFromGrid, max))}%
                </Typography>
            </div>
        </div>
    </div>
}