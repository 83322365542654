import './style.scss';
import { NoDevices } from "./NoDevices/NoDevices";
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Device } from './Device/Device';
import { SmartDevicesStore } from '../../stores/SmartDevicesStore/SmartDevicesStore';
import { FetchingDevicesFailed } from './FetchingDevicesFailed/FetchingDevicesFailed';
import { AddDevicePanel } from '../AddDevicePanel/AddDevicePanel';
import { Loader } from '../Loader/Loader';
import { GatewaysStore } from '../../stores/GatewaysStore/GatewaysStore';
import { Gateway } from './Gateway/Gateway';

export interface SmartDevicesProps {
	smartDevicesStore?: SmartDevicesStore;
	gatewaysStore?: GatewaysStore;
}

export const SmartDevices = inject('smartDevicesStore', 'gatewaysStore')(observer(class SmartDevices extends React.Component<SmartDevicesProps> {
	render() {
		const { smartDevicesStore, gatewaysStore } = this.props;
		const { devices, fetching, fetchingFailed: failed } = smartDevicesStore!;

		if (gatewaysStore!.fetchingGatewaysFailed) {
			return (
				<FetchingDevicesFailed onRetry={gatewaysStore!.refetchGateways}>
					Nie udało się wyświetlić listy urządzeń. <br /> Upewnij się, że masz połączenie z Internetem.
				</FetchingDevicesFailed>
			);
		}

		if (failed) {
			return (
				<FetchingDevicesFailed onRetry={smartDevicesStore!.refetchDevices}>
					Nie udało się wyświetlić listy urządzeń. <br /> Upewnij się, że masz połączenie z Internetem.
				</FetchingDevicesFailed>
			);
		}

		const gateways = gatewaysStore!.gateways || []

		return (
			<div className="SmartDevices-container">

				{(gateways || []).map(gateway => (<React.Fragment key={gateway.id}>
					<Gateway gateway={gateway} />
					<div>
						{this.getDevicesForGateway(devices, gateway)}
					</div>
				</React.Fragment>))}
				{(fetching || gateways.length !== 0) && <AddDevicePanel url="/add_device" />}

				{fetching && <Loader />}
				
				{!fetching && gatewaysStore!.hasGateway === false && devices.length === 0 &&
					<NoDevices url="/add_device">Nie masz jeszcze dodanej centralki. Kliknij "+" aby ją dodać.</NoDevices>
				}
			</div>
		);
	}
	getDevicesForGateway(devices, gateway) {
		const d = devices.filter((device => device.externalId === gateway.externalId))
		return d.map((device, key) => <Device key={device.id} className={`${"lastChild"}`} device={device} />)

	}
}));;
