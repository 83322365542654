import * as React from 'react';
import { observer } from 'mobx-react';
import "react-toggle-switch/dist/css/switch.min.css";
import { Gateway as IGateway } from '../../../infrastructure/GatewaysApiResponse';
import "./style.scss"
import { Link } from 'react-router-dom';

const Warning = require('../../../static/icons/warning_trangle.svg');

export interface GatewayProps {
    gateway: IGateway;
}
const GatewayIcon = require('../../../static/icons/gateway.svg');

export const Gateway = observer(({ gateway }: GatewayProps) => (
    <div className={`Gateway-container ${!gateway.paired ? 'Gateway-notPaired' : ''}`}>
        {gateway.paired ?
            <GatewayContent gateway={gateway} /> :
            <Link to="/add_gateway/notPaired" ><GatewayContent gateway={gateway} /></Link>}
    </div>
));


const GatewayContent = observer(({ gateway }: GatewayProps) => {
    return (
        <React.Fragment>
            <div className="Gateway-icon"><GatewayIcon /></div>
            <div className="Gateway-description">
                <div className="Gateway-name">{`Centralka ${(gateway.serialNumber || '').slice(gateway.serialNumber.length - 4)}`}</div>
            </div>
            {!gateway.paired && <div className="Gateway-icon"><Warning /></div>}
        </React.Fragment>
    );
});
