import { SingleDevice } from './SingleDevice';
import { UrlProvider } from '../../services/UrlProvider';
import { HttpService } from '../../services/HttpService';
import { EnergyUsageData } from '../DeviceData/EnergyUsageData';

export class SmartcableDevice extends SingleDevice {
    constructor(urlProvider: UrlProvider, httpService: HttpService, storage: Storage) {
        super(urlProvider, httpService, storage);
        
        this.power = '-';
        this.switched = false;

        this.energyUsageData = new EnergyUsageData(httpService, urlProvider, this);

        this.dictionary = {
            addDeviceFormName: "przewód",
            suggestedDeviceName: "Przewód zasilania ...",
            connectDevicePrompt: () => null,
            reconnectDevicePrompt: () => null,
            reconnectDeviceSecondPrompt: () => null,
            instructions: {
                instruction: () => null,
                reconnect: () => null,
                factoryReset: () => null,
            },
            defaultName: "Przewód bez nazwy",
            deviceLogo: 'smart_cable.svg',
        };
    }
}
