import * as React from 'react';
import './AddMeter.scss';
import { Button } from '../Button';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { Header } from '../Text/Header/Header';

const Image = require('../../static/lerta_meter_top_view_code.svg');

export interface WhereIsCodeProps {
    onNextWasClicked(): void;
    onEnterManuallyWasClicked?(): void;
}

export const WhereIsMeterCode = ({ onNextWasClicked, onEnterManuallyWasClicked }: WhereIsCodeProps) => (
    <FullScreenContainer gradient>
        <Header>Zeskanuj za pomocą aparatu kod umieszczony na obudowie Lerta Meter.</Header>
        <div className="WhereIsMeterCode-subheader">Gdzie znaleźć kod?</div>
        <Image className="WhereIsMeterCode-image" />
        <div className="WhereIsMeterCode-paragraph">Kliknij przycisk <b>"SKANUJ"</b> aby rozpocząć skanowanie kodu.</div>
        <Button onClick={onNextWasClicked}>Skanuj</Button>
        {onEnterManuallyWasClicked && <Button outlined small onClick={onEnterManuallyWasClicked}>Wprowadź kod ręcznie</Button>}
    </FullScreenContainer>
);