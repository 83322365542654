const unitToReadableUnitMap = {
    'm2': 'm²',
    'm3': 'm³',
    'C': '°C'
};

export const unitToReadableUnit = (unit: string | null | undefined): string => {
    if (unit && unitToReadableUnitMap[unit]) {
        return unitToReadableUnitMap[unit]
    }
    return unit || '';
};