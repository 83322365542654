import { FieldState, FormState } from 'formstate';

import { SmartDevicesItem } from '../../infrastructure/SmartDevicesApiResponse';
import { HttpService } from '../../services/HttpService';
import { UrlProvider } from '../../services/UrlProvider';
import { notEmpty, notWhitespaceOnly } from '../Form/validators';
import { action, observable, computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { SmartDevicesStore } from '../SmartDevicesStore/SmartDevicesStore';
import { DeviceDictionary, SingleDevice } from '../SingleDevice/SingleDevice';
import { SingleDeviceFactory } from '../SingleDevice/SingleDeviceFactory';
import { LocationsStore } from '../LocationsStore/LocationsStore';
import { DeviceTypeStore } from '../DeviceTypeStore/DeviceTypeStore';
import { parseProductNumber, parseSerialNumber } from '../../utils/productCodes';

export class AddDeviceStore {
    form = new FormState({
        name: new FieldState('').validators(notEmpty('Nazwa urządzenia nie może być pusta'),
            notWhitespaceOnly('Nazwa urządzenia nie może składać się z białych znaków')),
        gatewayId: new FieldState('').validators(notEmpty('Centralka nie może być pusta')),
        roomType: new FieldState('').validators(notEmpty('Typ pomieszczenia nie może być pusty'))
    });

    addedDeviceName: string | null = null

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private smartDevicesStore: SmartDevicesStore,
        private singleDeviceFactory: SingleDeviceFactory,
        private locationsStore: LocationsStore,
        private deviceTypesStore: DeviceTypeStore
    ) {
        makeObservable<AddDeviceStore, "addDevicePromise">(this, {
            originalCode: observable,
            addDevicePromise: observable,
            addingDeviceFailed: computed,
            triedToAddSameDevice: computed,
            addedDeviceName: observable,
            addingDevice: computed,
            code: computed,
            addedDevicePromise: computed,
            addedDeviceDictionary: computed,
            resetForm: action,
            addDevice: action.bound
        });
    }

    originalCode: string = "|||";
    private addDevicePromise: IPromiseBasedObservable<{ id: string, name: string, class: string, subclass: string | undefined }> | null = null;

    get addingDeviceFailed(): boolean {
        return this.addDevicePromise !== null && this.addDevicePromise.state === 'rejected';
    }

    get triedToAddSameDevice(): boolean {
        return this.addDevicePromise !== null && this.addDevicePromise.state === 'rejected' && (this.addDevicePromise.value as { status: number }).status === 409;
    }

    get addingDevice(): boolean {
        if (this.addDevicePromise === null || this.addDevicePromise.state !== 'pending') {
            return false;
        }

        return true;
    }

    get code() {
        const serialNumber = parseSerialNumber(this.originalCode);
        const productNumber = parseProductNumber(this.originalCode);
        return {
            serialNumber: serialNumber,
            productNumber: productNumber
        };
    }

    get addedDevicePromise(): IPromiseBasedObservable<SingleDevice> | null {
        const deviceTypeDictionary = this.deviceTypesStore.deviceTypeDictionary;

        if (deviceTypeDictionary === null) {
            return null;
        }

        const deviceType = deviceTypeDictionary.find(({ key }) => key === this.code.productNumber);

        if (!deviceType) {
            return null;
        }

        return fromPromise(
            this.singleDeviceFactory.fromDeviceClass(
                deviceType.value.class,
                deviceType.value.subclass
            )
        );
    }

    get addedDeviceDictionary(): DeviceDictionary | null {
        if (this.addedDevicePromise === null || this.addedDevicePromise.state !== 'fulfilled') {
            return null;
        }
        return this.addedDevicePromise.value.dictionary;
    }

    async isValidDeviceCode(code: string) {
        const productNumber = parseProductNumber(code);
        return this.deviceTypesStore.isValidDeviceType(productNumber);
    }

    resetForm() {
        this.addDevicePromise = null;
        this.form.reset();
    }

    async addDevice(onSuccess: () => void = () => { }): Promise<any> {
        const { hasError } = await this.form.validate();

        if (!hasError) {
            let trimmedName = this.form.$.name.$.trim();
            try {
                const currentLocationId = await this.locationsStore.getCurrentLocationId();
                if (currentLocationId === null) {
                    return;
                }

                const url = this.urlProvider.getAddDeviceUrl(currentLocationId);

                await (this.addDevicePromise = fromPromise(this.httpService.post<SmartDevicesItem>(url, {
                    name: trimmedName,
                    serialNumber: this.code.serialNumber,
                    productNumber: this.code.productNumber,
                    gatewayId: this.form.$.gatewayId.$,
                    roomType: this.form.$.roomType.$,
                    zoneId: currentLocationId
                }).then(response => {
                    onSuccess();
                    this.smartDevicesStore.refetchDevices();
                    
                    this.addedDeviceName = response.name

                    return ({ id: response.id, name: response.name, class: response.class, subclass: response.subclass })
                }).catch(err => {
                    throw err
                })));
                this.form.reset();
            } catch { }
        }
    }
}
