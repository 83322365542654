import * as React from 'react';
import './AddMeter.scss';
import { observer } from 'mobx-react';
import { Scanner } from './Scanner';
import { MeterKind } from './MeterKind';
import { MeterKind as MeterKindType } from '../../domain/MeterKind';
import { ChooseMeterAttachment } from './ChooseMeterAttachment';
import { Instruction } from './Instruction';
import { MeterType as MeterTypeType } from '../../domain/MeterType';
import { MagneticMeterInitialValue } from './MagneticMeterInitialValue';
import { CalibrationParameters } from '../../domain/CalibrationParameters';
import { WhereIsMeterCode } from './WhereIsMeterCode';

export interface Props {
	meterType: MeterTypeType;
	onRegisterMeter(calibrationParameters?: CalibrationParameters): void;
	onCodeWasScanned(code: string): void;
}

export interface State {
	meterKind: MeterKindType;
	display: Display;
}

type Display = 'meter-kind' | 'choose-meter-attachment' | 'where-is-code' | 'scanner' | 'instruction' | 'initial-value';

export const AddLertaMeter = observer(class AddLertaMeter extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			meterKind: MeterKindType.Optic,
			display: 'meter-kind',
		};
	}

	handleInstructionClick = () => {
		if (this.state.meterKind === MeterKindType.Magnetic) {
			this.setState({ display: 'initial-value' });
		} else {
			this.props.onRegisterMeter();
		}
	};

	handleScan = (code: string) => {
		this.props.onCodeWasScanned(code);
		this.setState({ display: 'instruction' });
	}

	render() {
		switch (this.state.display) {
			case 'meter-kind':
				return (
					<MeterKind
						meterType={this.props.meterType}
						onKindWasChosen={meterKind => this.setState({ meterKind, display: 'choose-meter-attachment' })}
					/>
				);
			case 'choose-meter-attachment':
				return (
					<ChooseMeterAttachment
						meterKind={this.state.meterKind}
						onClick={() => this.setState({ display: 'where-is-code' })}
					/>
				);
			case 'where-is-code':
				return (
					<WhereIsMeterCode onNextWasClicked={() => this.setState({ display: 'scanner' })} />
				);
			case 'scanner':
				return (
					<Scanner
						onShowWhereIsCode={() => this.setState({ display: 'where-is-code' })}
						onScan={this.handleScan}
					/>
				);
			case 'instruction':
				return (
					<Instruction
						meterType={this.props.meterType}
						meterKind={this.state.meterKind}
						onClick={this.handleInstructionClick}
					/>
				);
			case 'initial-value':
				return <MagneticMeterInitialValue meterType={this.props.meterType} onSubmit={this.props.onRegisterMeter} />;
		}
	}
});
