import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Header } from '../../Text/Header/Header';
import { Paragraph } from '../../Text/Paragraph/Paragraph';
import { Button } from '../../Button';

export interface Props {
    error: 'invalid-code' | 'fail';
    onRetry(): void;
}

export const ScanError = ({onRetry, error}: Props) => (
    <FullScreenContainer gradient>
        {error === 'invalid-code' ? <InvalidCodeContent /> : <FailContent />}
        <Button onClick={onRetry}>Spróbuj ponownie</Button>
    </FullScreenContainer>
);

export const InvalidCodeContent = () => (
    <div>
        <Header>Zeskanowano niepoprawny kod produktu!</Header>
        <Paragraph>Upewnij się czy skanujesz kod urządzenia smart home.</Paragraph>
    </div> 
);

export const FailContent = () => (
    <div>
        <Header>Coś poszło nie tak podczas skanowania produktu!</Header>
        <Paragraph>Upewnij się, że jesteś podłączony do internetu, lub spróbuj ponownie później.</Paragraph>
    </div>
);
