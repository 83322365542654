import * as React from 'react';
import { observer } from 'mobx-react';
import { DeviceData } from '../../../stores/DeviceData/DeviceData';
import { DataHighlight } from '../DataHighlight/index';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';
import { TemperatureControl as Control } from '../../SmartDevices/Device/Subheading/TemperatureControl/TemperatureControl';

export interface CurMinMaxAvgProps {
    device: SingleDevice;
    deviceData: DeviceData;
}

export const TemperatureControl = observer(({ device }: CurMinMaxAvgProps) => (
    <React.Fragment>
        <DataHighlight
            label="Bieżąca"
            unit="°"
            color="secondary"
        >{device.temperature}</DataHighlight>
        <Control device={device}>
            <DataHighlight
                label="Zadana"
                unit="°"
                color="primary"
            >{device.targetTemperature}</DataHighlight>
        </Control>
    </React.Fragment>
));
