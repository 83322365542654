import * as React from 'react';
import './style.scss';
import { Input } from '../Input';
import { observer, inject } from 'mobx-react';
import { AccountDetailsStore } from '../../stores/AccountDetailsStore/AccountDetailsStore';
import { InputAdornment, Select, FormControl, MenuItem, InputLabel, Button } from '@material-ui/core';
import { unitToReadableUnit } from '../../utils/unitToReadableUnit';
import { Loader } from '../Loader/Loader';

export interface AccountDetailsProps {
    accountDetailsStore: AccountDetailsStore;
}

export const AccountDetails = inject('accountDetailsStore')(
    observer(class AccountDetails extends React.Component<AccountDetailsProps> {

        async componentDidMount() {
            await this.props.accountDetailsStore.fetchAccountDetails()
            this.props.accountDetailsStore.update.resetForm();
        }

        render() {
            const { accountDetailsStore } = this.props;
            const update = accountDetailsStore.update;

            if (accountDetailsStore.isFetching) {
                return (
                    <div className="AccountDetails-container">
                        <Loader />
                    </div>
                );
            }

            if (accountDetailsStore.isFailed) {
                return (
                    <div className="AccountDetails-container">
                        <div className="AccountDetails-container__noData">Brak danych</div>
                    </div>
                )
            }

            return (
                <div className="AccountDetails-container">
                    <form className="AccountDetails-container__content-wrapper" >
                        <div>
                            <Input
                                autoFocus
                                label="Imię"
                                value={update.form.$.firstName.value}
                                error={update.form.$.firstName.error}
                                onChange={update.form.$.firstName.onChange}
                            />
                            <Input
                                label="Nazwisko"
                                value={update.form.$.surname.value}
                                error={update.form.$.surname.error}
                                onChange={update.form.$.surname.onChange}
                            />
                            <Input
                                label="Adres"
                                rows={2}
                                value={update.form.$.address.value}
                                error={update.form.$.address.error}
                                onChange={update.form.$.address.onChange}
                            />
                            <FormControl error={!!update.form.$.category.error} className="AccountDetails-container__select-wrapper">
                                <InputLabel classes={{ focused: "AccountDetails-container__select-wrapper-focused" }}>{"Kategoria"}</InputLabel>
                                <Select
                                    value={update.form.$.category.value}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                        update.form.$.category.value = e.target.value;
                                        update.form.$.category.onChange(e.target.value);
                                    }}
                                >
                                    {accountDetailsStore.availableCategories.map((item, key) => <MenuItem key={key} value={item}>{item}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <Input
                                label="Powierzchnia"
                                value={update.form.$.area.value}
                                error={update.form.$.area.error}
                                onChange={update.form.$.area.onChange}
                                InputProps={{ endAdornment: <InputAdornment position="end">{unitToReadableUnit("m3")}</InputAdornment> }}
                            />
                            <Input
                                label="Liczba osób"
                                value={update.form.$.numberOfPeople.value}
                                error={update.form.$.numberOfPeople.error}
                                onChange={update.form.$.numberOfPeople.onChange}
                            />

                            {update.failed &&
                                <div className="AccountDetails-error">Edycja nie powiodła się. <br />Spróbuj ponownie albo poczekaj i spróbuj później.</div>
                            }

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => update.updateAccountDetails()}
                                className="AccountDetails-container__button"
                                fullWidth
                            >Zapisz</Button>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => update.resetForm()}
                                className="AccountDetails-container__button"
                                fullWidth
                            >Anuluj</Button>
                        </div>
                    </form>
                </div>
            )
        }
    })
);
