import * as React from 'react';
import { FullScreenContainer } from '../../FullScreenContainer/FullScreenContainer';
import { Link } from 'react-router-dom';
import { Button } from '../../Button';
import { Paragraph } from '../../Text/Paragraph/Paragraph';

export const NoGatewayError = () => (
    <FullScreenContainer>
        <Paragraph>
            Nie masz jeszcze dodanej centralki.
        </Paragraph>
        <div>
            <Link className="NoGatewayError-addGateway" to="/add_gateway"><Button>Dodaj centralkę</Button></Link>
            <Link to="/settings"><Button secondary>Wróć</Button></Link>
        </div>
    </FullScreenContainer>
);
