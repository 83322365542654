import { stringToNumber } from "../../utils/stringToNumber";

export const notEmpty = (errMsg: string) => (value: string) => value === "" ? errMsg : null;

export const notChecked = (errMsg: string) => (value: boolean) => value === false ? errMsg : null;

export const notRegex = (regex: RegExp, errMsg: string) => (value: string) => regex.test(value) ? null : errMsg;

export const invalidNoWhitespacesLength = (len: number, errMsg: string) => (value: string) => value.replace(/ /g, '').length === len ? null : errMsg;

export const notWhitespaceOnly = (errMsg: string) => (value: string) => /^\s*$/.test(value) ? errMsg : null;

export const positiveNumericString = (errMsg: string) => (value: string) => {
    let number = stringToNumber(value);

    return (Number.isNaN(number) || number <= 0) ? errMsg : null;
};

export const nonNegativeNumericString = (errMsg: string) => (value: string) => {
    let number = stringToNumber(value);

    return (Number.isNaN(number) || number < 0) ? errMsg : null;
};

export const required = (msg: string) => (item: string) => {
    return item === "" ? msg : null;
};

export const passwordMatch = (msg: string) => ($: any) => {
    return $.password.value !== $.confirmPassword.value ? msg : null;
};

export const validPassword = (msg: string) => (item: string) => {
    return (item.length < 8 || item.search(/[A-Z]/) === -1 || item.search(/[0-9]/) === -1) ? msg : null;
};

export const validEmail = (msg: string) => (item: string) => {
    return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(item) ? null : msg;
};

export const isInteger = () => (value: string) => {
    let pattern = /^\d+$/
    return pattern.test(`${value}`) ? null : 'Wartość musi być liczbą całkowitą'
}