import * as React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { Subheading } from './Subheading/Subheading';
import { TemperatureControl } from './Subheading/TemperatureControl/TemperatureControl';
import { SingleDevice } from '../../../stores/SingleDevice/SingleDevice';
import { observer } from 'mobx-react';
import Switch from "react-toggle-switch";
import "react-toggle-switch/dist/css/switch.min.css";
import { DotWave } from '../../DotWave/DotWave';
import { FailedConnectionWarning } from './FailedConnectionWarning/FailedConnectionWarning';
import { temperatureFormatter, humidityFormatter } from '../../../utils/formatters';

export interface DeviceProps {
    device: SingleDevice;
    className?: string;
}

export interface SubheadingType {
    title: string;
    value: React.ReactNode;
    steering?: boolean;
}

export const Device = observer(({ device, className }: DeviceProps) => {
    const subheadings: SubheadingType[] = [];

    if (device.paired || device.connection.exists) {
        if (device.temperature !== null) {
            if (device.targetTemperature !== null) {
                subheadings.push({
                    title: 'T. bieżąca:',
                    value: device.temperature === '-' ? '-' : `${temperatureFormatter(device.temperature)}°`
                });
            } else {
                subheadings.push({
                    title: 'Temperatura:',
                    value: device.temperature === '-' ? '-' : `${temperatureFormatter(device.temperature)}°`
                });
            }
        }

        if (device.targetTemperature !== null) {
            subheadings.push({
                title: 'T. zadana:',
                value: device.targetTemperature === '-' ? '-' : `${temperatureFormatter(device.targetTemperature)}°`,
                steering: true
            });
        }

        if (device.humidity !== null) {
            subheadings.push({
                title: 'Wilgotność:',
                value: device.humidity === '-' ? '-' : `${humidityFormatter(device.humidity)}%`
            });
        }

        if (device.airQuality !== null) {
            subheadings.push({
                title: 'Jakość powietrza:',
                value: device.airQuality === '-' ? '-' : getAirQualityTranslation(device.airQuality)
            });
        }

        if (device.power !== null) {
            subheadings.push({
                title: 'Pobór mocy:',
                value: device.power === '-' ? '-' : `${device.power}ᵂ`
            });
        }

        if (device.opened !== null) {
            subheadings.push({
                title: 'Stan:',
                value: device.opened === '-' ? '-' : (device.opened ? 'Otwarte' : 'Zamknięte')
            });
        }

        if (device.alarm) {
            subheadings.push({
                title: 'Stan:',
                value: `Alarm`
            });
        }
    } else {
        !device.connection.failed && subheadings.push({
            title: 'Stan:',
            value: <span>Trwa łączenie<DotWave /></span>
        });
    }

    const DeviceLogo = () => {
        if (device.dictionary.deviceLogo !== '') {
            const Icon = require(`../../../static/icons/${device.dictionary.deviceLogo}`)
            return <Icon />
        }
        return <></>
    }

    return (
        <div className={`Device-container ${device.alarm ? 'Device-alarm' : ''} ${!device.paired && !device.connection.exists ? 'Device-disconnected' : ''} ${className} `}>
            <Link
                className="Device-link"
                to={{
                    pathname: `/devices/${device.id}`,
                    state: { name: device.name, deviceType: device.class, deviceSubclass: device.subclass }
                }}
            >
                <>
                    <div className="Device-logo">{DeviceLogo()}</div>
                    <div className="Device-description">
                        <div className="Device-name">{device.name || 'Brak nazwy'}</div>
                        {subheadings.length !== 0 && <div className="Device-subheadings">
                            {subheadings.map(subheading => subheading.steering
                                ? <TemperatureControl
                                    key={subheading.title}
                                    device={device}
                                >
                                    <Subheading
                                        key={subheading.title}
                                        alarm={device.alarm}
                                        disconnected={!device.connection.exists}
                                        title={subheading.title}
                                        value={typeof subheading.value === 'number' ? temperatureFormatter(subheading.value) : subheading.value}
                                    />
                                </TemperatureControl>
                                : <Subheading
                                    key={subheading.title}
                                    alarm={device.alarm}
                                    disconnected={!device.connection.exists}
                                    {...subheading}
                                />)}
                        </div>}
                    </div>
                </>
            </Link>
            {(device.paired || device.connection.exists) && device.switched !== null && <div className={`Device-switch ${device.uncontrolled ? 'Device-switch-uncontrolled' : ''} `}>
                <Switch on={device.switched} onClick={device.toggle} />
            </div>}
            {!device.paired && device.connection.failed && <FailedConnectionWarning device={device} />}
        </div>
    );
});

function getAirQualityTranslation(airQuality: string): string {
    switch (airQuality) {
        case 'unhealthy':
            return 'Niezdrowa'
        case 'poor':
            return 'Kiepska'
        case 'moderate':
            return 'Umiarkowana'
        case 'good':
            return 'Dobra'
        case 'excellent':
            return 'Doskonała'
        default:
            return airQuality
    }
}