import * as React from 'react';

export interface Props {
    instructions: React.ReactNode[];
}

export const InstructionsList = ({instructions}: Props) => (
    <div>
        {instructions.map((instruction, index) => (
            <div key={index} className="Instructions-instruction">
                <div className="Instructions-number">{index + 1}</div><div className="Instructions-content">{instruction}</div>
            </div>
        ))}
    </div>
)
