import { HttpService } from "../../services/HttpService";
import { UrlProvider } from "../../services/UrlProvider";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { computed, observable, onBecomeObserved, action, makeObservable } from "mobx";
import { MetersStore } from "../MetersStore/MetersStore";
import { MeterType } from "../../domain/MeterType";
import { CalibrationParameters } from "../../domain/CalibrationParameters";
import { LocationsStore } from "../LocationsStore/LocationsStore";
import { MeterAttachmentType } from "../../domain/MeterAttachmentType";
import { parseSerialNumber, parseProductNumber } from "../../utils/productCodes";

export class MeterRegisterStore {
    meterTypesPromise: IPromiseBasedObservable<MeterType[]> | null = null;

    get meterTypes(): MeterType[] | null {
        if (this.meterTypesPromise === null || this.meterTypesPromise.state !== 'fulfilled') {
            return null;
        }

        return this.meterTypesPromise.value;
    }

    get fetchingMeterTypes(): boolean {
        return this.meterTypesPromise !== null && this.meterTypesPromise.state === 'pending';
    }

    meterAttachmentTypesPromise: IPromiseBasedObservable<MeterAttachmentType[]> | null = null;

    get meterAttachmentTypes(): MeterAttachmentType[] | null {
        if (this.meterAttachmentTypesPromise === null || this.meterAttachmentTypesPromise.state !== 'fulfilled') {
            return null;
        }

        return this.meterAttachmentTypesPromise.value;
    }

    get fetchingMeterAttachmentTypes(): boolean {
        return this.meterAttachmentTypesPromise !== null && this.meterAttachmentTypesPromise.state === 'pending';
    }

    registerMeterPromise: IPromiseBasedObservable<void> | null = null;

    get registeringMeter(): boolean {
        return this.registerMeterPromise !== null && this.registerMeterPromise.state === 'pending';
    }

    get registeringMeterFailed(): boolean {
        return this.registerMeterPromise !== null && this.registerMeterPromise.state === 'rejected';
    }

    get meterWithIdAlreadyExists(): boolean {
        return this.registerMeterPromise !== null &&
            this.registerMeterPromise.state === 'rejected' &&
            (this.registerMeterPromise.value as {status: number}).status === 409;
    }

    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private metersStore: MetersStore,
        private locationsStore: LocationsStore,
    ) {
        makeObservable(this, {
            meterTypesPromise: observable,
            meterTypes: computed,
            fetchingMeterTypes: computed,
            meterAttachmentTypesPromise: observable,
            meterAttachmentTypes: computed,
            fetchingMeterAttachmentTypes: computed,
            registerMeterPromise: observable,
            registeringMeter: computed,
            registeringMeterFailed: computed,
            meterWithIdAlreadyExists: computed,
            registerMeter: action,
            registerHANMeter: action
        });

        onBecomeObserved(this, 'meterTypesPromise', () => {
            this.meterTypesPromise = fromPromise(this.httpService.get(this.urlProvider.getSupplyPointTypesUrl()));
        });

        onBecomeObserved(this, 'meterAttachmentTypesPromise', () => {
            this.meterAttachmentTypesPromise = fromPromise(this.httpService.get(this.urlProvider.getMeterAttachmentTypesUrl()));
        });
    }

    async registerMeter(
        id: string,
        attachmentType: MeterAttachmentType,
        type: MeterType,
        calibrationParameters?: CalibrationParameters
    ): Promise<void> {
        const locationId = await this.locationsStore.getCurrentLocationId();

        this.registerMeterPromise = fromPromise(
            this.httpService.post(
                this.urlProvider.getMeterRegisterUrl(attachmentType),
                attachmentType === MeterAttachmentType.LertaMeter ?
                    this.createLertaMeterPostBody(id, type, locationId || undefined, calibrationParameters) :
                    this.createEMIPostBody(id, type, locationId || undefined, calibrationParameters)
            )
        );

        await this.registerMeterPromise;
        await this.locationsStore.retryFetchingLocations();
        await this.metersStore.retryFetchingMeterDevices();
    }

    async registerHANMeter(
        code: string,
        name: string,
        attachmentType: MeterAttachmentType,
        gatewayId: string
    ): Promise<void> {
        const locationId = await this.locationsStore.getCurrentLocationId();
        const splittedCode = code.split(';')
        const body = {
            meterSerialNumber: splittedCode[2],
            communicationAddress: splittedCode[1],
            encryptionKey: splittedCode[0],
            commodityType: "electricity",
            gatewayId,
            locationId,
            name,
            productNumber: attachmentType
        }

        this.registerMeterPromise = fromPromise(
            this.httpService.post(
                this.urlProvider.getMeterRegisterUrl(attachmentType),
                body
            )
        );

        await this.registerMeterPromise;
        await this.locationsStore.retryFetchingLocations();
        await this.metersStore.retryFetchingMeterDevices();
    }

    private createLertaMeterPostBody(id: string, type: MeterType, locationId?: string, calibrationParameters?: CalibrationParameters) {
        return {
            id,
            type,
            locationId,
            ...(calibrationParameters || {})
        };
    }

    private createEMIPostBody(id: string, type: MeterType, locationId?: string, calibrationParameters?: CalibrationParameters) {
        const serialNumber = parseSerialNumber(id);
        const productNumber = parseProductNumber(id);

        return {
            serialNumber,
            productNumber,
            type,
            locationId,
            ...(calibrationParameters || {})
        };
    }
}