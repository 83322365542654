import * as React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { CircularProgress } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { UnreadNotificationsCountStore } from '../../../stores/UnreadNotificationsCountStore/UnreadNotificationsCountStore';
import ArrowBack from '../../../static/icons/arrow_back.svg'

export interface Button {
    Logo: React.ComponentClass<any>;
    onClick?: () => void;
    url?: string;
    loading?: boolean;
    disabled?: boolean;
}

export interface AppHeaderProps {
    children?: React.ReactNode;
    urlBack?: string;
    onGoBackClick?: () => void;
    additionalButtons?: Button[];
    unreadNotificationsCountStore?: UnreadNotificationsCountStore;
    hideBadge?: boolean;
}

export const AppHeader = inject("unreadNotificationsCountStore")(observer(({ urlBack, children, additionalButtons, onGoBackClick, unreadNotificationsCountStore, hideBadge }: AppHeaderProps) => (
    <div className="AppHeader-container">
        {urlBack && <Link to={urlBack} className="AppHeader-link"><ArrowBack className="AppHeader-logo" /></Link>}

        {onGoBackClick && <ArrowBack className="AppHeader-logo" onClick={onGoBackClick} />}
        <div className="AppHeader-text">{children}</div>
        {
            additionalButtons && additionalButtons.map(({ Logo, onClick, url, loading, disabled }, i) =>
                url ?
                    <Link key={i} className="AppHeader-link" to={url}><Logo className="AppHeader-logo" onClick={onClick} /></Link> :
                    loading ?
                        <CircularProgress size={25} key={i} className="Button-spinner" /> :
                        <Logo key={i} style={disabled ? { opacity: 0.5 } : {}} className="AppHeader-logo" onClick={disabled ? () => { } : onClick} />
            )
        }
    </div>
)));

