import * as React from 'react';
import { MenuContent } from "./MenuContent/MenuContent";
import { Fade } from '@material-ui/core';
import './styles.scss';

export interface MainMenuProps {
	menuState: boolean;
	onMenuChange(): void;
	logoutHandler(): void;
}

export const MainMenu = ({ menuState, logoutHandler, onMenuChange }: MainMenuProps) => {
	return (<Fade in={menuState} unmountOnExit timeout={{ enter: 150, exit: 150, appear: 150 }}>
		<div className="MainMenu-container">
			<MenuContent onLogout={logoutHandler} onClose={() => onMenuChange()} />
		</div>
	</Fade>)
};
