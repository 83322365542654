import { UrlProvider } from '../../services/UrlProvider';
import { computed, makeObservable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { HttpService } from '../../services/HttpService';
import { isFetching, fetched, getValue, fetchingFailed } from '../../utils/PromiseBasedObservable';

export interface AvailableFeatures {
    availableFeatures: string[];
}

export interface UserStore {
    login: {
        logout(): void;
    }
}

export class AvailableFeaturesStore {
    constructor(
        private httpService: HttpService,
        private urlProvider: UrlProvider,
        private userStore: UserStore
    ) {
        makeObservable(this, {
            promise: computed,
            availableFeaturesPromise: computed,
            availableFeatures: computed,
            fetchingAvailableFeatures: computed,
            didAvailableFeaturesFetch: computed,
            failedAvailableFeaturesFetch: computed
        });
    }

    get promise(): Promise<AvailableFeatures> {
        const url = this.urlProvider.getAvailableFeaturesUrl();
        return this.httpService.get(url);
    }

    get availableFeaturesPromise(): IPromiseBasedObservable<AvailableFeatures> {
        const promise = fromPromise<AvailableFeatures>(this.promise)
        promise.then((response) => response, err => {
            if (err.status === 403) {
                this.userStore.login.logout();
            }
            return Promise.reject()
        })
        return promise;
    }

    get availableFeatures(): AvailableFeatures | null {
        const availableFeaturesValue = getValue<AvailableFeatures>(this.availableFeaturesPromise);
        return availableFeaturesValue ? {
            ...availableFeaturesValue
        } : null;
    }

    get fetchingAvailableFeatures(): boolean {
        return isFetching(this.availableFeaturesPromise);
    }

    get didAvailableFeaturesFetch() {
        return fetched(this.availableFeaturesPromise)
    }

    get failedAvailableFeaturesFetch() {
        return fetchingFailed(this.availableFeaturesPromise)
    }


    hasAvailableFeature(availableFeature: string): boolean {
        return !!this.availableFeatures!.availableFeatures && this.availableFeatures!.availableFeatures.includes(availableFeature)
    }
}
