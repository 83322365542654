import * as React from 'react';
import { Link } from 'react-router-dom';
import { FullScreenContainer } from '../FullScreenContainer/FullScreenContainer';
import { ScanErrorContent } from '../ScanErrorContent';

export interface Props {
    closeUrl: string;
    wrongCode?: boolean;
    onQuestionMarkWasClicked?(): void;
    onSubtextWasClicked?(): void;
    scanner(onError: () => void): React.ReactNode;
}

export interface State {
    showError: boolean;
}

const Quit = require('../../static/icons/quit.svg');
const Question = require('../../static/icons/question_sign.svg');

export class FullScreenScanner extends React.Component<Props, State>  {

    constructor(props: Props) {
        super(props);
        this.state = {
            showError: false
        };
    }

    render() {
        const { onQuestionMarkWasClicked, onSubtextWasClicked, closeUrl, scanner, wrongCode } = this.props;
        const { showError } = this.state;

        if (showError) {
            return (
                <FullScreenContainer gradient>
                    <ScanErrorContent
                        onRetry={() => this.setState({ showError: false })}
                        onManualEnter={onSubtextWasClicked}
                    />
                </FullScreenContainer>
            );
        }

        return (
            <div className="FullScreenScanner-container">
                <div className="FullScreenScanner-topMenu">
                    {onQuestionMarkWasClicked && <span onClick={onQuestionMarkWasClicked}><Question /></span>}
                    <Link to={closeUrl}><Quit /></ Link>
                </div>
                {scanner(() => this.setState({ showError: true }))}
                {wrongCode && <div className="FullScreenScanner-wrongCodeMsg">NIEPOPRWANY KOD QR</div>}
                {onSubtextWasClicked && <div onClick={onSubtextWasClicked} className="FullScreenScanner-bottomLink">
                    JEŚLI CI SIĘ NIE UDA ZESKANOWAĆ KODU <br />
                    <span>WPISZ NUMER SERYJNY</span>
                </div>}
            </div>
        );
    }
}
