import * as React from 'react';
import './Circle.scss';
import classNames from 'classnames';

export interface Props {
    children?: React.ReactNode;
    className?: string;
}

export const Circle = ({children, className}: Props) => (
    <div className={classNames('Circle-container', className)}>
        {children}
    </div>
)
