import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import './styles.scss';

export interface Props {
    children?: React.ReactNode;
    error?: string;
    value?: boolean;
    required?: boolean;
    onChange(checked: boolean): void;
}

export const LabeledCheckbox = ({ children, onChange, error, required }: Props) => (
    <div className="LabeledCheckbox-container">
        <div className="LabeledCheckbox-wrapper">
            <Checkbox onChange={(e, checked) => onChange(checked)} className={`LabeledCheckbox-checkbox ${error ? "LabeledCheckbox-error" : ""}`} />
            <span className={`LabeledCheckbox-label ${error ? "LabeledCheckbox-error" : ""}`}>{children}{required && "*"}</span>
        </div>
        {error && <span className="LabeledCheckbox-error LabeledCheckbox-error-msg">{error}</span>}
    </div>
);
